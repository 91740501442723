import React, { useState } from "react";
import styled from "@emotion/styled";
import AutoSizer from "react-virtualized-auto-sizer";
import CardInnerWithTime from "../../../../CardInnerWithTime";
import {
  Hint,
  HorizontalBarSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
} from "react-vis";
import { formatNumber } from "../../../../../Utils/utils";
import TooltipItem from "../../../../TooltipItem";
import "../../../../../styles/ProjectsReviewed.css";

const LegendWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const CommonTabs = ({ data, timeStamp, title }) => {
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);

  let currentYear = new Date().getFullYear();
  let completeArray = [],
    inProgressArray = [],
    maxArray = [],
    maxValue = 15,
    xDomainTopValue;

  if (data?.length > 0) {
    for (let year = currentYear - 3; year <= currentYear; year++) {
      let yearData = data?.filter(
        (item) => Number(item.fyYearAssigned) === year
      );

      if (yearData.length > 0) {
        let completeData = yearData.filter(
          (item) => item.status === "Complete"
        )?.[0]?.count;
        let inProgressData = yearData.filter(
          (item) => item.status === "In Progress"
        )?.[0]?.count;

        if (completeData === undefined) completeData = 0;
        if (inProgressData === undefined) inProgressData = 0;

        completeArray.push({
          y: `FY${year.toString().slice(2)}`,
          x: completeData,
          color: "#0066a6",
        });

        inProgressArray.push({
          y: `FY${year.toString().slice(2)}`,
          x: inProgressData,
          color: "#ff8c00",
        });

        maxArray.push(completeData + inProgressData);
      }
    }
  }

  maxValue =
    Math.max(...maxArray) > maxValue ? 2 * Math.max(...maxArray) : maxValue;
  xDomainTopValue = [0, maxValue];

  return (
    <div className="CAPCommonTabs">
      <CardInnerWithTime
        loading={!data}
        title={title}
        refreshedTime={timeStamp ?? ""}
        mainComponent={() => (
          <>
            <AutoSizer disableHeight>
              {({ width }) => (
                <XYPlot
                  yType="ordinal"
                  colorType="literal"
                  stackBy="x"
                  width={width}
                  height={200}
                  xDomain={xDomainTopValue}
                  onMouseLeave={() => {
                    setHint(false);
                    setAnimation(true);
                  }}
                >
                  <VerticalGridLines />
                  <XAxis />
                  <YAxis />
                  <HorizontalBarSeries
                    barWidth={0.5}
                    data={completeArray}
                    animation={animation}
                    onValueMouseOver={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                    }}
                    onValueMouseOut={() => {
                      setHint(false);
                      setAnimation(true);
                    }}
                  />
                  <HorizontalBarSeries
                    barWidth={0.5}
                    data={inProgressArray}
                    animation={animation}
                    onValueMouseOver={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                    }}
                    onValueMouseOut={() => {
                      setHint(false);
                      setAnimation(true);
                    }}
                  />
                  {hint && (
                    <Hint value={hint}>
                      <div
                        className="rv-hint__content"
                        style={{ pointerEvents: "none" }}
                      >
                        <TooltipItem
                          fill={hint.color}
                          text={`${hint.y}: ${
                            hint.color === "#0066a6"
                              ? formatNumber(hint.x)
                              : formatNumber(hint.x - hint.x0)
                          }`}
                        />
                      </div>
                    </Hint>
                  )}
                </XYPlot>
              )}
            </AutoSizer>
            <LegendWrap>
              <TooltipItem fill="#0066a6" text="Complete" />
              <TooltipItem fill="#ff8c00" text="In Progress" />
            </LegendWrap>
          </>
        )}
      />
    </div>
  );
};

export default CommonTabs;
