function objectMap(object, mapFn) {
  return Object.keys(object).reduce(function (result, key) {
    result[key] = mapFn(object[key]);
    return result;
  }, {});
}

const breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

const sizes = {
  none: '0',
  px: '1px',
  '3xs': '.125rem',
  xxs: '.25rem',
  xs: '.5rem',
  sm: '.75rem',
  base: '1rem',
  md: '1.5rem',
  lg: '2.5rem',
  xl: '3rem',
};

const textSizes = {
  xs: '.75rem',
  sm: '.875rem',
  base: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.5rem',
  '3xl': '1.875rem',
  '4xl': '2.25rem',
  '5xl': '3rem',
  '6xl': '4rem',
};

const textWeight = {
  normal: '400',
  medium: '500',
  semibold: '600',
  bold: '700',
};

const widths = { '1/5': '20%' };

const colors = {
  white: 'hsl(0,0%,100%)',
  black: 'hsl(0, 0%, 0%)',
  skyBlue: {
    dark: {
      lightest: '#e6f0f6',
      lighter: '#bfd9e9',
      light: '#99c2db',
      lighten: '#4d94c1',
      base: '#0066a6',
      darken: '#005c95',
      dark: '#004d7d',
      darker: '#003d64',
      darkest: '#003251',
    },
  },
  woodlandGreen: {
    dark: {
      lightest: '#ebf0e9',
      lighter: '#ccd9c7',
      light: '#adc3a6',
      lighten: '#709563',
      base: '#326820',
      darken: '#2d5e1d',
      dark: '#264e18',
      darker: '#1e3e13',
      darkest: '#193310',
    },
  },
  clayRed: {
    dark: {
      lightest: '#f4ece8',
      lighter: '#e4d0c5',
      light: '#d3b3a1',
      lighten: '#b27a5b',
      base: '#914115',
      darken: '#833b13',
      dark: '#6d3110',
      darker: '#57270d',
      darkest: '#47200a',
    },
  },
  prairieGold: {
    dark: {
      lightest: '#f6f0ea',
      lighter: '#ead9c9',
      light: '#ddc2a9',
      lighten: '#c39569',
      base: '#a96728',
      darken: '#985d24',
      dark: '#7f4d1e',
      darker: '#653e18',
      darkest: '#533214',
    },
  },
  waterBlue: {
    dark: {
      lightest: '#e6edf5',
      lighter: '#bfd3e6',
      light: '#99b8d7',
      lighten: '#4d83b8',
      base: '#004e9a',
      darken: '#00468b',
      dark: '#003b74',
      darker: '#002f5c',
      darkest: '#00264b',
    },
  },
  slateGray: {
    dark: {
      lightest: '#ededed',
      lighter: '#d1d1d1',
      light: '#b5b5b5',
      lighten: '#7e7e7e',
      base: '#464646',
      darken: '#3f3f3f',
      dark: '#353535',
      darker: '#2a2a2a',
      darkest: '#222222',
    },
  },
  visDefaults: [
    'hsl(183, 79%, 34%)',
    'hsl(196, 65%, 68%)',
    'hsl(225, 64%, 28%)',
    'hsl(30, 100%, 60%)',
    'hsl(16, 86%, 55%)',
  ],
};

export const theme = {
  general: {
    breakpoints: objectMap(breakpoints, (val) => `@media (min-width: ${val})`),
    breakpointsSizes: objectMap(breakpoints, (val) => val.replace('px', '')),
    margin: objectMap(sizes, (val) => val),
    padding: objectMap(sizes, (val) => val),
    radius: '.25rem',
    text: {
      // eslint-disable-next-line max-len
      family:
        '"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      size: objectMap(textSizes, (val) => val),
      weight: objectMap(textWeight, (val) => val),
    },
    width: objectMap(
      {
        ...sizes,
        ...widths,
      },
      (val) => val
    ),
  },
  colors: {
    dark: {},
    light: {
      colors: {
        clayRed: { ...colors.clayRed.dark },
        prairieGold: { ...colors.prairieGold.dark },
        skyBlue: { ...colors.skyBlue.dark },
        slateGray: { ...colors.slateGray.dark },
        waterBlue: { ...colors.waterBlue.dark },
        woodlandGreen: { ...colors.woodlandGreen.dark },
        visDefaults: { ...colors.visDefaults },
      },
      card: {
        border: colors.slateGray.dark.lighter,
        background: colors.white,
        text: {
          base: colors.slateGray.dark.dark,
          subtle: colors.slateGray.dark.base,
          warning: 'hsl(0, 61%, 48%)',
        },
        well: { background: colors.slateGray.dark.lightest },
      },
      charts: [
        colors.clayRed.dark.base,
        colors.skyBlue.dark.base,
        colors.woodlandGreen.dark.base,
        colors.waterBlue.dark.base,
        colors.prairieGold.dark.base,
      ],
      buttons: {
        blue: {
          background: colors.skyBlue.dark.base,
          border: colors.skyBlue.dark.base,
          text: colors.white,
          icon: {
            primary: colors.skyBlue.dark.lightest,
            secondary: colors.skyBlue.dark.lighter,
          },
          hover: {
            icon: {
              primary: colors.skyBlue.dark.lightest,
              secondary: colors.skyBlue.dark.lighter,
            },
            background: colors.skyBlue.dark.darken,
            border: colors.skyBlue.dark.darken,
            text: colors.white,
          },
        },
        gray: {
          background: colors.slateGray.dark.base,
          border: colors.slateGray.dark.base,
          text: colors.slateGray.dark.lightest,
          icon: {
            primary: colors.slateGray.dark.lightest,
            secondary: colors.slateGray.dark.lighter,
          },
          hover: {
            icon: {
              primary: colors.slateGray.dark.lightest,
              secondary: colors.slateGray.dark.lighter,
            },
            background: colors.slateGray.dark.dark,
            border: colors.slateGray.dark.dark,
            text: colors.slateGray.dark.lightest,
          },
        },
        green: {
          background: colors.woodlandGreen.dark.base,
          border: colors.woodlandGreen.dark.base,
          text: colors.white,
          icon: {
            primary: colors.woodlandGreen.dark.lightest,
            secondary: colors.woodlandGreen.dark.lighter,
          },
          hover: {
            icon: {
              primary: colors.woodlandGreen.dark.lightest,
              secondary: colors.woodlandGreen.dark.lighter,
            },
            background: colors.woodlandGreen.dark.darken,
            border: colors.woodlandGreen.dark.darken,
            text: colors.white,
          },
        },
      },
      tag: {
        red: {
          background: 'hsl(0, 61%, 48%)',
          text: colors.white,
        },
        yellow: {
          background: 'hsl(51, 89%, 67%)',
          text: colors.black,
        },
      },
      header: { background: colors.white },
      footer: {
        background: colors.slateGray.dark.base,
        color: colors.white,
      },
      icon: {
        primary: colors.slateGray.dark.lighter,
        secondary: colors.slateGray.dark.lighter,
        lightbulb: 'hsl(51, 89%, 67%)',
        cardPrimary: colors.slateGray.dark.dark,
        cardSecondary: colors.slateGray.dark.base,
        noneStatusPrimary: 'hsl(150, 48%, 35%)',
        noneStatusSecondary: 'hsl(143, 55%, 62%)',
        minorStatusPrimary: 'hsl(181, 47%, 33%)',
        minorStatusSecondary: 'hsl(174, 59%, 56%)',
        moderateStatusPrimary: 'hsl(36, 71%, 42%)',
        moderateStatusSecondary: 'hsl(51, 89%, 67%)',
        seriousStatusPrimary: 'hsl(20, 71%, 44%)',
        seriousStatusSecondary: 'hsl(33, 90%, 65%)',
        criticalStatusPrimary: 'hsl(0, 61%, 48%)',
        criticalStatusSecondary: 'hsl(0, 95%, 75%)',
      },
      input: {
        border: colors.slateGray.dark.lighter,
        focus: colors.skyBlue.dark.darken,
      },
      main: {
        background: colors.slateGray.dark.lightest,
        link: colors.skyBlue.dark.base,
        linkHover: colors.skyBlue.dark.darken,
      },
      // eslint-disable-next-line max-len
      shadow: {
        lg: 'hsla(0, 0%, 0%, 0.1) 0px 10px 15px -3px, hsla(0, 0%, 0%, 0.05) 0px 4px 6px -2px;',
        inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);',
      },
      sidebar: {
        text: colors.slateGray.dark.lightest,
        background: colors.slateGray.dark.base,
        hover: colors.slateGray.dark.dark,
        visited: colors.skyBlue.dark.base,
      },
      table: {
        base: {
          border: colors.slateGray.dark.lighter,
          row: {
            base: {
              background: 'inherit',
              color: 'inherit',
            },
            alt: {
              background: colors.slateGray.dark.lightest,
              color: 'inherit',
            },
            heading: {
              background: colors.slateGray.dark.lightest,
              color: 'inherit',
            },
          },
        },
        green: {
          border: colors.woodlandGreen.dark.lighter,
          row: {
            base: {
              background: 'inherit',
              color: colors.woodlandGreen.dark.darkest,
            },
            alt: {
              background: colors.woodlandGreen.dark.lightest,
              color: colors.woodlandGreen.dark.darkest,
            },
            heading: {
              background: 'inherit',
              color: 'inherit',
            },
          },
        },
        blue: {
          border: colors.skyBlue.dark.lighter,
          row: {
            base: {
              background: 'inherit',
              color: colors.skyBlue.dark.darkest,
            },
            alt: {
              background: colors.skyBlue.dark.lightest,
              color: colors.skyBlue.dark.darkest,
            },
            heading: {
              background: 'inherit',
              color: 'inherit',
            },
          },
        },
      },
      grid: {
        background: colors.white,
        color: colors.slateGray.dark.darkest,
        striped: colors.slateGray.dark.lightest,
        hover: colors.slateGray.dark.lighter,
        border: colors.slateGray.dark.base,
        button: colors.slateGray.dark.lighten,
        buttonHover: colors.slateGray.dark.lighter,
        green: {
          background: 'rgb(134 239 172)',
          text: 'rgb(20 83 45)',
        },
        red: {
          background: 'rgb(252 165 165)',
          text: 'rgb(127 29 29)',
        },
        yellow: {
          background: 'rgb(253 224 71)',
          text: 'rgb(113 63 18)',
        },
      },
      alert: {
        gray: {
          background: colors.slateGray.dark.lightest,
          border: colors.slateGray.dark.darker,
          text: colors.slateGray.dark.darker,
          icon: {
            primary: colors.slateGray.dark.darker,
            secondary: colors.slateGray.dark.dark,
          },
        },
        blue: {
          background: colors.skyBlue.dark.lightest,
          border: colors.skyBlue.dark.darker,
          text: colors.skyBlue.dark.darker,
          icon: {
            primary: colors.skyBlue.dark.darker,
            secondary: colors.skyBlue.dark.dark,
          },
        },
        red: {
          background: colors.clayRed.dark.lightest,
          border: colors.clayRed.dark.darker,
          text: colors.clayRed.dark.darker,
          icon: {
            primary: colors.clayRed.dark.darker,
            secondary: colors.clayRed.dark.dark,
          },
        },
        green: {
          background: colors.woodlandGreen.dark.lightest,
          border: colors.woodlandGreen.dark.darker,
          text: colors.woodlandGreen.dark.darker,
          icon: {
            primary: colors.woodlandGreen.dark.darker,
            secondary: colors.woodlandGreen.dark.dark,
          },
        },
        yellow: {
          background: colors.prairieGold.dark.lightest,
          border: colors.prairieGold.dark.darker,
          text: colors.prairieGold.dark.darker,
          icon: {
            primary: colors.prairieGold.dark.darker,
            secondary: colors.prairieGold.dark.dark,
          },
        },
      },
    },
  },
};
