export const PIGPRIVATEVIEW = {
  PIGLEADERS: "pigleader",
  DIRECTOR: "director",
  ADMIN: "admin",
};
export const PIGEMPLOYEEVIEW = {
  PIGLEADERS: "pigleader",
  DIRECTOR: "director",
  ADMIN: "admin",
  PIGEMPLOYEE: "pigemployees"
};


export const CheckPrivateViewRole = (role) =>
{
  let boolIsPrivateView = false;
  if(Object.values(PIGPRIVATEVIEW).includes(role))
  {
    boolIsPrivateView = true;
  }
  return boolIsPrivateView
}
export const CheckEmployeeViewRole = (role) =>
{
  let boolIsEmployeeView = false;
  if(Object.values(PIGEMPLOYEEVIEW).includes(role))
  {
    boolIsEmployeeView = true;
  }
  return boolIsEmployeeView
}