import React from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";

const DivisionsItem = ({ path, heading, isMetricActive }) => {
  const theme = useTheme();
  const location = useLocation();

  const active = location.pathname.toLowerCase() === path.toLowerCase();

  const LiWrap = styled.li`
    border-radius: ${theme.radius};
    font-size: ${({ theme }) => theme.text.size.xs};
    line-height: ${({ theme }) => theme.text.size.xs};

    a,
    a:visited {
      color: ${theme.sidebar.text};
      display: grid;
      font-weight: ${theme.text.weight.medium};
      grid-gap: ${theme.margin.sm};
      justify-items: center;
      padding-left: ${theme.padding.md};
      text-decoration: none;

      > span {
        align-content: center;
        display: grid;
        grid-auto-flow: column;
        grid-gap: ${theme.margin.sm};
        justify-items: center;
        opacity: ${isMetricActive ? 1 : 0.2};
      }
    }

    &.active {
      a {
        color: #C7E4FF;
        font-weight: bold;
      }
    }
    ,
    &:hover {
      a:hover {
        color: ${theme.sidebar.text};
      }
    }

    ${theme.breakpoints.xl} {
      a {
        justify-items: start;
      }
    }
  `;

  return (
    <LiWrap className={active ? "active" : ""}>
      <Link to={path}>
        <span>
          <span>{heading}</span>
        </span>
      </Link>
    </LiWrap>
  );
};

DivisionsItem.propTypes = {
  path: PropTypes.string,
  icon: PropTypes.any,
  heading: PropTypes.string,
};

export default DivisionsItem;
