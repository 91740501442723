import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Wrap = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${(props) => props.theme.width.xs};
  padding: ${(props) => props.theme.width.xs} ${(props) => props.theme.width.sm};

  & > div {
    grid-column: 1/-1;
    justify-self: right;
  }

  ${(props) => props.theme.breakpoints.md} {
    grid-template-columns: auto 1fr auto 1fr;
  }
`;

const Item = ({ tag, data, wide }) => {
  if (wide) {
    return (
      <>
        <span style={{ gridColumn: '1' }}>
          <b>{tag}:</b>
        </span>
        <span style={{ gridColumn: '2/-1' }}>{data}</span>
      </>
    );
  }
  return (
    <>
      <span>
        <b>{tag}:</b>
      </span>
      <span>{data}</span>
    </>
  );
};

Item.propTypes = {
  tag: PropTypes.string,
  data: PropTypes.any,
  wide: PropTypes.bool,
};

Item.defaultProps = {
  tag: '',
  data: null,
  wide: false,
};

const ExpandedGridItem = ({ data, columns }) => (
  <>
    <Wrap>
      {columns.map((x, i) => (
        <Item key={i} tag={x.name} data={data[x.selector]} wide={x.wide} />
      ))}
    </Wrap>
  </>
);

ExpandedGridItem.propTypes = {
  data: PropTypes.any,
  columns: PropTypes.array,
};

ExpandedGridItem.defaultProps = {
  data: null,
  columns: null,
};

export default ExpandedGridItem;
