import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { faBars, faTimes } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";

import Navigation from './Navigation/Index';

import logo from '../Images/OMES-logo-horiz-white-RGB.png';

const Sidebar = ({ routes, authenticationState, isAuthenticatedUser }) => {
  const theme = useTheme();
  const [navOpen, setNavOpen] = useState(false);

  const SidebarWrap = styled.div`
    background-color: ${theme.sidebar.background};
    color: ${theme.sidebar.text};
    display: grid;
    padding: ${theme.padding.sm};

    ${theme.breakpoints.xl} {
      grid-template-rows: auto 1fr;
      position: sticky;
      top: 0;
      height: 100vh;
      overflow: auto;
    }

    ::-webkit-scrollbar {
      width: 0.5rem;
    }

    ::-webkit-scrollbar-thumb {
      background: #8c8c8c;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #838383;
    }

    ::-webkit-scrollbar-thumb:active {
      background: #686868;
    }
  `;

  const Toggle = styled.div`
    display: grid;
  `;

  const ImageWrap = styled.div`
    grid-template-columns: 1fr;
    justify-self: center;
    margin-bottom: ${theme.margin.md};

    img {
      width: 100%;
    }

    span {
      display: none;
      font-size: ${theme.text.size['2xl']};
      letter-spacing: 0.05em;
    }

    ${theme.breakpoints.xl} {
      align-items: center;
      display: grid;
      grid-template-columns: auto auto;

      img {
        margin-right: ${theme.margin.sm};
      }

      span {
        display: inline-block;
      }
    }
  `;

  const Burger = styled.span`
    position: absolute;
    top: ${theme.margin.sm};
    right: ${theme.margin.sm};
    cursor: pointer;

    ${theme.breakpoints.xl} {
      display: none;
    }
  `;

  return (
    <SidebarWrap>
      <Toggle>
        <Link to="/">
          <ImageWrap>
            <img
              src={logo}
              alt="Office of Management and Enterprise Services"
            />
          </ImageWrap>
        </Link>
        <Burger>
          <FontAwesomeIcon
            icon={navOpen ? faTimes : faBars}
            fixedWidth
            size="2x"
            onClick={() => setNavOpen(!navOpen)}
          />
        </Burger>
          </Toggle>
          <Navigation open={navOpen} authenticationState={authenticationState} isAuthenticatedUser={isAuthenticatedUser} />
    </SidebarWrap>
  );
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.any),
    authenticationState: PropTypes.string,
    isAuthenticatedUser: PropTypes.bool,
};

export default Sidebar;
