import React from "react";
import { TimeIntervals , fetcher} from "../../Utils/utils";
import SingleCard from "./SingleCard";
import useSWR from "swr";


const CyberOpsIncident = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/CyberOperationsClosed_Incidents.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );
  if (!data && !error && !error?.status) {
    return <>No data was found</>;
  }
  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }
  let isRender = data?.renderCard;
  return (
    <>
      {isRender && (
        <>
          <SingleCard
            title="Cyber Operation Closed/Resolved Incidents"
            data={data?.cyberOperationsClosedYesterday}
            which="provisioning_incidents"
          />
        </>
      )}
    </>
  );
  };

export default CyberOpsIncident;
