import React, { useEffect, useState } from "react";
import useSWR from "swr";
import Card from "../../Card";
import CardInnerWithTime from "../../CardInnerWithTime";
import {
  TimeIntervals,
  dateFormat,
  fetcherWithAuth,
  formatNumber,
  buildApiInternalUrl,
} from "../../../Utils/utils";
import { H1, H2, H3 } from "../../Headings";
import { useTheme } from "emotion-theming";
import TooltipItem from "../../TooltipItem";
import "../../../styles/CashReporting.css";
import "../../../styles/Common.css";
import {
  FlexibleWidthXYPlot,
  Hint,
  HorizontalGridLines,
  LineSeries,
  XAxis,
  YAxis,
} from "react-vis";
import {
  Tabs,
  TabList,
  TabPanelsEnhanced,
  TabPanel,
  TabEnhanced,
} from "../../Tabs";
import FundsTable from "./FundsTable";

const InnerComponent = ({ data, pdfVersion }) => {
  const theme = useTheme();
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);
  const [restrictedData, setRestrictedData] = useState([]);
  const [unrestrictedData, setUnrestrictedData] = useState([]);
  const [restrictedSum, setRestrictedSum] = useState(0);
  const [unrestrictedSum, setUnrestrictedSum] = useState(0);

  useEffect(() => {
    let restData = data?.cashReports.filter(
      (item) => item.fundRestriction === "Restricted"
    );
    let unrestData = data?.cashReports.filter(
      (item) => item.fundRestriction === "Unrestricted"
    );

    setRestrictedData(restData);
    setUnrestrictedData(unrestData);

    setRestrictedSum(
      restData?.reduce((acc, item) => acc + item?.cashBalance, 0)
    );
    setUnrestrictedSum(
      unrestData?.reduce((acc, item) => acc + item?.cashBalance, 0)
    );
  }, [data]);

  let yDomainTopValue = 0;
  let yDomainBottomValue = 0;
  let cashReportArray = [];
  let sortedArray = [];
  let startWeekDate, endWeekDate;

  if (data?.cashWeek) {
    data.cashWeek
      .sort((a, b) => new Date(a) - new Date(b))
      .forEach((ele) => {
        if (ele.date !== null) {
          cashReportArray.push({
            x: new Date(ele.date).toLocaleDateString(undefined, {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            y: ele.amount,
          });
          sortedArray.push({
            x: new Date(ele.date).toLocaleDateString(undefined, {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            y: ele.amount,
          });
        }
      });
  }

  const maxValue =
    data?.cashWeek?.length > 0 &&
    Math.max(...data?.cashWeek?.map((d) => d.amount));

  const minValue =
    data?.cashWeek?.length > 0 &&
    Math.min(...data?.cashWeek?.map((d) => d.amount));

  yDomainTopValue = maxValue * 1.00917;
  yDomainBottomValue = minValue / 1.00917;

  endWeekDate =
    data?.cashWeek?.length > 0 &&
    dateFormat(
      new Date(Math.max(...data?.cashWeek?.map((d) => new Date(d.date))))
    );

  startWeekDate =
    data?.cashWeek?.length > 0 &&
    dateFormat(
      new Date(Math.min(...data?.cashWeek?.map((d) => new Date(d.date))))
    );

  return (
    <>
      <H2>OMES Finance Updates</H2>
      <p>
        Week of: {startWeekDate} - {endWeekDate}
      </p>

      <Card className="CashReporting">
        <CardInnerWithTime
          loading={!data}
          title="Cash Reporting"
          subtitle={`As of ${endWeekDate}`}
          refreshedTime={data?.timeStamp ?? ""}
          mainComponent={() => (
            <>
              <div className="TopHeader">
                <div className="HeadCounts">
                  <H2 as="h3">Available Cash</H2>
                  <H1 as="span">
                    {formatNumber(
                      data?.totalCash.toFixed(2),
                      true,
                      false,
                      false,
                      true
                    )}
                  </H1>
                </div>
                <div className="Divider"></div>
                <div className="HeadCounts">
                  <H2 as="h3">Unrestricted Cash</H2>
                  <H1 as="span">
                    {formatNumber(
                      data?.unRestricted.toFixed(2),
                      true,
                      false,
                      false,
                      true
                    )}
                  </H1>
                </div>
                <div className="HeadCounts">
                  <H2 as="h3">Restricted Cash</H2>
                  <H1 as="span">
                    {formatNumber(
                      data?.restricted.toFixed(2),
                      true,
                      false,
                      false,
                      true
                    )}
                  </H1>
                </div>
              </div>

              {data?.keyNotes?.length > 0 && (
                <div>
                  <H3>Key Notes:</H3>
                  <ul>
                    <li>{data?.keyNotes?.[0]}</li>
                    <li>{data?.keyNotes?.[1]}</li>
                  </ul>
                </div>
              )}

              <div>
                <H3>Weekly Cash Balance Trend</H3>
                <FlexibleWidthXYPlot
                  height={200}
                  xType="ordinal"
                  margin={{
                    left: 90,
                  }}
                  yDomain={[yDomainBottomValue, yDomainTopValue]}
                  onMouseLeave={() => {
                    setHint(false);
                    setAnimation(true);
                  }}
                >
                  <HorizontalGridLines />
                  <XAxis />
                  <YAxis tickFormat={(d) => `$${formatNumber(d)}`} />

                  <LineSeries
                    data={cashReportArray}
                    animation={animation}
                    color={theme.colors.skyBlue.darken}
                    onNearestX={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                    }}
                  />
                  {hint && (
                    <Hint value={hint}>
                      <div
                        className="rv-hint__content"
                        style={{ pointerEvents: "none" }}
                      >
                        <TooltipItem
                          fill={`#005c95`}
                          text={`${hint.x}: ${formatNumber(
                            hint.y,
                            true,
                            false,
                            false,
                            true
                          )}`}
                        />
                      </div>
                    </Hint>
                  )}
                </FlexibleWidthXYPlot>
              </div>
            </>
          )}
        />
      </Card>

      {pdfVersion == null ? (
        <Tabs>
          <TabList>
            <TabEnhanced>Unrestricted Funds</TabEnhanced>
            <TabEnhanced>Restricted Funds</TabEnhanced>
          </TabList>
          <TabPanelsEnhanced>
            <TabPanel>
              <FundsTable
                heading="Unrestricted Funds"
                data={unrestrictedData}
                unrestrictedSum={unrestrictedSum}
                weekDate={endWeekDate}
                timeStamp={data?.timeStamp}
              />
            </TabPanel>
            <TabPanel>
              <FundsTable
                heading="Restricted Funds"
                data={restrictedData}
                restrictedSum={restrictedSum}
                weekDate={endWeekDate}
                timeStamp={data?.timeStamp}
              />
            </TabPanel>
          </TabPanelsEnhanced>
        </Tabs>
      ) : (
        <Card>
          <FundsTable
            heading="Unrestricted Funds"
            data={unrestrictedData}
            unrestrictedSum={unrestrictedSum}
            weekDate={endWeekDate}
          />{" "}
          <br></br>
          <br></br>
          <br></br>
          <FundsTable
            heading="Restricted Funds"
            data={restrictedData}
            restrictedSum={restrictedSum}
            weekDate={endWeekDate}
          />
        </Card>
      )}
    </>
  );
};

const CashReporting = ({ pdfVersion }) => {
  const { data, error } = useSWR(
    buildApiInternalUrl("cash-reporting"),
    fetcherWithAuth,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (error?.status === 401) {
    return <></>;
  }
  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} pdfVersion={pdfVersion} error={error} />;
};

export default CashReporting;
