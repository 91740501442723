import React from "react";
import styled from "@emotion/styled";
import Cell from "../AppCTable/Cell";
import { TableStyle } from "../BaseTable";
import "@reach/tooltip/styles.css";
import CardInnerWithTime from "../CardInnerWithTime";
import { formatNumber } from "../../Utils/utils";
import { H2 } from "../Headings";
import "../../styles/Common.css";

const TableWrap = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;
`;
const TableTitle = styled.tr`
  background-color: #005c95;
  color: #ffffff;
`;
const TitleButtonWrapp = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

const OMESGlanceTable = ({ agencyID, data }) => {
  let omesData = [];
  const found = data.omesGlance.omesAgency.some(
    (el) => el.agencyID === agencyID
  );
  var today = new Date();
  let currentYear = today.getFullYear();
  if (found) {
    let pastTotal = 0;
    let currentTotal = 0;
    let projectedTotal = 0;
    let sheetName = "";
    let allPastTotals = 0;
    let allCurrentTotals = 0;
    let allProjectedTotals = 0;
    let description = "";
    let agencyNameFromSheet = "";
    data.omesGlance.omesGlance.forEach((x, i) => {
      pastTotal = 0;
      currentTotal = 0;
      projectedTotal = 0;
      sheetName = x.name;
      switch (x.name) {
        case "Payroll-HR":
          sheetName = "Human Resources/Payroll";
          description =
            "FY24 projected billing is calculated on an annualized amount based on February 2023 invoices. Actual FY24 invoices are based on customer’s head count and other billable events as agreed upon at the time payroll is processed.";
          break;
        case "Rent":
          sheetName = "OMES Managed Building Rent";
          description =
            "July 1, 2023-September 30, 2023 agencies will be charges FY23 rental rates. Effective October 1, 2023, through the remainder of FY24, rental rates will be increased and capped at 20%.";
          break;
        case "Central Printing":
          sheetName = x.name;
          description =
            "FY24 spend projected at same rate and consumption as FY23 actuals. Rate remains the same; any change in spend is based on customer consumption.";
          break;
        case "Fleet":
          sheetName = "Fleet Management";
          description =
            "FY24 spend projected by annualizing amount on May 2023 invoice. Rate remains the same but includes variables such as insurance and fuel.";
          break;
        case "Interagency Mail":
          sheetName = x.name;
          description =
            "FY24 spend projected at same rate and consumption as FY23 actuals. Rate remains the same; any change in spend is based on customer consumption.";
          break;
        case "Risk":
          sheetName = x.name;
          description =
            "All premiums are estimates based off initial market indications and can fluctuate due to changes in exposure and loss development for the various programs. Program premiums referenced include: Property Insurance, Motor Vehicle Liability, Tort Liability, Public Officials/Employment Practices Liability. **Workers' Compensation coverage is not referenced as anticipated premiums are flat of expiring.";
          break;
        case "ABS":
          sheetName = "Agency Business Services";
          description =
            "FY24 spend is flat rate per Agreement for Shared Services, Appendix F.";
          break;
        default:
          break;
      }
      x.data.forEach((agency, index) => {
        if (agency.agencyID === agencyID) {
          pastTotal = agency.pastTotal;
          currentTotal = agency.currentTotal;
          projectedTotal = agency.projectedTotal;
          allPastTotals =
            allPastTotals +
            (isNaN(parseFloat(pastTotal)) ? 0 : parseFloat(pastTotal));
          allCurrentTotals =
            allCurrentTotals +
            (isNaN(parseFloat(currentTotal)) ? 0 : parseFloat(currentTotal));
          allProjectedTotals =
            allProjectedTotals +
            (isNaN(parseFloat(projectedTotal))
              ? 0
              : parseFloat(projectedTotal));
          agencyNameFromSheet = agency.agencyName;
          omesData.push({
            sheetName: sheetName,
            description: description,
            agencyNameFromSheet: agencyNameFromSheet,
            pastTotal: pastTotal,
            currentTotal: currentTotal,
            projectedTotal: projectedTotal,
          });
        }
      });
    });
    omesData.push({
      sheetName: "Total",
      pastTotal: allPastTotals,
      currentTotal: allCurrentTotals,
      projectedTotal: allProjectedTotals,
    });
  }
  return (
    <>
      {!found && (
        <>
          <TitleButtonWrapp>
            <H2 as="h3">OMES Shared Services at a Glance</H2>
          </TitleButtonWrapp>
          <p>
            Agency data to come for this tab – please check for ongoing updates
            to the PIG.
          </p>
        </>
      )}
      {found && (
        <CardInnerWithTime
          loading={!data}
          title=""
          refreshedTime={data?.timeStamp ?? ""}
          mainComponent={() => (
            <>
              <H2 as="h3" className="OMES-At-Glance">
                OMES Shared Services at a Glance
              </H2>
              <TableWrap>
                <TableStyle color="blue" wrapped>
                  <thead>
                    <TableTitle>
                      <Cell as="th" alignment="centre"></Cell>
                      {data?.settings?.showPreviousYear && (
                        <Cell as="th" alignment="centre">
                          FY{(data?.settings?.activeYear - 1) % 100}
                        </Cell>
                      )}
                      <Cell as="th" alignment="centre">
                        FY{data?.settings?.activeYear % 100}
                      </Cell>
                      {data?.settings?.showNextYear ? (
                        data?.settings?.showDraft ? (
                          <Cell as="th" alignment="centre">
                            {`FY${
                              (data?.settings?.activeYear + 1) % 100
                            } - Draft`}
                          </Cell>
                        ) : (
                          <Cell as="th" alignment="centre">
                            FY{(data?.settings?.activeYear + 1) % 100}
                          </Cell>
                        )
                      ) : null}
                    </TableTitle>
                  </thead>
                  <tbody>
                    {omesData?.map((eachTd, index) => (
                      <>
                        {index !== omesData.length - 1 &&
                          omesData[index]?.sheetName !==
                            omesData[index - 1]?.sheetName && (
                            <tr className="Light-Blue">
                              <Cell
                                as="td"
                                colSpan="4"
                                alignment="left"
                                key={index}
                              >
                                <b>{eachTd.sheetName + " | "}</b>{" "}
                                {eachTd.description}
                              </Cell>
                            </tr>
                          )}
                        <tr
                          className={
                            index === omesData.length - 1 ? "Total-Value" : ""
                          }
                        >
                          <Cell as="td" alignment="left" key={index}>
                            {index === omesData.length - 1 ? (
                              <b>{"Total"}</b>
                            ) : (
                              eachTd.agencyNameFromSheet
                            )}
                          </Cell>
                          {data?.settings?.showPreviousYear && (
                            <Cell as="td" alignment="right">
                              {formatNumber(
                                eachTd.pastTotal,
                                true,
                                true,
                                false,
                                true
                              )}
                            </Cell>
                          )}

                          <Cell as="td" alignment="right">
                            {formatNumber(
                              eachTd.currentTotal,
                              true,
                              true,
                              false,
                              true
                            )}
                          </Cell>
                          {data?.settings?.showNextYear && (
                            <Cell as="td" alignment="right">
                              {formatNumber(
                                eachTd.projectedTotal,
                                true,
                                true,
                                false,
                                true
                              )}
                            </Cell>
                          )}
                        </tr>
                      </>
                    ))}
                  </tbody>
                </TableStyle>
              </TableWrap>
            </>
          )}
        />
      )}
    </>
  );
};

export default OMESGlanceTable;
