import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import In from './In';

function ProtectedRoute({ children, adGroup,path, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => (
        <In
              which={adGroup}
              reqPath={path}
          yes={() => <>{children}</>}
          no={() => (
            <Redirect to={{ pathname: '/', state: { from: location } }} />
          )}
        />
      )}
    />
  );
}

ProtectedRoute.propTypes = {
  children: PropTypes.any,
  adGroup: PropTypes.string,
};

ProtectedRoute.defaultProps = {
  children: null,
  adGroup: null,
};

export default ProtectedRoute;
