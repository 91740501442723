import styled from '@emotion/styled';

export const TableStyle = styled.table`
  border-collapse: collapse;
  margin-bottom: ${({ theme }) => theme.margin.sm};
  width: ${({ wrapped }) => (wrapped ? '99%' : '100%')};

  &.striped {
    tr {
      &:nth-child(even) {
        background: ${({ theme, color = 'base' }) =>
          theme.table[color].row.alt.background};
        color: ${({ theme, color = 'base' }) =>
          theme.table[color].row.alt.color};
      }
    }
  }

  tr {
    &.alt {
      background: ${({ theme, color = 'base' }) =>
        theme.table[color].row.alt.background};
      color: ${({ theme, color = 'base' }) => theme.table[color].row.alt.color};
    }

    &.extra-padding {
      td,
      th {
        padding-top: ${({ theme }) => theme.padding.sm};
      }
    }

    th {
      font-weight: ${({ theme }) => theme.text.weight.bold};
      background: ${({ theme, color = 'base' }) =>
        theme.table[color].row.heading.background};
      color: ${({ theme, color = 'base' }) =>
        theme.table[color].row.heading.color};
      white-space: nowrap;
    }

    td,
    th {
      padding: ${({ theme }) => `${theme.padding.xs} ${theme.padding.sm}`};
      border: ${({ theme, color = 'base' }) =>
        `${theme.width.px} ${theme.table[color].border} solid`};

      ul {
        margin: 0;
        padding-left: ${({ theme }) => theme.padding.sm};
      }

      &.right {
        text-align: right;
      }

      &.left {
        text-align: left;
      }

      &.number {
        font-variant-numeric: tabular-nums;
      }

      &.wrapped {
        white-space: unset;
      }

      &.bottom-lined {
        border-bottom: ${({ theme, color = 'base' }) =>
          `${theme.width.px} ${theme.table[color].border} solid`};
      }

      &.top-lined {
        border-top: ${({ theme, color = 'base' }) =>
          `${theme.width.px} ${theme.table[color].border} solid`};
      }

      &.right-divide {
        border-right: ${({ theme }) =>
          `${theme.width.xxs} ${theme.card.background} solid`};
      }
    }
  }

  /* @media print { */

  /* } */
`;

export const getCellClass = (index, columns) => {
  const column = columns[index];
  let className = '';
  if (column.align === 'right') {
    className += column.align;
  }

  if (column.isNumber) {
    className += className !== '' ? ' number' : 'number';
  }

  if (column.wrapped) {
    className += className !== '' ? ' wrapped' : 'wrapped';
  }
  return className;
};
