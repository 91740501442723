/* eslint-disable react/display-name */
import React from 'react';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { BrowserRouter as Router } from 'react-router-dom';

import { authProvider } from './authProvider';
import InternalApp from './Components/InternalApp';
import useInterval from './Hooks/useInterval';
import ScrollToTop from './Pages/ScrollToTop'
import 'normalize.css';

const App = () => {
  const load = async () => {
    if (
      authProvider.authenticationState === AuthenticationState.Authenticated
    ) {
      await authProvider.getIdToken();
    }
  };

  useInterval(() => {
    load();
  }, 1800000);

  return (
    <Router>
      <ScrollToTop/>
      <AzureAD provider={authProvider}>
        {({ authenticationState }) => (
          <InternalApp authenticationState={authenticationState} />
        )}
      </AzureAD>
    </Router>
  );
};

export default App;
