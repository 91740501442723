import { authProvider } from "../authProvider";

const base = `${process.env.REACT_APP_API_BASE}/api/`;
const codeParam = `code=${process.env.REACT_APP_API_CODE}`;

const dataUrl = (area, name) => `${base}data${area}?${codeParam}&name=${name}`;

export const TimeIntervals = {
  FIVE_SECONDS: 5000,
  FIFTHTEEN_SECONDS: 15000,
  ONE_MINUTE: 60000,
  TEN_MINUTES: 600000,
  THIRTY_MINUTES: 1800000,
  TWELVE_HOURS: 43200000,
  ONE_DAY: 86400000,
};

export const buildTeamBurndownUrl = (team, agency = null) =>
  `${base}teamchart?${codeParam}&team=${team}${
    agency ? `&agency=${agency}` : ``
  }`;
export const buildApiDataUrl = (tag) => `${base}data?${codeParam}&tag=${tag}`;
export const buildApiGroupsUrl = (guid) =>
  `${base}groups/in/${guid}?${codeParam}`;
export const buildApiIncidentsUrl = () => `${base}Incidents?${codeParam}`;
export const buildApiImageUrl = (fileName) =>
  `${base}Image?${codeParam}&img=${fileName}`;
export const buildCmsUrl = (path) => `${base}cms/${path}`;

export const buildApiInUrl = (which) => `${base}in?${codeParam}&group=${which}`;
export const buildApiInternalUrl = (name) => dataUrl('internal', name);
export const buildApiDirectorUrl = (name) => dataUrl('director', name);
export const buildApiAdminUrl = (name) => dataUrl('admin', name);

export const getToken = () => sessionStorage.getItem('msal.idtoken');

export const DateShortString = (d) => new Date(d).toISOString().split('T')[0];
export const DateMonthDay = (d) => {
  const date = new Date(d);
  let month = `${date.getMonth() + 1}`;
  let day = `${date.getDate()}`;

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [month, day].join('/');
};

export const SecondsToHourMinuteSecond = (seconds) => {
  let result = '';

  const sec = Number(seconds);

  const h = Math.floor(sec / 3600);
  const m = Math.floor((sec % 3600) / 60);
  const s = Math.floor((sec % 3600) % 60);

  if (h > 0) {
    const verbiage = h === 1 ? 'hour' : 'hours';
    result = `${h} ${verbiage}`;
  }

  if (m > 0) {
    const verbiage = m === 1 ? 'minute' : 'minutes';
    result = `${result} ${m} ${verbiage}`;
  }

  if (s > 0) {
    const verbiage = s === 1 ? 'second' : 'seconds';
    result = `${result} ${s} ${verbiage}`;
  }

  return result;
};

export const formatNumber = (
  number,
  asCurrency = false,
  zeroAsBlank = false,
  negative = false,
  zeroAsHyphen = false
) => {
  if (number === null) return '';
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(number) || number === '') return '';
  if ((number === 0 || number === '0') && zeroAsBlank) return '';
  if ((number === 0 || number === "0" || number === null) && zeroAsHyphen) return "-";
  let options = {};
  if (asCurrency) {
    options = {
      style: 'currency',
      currency: 'USD',
    };
  }

  const formatter = new Intl.NumberFormat('en-US', options);
  return negative
    ? `- ${formatter.format(number)}`
    : `${formatter.format(number)}`;
};

const fetchWithOptions = async (url, options, asText = false, asBlob = false) => {
  const res = await fetch(url, options);
  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const msg = await res.text();
    const error = new Error(msg);
    // Attach extra info to the error object.
    error.status = res.status;
    throw error;
  }
  if (asText) {
    return res.text();
  }
  if (asBlob) {
    return res.blob()
  }
  return res.json();
};

export const fetcher = async (url) =>
  fetchWithOptions(url, {
    cache: 'no-store',
  });

  export const fetchPDF = async (url, asText = false, asBlob = false) => {
    return fetchWithOptions(
      url,
      {
        cache: 'no-store',
      },
      asText,
      asBlob
    );
  };

export const fetcherWithAuth = async (url, asText = false, asBlob = false) => {
  const token = await authProvider.getAccessToken();
  return fetchWithOptions(
    url,
    {
      cache: 'no-store',
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      },
    },
    asText,
    asBlob
  );
};

export const groupBy = (items, key) => {
  if (!items) return null;
  const d = items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );

  const v = Object.values(d);
  return Object.keys(d).map((x, i) => ({
    key: x,
    items: [...v[i]],
  }));
};

export const sum = (items, prop) =>
  items.reduce(function (a, b) {
    return a + b[prop] || 0;
  }, 0);

export const getCurrentFY = () => {
  var fiscalYear = "";
  var today = new Date();

  if (today.getMonth() + 1 <= 6) {
    fiscalYear = today.getFullYear();
  } else {
    fiscalYear = today.getFullYear() + 1;
  }
  return fiscalYear;
};

export const dateFormat = (date) => {
  return new Date(date).toLocaleDateString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export const dateFormatWithoutDay = (date) => {
  return new Date(date).toLocaleDateString(undefined, {
    year: "numeric",
    month: "2-digit",
  });
};

export const dateFormatWithoutYear = (date) => {
  return new Date(date).toLocaleDateString(undefined, {
    month: "2-digit",
    day: "2-digit",
  });
};

export const removePercentageSymbol = (val) => {
  if (val !== null) {
    return Number(val.substring(0, val.length - 1));
  }
};

export const monthsList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const monthsInShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const addTrailingZeroes = (val) => {
  let splitNumber = val.split(".");
  let decimalValue = splitNumber[1];

  if (decimalValue === undefined) {
    return val + ".00";
  } else if (decimalValue.length === 1) {
    return val + "0";
  } else {
    return val;
  }
};

export const formatNumData = ( num ) => {
  var str = num.toString().split('.');
  if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  if (str[1] && str[1].length >= 4) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }
  return str.join('.');
}

export const getMonthName = (monthNumber) => {
  if (monthNumber === 1) return "January";
  if (monthNumber === 2) return "February";
  if (monthNumber === 3) return "March";
  if (monthNumber === 4) return "April";
  if (monthNumber === 5) return "May";
  if (monthNumber === 6) return "June";
  if (monthNumber === 7) return "July";
  if (monthNumber === 8) return "August";
  if (monthNumber === 9) return "September";
  if (monthNumber === 10) return "October";
  if (monthNumber === 11) return "November";
  if (monthNumber === 12) return "December";
};

export const downloadExcelFile = (url, fileName) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};
