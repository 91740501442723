import React from 'react';

import { TimeIntervals, buildApiDirectorUrl } from '../../Utils/utils';

import AuthorizedCard from '../AuthorizedCard';
import Datagrid from '../Datagrid';
import { H2, H3 } from '../Headings';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '../Tabs';

const SpaceReduction = () => (
  <AuthorizedCard
    url={buildApiDirectorUrl('RealsSpaceReduction')}
    refreshInterval={TimeIntervals.ONE_DAY}
    innerComponent={({ data, error }) => {
      if (!data) return <></>;

      const nonSettingData = data.filter((x) => x.name !== 'Settings');

      return (
        <>
          <H2 as="h3">
            {data.filter((x) => x.name === 'Settings')[0].data[0]}
          </H2>
          <Tabs>
            <TabList>
              {nonSettingData.map((x, idx) => (
                <Tab key={idx}>{x.name}</Tab>
              ))}
            </TabList>
            <TabPanels>
              {nonSettingData.map((x, idx) => {
                const cols = x.headers.map((h, jdx) => ({
                  name: h,
                  sortable: true,
                  selector: `${jdx}`,
                }));
                return (
                  <TabPanel key={idx}>
                    <H3>{x.name}</H3>
                    <Datagrid columns={cols} data={x.data} expandable={false} />
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
        </>
      );
    }}
  />
);

export default SpaceReduction;
