import React from "react";
import useSWR from "swr";
import Card from "../../Card";
import CardInnerWithTime from "../../CardInnerWithTime";
import { TimeIntervals, fetcher } from "../../../Utils/utils";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { H1, H4 } from "../../Headings";
import ColumnSection from "../../ColumnSection";
import styled from "@emotion/styled";

const CardWrap = styled.div`
  margin-bottom: 1rem;
`;

const ActiveLitigations = () => {
  const { data } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/open-litigations.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  return (
    <>
      {data?.renderCard && (
        <>
          <p
            style={{
              fontStyle: "italic",
            }}
          >
            Note: Any General Counsel metric provided on PIG is an attorney work
            product and deemed confidential.
          </p>
          <ColumnSection cols={3}>
            <CardWrap>
              <Card>
                <CardInnerWithTime
                  loading={!data}
                  title="Active Litigations"
                  refreshedTime={data?.timeStamp ?? ""}
                  extraIcons={[
                    {
                      text: `These are cases that are either
pending before an Administrative Law
Judge or a District Court Judge.`,
                      icon: faInfoCircle,
                      style: {
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                        textAlign: "justify",
                      },
                    },
                  ]}
                  mainComponent={() => (
                    <H1 as="span">
                      {data?.openLitigations !== "" ? (
                        data?.openLitigations
                      ) : (
                        <H4>No data available at this time</H4>
                      )}
                    </H1>
                  )}
                />
              </Card>
            </CardWrap>
          </ColumnSection>
        </>
      )}
    </>
  );
};

export default ActiveLitigations;
