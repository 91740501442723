import React from "react";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import AvgDaysOrderToDeployment from "../Components/Cards/AvgDaysOrderToDeployment";
import AvgDaysOrderToDeploymentHistorical from "../Components/Cards/AvgDaysOrderToDeploymentHistorical";
import Microsoft365UsageChart from "../Components/Cards/Microsoft365UsageChart";
import ColumnSection from "../Components/ColumnSection";
import NewEstDeploymentsByDate from "../Components/Cards/NewEstDeploymentsByDate";
import { authProvider } from "../authProvider";
import EpmoCard from "../Components/Cards/EpmoCard";
import SectionHeading from "../Components/SectionHeading";
import ITOps from "../Components/Cards/ItOps";
import QAMetrics from "../Components/Cards/QAMetrics";
import Defense from "../Components/Cards/Defense";
import CyberCommandCyberOperations from "../Components/CyberCommandCyberOperations/Index";
import SecurityReviews from "../Components/SecurityReviews/Index";
import AverageSpeedToAnswer from "../Components/Cards/AverageSpeedToAnswer";
import AverageHandleTime from "../Components/Cards/AverageHandleTime";
import NetworkUptime from "../Components/Cards/NetworkUptime";
import StateWebsiteVisits from "../Components/Cards/StateWebsiteVisits";
import { CheckEmployeeViewRole } from "../Utils/Roles";
import FirstCallResolution from "../Components/Cards/FirstCallResolution";
import Epmo from "./Epmo";
import COOPScore from "../Components/Cards/COOPScore";
import DisasterRecovery from "../Components/Cards/DisasterRecovery";
import COBITMaturityTasksCompleted from "../Components/COBIT/COBITMaturityTasksCompleted";
import COBITMaturityTasksBreakdown from "../Components/COBIT/COBITMaturityTasksBreakdown";
import ServerData from "../Components/ServerData/ServerData";
import EndUserCasesByMonth from "./EndUserCasesByMonth";
import ProvisioningResolutionTime from "../Components/Cards/ProvisioningResolutionTime/ProvisioningResolutionTime";
import CustomerSatisfactionScore from "./CustomerSatisfactionScore";
import IndividualDeploymentTotals from "../Components/Cards/IndividualDeploymentTotals";
import ClosedCases from "../Components/Cards/ClosedCases";
import EndUserCasesByPercentage from "../Components/EndUserCasesByPercentage";
import ServiceDeskIntake from "../Components/Cards/ServiceDeskIntake";

const Index = ({ role }) => (
  <>
     <AzureAD provider={authProvider}>
      {({ authenticationState }) => {
        return (
          authenticationState === AuthenticationState.Authenticated &&
          CheckEmployeeViewRole(role) && <CustomerSatisfactionScore />
        );
      }}
    </AzureAD>
    
    <SectionHeading
      title={"Customer Success"}
      description="A firsthand look at the OMES Service Desk and our commitment, or Service Level Agreement (SLA), to you."
    />    
    <ColumnSection cols={1} colsAtMd={3}>
      <AverageSpeedToAnswer />
      <AverageHandleTime />
      <FirstCallResolution />
    </ColumnSection>

    <AzureAD provider={authProvider}>
      {({ authenticationState }) => {
        return (
          authenticationState === AuthenticationState.Authenticated &&
          CheckEmployeeViewRole(role) && (
            <>
              <ProvisioningResolutionTime />
              <EndUserCasesByMonth />
              <ColumnSection cols={2} colsAtMd={2}>
                <EndUserCasesByPercentage />
              </ColumnSection>
              <ColumnSection cols={2} colsAtMd={2}>
                <IndividualDeploymentTotals />
                <ClosedCases />
              </ColumnSection>
              <ColumnSection cols={2} colsAtMd={2}>
                <ServiceDeskIntake />
              </ColumnSection>  
            </>
          )
        );
      }}
    </AzureAD>

    <>
      <SectionHeading
        title="Network and Server Operations"
        description="Identifies the percentage of time the state network and server operations are available as well as our commitment, or Service Level Agreement (SLA), to provide service."
      />
      <ColumnSection cols={1} colsAtMd={3}>
        <NetworkUptime
          which="/OverallNetwork30Days.json"
          title={
            <>
              {" "}
              Overall Network Uptime
              <br />
              Past 30 days
            </>
          }
          goal="SLA Goal 99.9%"
        />
        <NetworkUptime
          which="/CoreNetwork30Days.json"
          title={
            <>
              Core Network Uptime
              <br />
              Past 30 days
            </>
          }
          goal="SLA Goal 99.9%"
        />
        <NetworkUptime
          which="/ServerUptime30Days.json"
          title={
            <>
              Server Uptime
              <br />
              Past 30 days
            </>
          }
          goal="SLA Goal 99%"
        />
        <NetworkUptime
          which="/OverallNetwork24Hours.json"
          title={
            <>
              Overall Network Uptime
              <br />
              Past 24 hours
            </>
          }
          goal="SLA Goal 99.9%"
        />
        <NetworkUptime
          which="/CoreNetwork24Hours.json"
          title={
            <>
              Core Network Uptime
              <br />
              Past 24 hours
            </>
          }
          goal="SLA Goal 99.9%"
        />
        <NetworkUptime
          which="/ServerUptime24Hours.json"
          title={
            <>
              Server Uptime
              <br />
              Past 24 hours
            </>
          }
          goal="SLA Goal 99%"
        />
      </ColumnSection>
      <ServerData role={role} />
      <DisasterRecovery />
    </>
    <AzureAD provider={authProvider}>
      {({ authenticationState }) => {
        return (
          authenticationState === AuthenticationState.Authenticated &&
          CheckEmployeeViewRole(role) && <QAMetrics />
        );
      }}
    </AzureAD>

    <ITOps />
    <COOPScore />
    <SectionHeading
      title="Cyber Command"
      description="Number of requests received over the past 30 days by Defense."
    />
    <ColumnSection cols={1} colsAtMd={2}>
      <Defense />
    </ColumnSection>
    <ColumnSection cols={1} colsAtMd={3}>
      <CyberCommandCyberOperations />
    </ColumnSection>
    <ColumnSection cols={1} colsAtMd={1}>
      <COBITMaturityTasksCompleted />
      <AzureAD provider={authProvider}>
        {({ authenticationState }) => {
          return (
            authenticationState === AuthenticationState.Authenticated &&
            CheckEmployeeViewRole(role) && <COBITMaturityTasksBreakdown />
          );
        }}
      </AzureAD>
    </ColumnSection>
    <ColumnSection cols={1} colsAtMd={1}>
      <SecurityReviews />
    </ColumnSection>

    {/* <SectionHeading
                title="NTT Key Metrics"
                desctiption="Showcase of NTT estimated computer deployments by date."
              />
              <ColumnSection cols={1}>
                <NewEstDeploymentsByDate />
                <AvgDaysOrderToDeployment />
                <AvgDaysOrderToDeploymentHistorical />

              </ColumnSection> */}
    <StateWebsiteVisits />
    <ColumnSection cols={1}>
      <Microsoft365UsageChart />
    </ColumnSection>
    <AzureAD provider={authProvider}>
      {({ authenticationState }) => {
        if (
          authenticationState === AuthenticationState.Authenticated &&
          CheckEmployeeViewRole(role)
        ) {
          return (
            <>
              <Epmo />
            </>
          );
        }
        return (
          <>
            <EpmoCard heading={"EPMO - Project Status Metrics"} />
          </>
        );
      }}
    </AzureAD>
  </>
);

export default Index;
