import React from "react";
import SingleCard from "../Components/SecurityReviews/SingleCard";
import {fetcher, TimeIntervals } from "../Utils/utils";
import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import ColumnSection from "../Components/ColumnSection";
import useSWR from "swr";

const SecurityThreatReport = () => {
  const theme = useTheme();

  const CardWrap = styled.div`
    display: grid;
    grid-template-columns: none;
    margin-top: 2rem;
    div {
      margin: 0.75rem;
    }
    ${theme.breakpoints.md} {
      grid-template-columns: 1fr 1fr;
    }
  `;
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/NetworkTraffic.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );
  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (error?.status === 401) {
    return <></>;
  }
  if(data?.renderCard !== undefined && !data.renderCard)
  {
    return <></>;
  }

  const inboundNetworkTrafficData = data?.SecurityThreatReport[0].Data;
  const inboundDroppedbySrcData = data?.SecurityThreatReport[1].Data;
  const outboundInternetData = data?.SecurityThreatReport[2].Data;
  const outboundbyLabelinationData = data?.SecurityThreatReport[3].Data;


  return (
          <ColumnSection cols={1}>
            <CardWrap>
              <SingleCard
                title="Inbound Network Traffic"
                data={inboundNetworkTrafficData}
                networkTrafficcolors={true}
              />
              <SingleCard
                title="Inbound Threat Traffic Dropped by Source"
                data={inboundDroppedbySrcData}
                networkTrafficcolors={true}
              />
            </CardWrap>

            <CardWrap>
              <SingleCard
                title="Outbound Internet Traffic by Category"
                data={outboundInternetData}
                networkTrafficcolors={true}
              />
              <SingleCard
                title="Outbound Internet Traffic by Destination"
                data={outboundbyLabelinationData}
                networkTrafficcolors={true}
                onlylowercase={true}
              />
            </CardWrap>
          </ColumnSection>
    
  );
};

export default SecurityThreatReport;
