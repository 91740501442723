import React from "react";
import TimeReporting from "../Components/TimeReporting/TimeReporting";
import ResourceReporting from "../Components/TimeReporting/ResourceReporting";

const TimeResourceReporting = () => {
  return (
    <div>
      <TimeReporting />
      <ResourceReporting />
    </div>
  );
};

export default TimeResourceReporting;
