import React, { useState } from "react";
import {
  FlexibleWidthXYPlot,
  VerticalBarSeries,
  XAxis,
  YAxis,
  Hint,
  LineSeries,
} from "react-vis";
import styled from "@emotion/styled";
import Card from "../Card";
import { TimeIntervals, fetcher } from "../../Utils/utils";
import TooltipItem from "../TooltipItem";
import useSWR from "swr";
import CardInnerWithTime from "../CardInnerWithTime";
import "../../styles/TechnologyStandards.css";

const LegendWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const PublishedStandards = () => {
  const [hint, setHint] = useState(false);
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/PublishedStandards.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );
  if (!data && !error && !error?.status) {
    return <>No data was found.</>;
  }
  if (data?.RenderCard !== undefined && !data.RenderCard) {
    return <></>;
  }

  let goalArray = [];
  for (let i = 0; i < data.Data.length; i++) {
    if (data.Data[i].Quarter !== "FY23 Q3") {
      goalArray.push(data.Data[i]);
    } else {
      break;
    }
  }

  return (
    <Card className="TechnologyStandards">
      <CardInnerWithTime
        loading={!data}
        title="Technology Standards"
        subtitle={
          <>
            Identifies frequency of publication for technology standards. View
            all published technology standards on the{" "}
            {
              <a
                href="https://oklahoma.gov/omes/services/information-services/policy-standards-publications.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                OMES policy, standards and publications webpage
              </a>
            }
            .
          </>
        }
        refreshedTime={data?.UpdatedDateTime ?? ""}
        mainComponent={() => (
          <>
            <div>
              <FlexibleWidthXYPlot
                height={200}
                xType="ordinal"
                onMouseLeave={() => {
                  setHint(false);
                }}
              >
                <XAxis tickFormat={(d) => data.Data[d].Quarter} />
                <YAxis />

                <VerticalBarSeries
                  color="#5b9bd5"
                  style={{ cursor: "pointer" }}
                  data={data.Data.map((x, i) => ({
                    x: i,
                    y: x.NumPublished + 1,
                  }))}
                  onValueMouseOver={(datapoint) => {
                    setHint(datapoint);
                  }}
                  onValueMouseOut={() => {
                    setHint(false);
                  }}
                />

                <VerticalBarSeries
                  color="#bfbfbf"
                  style={{ cursor: "pointer" }}
                  data={data.Data.map((x, i) => ({
                    x: i,
                    y: x.NumProjected + 1,
                  }))}
                  onValueMouseOver={(datapoint) => {
                    setHint(datapoint);
                  }}
                  onValueMouseOut={() => {
                    setHint(false);
                  }}
                />

                <LineSeries
                  color="#ed7d31"
                  data={goalArray.map((x, i) => ({
                    x: i,
                    y: x.Goal + 1,
                  }))}
                />

                {hint && (
                  <Hint value={hint}>
                    <div
                      className="rv-hint__content"
                      style={{ pointerEvents: "none" }}
                    >
                      <b>{data.Data[hint.x].Quarter}</b>
                      <br />
                      <TooltipItem
                        fill="#5b9bd5"
                        text={data.Data[hint.x].NumPublished}
                      />
                      <TooltipItem
                        fill="#bfbfbf"
                        text={data.Data[hint.x].NumProjected}
                      />
                      <TooltipItem
                        fill="#ed7d31"
                        text={data.Data[hint.x].Goal}
                      />
                    </div>
                  </Hint>
                )}
              </FlexibleWidthXYPlot>
            </div>
            <LegendWrap>
              <TooltipItem fill="#5b9bd5" text="Published" />
              <TooltipItem fill="#bfbfbf" text="Projected" />
              <TooltipItem fill="#ed7d31" text="Goal" />
            </LegendWrap>
          </>
        )}
      />
    </Card>
  );
};

export default PublishedStandards;
