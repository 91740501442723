import React, { useState, useEffect } from "react";
import useSWR from "swr";
import styled from "@emotion/styled";
import AutoSizer from "react-virtualized-auto-sizer";
import Card from "../Card";
import { TimeIntervals, fetcher, formatNumber } from "../../Utils/utils";
import CardInnerWithTime from "../CardInnerWithTime";
import TooltipItem from "../TooltipItem";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalBarSeries,
  Crosshair,
} from "react-vis";
import "../../styles/Common.css";

const LegendWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const COBITMaturityTasksCompleted = () => {
  const [hint, setHint] = useState(false);
  const [crosshairValues, setCrosshairValues] = useState([]);
  const [hirt, setHIRT] = useState({});
  const [defenseOperations, setDefenseOperations] = useState({});
  const [defenseEngineering, setDefenseEngineering] = useState({});
  const [cyberOperations, setCyberOperations] = useState({});

  const { data } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/cobit-public.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );
  useEffect(() => {
    if (data?.card1) {
      setHIRT(data?.card1?.filter((item) => item.tasks === "HIRT")[0]);
      setDefenseOperations(
        data?.card1?.filter((item) => item.tasks === "Defense Operations")[0]
      );
      setDefenseEngineering(
        data?.card1?.filter((item) => item.tasks === "Defense Engineering")[0]
      );
      setCyberOperations(
        data?.card1?.filter((item) => item.tasks === "Cyber Operations")[0]
      );
    }
  }, [data]);

  const calculateYAxisPercentage = (yData) => {
    return (yData * 100).toFixed(2);
  };
  let documentation = [
    {
      x: "HIRT",
      y: hirt.documentationComplete,
      color: "purple",
    },
    {
      x: "Defense Operations",
      y: defenseOperations.documentationComplete,
      color: "purple",
    },
    {
      x: "Defense Engineering",
      y: defenseEngineering.documentationComplete,
      color: "purple",
    },
    {
      x: "Cyber Operations",
      y: cyberOperations.documentationComplete,
      color: "purple",
    },
  ];

  let processOptimization = [
    {
      x: "HIRT",
      y: hirt.processOptimizationComplete,
      color: "green",
    },
    {
      x: "Defense Operations",
      y: 
        defenseOperations.processOptimizationComplete
      ,
      color: "green",
    },
    {
      x: "Defense Engineering",
      y: 
        defenseEngineering.processOptimizationComplete
      ,
      color: "green",
    },
    {
      x: "Cyber Operations",
      y: cyberOperations.processOptimizationComplete,
      color: "green",
    },
  ];

  let implementation = [
    {
      x: "HIRT",
      y: hirt.implementationComplete,
      color: "#ff8c00",
    },
    {
      x: "Defense Operations",
      y: defenseOperations.implementationComplete,
      color: "#ff8c00",
    },
    {
      x: "Defense Engineering",
      y: defenseEngineering.implementationComplete,
      color: "#ff8c00",
    },
    {
      x: "Cyber Operations",
      y: cyberOperations.implementationComplete,
      color: "#ff8c00",
    },
  ];

  return (
    <>
      {data?.renderCard && (
        <Card className="Card-Margin-Top">
          <CardInnerWithTime
            loading={!data}
            title="COBIT Maturity Task Completion Across Teams"
            refreshedTime={data?.timeStamp ?? ""}
            extraIcons={[
                {
                  text: `HIRT refers to the Hunting and Incident Response Team, which is 
responsible for proactively hunting for malicious cyber incidents. `,
                  icon: faInfoCircle,
                  style: {
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                    textAlign: "justify",
                  },
                },
              ]}
            mainComponent={() => (
              <>
                <div>
                  <AutoSizer disableHeight>
                    {({ width }) => (
                      <XYPlot
                        xType="ordinal"
                        colorType="literal"
                        width={width}
                        height={200}
                        onMouseLeave={() => {
                          setHint(false);
                          setCrosshairValues(false);
                        }}
                      >
                        <HorizontalGridLines />
                        <XAxis
                          style={{ fontSize: "11px", wordBreak: "break-all" }}
                        />
                        <YAxis tickFormat={(d) => formatNumber(d * 100) + "%"} />
                        <VerticalBarSeries
                          data={documentation}
                          barWidth={0.5}
                          onValueMouseOver={(datapoint) => {
                            setHint(datapoint);
                            setCrosshairValues([datapoint]);
                          }}
                        />
                        <VerticalBarSeries
                          data={processOptimization}
                          barWidth={0.5}
                          onValueMouseOver={(datapoint) => {
                            setHint(datapoint);
                            setCrosshairValues([datapoint]);
                          }}
                        />

                        <VerticalBarSeries
                          data={implementation}
                          barWidth={0.5}
                          onValueMouseOver={(datapoint) => {
                            setHint(datapoint);
                            setCrosshairValues([datapoint]);
                          }}
                        />

                        {crosshairValues && (
                          <Crosshair values={crosshairValues}>
                            <div className="ShowHint">
                              <h3>{hint.x}</h3>
                              <TooltipItem
                                fill={"purple"}
                                text={`Documentation: ${
                                 calculateYAxisPercentage(documentation.filter(
                                    (item) => item.x === hint.x
                                  )?.[0]?.y)
                                }%`}
                              />
                              <TooltipItem
                                fill={"green"}
                                text={`Process Optimization: ${
                                  calculateYAxisPercentage(processOptimization.filter(
                                    (item) => item.x === hint.x
                                  )?.[0]?.y)
                                }%`}
                              />
                              <TooltipItem
                                fill={"#ff8c00"}
                                text={`Implementation: ${
                                  calculateYAxisPercentage(implementation.filter(
                                    (item) => item.x === hint.x
                                  )?.[0]?.y)
                                }%`}
                              />
                            </div>
                          </Crosshair>
                        )}
                      </XYPlot>
                    )}
                  </AutoSizer>
                </div>

                <LegendWrap>
                  <TooltipItem fill="purple" text="Documentation" />
                  <TooltipItem fill="green" text="Process Optimization" />
                  <TooltipItem fill="#ff8c00" text="Implementation" />
                </LegendWrap>
              </>
            )}
          />
        </Card>
      )}
    </>
  );
};

export default COBITMaturityTasksCompleted;
