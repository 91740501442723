import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const FormatPercent = ({ value }) => (
  <NumberFormat
    value={value * 100}
    displayType="text"
    thousandSeparator
    decimalScale={2}
    fixedDecimalScale
    suffix=" %"
  />
);

FormatPercent.propTypes = { value: PropTypes.number };

FormatPercent.defaultProps = { value: 0 };

export default FormatPercent;
