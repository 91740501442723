import React from "react";
import styled from "@emotion/styled";
import "@reach/tooltip/styles.css";
import Datagrid from "../Datagrid";
import CardInnerWithTime from "../CardInnerWithTime";
import "../../styles/Common.css";
import { H2 } from "../Headings";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";

const Heading = styled.div`
  font-weight: bold;
`;

const GoogleSearchTable = ({
  responseData,
  mainTitle,
  subTitle,
  refreshedTime,
}) => {
  const columns = [
    {
      name: <Heading>Rank</Heading>,
      selector: (row) => row.rank,
      sortable: true,
      right: true,
    },
    {
      name: <Heading>Top Search Terms</Heading>,
      selector: (row) => (row.query !== null ? row.query : "-"),
      sortable: true,
      grow: 2,
      wrap: true,
      style: { textAlign: "left !important", textTransform: "capitalize" },
    },
    {
      name: <Heading>Clicks</Heading>,
      selector: (row) => (row.clicks !== null ? row.clicks : "-"),
      sortable: true,
      grow: 1,
      right: true,
    },
    {
      name: <Heading>Impressions</Heading>,
      selector: (row) => (row.impressions !== null ? row.impressions : "-"),
      sortable: true,
      grow: 1,
      right: true,
    },
  ];
  return (
    <>
      {responseData?.length > 0 ? (
        <CardInnerWithTime
          loading={!responseData}
          title={""}
          refreshedTime={refreshedTime ?? ""}
          extraIcons={[
            {
              text: `Agencies with ‘Oklahoma.gov’ or ‘Ok.gov’ domain names have eligible data to 
surface for search term metrics. This data is being pulled from Google Search Console.`,
              icon: faInfoCircle,
              style: {
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
                textAlign: "justify",
              },
            },
          ]}
          mainComponent={() => (
            <>
              <H2 as="h3" className="Main-Title">
                {mainTitle}
              </H2>
              <p className="Sub-Title">{subTitle}</p>
              <Datagrid
                columns={columns}
                data={responseData}
                expandable={false}
                defaultSortField=""
                filterPlaceHolder="Filter by Search Terms"
              />
            </>
          )}
        />
      ) : (
        <div>No data available</div>
      )}
    </>
  );
};

export default GoogleSearchTable;
