import React from "react";
import PropTypes from "prop-types";

import VisuallyHidden from "@reach/visually-hidden";
import { formatNumber } from "../../Utils/utils";
import { H3 } from "../Headings";
import Row from "./Row";
import Cell from "./Cell";
import styled from "@emotion/styled";

export const getTotal = (totals, year, which) =>
  totals.filter((x) => x.year === year && x.which === which)[0]?.total;

const ListText = (list, prop, asCurrency = false) => {
  const sorted = list?.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
  // eslint-disable-next-line no-nested-ternary
  return (sorted?.length ?? 1) <= 1
    ? formatNumber(sorted?.[0]?.[prop] ?? "", asCurrency, true)
    : prop === "total"
    ? formatNumber(
        sorted.reduce(function (a, b) {
          return a + b[prop];
        }, 0) ?? "",
        asCurrency,
        true
      )
    : `${formatNumber(
        sorted?.[0]?.[prop] ?? "",
        asCurrency,
        true
      )} - ${formatNumber(
        sorted?.[sorted?.length - 1]?.[prop] ?? "",
        asCurrency,
        true
      )}`;
};

const getYearData = (list, year) =>
  list.partNumbers[0].years.filter((x) => x.year === year)[0]?.data;

const getYearDataWhole = (list, year) => {
  const { partNumbers } = list;
  const returnSet = [];

  for (let index = 0; index < partNumbers.length; index += 1) {
    const element = partNumbers[index];
    const foundYear = element.years.filter((y) => y.year === year);
    if (foundYear.length > 0) {
      returnSet.push({
        ...foundYear[0].data,
        year,
        partNumber: element.partNumber,
      });
    }
  }
  if (returnSet.length > 0) {
    return returnSet;
  }
};

const InnerBodySection = ({
  header,
  data,
  settings,
  fullHeadingSupplied,
  totals,
  whichTotal,
}) => {
  const footerCellText = (key, which) =>
    formatNumber(getTotal(totals, key, which), true, true);

  const yoyEnabled = header !== "Appropriated and CARES Act Funding";

  const TableHeader = styled.div`
    thead tr:nth-child(2) th,
    tbody tr th {
      background-color: #005c95;
      color: #ffffff;
    }
  `;

  return (
    <>
      <TableHeader>
        <thead>
          <tr>
            <Cell as="th" colSpan="12" alignment="left">
              <H3>
                {header}
                {!fullHeadingSupplied && " Billed Service and Products"}
              </H3>
            </Cell>
          </tr>
          <tr>
            <Cell as="th">
              <VisuallyHidden>Service</VisuallyHidden>
            </Cell>
            {settings?.previousYearEnabled && (
              <Cell as="th" alignment="center" colSpan="3" bottomLined divider>
                {settings.shortPreviousString}
              </Cell>
            )}
            <Cell as="th" alignment="center" colSpan={yoyEnabled ? "4": "3"} bottomLined divider>
              {settings.shortActiveString}
            </Cell>
            {settings?.nextYearEnabled ? (
              settings?.draftEnabled ? (
                <Cell
                  as="th"
                  alignment="center"
                  colSpan={yoyEnabled ? "4": "3"}
                  bottomLined
                  divider
                >
                  {`${settings.shortNextString} - Draft`}
                </Cell>
              ) : (
                <Cell
                  as="th"
                  alignment="center"
                  colSpan="4"
                  bottomLined
                  divider
                >
                  {`${settings.shortNextString}`}
                </Cell>
              )
            ) : null}
          </tr>
        </thead>
        {data?.map((rate, rateIdx) => (
          <tbody key={rateIdx}>
            {rateIdx !== 0 ? (
              <tr className="extra-padding">
                <Cell as="th" alignment="left" colSpan="12">
                  {rate.rate}
                </Cell>
              </tr>
            ) : (
              <tr>
                <Cell as="th" alignment="left">
                  {rate.rate}
                </Cell>
                {settings?.previousYearEnabled && (
                  <>
                    <Cell as="th">Quantity</Cell>
                    <Cell as="th">Price</Cell>
                    <Cell as="th" divider>
                      Total
                    </Cell>
                  </>
                )}
                <Cell as="th">Quantity</Cell>
                <Cell as="th">Price</Cell>
                {yoyEnabled ? <Cell as="th">Total</Cell> : <Cell as="th" divider>Total</Cell>}
                {yoyEnabled && (
                  <Cell as="th" divider>
                    YOY Change
                  </Cell>
                )}
                {settings?.nextYearEnabled && (
                  <>
                    <Cell as="th">Quantity</Cell>
                    <Cell as="th">Price</Cell>
                    {yoyEnabled ? <Cell as="th">Total</Cell> : <Cell as="th" divider>Total</Cell>}
                    {yoyEnabled && (
                      <Cell as="th">YOY Change</Cell>
                    )}
                  </>
                )}
              </tr>
            )}

            {rate.parts.map((part, partIdx) => {
              let prev, prevTotal;
              let curr, currentTotal, currYoYChange;
              let next, nextTotal, nextYoYChange;
              if (!part.hasMultiplePartNumbers) {
                prev = getYearData(part, settings.shortPreviousYear);
                curr = getYearData(part, settings.shortActiveYear);
                next = getYearData(part, settings.shortNextYear);
                prevTotal = prev?.total;
                currentTotal = curr?.total;
                nextTotal = next?.total;
                currYoYChange = (
                  ((currentTotal - prevTotal) / prevTotal) *
                  100
                )?.toFixed(1);
                nextYoYChange = (
                  ((nextTotal - currentTotal) / currentTotal) *
                  100
                )?.toFixed(1);
                currYoYChange =
                  currentTotal > prevTotal
                    ? "+" + currYoYChange
                    : currYoYChange;
                nextYoYChange =
                  nextTotal > currentTotal
                    ? "+" + nextYoYChange
                    : nextYoYChange;
                currYoYChange = isNaN(currYoYChange) ? "-%" : currYoYChange;
                nextYoYChange = isNaN(nextYoYChange) ? "-%" : nextYoYChange;
                return (
                  <Row
                    key={partIdx}
                    idx={partIdx}
                    description={part.partDescription}
                    prevPrice={prev?.price}
                    prevQty={prev?.qty}
                    prevTotal={prev?.total}
                    currPrice={curr?.price}
                    currQty={curr?.qty}
                    currTotal={curr?.total}
                    {...(yoyEnabled && {
                      currYoY:
                        currYoYChange === "-%"
                          ? currYoYChange
                          : `${currYoYChange}%`,
                    })}
                    nextPrice={next?.price}
                    nextQty={next?.qty}
                    nextTotal={next?.total}
                    {...(yoyEnabled && {
                      nextYoY: 
                      nextYoYChange === "-%"
                        ? nextYoYChange
                        : `${nextYoYChange}%`
                    })}
                    settings={settings}
                  />
                );
              }
              prev = getYearDataWhole(part, settings.shortPreviousYear);
              curr = getYearDataWhole(part, settings.shortActiveYear);
              next = getYearDataWhole(part, settings.shortNextYear);
              prevTotal = ListText(prev, "total", true);
              currentTotal = ListText(curr, "total", true);
              nextTotal = ListText(next, "total", true);
              currYoYChange = (
                ((currentTotal - prevTotal) / prevTotal) *
                100
              )?.toFixed(1);
              nextYoYChange = (
                ((nextTotal - currentTotal) / currentTotal) *
                100
              )?.toFixed(1);
              currYoYChange =
                currentTotal > prevTotal ? "+" + currYoYChange : currYoYChange;
              nextYoYChange =
                nextTotal > currentTotal ? "+" + nextYoYChange : nextYoYChange;
              currYoYChange = isNaN(currYoYChange) ? "-%" : currYoYChange;
              nextYoYChange = isNaN(nextYoYChange) ? "-%" : nextYoYChange;
              return (
                <Row
                  key={partIdx}
                  idx={partIdx}
                  description={part.partDescription}
                  prevPrice={ListText(prev, "price", true)}
                  prevQty={ListText(prev, "qty")}
                  prevTotal={ListText(prev, "total", true)}
                  currPrice={ListText(curr, "price", true)}
                  currQty={ListText(curr, "qty")}
                  currTotal={ListText(curr, "total", true)}
                  {...(yoyEnabled && {
                    currYoY:
                    currYoYChange === "-%" ? currYoYChange : `${currYoYChange}%`
                  })}
                  nextPrice={ListText(next, "price", true)}
                  nextQty={ListText(next, "qty")}
                  nextTotal={ListText(next, "total", true)}
                  {...(yoyEnabled && {
                    nextYoY:
                    nextYoYChange === "-%" ? nextYoYChange : `${nextYoYChange}%`
                  })}
                  prevData={prev}
                  currData={curr}
                  nextData={next}
                  skipFormat
                  withExpansion
                  settings={settings}
                />
              );
            })}
          </tbody>
        ))}
        <tbody>
          <tr>
            <Cell as="th" colSpan="3">
              {header === "Monthly" || header === "Annual" ? "" : "Annual"}{" "}
              {header} Total
            </Cell>
            {settings?.previousYearEnabled && (
              <Cell as="th">
                {footerCellText(settings.shortPreviousYear, whichTotal)}
              </Cell>
            )}
            <Cell as="th" colSpan="3">
              {footerCellText(settings.shortActiveYear, whichTotal)}
            </Cell>
            <Cell as="th"></Cell>
            {settings?.nextYearEnabled && (
              <>
                <Cell as="th" colSpan="3">
                  {footerCellText(settings.shortNextYear, whichTotal)}
                </Cell>
                {yoyEnabled && <Cell as="th"></Cell>}
              </>
            )}
          </tr>
        </tbody>
        {header === "Monthly" && (
          <tbody>
            <tr>
              <Cell as="th" colSpan="3">
                Annual Total
              </Cell>
              {settings?.previousYearEnabled && (
                <Cell as="th">
                  {footerCellText(settings.shortPreviousYear, "monthlyAnnual")}
                </Cell>
              )}
              <Cell as="th" colSpan="3">
                {footerCellText(settings.shortActiveYear, "monthlyAnnual")}
              </Cell>
              <Cell as="th"></Cell>
              {settings?.nextYearEnabled && (
                <>
                  <Cell as="th" colSpan="3">
                    {footerCellText(settings.shortNextYear, "monthlyAnnual")}
                  </Cell>
                  <Cell as="th"></Cell>
                </>
              )}
            </tr>
          </tbody>
        )}
      </TableHeader>
    </>
  );
};

InnerBodySection.propTypes = {
  header: PropTypes.string,
  data: PropTypes.any,
  settings: PropTypes.any,
  fullHeadingSupplied: PropTypes.bool,
  totals: PropTypes.any,
  whichTotal: PropTypes.string,
};

InnerBodySection.defaultProps = {
  header: "",
  data: null,
  settings: null,
  fullHeadingSupplied: false,
  totals: null,
  whichTotal: "",
};

export default InnerBodySection;
