import React, { useState } from 'react';
import styled from '@emotion/styled';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import '@reach/dialog/styles.css';
import {
  faBrowser,
  faBuilding,
  faCommentAltExclamation,
  faTimes,
  faUserHeadset,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VisuallyHidden from '@reach/visually-hidden';
import Button from './Button';
import ColumnSection from './ColumnSection';
import Alert from './Alert';
import { TEXTS } from '../Utils/staticText';

const OverLay = styled(DialogOverlay)`
  background-color: hsla(0, 0%, 92.9%, 0.9);
`;

const Dialog = styled(DialogContent)`
  --fa-primary-color: ${({ theme }) => theme.icon.cardPrimary};
  --fa-secondary-color: ${({ theme }) => theme.icon.cardSecondary};
  background-color: ${({ theme }) => theme.card.background};
  border: solid ${({ theme }) => `${theme.width.px} ${theme.card.border}`};
  border-radius: ${({ theme }) => theme.radius};
  box-shadow: ${({ theme }) => theme.shadow.lg};
  color: ${({ theme }) => theme.card.text.base};
  padding: ${({ theme }) => `${theme.padding.sm} ${theme.padding.md}`};
  overflow: hidden;
  position: relative;
  font-family: ${({ theme }) => theme.text.family};
  font-size: ${({ theme }) => theme.text.size.base};
  font-weight: ${({ theme }) => theme.text.weight.normals};
  line-height: ${({ theme }) => theme.text.size.xl};
  width: 95vw;

  ${({ theme }) => theme.breakpoints.md} {
    width: 75vw;
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding-bottom: ${({ theme }) => theme.width.md};
`;

// eslint-disable-next-line react/prop-types
const ContactType = ({ icon, title, children }) => (
  <Alert>
    <FontAwesomeIcon
      fixedWidth
      icon={icon}
      size="3x"
      style={{ float: 'left' }}
    />
    <strong>{title}</strong>
    <br />
    {children}
  </Alert>
);

// eslint-disable-next-line react/prop-types
const HelpType = ({ color, gotoState, changeStep, children }) => (
  <Alert
    color={color}
    onClick={() => changeStep(gotoState)}
    as="button"
    style={{ cursor: 'pointer' }}
  >
    <div style={{ height: '100%' }}>{children}</div>
  </Alert>
);

// eslint-disable-next-line react/prop-types
const MoreHelpButton = ({ onClick }) => (
  <Button size="small" onClick={onClick} style={{ float: 'right' }}>
    Need Further Assistance?
  </Button>
);

const stepRender = (step, changeStep) => {
  switch (step) {
    case 'HOW':
      return (
        <>
          <ColumnSection cols={2}>
            <ContactType icon={faUserHeadset} title="Email or Phone Support">
              <span>
                Local: <a href="tel:405-521-2444">405-521-2444</a>
              </span>
              <br />
              <span>
                Toll-Free: <a href="tel:866-521-2444">866-521-2444</a>
              </span>
              <br />
              <span>
                Email:{' '}
                <a href="mailto:ServiceDesk@omes.ok.gov?subject=Service Request">
                  ServiceDesk@omes.ok.gov
                </a>
              </span>
            </ContactType>

            <ContactType icon={faCommentAltExclamation} title="Chat Support">
              <span>
                Visit{' '}
                <a
                  href="https://servicedesk.ok.gov"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  servicedesk.ok.gov
                </a>
              </span>
              <br />
              <span>
                Click on live chat and type{' '}
                <strong>speak with a live agent</strong> to start a session.
              </span>
            </ContactType>

            <ContactType icon={faBrowser} title="Support Portal">
              <span>
                Visit{' '}
                <a
                  href="https://servicedesk.ok.gov"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  servicedesk.ok.gov
                </a>{' '}
                where you can request service, report issues, review FAQs and
                more.
              </span>
            </ContactType>

            <ContactType icon={faBuilding} title="In Person Support">
              <span>
                Visit the service desk in person. Go to contact us at{' '}
                <a
                  href="https://servicedesk.ok.gov"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  servicedesk.ok.gov
                </a>{' '}
                to find available locations.
              </span>
            </ContactType>
          </ColumnSection>
          <MoreHelpButton onClick={() => changeStep('WHAT_KIND')} />
        </>
      );
    case 'WHAT_KIND':
      return (
        <>
          <ColumnSection cols={1} colsAtMd={2} colsAtLg={3}>
            <HelpType color="red" gotoState="SECURITY" changeStep={changeStep}>
              <h4>Security</h4>
              <p>Safeguarding and protecting state data and applications.</p>
              <ul>
                <li>Compliance</li>
                <li>Cyber Command</li>
                <li>Cyber Defense</li>
                <li>Incident Response</li>
                <li>PC Engineering</li>
              </ul>
            </HelpType>
            <HelpType
              color="green"
              gotoState="COMPUTERS"
              changeStep={changeStep}
            >
              <h4>Computers</h4>
              <p>Support personal computers, laptops, devices and software</p>
              <ul>
                <li>Ordering</li>
                <li>installation</li>
                <li>Troubleshooting</li>
                <li>Lifecycle management</li>
              </ul>
            </HelpType>
            <HelpType
              color="green"
              gotoState="INFRASTRUCTURE"
              changeStep={changeStep}
            >
              <h4>Infrastructure</h4>
              <p>
                Build and support infrastructure for state data and
                applications.
              </p>
              <ul>
                <li>Network Connectivity</li>
                <li>Server & Storage</li>
                <li>Change Management</li>
                <li>Diaster Recovery & Business Continuity</li>
              </ul>
            </HelpType>
            <HelpType
              color="yellow"
              gotoState="APPLICATIONS"
              changeStep={changeStep}
            >
              <h4>Applications</h4>
              <p>Build, maintain and support applications and web services.</p>
              <ul>
                <li>Citizen Experience & Web Services</li>
                <li>Custom, Enterprise & Legacy Applications</li>
                <li>Maintenance</li>
              </ul>
            </HelpType>
            <HelpType color="yellow" gotoState="DATA" changeStep={changeStep}>
              <h4>Data</h4>
              <p>Support data integrations and data standards</p>
              <ul>
                <li>Governance</li>
                <li>Data Standards</li>
                <li>Data Sharing & Exchange</li>
              </ul>
            </HelpType>
            <HelpType
              color="blue"
              gotoState="ADMINISTRATION"
              changeStep={changeStep}
            >
              <h4>Administration</h4>
              <p>Support administrative tasks for delivery of tech support.</p>
              <ul>
                <li>Outreach</li>
                <li>Training</li>
                <li>Legal Services</li>
                <li>Financial Services</li>
              </ul>
            </HelpType>
          </ColumnSection>
        </>
      );
    case 'SECURITY':
      return (
        <>
          <Alert color="red">
            <strong>Security TAM</strong>
            <br />
            Toshawnya Clay <br />
            <a href="mailto:toshawnya.clay@omes.ok.gov">
              toshawnya.clay@omes.ok.gov
            </a>
            <br />
            <a href="tel:405-522-0179">405-522-0179</a>
          </Alert>
          <MoreHelpButton onClick={() => changeStep('OFFICER_SECURITY')} />
        </>
      );
    case 'COMPUTERS':
      return (
        <>
          <Alert color="green">
            <strong>Computer TAM</strong>
            <br />
            Sherri Henderson <br />
            <a href="mailto:sherri.henderson@omes.ok.gov">
              sherri.henderson@omes.ok.gov
            </a>
            <br />
            <a href="tel:405-664-9725">405-664-9725</a>
          </Alert>
          <MoreHelpButton onClick={() => changeStep('OFFICER_OPERATIONS')} />
        </>
      );
    case 'INFRASTRUCTURE':
      return (
        <>
          <Alert color="green">
            <strong>Infrastructure TAM</strong>
            <br />
            Judy Jackson <br />
            <a href="mailto:judy.jackson@omes.ok.gov">
              judy.jackson@omes.ok.gov
            </a>
            <br />
            <a href="tel:405-249-2858">405-249-2858</a>
            <hr />
            Sherri Henderson <br />
            <a href="mailto:sherri.henderson@omes.ok.gov">
              sherri.henderson@omes.ok.gov
            </a>
            <br />
            <a href="tel:405-664-9725">405-664-9725</a>
          </Alert>
          <MoreHelpButton onClick={() => changeStep('OFFICER_OPERATIONS')} />
        </>
      );
    case 'APPLICATIONS':
      return (
        <>
          <Alert color="yellow">
            <strong>Applications TAM</strong>
            <br />
            Abdul Bashroun <br />
            <a href="mailto:abdulhameed.bashorun@omes.ok.gov">
              abdulhameed.bashorun@omes.ok.gov
            </a>
            <br />
            <a href="tel:405-213-9805">405-213-9805</a>
            <hr />
            Deann Romine <br />
            <a href="mailto:deann.romine@omes.ok.gov">
              deann.romine@omes.ok.gov
            </a>
            <br />
            <a href="tel:405-365-3578">405-365-3578</a>
          </Alert>
          <MoreHelpButton onClick={() => changeStep('OFFICER_APPS')} />
        </>
      );
    case 'DATA':
      return (
        <>
          <Alert color="yellow">
            <strong>Data TAM</strong>
            <br />
            Abdul Bashroun <br />
            <a href="mailto:abdulhameed.bashorun@omes.ok.gov">
              abdulhameed.bashorun@omes.ok.gov
            </a>
            <br />
            <a href="tel:405-213-9805">405-213-9805</a>
            <hr />
            Deann Romine <br />
            <a href="mailto:deann.romine@omes.ok.gov">
              deann.romine@omes.ok.gov
            </a>
            <br />
            <a href="tel:405-365-3578">405-365-3578</a>
          </Alert>
          <MoreHelpButton onClick={() => changeStep('OFFICER_APPS')} />
        </>
      );
    case 'ADMINISTRATION':
      return (
        <>
          <Alert color="blue">
            <strong>Administration TAM</strong>
            <br />
            Linda Haley <br />
            <a href="mailto:linda.haley@omes.ok.gov">linda.haley@omes.ok.gov</a>
            <br />
            <a href="tel:405-740-6709">405-740-6709</a>
          </Alert>
          <MoreHelpButton onClick={() => changeStep('OFFICER_ADMIN')} />
        </>
      );
    case 'OFFICER_SECURITY':
      return (
        <>
          <Alert color="red">
            <strong>Chief Information Security Officer</strong>
            <br />
            Matt Singleton <br />
            <a href="mailto:matt.singleton@omes.ok.gov">
              matt.singleton@omes.ok.gov
            </a>
            <br />
            <a href="tel:405-521-4804">405-521-4804</a>
          </Alert>
          <MoreHelpButton onClick={() => changeStep('CIO')} />
        </>
      );
    case 'OFFICER_APPS':
      return (
        <>
          <Alert color="yellow">
            <strong>Application & Data Services Senior Director</strong>
            <br />
            Joe McIntosh
            <br />
            <a href="mailto:joe.mcintosh@omes.ok.gov">
              joe.mcintosh@omes.ok.gov
            </a>
          </Alert>
          <MoreHelpButton onClick={() => changeStep('CIO')} />
        </>
      );
    case 'OFFICER_OPERATIONS':
      return (
        <>
          <Alert color="green">
            <strong>IT Operations Senior Director</strong>
            <br />
            Rich Ropac <br />
            <a href="mailto:richard.ropac@omes.ok.gov">
              richard.ropac@omes.ok.gov
            </a>
            <br />
            <a href="tel:210-248-8694">210-248-8694</a>
          </Alert>
          <MoreHelpButton onClick={() => changeStep('CIO')} />
        </>
      );
    case 'OFFICER_ADMIN':
      return (
        <>
          <Alert color="blue">
            <strong>Chief Administrative Office</strong>
            <br />
            Lauren Kelliher <br />
            <a href="mailto:lauren.kelliher@omes.ok.gov">
              lauren.kelliher@omes.ok.gov
            </a>
            <br />
            <a href="tel:405-522-8085">405-522-8085</a>
          </Alert>
          <MoreHelpButton onClick={() => changeStep('CIO')} />
        </>
      );
    case 'CIO':
      return (
        <>
          <Alert color="gray">
            <h4>{TEXTS.STATE_TITLE} {TEXTS.NAME}</h4>
            Office: <a href="tel:405-522-5828">405-522-5828</a>
            <br />
            Cell: <a href="tel:405-625-4853">405-625-4853</a>
            <br />
            <a href="mailto:jerry.moore@health.ok.gov">
              jerry.moore@health.ok.gov
            </a>
          </Alert>
        </>
      );

    default:
      break;
  }
};

const NeedHelp = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [step, setStep] = useState('HOW');
  const open = () => setShowDialog(true);
  const close = () => {
    setShowDialog(false);
    setStep('HOW');
  };
  return (
    <>
      <Button color="blue" onClick={() => open()}>
        <FontAwesomeIcon fixedWidth icon={faUserHeadset} /> Need Assistance?
      </Button>
      <OverLay isOpen={showDialog} onDismiss={close}>
        <Dialog aria-labelledby="need-help-dialog">
          <Header>
            <span id="need-help-dialog">
              {step === 'HOW' && (
                <strong>How would you like to get assistance?</strong>
              )}
              {step === 'WHAT_KIND' && (
                <strong>What do you need assistance with?</strong>
              )}
              {[
                'SECURITY',
                'COMPUTERS',
                'INFRASTRUCTURE',
                'APPLICATIONS',
                'DATA',
                'ADMINISTRATION',
              ].includes(step) && (
                <strong> Please reach out to the TAM.</strong>
              )}
              {[
                'OFFICER_SECURITY',
                'OFFICER_APPS',
                'OFFICER_OPERATIONS',
                'OFFICER_ADMIN',
              ].includes(step) && (
                <strong>
                  {' '}
                  Please reach out to the Chief Officer or Director.
                </strong>
              )}
              {step === 'CIO' && (
                <strong>
                  Please contact the State Chief Information Officer
                </strong>
              )}
            </span>
            <button
              type="button"
              style={{
                outline: 'none',
                backgroundColor: 'transparent',
                border: 'none',
                padding: '0',
                cursor: 'pointer',
              }}
              onClick={() => close()}
            >
              <FontAwesomeIcon icon={faTimes} />
              <VisuallyHidden>Close</VisuallyHidden>
            </button>
          </Header>
          {stepRender(step, setStep)}
        </Dialog>
      </OverLay>
    </>
  );
};

NeedHelp.propTypes = {};

export default NeedHelp;
