import React from "react";
import useSWR from "swr";
import { H2White } from "../Headings";
import "./Microsoft365UsageChart.css";
import {
  TimeIntervals, fetcher
} from "../../Utils/utils";
import UsageChartComponent from "./UsageChartComponent";
import Card from "../Card";

const Microsoft365UsageChart = () => {
  const { data: activeusercountData, error: activeusercounterror } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/ActiveUserCount.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.TWELVE_HOURS,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );

  const { data: failedlogincountData, error: failedlogincounterror } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/FailedLoginCount.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.TWELVE_HOURS,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );
  const { data: servicedegradationcount, error: servicedegradationerror } =
    useSWR(`${process.env.REACT_APP_PUBLIC_STORE}/ServiceDegradation.json`,
    fetcher,{
      refreshInterval: TimeIntervals.TWELVE_HOURS,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    });
  const { data: securityalertscount, error: securityalertserror } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/SecurityAlertsCount.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.TWELVE_HOURS,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );

  let activeRenderCard = activeusercountData?.RenderCard;
  let failedRenderCard = failedlogincountData?.RenderCard;
  let serviceRenderCard = servicedegradationcount?.RenderCard;
  let securityRenderCard = securityalertscount?.RenderCard;

  return (
    <>
      { (activeRenderCard ||
        failedRenderCard ||
        serviceRenderCard ||
        securityRenderCard) && 
           
            <Card wide={true} redCard = {true}><div>
              <H2White as="h3">
                <div className="Microsoft-Heading">
                  <div>MICROSOFT O365: USAGE & ADOPTION </div>
                </div>
              </H2White>
              {activeusercounterror && <>There was an error getting the data</>}
              <div className="GreyCard">
                <div className="UsageCardContent">
                  {activeRenderCard && (
                    <UsageChartComponent
                      data={activeusercountData}
                      label="Active Users"
                    />
                  )}
                  {failedRenderCard && (
                    <UsageChartComponent
                      data={failedlogincountData}
                      label="Failed Logins"
                    />
                  )}
                  {serviceRenderCard && (
                    <UsageChartComponent
                      data={servicedegradationcount}
                      label="M365 Service Degradations"
                    />
                  )}
                  {securityRenderCard && (
                    <UsageChartComponent
                      data={securityalertscount}
                      label="Security Alerts"
                    />
                  )}
                </div>
              </div>
            </div>
          </Card>
       
      }
    </>
  );
};

export default Microsoft365UsageChart;
