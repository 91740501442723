import React, { useState } from "react";
import AuthorizedArea from "../AuthorizedArea";
import Card from "../Card";
import CardInnerWithTime from "../CardInnerWithTime";
import {
  TimeIntervals,
  buildApiInternalUrl,
  formatNumber,
  getMonthName,
} from "../../Utils/utils";
import AutoSizer from "react-virtualized-auto-sizer";
import TooltipItem from "../TooltipItem";
import {
  Hint,
  HorizontalGridLines,
  LineMarkSeries,
  XAxis,
  XYPlot,
  YAxis,
} from "react-vis";
import "../../styles/IndividualDeploymentTotals.css";

const InnerComponent = ({ data }) => {
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);

  let yDomainTopValue = 0;
  let totalsArray = [];
  let sortedArray = [];

  if (data?.indDeploymentData) {
    let tempArray = data.indDeploymentData.sort((a, b) => {
      if (a.year !== b.year) return b.year - a.year;
      return b.month - a.month;
    });

    tempArray = tempArray?.reverse();
    tempArray.length = 12;
    tempArray.forEach((ele) => {
      totalsArray.push({
        x: `${getMonthName(ele.month).slice(0, 3)} ${ele.year}`,
        y: ele.count,
        date: `${getMonthName(ele.month)} ${ele.year}`,
      });
    });
  }

  let copyArray = [...totalsArray];
  sortedArray = copyArray?.sort((a, b) => b.y - a.y);
  yDomainTopValue = sortedArray[0]?.y * 1.3;

  return (
    <Card className="Card-Margin-Top IndividualDeploymentTotals">
      <CardInnerWithTime
        loading={!data}
        title={`Individual Deployments Completed by Month`}
        refreshedTime={data?.timeStamp ?? ""}
        mainComponent={() => (
          <div>
            <AutoSizer disableHeight>
              {({ width }) => (
                <XYPlot
                  width={width}
                  height={200}
                  xType="ordinal"
                  margin={{
                    left: 70,
                  }}
                  yDomain={[0, yDomainTopValue]}
                  onMouseLeave={() => {
                    setHint(false);
                    setAnimation(true);
                  }}
                >
                  <HorizontalGridLines />
                  <XAxis tickLabelAngle={-45} />
                  <YAxis
                    title="Number of Deployments"
                    tickFormat={(d) => `${formatNumber(d)}`}
                  />

                  <LineMarkSeries
                    style={{
                      strokeWidth: "3px",
                    }}
                    lineStyle={{ stroke: "#990000" }}
                    markStyle={{ stroke: "#990000" }}
                    data={totalsArray}
                    animation={animation}
                    color={`#990000`}
                    onNearestX={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                    }}
                  />
                  {hint && (
                    <Hint value={hint}>
                      <div
                        className="rv-hint__content"
                        style={{ pointerEvents: "none" }}
                      >
                        <TooltipItem
                          fill={hint.color}
                          text={`${hint.date}: ${formatNumber(hint.y)}`}
                        />
                      </div>
                    </Hint>
                  )}
                </XYPlot>
              )}
            </AutoSizer>
          </div>
        )}
      />
    </Card>
  );
};

const IndividualDeploymentTotals = () => (
  <AuthorizedArea
    url={buildApiInternalUrl("is-ind-deployment")}
    refreshInterval={TimeIntervals.ONE_DAY}
    innerComponent={({ data, error }) => {
      if (!data && !error && !error?.status) {
        return <></>;
      }
      if (data?.renderCard !== undefined && !data.renderCard) {
        return <></>;
      }
      return <InnerComponent data={data} />;
    }}
  />
);

export default IndividualDeploymentTotals;
