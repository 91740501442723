import React, { useState } from "react";
import FeesByFiscalYear from "./FeesByFiscalYear";
import FeesByTribe from "./FeesByTribe";
import "../../../styles/BudgetCompliance.css";
import "../../../styles/Common.css";

const Gaming = () => {
  const [isFiscalYearRender, setIsFiscalYearRender] = useState(false);
  const [isTribeRender, setIsTribeRender] = useState(false);

  return (
    <>
      {(isFiscalYearRender || isTribeRender) && (
        <span className="Heading">Gaming</span>
      )}
      <FeesByFiscalYear setIsFiscalYearRender={setIsFiscalYearRender} />
      <FeesByTribe setIsTribeRender={setIsTribeRender} />
    </>
  );
};

export default Gaming;
