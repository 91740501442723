import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { H2 } from '../Headings';
import { TableStyle } from '../BaseTable';
import Button from '../Button';
import Cell from './Cell';

const TableWrap = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;

  thead tr {
    background-color: #005c95 !important;
    color: #ffffff !important;
  }
`;

const TitleButtonWrapp = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

const BalanceSheet = ({ data }) => {
  const [head, ...rest] = data;

  return (
    <>
      <TitleButtonWrapp>
        <H2 as="h3">App C Balance Sheet</H2>
        <Button
          as="a"
          color="blue"
          size="small"
          href={`${process.env.REACT_APP_PUBLIC_STORE}/BalanceSheet.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View Balance Sheet
        </Button>
      </TitleButtonWrapp>

      <p>
        The balance sheet provides OMES IS total revenue, total expense and net
        position for each service provided to your agency.
      </p>
      <TableWrap>
        <TableStyle color="blue" wrapped>
          <thead>
            <tr>
              <Cell as="th" alignment="left">
                {head.a}
              </Cell>
              <Cell as="th" alignment="left">
                {head.b}
              </Cell>
              <Cell as="th" alignment="left">
                {head.c}
              </Cell>
              <Cell as="th" alignment="left">
                {head.d}
              </Cell>
              <Cell as="th" alignment="left">
                {head.e}
              </Cell>
              <Cell as="th" alignment="left">
                {head.f}
              </Cell>
              <Cell as="th" alignment="left">
                {head.g}
              </Cell>
              <Cell as="th" alignment="left">
                {head.h}
              </Cell>
            </tr>
          </thead>
          <tbody>
            {rest.map((x, i) => (
              <tr key={i}>
                <Cell as="td" alignment="left">
                  {x.a}
                </Cell>
                <Cell as="td" alignment="left">
                  {x.b}
                </Cell>
                <Cell as="td" alignment="right">
                  {x.c}
                </Cell>
                <Cell as="td" alignment="left">
                  {x.d}
                </Cell>
                <Cell as="td" alignment="right">
                  {x.e}
                </Cell>
                <Cell as="td" alignment="right">
                  {x.f}
                </Cell>
                <Cell as="td" alignment="right">
                  {x.g}
                </Cell>
                <Cell as="td" alignment="right">
                  {x.h}
                </Cell>
              </tr>
            ))}
          </tbody>
        </TableStyle>
      </TableWrap>
    </>
  );
};

BalanceSheet.propTypes = {
  data: PropTypes.any,
};

BalanceSheet.defaultProps = {
  data: null,
};

export default BalanceSheet;
