import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@reach/tooltip';
import '@reach/tooltip/styles.css';
import PropTypes from 'prop-types';
import React from 'react';
import VisuallyHidden from '@reach/visually-hidden';

const Top = styled.span`
  position: absolute;
  right: ${({ theme }) => theme.width.sm};
`;

const Icon = styled(FontAwesomeIcon)`
  --fa-primary-color: ${({ theme, warning }) =>
    warning ? theme.icon.criticalStatusPrimary : 'inherit'};
  --fa-secondary-color: ${({ theme, warning }) =>
    warning ? theme.icon.criticalStatusSecondary : 'inherit'};
`;

const CardTopItems = ({ items }) => {
  if (!items || items.length === 0) {
    return <></>;
  }

  return (
    <Top>
      {items
        .filter((x) => x)
        .map((x, i) => (
          <Tooltip key={i} label={x.text} style={x.style !== null && x.style}>
            {x.onClick ? (
              <button type="button" onClick={() => x.onClick()}>
                <Icon
                  icon={x.icon}
                  size="sm"
                  warning={x?.warning?.toString()}
                />
                <VisuallyHidden>{x.text}</VisuallyHidden>{' '}
              </button>
            ) : (
              <span>
                <Icon
                  icon={x.icon}
                  size="sm"
                  warning={x?.warning?.toString()}
                />
                <VisuallyHidden>{x.text}</VisuallyHidden>{' '}
              </span>
            )}
          </Tooltip>
        ))}
    </Top>
  );
};

CardTopItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.any,
      warning: PropTypes.bool,
    })
  ),
};

export default CardTopItems;
