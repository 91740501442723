import React, { useState } from "react";
import { faClock } from "@fortawesome/pro-duotone-svg-icons";
import { Hint, RadialChart } from "react-vis";
import AutoSizer from "react-virtualized-auto-sizer";
import styled from "@emotion/styled";
import { H3 } from "../Headings";
import CardTopItems from "../CardTopItems";
import TooltipItem from "../TooltipItem";
import { buildApiInternalUrl, TimeIntervals } from "../../Utils/utils";
import AuthorizedArea from "../AuthorizedArea";
import Card from "../Card";
import Legend from "../BaseCharts/Legend";

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const QAPieChart = ({ title, data, updatedDateTime }) => {
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);
  return (
    <Card>
      <CardTopItems
        items={[
          {
            text: `As of ${new Date(updatedDateTime).toLocaleString()}`,
            icon: faClock,
          },
        ]}
      />
      <H3>{title}</H3>
      <Wrap>
        <div>
          <AutoSizer disableHeight>
            {({ width }) => (
              <RadialChart
                colorType="literal"
                innerRadius={0}
                radius={100}
                data={data}
                animation={animation}
                onValueMouseOver={(datapoint) => {
                  setAnimation(false);
                  setHint(datapoint);
                }}
                onValueMouseOut={() => {
                  setHint(false);
                  setAnimation(true);
                }}
                width={width}
                height={200}
              >
                {hint && (
                  <Hint value={hint}>
                    <div
                      className="rv-hint__content"
                      style={{ pointerEvents: "none" }}
                    >
                      <TooltipItem
                        fill={hint.color}
                        text={`${hint.text}: ${hint.value}`}
                      />
                    </div>
                  </Hint>
                )}
              </RadialChart>
            )}
          </AutoSizer>
        </div>
        <Legend>
          {data.map((x, i) => (
            <TooltipItem
              key={i}
              fill={x.color}
              text={`${x.text}: ${x.value}`}
            />
          ))}
        </Legend>
      </Wrap>
    </Card>
  );
};

const QAProjects = () => {
  return (
    <>
      <AuthorizedArea
        url={buildApiInternalUrl("qaprojectmetrics")}
        refreshInterval={TimeIntervals.ONE_DAY}
        innerComponent={({ data, error }) => {
          if (!data) {
            return <>No data was found</>;
          }
          const { data: result, updatedDateTime } = data;
          const project = [
            {
              angle: result.completeProject,
              value: result.completeProject,
              color: "#669b41",
              text: "Completed",
            },
            {
              angle: result.inProgressProject,
              value: result.inProgressProject,
              color: "#1ca6df",
              text: "In Progress",
            },
            {
              angle: result.assignedProject,
              value: result.assignedProject,
              color: "#de9027",
              text: "Assigned",
            },
            {
              angle: result.newProject,
              value: result.newProject,
              color: "#464646",
              text: "New",
            },
            {
              angle: result.onHoldProject,
              value: result.onHoldProject,
              color: "#d15420",
              text: "On Hold",
            },
          ];

          return (
            <>
              {data?.renderCard && (
                <QAPieChart
                  title={result.project}
                  data={project}
                  updatedDateTime={updatedDateTime}
                />
              )}
            </>
          );
        }}
      />
    </>
  );
};

export default QAProjects;
