import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

export const COLOR = ['gray', 'blue', 'red', 'green', 'yellow'];

const Wrap = styled.div`
  --fa-primary-color: ${({ theme, color }) => theme.alert[color].icon.primary};
  --fa-secondary-color: ${({ theme, color }) =>
    theme.alert[color].icon.secondary};
  background-color: ${({ theme, color }) => theme.alert[color].background};
  border-color: ${({ theme, color }) => theme.alert[color].border};
  color: ${({ theme, color }) => theme.alert[color].text};

  line-height: ${({ theme }) => theme.text.size.xl};
  border-style: solid;
  border-width: ${({ theme }) => theme.width.px};
  border-radius: ${({ theme }) => theme.radius};
  box-shadow: ${({ theme }) => theme.shadow.lg};
  margin-bottom: ${({ theme }) => theme.margin.xxs};
  padding: ${({ theme }) => theme.padding.sm};
  word-break: break-word;
  text-align: inherit;

  a {
    color: ${({ theme, color }) => theme.alert[color].text};

    &:hover,
    &:visited {
      color: ${({ theme, color }) => theme.alert[color].text};
    }
  }

  h4 {
    margin: 0;
    margin-bottom: ${({ theme }) => theme.margin.xs};
  }

  hr {
    border-width: 0;
    border-top: ${({ theme, color }) =>
      `${theme.width.px} solid ${theme.alert[color].border}`};
  }
`;

const Alert = ({ children, color, as, ...rest }) => (
  <Wrap color={color.toLowerCase()} as={as} {...rest}>
    {children}
  </Wrap>
);

Alert.propTypes = {
  children: PropTypes.any,
  color: PropTypes.oneOf(COLOR),
  as: PropTypes.string,
};

Alert.defaultProps = {
  children: null,
  color: 'gray',
  as: 'div',
};

export default Alert;
