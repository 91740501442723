import React from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ListItme = ({ path, heading, icon, redirection = false }) => {
  const theme = useTheme();
  const location = useLocation();

  const active = location.pathname.toLowerCase() === path.toLowerCase();

  const LiWrap = styled.li`
    border-radius: ${theme.radius};

    a,
    a:visited {
      color: ${theme.sidebar.text};
      display: grid;
      font-weight: ${theme.text.weight.medium};
      grid-gap: ${theme.margin.sm};
      justify-items: center;
      padding: ${theme.padding.sm};
      text-decoration: none;

      > span {
        align-content: center;
        display: grid;
        grid-auto-flow: column;
        grid-gap: ${theme.margin.sm};
        justify-items: center;
      }
    }

    &.active,
    &:hover {
      background-color: ${theme.sidebar.hover};
      a:hover {
        color: ${theme.sidebar.text};
      }
    }

    ${theme.breakpoints.xl} {
      margin-bottom: ${theme.margin.sm};
      a {
        justify-items: start;
      }
    }
  `;
  const agencyRedirect = () => {
    debugger;
    console.log("link",  `${process.env.REACT_APP_AEM_AGENCY_RATE_PACKET}`);
    window.open(`${process.env.REACT_APP_AEM_AGENCY_RATE_PACKET}`, "_blank");
  };

  return (
    <LiWrap className={active ? "active" : ""}>
      <Link to={path}>
        <span>
          <FontAwesomeIcon icon={icon} fixedWidth />
          {redirection ? (
            <span onClick={() => agencyRedirect()} dangerouslySetInnerHTML={{ __html: heading}}/>
          ) : (
            <span>{heading}</span> 
          )}
        </span>
      </Link>
    </LiWrap>
  );
};

ListItme.propTypes = {
  path: PropTypes.string,
  icon: PropTypes.any,
  heading: PropTypes.string,
};

export default ListItme;
