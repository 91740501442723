import React, { useState } from "react";
import styled from "@emotion/styled";
import { H2 } from "../../Headings";
import Select from "../../Select";
import { TableStyle } from "../../BaseTable";
import Cell from "../../AppCTable/Cell";
import { formatNumber } from "../../../Utils/utils";
import CardTopItems from "../../CardTopItems";
import { faClock } from "@fortawesome/pro-duotone-svg-icons";

const TableWrap = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;

  thead tr {
    background-color: #005c95;
    color: #ffffff;
  }

  thead th {
    overflow-wrap: break-word;
    white-space: pre-wrap !important;
  }
`;

const ProductPO = ({ data }) => {
  const [product, setProduct] = useState("");
  const [productData, setProductData] = useState(null);
  const topIcons = [
    {
      text: `As of ${new Date(data?.timeStamp).toLocaleString()}`,
      icon: faClock,
    },
  ];
  return (
    <>
     <CardTopItems items={topIcons} />
      <H2>Inbound POs by Product - Past 90 days</H2>
      <Select
        onChange={(e) => {
          const val = e.target.value;
          setProduct(val);
          setProductData(data?.product?.filter((x) => x.category === val)[0]);
        }}
        defaultValue={product}
        aria-label="Select Item to View"
      >
        <option>Select a product</option>
        {data?.product
          ?.filter((item) => item.category !== "")
          .map((x, i) => (
            <option key={i} value={x.category}>
              {x.category}
            </option>
          ))}
      </Select>

      {product !== "" && (
        <TableWrap>
          <TableStyle color="blue" wrapped>
            <thead>
              <tr>
                <Cell as="th" alignment="left">
                  Product Name
                </Cell>
                <Cell as="th" alignment="left">
                  Number of Inbound POs
                </Cell>
                <Cell as="th" alignment="left">
                  Order Quantity
                </Cell>
              </tr>
            </thead>
            <tbody>
              <tr>
                <Cell as="td" alignment="left">
                  {productData?.category !== "" ? productData?.category : ""}
                </Cell>
                <Cell as="td" alignment="right">
                  {productData?.totalOrders !== ""
                    ? formatNumber(productData?.totalOrders)
                    : ""}
                </Cell>
                <Cell as="td" alignment="right">
                  {productData?.quantity !== ""
                    ? formatNumber(productData?.quantity)
                    : ""}
                </Cell>
              </tr>
            </tbody>
          </TableStyle>
        </TableWrap>
      )}
    </>
  );
};

export default ProductPO;
