import React from "react";
import PayrollWarrentsandReversal from "./PayrollWarrentsandReversal";
import { H2 } from "../../Headings";
import "../../../styles/PayrollReturn.css";
import PayrollWarrantsProcessed from "./PayrollWarrantsProcessed";

const CentralPayrollGroup = () => {
  return (
    <>
      <H2>{"Central Payroll Group"}</H2>
      <p className="SubHeading">
        Reflects payrolls processed for all State of Oklahoma employees. Data
        does not include payroll information for state institutions of higher
        education.
      </p>
      <PayrollWarrantsProcessed />
      <PayrollWarrentsandReversal />
    </>
  );
};

export default CentralPayrollGroup;
