import React from "react";
import useSWR from "swr";
import { fetcher, TimeIntervals } from "../../../Utils/utils";
import ColumnSection from "../../../Components/ColumnSection";
import {
  Tabs,
  TabList,
  TabPanelsEnhanced,
  TabPanel,
  TabEnhanced,
} from "../../../Components/Tabs";
import GovDelivery from "./GovDelivery";
import MediaStories from "./MediaStories";
import OmesVisits from "../../InternetTraffic/OmesVisits";
import LPAInternalComm from "./LPAInternalComm";
import "../../../styles/LegislativePublicAffairs.css";
import SocialMedia from "./SocialMedia";

const InnerComponent = ({ data }) => {
  return (
    <>
      {data?.renderCard && (
        <div className="LPAGraph">
          <span className="Heading">External Communications</span>
          <OmesVisits />
          <div className="TabsMetric">
            <ColumnSection cols={1}>
              <Tabs>
                <TabList>
                  <TabEnhanced>govDelivery</TabEnhanced>
                  <TabEnhanced>Media Stories</TabEnhanced>
                </TabList>
                <TabPanelsEnhanced>
                  <TabPanel>
                    <GovDelivery data={data} />
                  </TabPanel>
                  <TabPanel>
                    <MediaStories data={data} />
                  </TabPanel>
                </TabPanelsEnhanced>
              </Tabs>
            </ColumnSection>
          </div>
          <SocialMedia />
        </div>
      )}

      <LPAInternalComm />
    </>
  );
};

const LegislativePublicAffairs = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/top-web-visits.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (error?.status === 401) {
    return <></>;
  }

  return <InnerComponent data={data} />;
};

export default LegislativePublicAffairs;
