import React, { useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import styled from "@emotion/styled";
import Card from "../../Card";
import CardTopItems from "../../CardTopItems";
import { faClock } from "@fortawesome/pro-duotone-svg-icons";
import { H3 } from "../../Headings";
import { formatNumber } from "../../../Utils/utils";
import { Hint, RadialChart } from "react-vis";
import TooltipItem from "../../TooltipItem";
import Legend from "../../BaseCharts/Legend";
import "../../../styles/CAPProjects.css";

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin-top: 1rem;
`;

const CountPieChart = ({ subtitle, timeStamp, data }) => {
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);

  return (
    <Card className="CAPPieChart">
      <CardTopItems
        items={[
          {
            text: `As of ${new Date(timeStamp).toLocaleString()}`,
            icon: faClock,
          },
        ]}
      />
      <H3>{`Count of Active Projects`}</H3>
      <div className="ChartSubTitle">{subtitle}</div>
      <Wrap>
        <div>
          <AutoSizer disableHeight>
            {({ width }) => (
              <RadialChart
                colorType="literal"
                innerRadius={0}
                radius={100}
                data={data}
                animation={animation}
                onValueMouseOver={(datapoint) => {
                  setAnimation(false);
                  setHint(datapoint);
                }}
                onValueMouseOut={() => {
                  setHint(false);
                  setAnimation(true);
                }}
                width={width}
                height={200}
              >
                {hint && (
                  <Hint value={hint}>
                    <div
                      className="rv-hint__content"
                      style={{ pointerEvents: "none" }}
                    >
                      <TooltipItem
                        fill={hint.color}
                        text={`${hint.text.slice(
                          0,
                          hint.text.length - 6
                        )}: ${formatNumber(hint.value)}`}
                      />
                    </div>
                  </Hint>
                )}
              </RadialChart>
            )}
          </AutoSizer>
        </div>
        <Legend>
          {data.map((x, i) => (
            <TooltipItem
              key={i}
              fill={x.color}
              text={`${x.text.slice(0, x.text.length - 6)}`}
            />
          ))}
        </Legend>
      </Wrap>
    </Card>
  );
};

export default CountPieChart;
