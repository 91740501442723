import React from 'react';
import PropTypes from 'prop-types';

import { H3, H4 } from '../Headings';
import Row from './Row';
import Cell from './Cell';
import styled from '@emotion/styled';

export const getYearlyTotal = (list, year) => {
  if (!list) {
    return '0';
  }

  const returnSet = [];

  for (let listIdx = 0; listIdx < list.length; listIdx += 1) {
    const rate = list[listIdx];
    for (let rateIdx = 0; rateIdx < rate.parts.length; rateIdx += 1) {
      const part = rate.parts[rateIdx];
      for (let partIdx = 0; partIdx < part.partNumbers.length; partIdx += 1) {
        const element = part.partNumbers[partIdx];
        const foundYear = element.years.filter((y) => y.year === year);
        if (foundYear.length > 0) {
          returnSet.push({
            ...foundYear[0].data,
          });
        }
      }
    }
  }

  if (returnSet.length > 0) {
    return returnSet.reduce(function (a, b) {
      return a + b.Total;
    }, 0);
  }
  return '0';
};

const TableBody = styled.div`
  tbody tr:nth-child(2) th {
    color: #ffffff;
    background-color: #005c95;
  }
`;

const InnerBodySection = ({ header, data, settings, fullHeadingSupplied }) => (
  <>
    <thead>
      <tr>
        <Cell as="th" colSpan="4" alignment="left">
          <H3 style={{ marginBottom: "0" }}>
            {fullHeadingSupplied
              ? header
              : `${header} Billed Service and Products`}
          </H3>
        </Cell>
      </tr>
    </thead>

    {data?.map((rate, rateIdx) => (
      <TableBody>
        <tbody key={rateIdx}>
          <tr>
            <Cell as="th" alignment="left" colSpan="4">
              <H4 style={{ margin: "0" }}>{rate.rate}</H4>
            </Cell>
          </tr>
          <tr>
            <Cell as="th" alignment="left">
              Name
            </Cell>
            <Cell as="th" alignment="left">
              Description
            </Cell>
            <Cell as="th" alignment="left">
              Rate Cost Details
            </Cell>
            <Cell as="th">Rate</Cell>
          </tr>

          {rate.parts.map((part, partIdx) => (
            <Row
              key={partIdx}
              idx={partIdx}
              name={part.name}
              description={part.rateDescription}
              expenseItems={part.expenseItems}
              price={part.price}
              settings={settings}
            />
          ))}
        </tbody>
      </TableBody>
    ))}
  </>
);

InnerBodySection.propTypes = {
  header: PropTypes.string,
  data: PropTypes.any,
  settings: PropTypes.any,
  fullHeadingSupplied: PropTypes.bool,
};

InnerBodySection.defaultProps = {
  header: '',
  data: null,
  settings: null,
  fullHeadingSupplied: false,
};

export default InnerBodySection;
