import React from "react";
import useSWR from "swr";
import { fetcher, TimeIntervals } from "../../Utils/utils";
import ColumnSection from "../../Components/ColumnSection";
import OMESGlanceTable from "./OMESGlanceTable";

const InnerComponent = ({ agencyID, data }) => {
  return (
    <>
      {/* <SectionHeading title="OMES Shared Services at a Glance" description="" /> */}
      <ColumnSection cols={1} colsAtMd={1}>
        <OMESGlanceTable agencyID = {agencyID} data={data} />
      </ColumnSection>
    </>
  );
};

const OMESSharedService = ({ agencyKey }) => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/OMES-At-A-Glance.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }
  
  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (error?.status === 401) {
    return <></>;
  }
  return <InnerComponent agencyID = {agencyKey} data={data} />;
};

export default OMESSharedService;
