import React, { useState } from "react";
import CardInnerWithTime from "../../../CardInnerWithTime";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { H1, H4 } from "../../../Headings";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  dateFormatWithoutDay,
  formatNumber,
  monthsList,
} from "../../../../Utils/utils";
import TooltipItem from "../../../TooltipItem";
import {
  Crosshair,
  XYPlot,
  HorizontalGridLines,
  LineSeries,
  VerticalBarSeries,
  XAxis,
  YAxis,
} from "react-vis";
import "../../../../styles/DirectPOTrendline.css";

const changeDateFormat = (date) => {
  let newDate = `${monthsList[new Date(date).getMonth()]} ${new Date(
    date
  ).getFullYear()}`;
  return newDate;
};

const DirectPO = ({ data, agency }) => {
  const [hint, setHint] = useState(false);
  const [crosshairValues, setCrosshairValues] = useState([]);

  let sortedArray, fromDate, toDate, directPOLines, maxValue, yDomainTopValue;

  if (agency === "" || agency === "All Agencies") {
    directPOLines = data?.directPOLines
      ? formatNumber(data?.directPOLines)
      : "-";
    fromDate = data?.fromDate ? dateFormatWithoutDay(data?.fromDate) : "-";
    toDate = data?.toDate ? dateFormatWithoutDay(data?.toDate) : "-";

    sortedArray = data?.purchaseOrders?.sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );

    maxValue =
      data?.purchaseOrders?.length > 0 &&
      Math.max(...data?.purchaseOrders?.map((d) => d.count));

    yDomainTopValue = [0, 1.2 * maxValue];
  } else {
    let agencyData = data?.directPOLinesByBusinessUnit?.filter(
      (item) => item.businessUnitName === agency
    );
    directPOLines = agencyData?.[0]?.count
      ? formatNumber(agencyData?.[0]?.count)
      : "-";
    fromDate = agencyData?.[0]?.fromDate
      ? dateFormatWithoutDay(agencyData?.[0]?.fromDate)
      : "-";
    toDate = agencyData?.[0]?.toDate
      ? dateFormatWithoutDay(agencyData?.[0]?.toDate)
      : "-";

    let filteredArray = data?.purchaseOrdersByBusinessUnit?.filter(
      (item) => item.businessUnitName === agency
    );
    sortedArray = filteredArray?.sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );

    maxValue =
      data?.purchaseOrdersByBusinessUnit?.length > 0 &&
      Math.max(...filteredArray?.map((d) => d.count));

    yDomainTopValue = [0, 1.3 * maxValue];
  }

  sortedArray = sortedArray.slice(-12);

  return (
    <div className="DirectPO">
      <CardInnerWithTime
        loading={!data}
        title="Direct Purchase Order Line"
        agencyName={agency}
        subtitle={
          fromDate !== "-" && toDate !== "-" && `${fromDate} - ${toDate}`
        }
        refreshedTime={data?.timeStamp ?? ""}
        extraIcons={[
          {
            text: `Procurement data is shown by Purchase Order (PO) line; there can be
multiple purchase order lines within a single purchase order.

If a PO line does not have an enterprise procurement requisition
attached, it is considered a Direct PO. All PO lines should have an
attached requisition; trendlines should decline over time as division
policies are implemented.`,
            icon: faInfoCircle,
            style: {
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              textAlign: "justify",
            },
          },
        ]}
        mainComponent={() =>
          data?.purchaseOrders && data?.purchaseOrders.length > 0 ? (
            <div className="POHeader">
              <div className="HeaderInfo">
                <H1>{directPOLines}</H1>
                <p>Direct PO Lines</p>
              </div>
              <div className="PODivider"></div>
              <div className="HeaderChart">
                {sortedArray?.length === 0 ? (
                  <H4>No data available</H4>
                ) : (
                  <AutoSizer disableHeight>
                    {({ width }) => (
                      <XYPlot
                        height={200}
                        xType="ordinal"
                        width={width}
                        margin={{
                          left: 50,
                        }}
                        onMouseLeave={() => {
                          setHint(false);
                          setCrosshairValues(false);
                        }}
                        yDomain={yDomainTopValue}
                      >
                        <HorizontalGridLines />
                        <XAxis tickLabelAngle={-45} />
                        <YAxis />

                        <VerticalBarSeries
                          color="#005c95"
                          style={{ cursor: "pointer" }}
                          data={sortedArray.map((item) => ({
                            x: changeDateFormat(item.date),
                            y: item.count,
                          }))}
                          onValueMouseOver={(datapoint) => {
                            setHint(datapoint);
                            setCrosshairValues([datapoint]);
                          }}
                          onValueMouseOut={() => {
                            setHint(false);
                            setCrosshairValues(false);
                          }}
                        />

                        {/* <LineSeries
                          color="#ed7d31"
                          data={sortedArray.map((item) => ({
                            x: changeDateFormat(item.date),
                            y: item.count,
                          }))}
                          onValueMouseOver={(datapoint) => {
                            setHint(datapoint);
                            setCrosshairValues([datapoint]);
                          }}
                          onValueMouseOut={() => {
                            setHint(false);
                            setCrosshairValues(false);
                          }}
                        /> */}

                        {crosshairValues && (
                          <Crosshair values={crosshairValues}>
                            <div className="ShowHint">
                              <TooltipItem
                                fill="#005c95"
                                text={`${hint.x}: ${formatNumber(hint.y)}`}
                              />
                            </div>
                          </Crosshair>
                        )}
                      </XYPlot>
                    )}
                  </AutoSizer>
                )}
              </div>
            </div>
          ) : (
            <p className="Center-align">No data available</p>
          )
        }
      />
    </div>
  );
};

export default DirectPO;
