import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const DivWrap = styled.div`
  --fa-primary-color: ${({ theme }) => theme.icon.cardPrimary};
  --fa-secondary-color: ${({ theme }) => theme.icon.cardSecondary};
  background-color: ${({ theme }) => theme.card.background};
  border: ${({ theme }) => `solid ${theme.width.px} ${theme.card.border}`};
  border-radius: ${({ theme }) => theme.radius};
  box-shadow: ${({ theme }) => theme.shadow.lg};
  color: ${({ theme }) => theme.card.text.base};
  padding: ${({ theme }) => theme.padding.sm} ${({ theme }) => theme.padding.sm};
  overflow: hidden;
  position: relative;
  min-height: 11rem;
`;
const DivWrapRed = styled.div`
  --fa-primary-color: ${({ theme }) => theme.icon.cardPrimary};
  --fa-secondary-color: ${({ theme }) => theme.icon.cardSecondary};
  background-color: #5F9EA0;
  border: ${({ theme }) => `solid ${theme.width.px} ${theme.card.border}`};
  border-radius: ${({ theme }) => theme.radius};
  box-shadow: ${({ theme }) => theme.shadow.lg};
  color: ${({ theme }) => theme.card.text.base};
  padding: ${({ theme }) => theme.padding.sm} ${({ theme }) => theme.padding.md};
  overflow: hidden;
  position: relative;
`;

const Card = ({ children, wide, redCard, ...rest }) => (
  redCard ? <DivWrapRed className={wide ? 'wide' : ''} {...rest}>
    {children}
  </DivWrapRed> :
    <DivWrap className={wide ? 'wide' : ''} {...rest}>
      {children}
    </DivWrap>
);

Card.propTypes = {
  children: PropTypes.any,
  wide: PropTypes.bool,
  redCard: PropTypes.bool
};

Card.defaultProps = {
  children: null,
  wide: false,
  redCard: false
};
export default Card;
