import React, { useState } from "react";
import styled from "@emotion/styled";
import { faClock } from "@fortawesome/pro-duotone-svg-icons";
import Button from "../Components/Button";
import CardTopItems from "../Components/CardTopItems";
import Card from "../Components/Card";
import Datagrid from "../Components/Datagrid";
import Loader from "../Components/Loader";
import { H2 } from "../Components/Headings";
import { TimeIntervals, fetcher } from "../Utils/utils";
import "../styles/EProTimeLine.css";
import useSWR from "swr";

const Heading = styled.div`
  text-align: right;
  color: #ffffff;

  strong {
    display: inline-block;
    padding-bottom: ${({ theme }) => theme.width.xs};
  }
`;

const MapFormat = (d, which) =>
  d?.[which]?.map((x) => ({
    ...x,
    AvgTimeWithAgencyLabel: `${x.AvgTimeWithAgency} days`,
    AvgTimeWithCIOLabel: `${x.AvgTimeWithCIO} days`,
    AvgTimeToIssuePOLabel: `${x.AvgTimeToIssuePO} days`,
    AvgTimeLabel: `${x.AvgTime} days`,
  }));

const AvgProp = (d, prop) => d.reduce((ac, a) => a[prop] + ac, 0) / d.length;

// eslint-disable-next-line react/prop-types
const H = ({ d, prop, label, showingAbove }) => (
  <Heading>
    {showingAbove && <><strong>{`${AvgProp(d, prop).toFixed(1)} days`}</strong>
    <br /></>}
    {label}
  </Heading>
);

const EProTimeline = () => { 
  const [showingAbove, setShowingAbove] = useState(false);
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/EProTimeline.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );
  if (!data && !error && !error?.status) {
    return <></>;
  }
  if (data?.RenderCard !== undefined && !data.RenderCard) {
    return <></>;
  }
  const above = MapFormat(data, "Above");
  const below = MapFormat(data, "Below");
  const columns = [
    {
      name: "Agency Name",
      selector: "AgencyName",
      sortable: true,
      grow: 2,
    },
    {
      name: (
        <H
          d={showingAbove ? above : below}
          prop="AvgTimeWithAgency"
          label="Time With Agency"
          showingAbove={showingAbove}
        />
      ),
      selector: "AvgTimeWithAgencyLabel",
      right: true,
      sortable: true,
    },
    {
      name: (
        <H
          d={showingAbove ? above : below}
          prop="AvgTimeWithCIO"
          label="Time with ISD"
          showingAbove={showingAbove}
        />
      ),
      selector: "AvgTimeWithCIOLabel",
      right: true,
      sortable: true,
    },
    {
      name: (
        <H
          d={showingAbove ? above : below}
          prop="AvgTimeToIssuePO"
          label="Time To Issue PO"
          showingAbove={showingAbove}
        />
      ),
      selector: "AvgTimeToIssuePOLabel",
      right: true,
      sortable: true,
    },
    {
      name: (
        <H d={showingAbove ? above : below} prop="AvgTime" label="Whole Time" 
        showingAbove={showingAbove}/>
      ),
      selector: "AvgTimeLabel",
      right: true,
      sortable: true,
    },
  ];

  const topIcons = [
    {
      text: `As of ${new Date(data?.Reported).toLocaleString()}`,
      icon: faClock,
    },
  ];

  return (
    <div className="EProTimeLine">
      <Button
        size="default"
        color="blue"
        onClick={() => setShowingAbove(false)}
        className={showingAbove ? "LightTheme" : "DarkTheme"}
      >
        Request below 50K
      </Button>
      <Button
        size="default"
        color="blue"
        className={showingAbove ? "DarkTheme" : "LightTheme"}
        onClick={() => setShowingAbove(true)}
      >
        Request above 50K
      </Button>
      <Card>
        <CardTopItems items={topIcons} />
        {/* <H2 as="h3">Average Procurement Timeline for IT and Non-IT Purchases</H2> */}
        <H2 as="h3">Average Procurement Timeline for IT Purchases</H2>
        {/* <p>
        Displays the average number of days Purchase Requisitions are processed for agencies. Requisitions below $50,000 can be processed exclusively within agencies; requisitions above $50,000 require OMES involvement. All IT Purchase Requisitions must obtain CIO approval.
        </p> */}
        <p>
        Displays the average number of days Purchase Requisitions are processed for IT Purchases. Use the tabs to the left to toggle between total values below and above $50,000.
        </p>
        {error && <>There was an issue getting the data</>}
        {!data && !error && (
          <div style={{ textAlign: "center" }}>
            <Loader />
          </div>
        )}
        {data && data.Above && data.Below && (
          <Datagrid
            columns={columns}
            data={showingAbove ? above : below}
            expandable={false}
            defaultSortField="AgencyName"
          />
        )}
      </Card>

    </div>
  );
};

export default EProTimeline;
