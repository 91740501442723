import PropTypes from 'prop-types';
import { jsx, css } from '@emotion/core';
import { useTheme } from 'emotion-theming';

export const COLOR = ['gray', 'blue', 'green'];
export const SIZE = ['default', 'small'];

const Button = ({ as, color, size, active, children, ...props }) => {
  const c = color.toLowerCase();
  const theme = useTheme();

  const fontSize = () => {
    if (size === 'small') {
      return theme.text.size.sm;
    }
    return '';
  };
  const marginSize = () => {
    if (size === 'small') {
      return `${theme.margin.none} ${theme.margin.xxs}`;
    }
    return `${theme.margin.none} ${theme.margin.xs}`;
  };
  const paddingSize = () => {
    if (size === 'small') {
      return theme.padding.xs;
    }
    return theme.padding.sm;
  };
  const backgroundColor = () => {
    if (active) {
      return theme.buttons[c].hover.background;
    }
    return theme.buttons[c].background;
  };
  const borderColor = () => {
    if (active) {
      return theme.buttons[c].hover.border;
    }
    return theme.buttons[c].border;
  };
  const textColor = () => {
    if (active) {
      return theme.buttons[c].hover.text;
    }
    return theme.buttons[c].text;
  };

  const baseClass = css`
    --fa-primary-color: ${theme.buttons[c].icon.primary};
    --fa-secondary-color: ${theme.buttons[c].icon.secondary};
    background-color: ${backgroundColor()};
    border-radius: ${theme.radius};
    border: solid ${borderColor()} ${theme.width.px};
    color: ${textColor()} !important;
    cursor: pointer;
    font-size: ${fontSize()};
    font-weight: ${theme.text.weight.medium};
    margin: ${marginSize()};
    padding: ${paddingSize()};
    text-align: center;
    text-decoration: none;
    transition: all 75ms cubic-bezier(0.4, 0, 1, 1);

    &:hover {
      --fa-primary-color: ${theme.buttons[c].hover.icon.primary};
      --fa-secondary-color: ${theme.buttons[c].hover.icon.secondary};
      background-color: ${theme.buttons[c].hover.background};
      border: solid ${theme.buttons[c].hover.border} ${theme.width.px};
      color: ${theme.buttons[c].hover.text} !important;
    }
  `;

  return jsx(
    as,
    {
      ...props,
      ...{ css: baseClass },
    },
    children
  );
};

Button.propTypes = {
  as: PropTypes.string,
  color: PropTypes.oneOf(COLOR),
  size: PropTypes.oneOf(SIZE),
  active: PropTypes.bool,
};

Button.defaultProps = {
  as: 'button',
  color: 'gray',
  size: 'default',
  active: false,
};

export default Button;
