import React, { useState } from "react";
import useSWR from "swr";
import styled from "@emotion/styled";
import Card from "../../Card";
import TooltipItem from "../../TooltipItem";
import CardInnerWithTime from "../../CardInnerWithTime";
import { fetcher, TimeIntervals } from "../../../Utils/utils";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { useTheme } from "emotion-theming";
import {
  XAxis,
  YAxis,
  HorizontalGridLines,
  Hint,
  LineSeries,
  FlexibleWidthXYPlot,
} from "react-vis";
import "../../../styles/PayrollReturn.css";

const BottomText = styled.div`
  margin: 1rem 0rem 0rem 3rem;
  text-align: left;
  div {
    margin-bottom: 0.5rem;
  }
`;

const PayrollReturn = () => {
  const theme = useTheme();
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);

  const { data } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/payrollreturns.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_MINUTE,
    }
  );

  let yDomainTopValue = 0;
  if (data?.returns) {
    yDomainTopValue = Math.round(
      data?.returns?.sort((a, b) => b.count - a.count)?.[0].count * 1.3
    );
  }

  let asteriskCount = 0;
  let asteriskArray = [];
  let payRollArray = [];
  if (data?.returns) {
    data.returns
      .sort((a, b) => a.year - b.year)
      .forEach((ele) => {
        if (ele.count !== null && ele.comments === "") {
          payRollArray.push({
            x: ele.year,
            y: ele.count,
          });
        } else {
          asteriskCount++;
          asteriskArray.push(`${"*".repeat(asteriskCount)} ${ele.comments}`);
          payRollArray.push({
            x: `${ele.year} ${"*".repeat(asteriskCount)}`,
            y: ele.count,
          });
        }
      });
  }

  return (
    <div>
      {data?.renderCard && (
        <>
          {/* <H2>{"Central Payroll Group"}</H2> */}
          {/* <p className="SubHeading">
            Metrics related to the status and count of processed payrolls on a
            monthly basis.
          </p> */}
          <Card className="PayrollChart">
            <CardInnerWithTime
              loading={!data}
              title="Processed Payroll Returns to Employees"
              refreshedTime={data?.timeStamp ?? ""}
              extraIcons={[
                {
                  text: `Payroll returns represent EFTs, or direct 
deposit payments, that cannot be deposited by
an employee’s bank into a valid account.`,
                  icon: faInfoCircle,
                  style: {
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                    textAlign: "justify",
                  },
                },
              ]}
              mainComponent={() => (
                <div>
                  <FlexibleWidthXYPlot
                    height={200}
                    xType="ordinal"
                    yDomain={[0, yDomainTopValue]}
                    onMouseLeave={() => {
                      setHint(false);
                      setAnimation(true);
                    }}
                  >
                    <HorizontalGridLines />
                    <XAxis />
                    <YAxis />

                    <LineSeries
                      data={payRollArray}
                      animation={animation}
                      color={theme.colors.skyBlue.darken}
                      onNearestX={(datapoint) => {
                        setAnimation(false);
                        setHint(datapoint);
                      }}
                    />
                    {hint && (
                      <Hint value={hint}>
                        <div
                          className="rv-hint__content"
                          style={{ pointerEvents: "none" }}
                        >
                          <TooltipItem
                            fill={hint.color}
                            text={`${hint.x}: ${hint.y}`}
                          />
                        </div>
                      </Hint>
                    )}
                  </FlexibleWidthXYPlot>
                  <BottomText>
                    {asteriskArray.map((item) => (
                      <div key={item}>{item}</div>
                    ))}
                  </BottomText>
                </div>
              )}
            />
          </Card>
        </>
      )}
    </div>
  );
};

export default PayrollReturn;
