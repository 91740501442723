import React from "react";
import CardInnerWithTime from "../../CardInnerWithTime";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import "../../../styles/CapitalAssets.css";
import AuthorizedCard from "../../AuthorizedCard";
import { TimeIntervals, buildApiInternalUrl } from "../../../Utils/utils";
import ColumnSection from "../../ColumnSection";
import styled from "@emotion/styled";
import Datagrid from "../../Datagrid";

const WorkByDuration = () => {

  const Heading = styled.div`
  text-align: center;
  color: #ffffff;

  strong {
    display: inline-block;
    padding-bottom: ${({ theme }) => theme.width.xs};
  }
`;
const Header = ({ prop, label = "" }) => (
  <Heading>
    <strong>{prop}</strong>
    <br />
    {label}
  </Heading>
); 
 const columns = [
    {
      name: <Header prop="Work Code" />,
      selector: (row) => (row.workCode !== null && row.workCode !== "" ? row.workCode : ""),
     cell: (row) => (row.workCode !== null && row.workCode !== "" ? row.workCode : "OTHER"),
      sortable: true,
      wrap: true,
      style: { textAlign: "left !important" },
    },
    {
      name: <Header prop="Average Actual Task Duration (Hours)" />,
      selector: (row) => (row.duration?.toFixed(2)),
      sortable: true,
      wrap: true,
      right: true
    }
  ];
  return <ColumnSection cols={2}>
    {" "}
    <AuthorizedCard
      url={buildApiInternalUrl("work-order-by-taskduration")}
      refreshInterval={TimeIntervals.ONE_DAY}
      isFullWidth
      innerComponent={({ data, error }) => {
        let sortedData = data?.data?.sort((p1, p2) =>
          p1.workCode < p2.workCode ? 1 : p1.workCode > p2.workCode ? -1 : 0
        );
        if(sortedData){
          sortedData.reverse();
        }

        return (
          data?.renderCard && (
            <div className="WorkByDuration">
              <CardInnerWithTime
                loading={!data}
                title="Monthly Work Order Task Duration"
                subtitle="Task duration time keeping is currently being modified; data presented may not be accurate and should not be disclosed publicly."
                refreshedTime={data?.timeStamp ?? ""}
                extraIcons={[
                  {
                    text: `Data reflects work orders logged this previous month.`,
                    icon: faInfoCircle,
                  },
                ]}
                mainComponent={() => (
                  <>
                  <Datagrid
                    columns={columns}
                    data={sortedData}
                    expandable={false}
                    defaultSortField="workCode"
                    searchByKeys={["workCode"]}
                    filterPlaceHolder={"Filter by Work Code"}
                  />
                  </>
                )}
              />
            </div>
          )
        );
      }}
    />
  </ColumnSection>
};

export default WorkByDuration;
