import React, { useState } from "react";
import "../styles/Common.css";
import "../styles/EndUserCasesByMonth.css";
import {
  Crosshair,
  XYPlot,
  HorizontalGridLines,
  XAxis,
  YAxis,
  VerticalBarSeries,
  ChartLabel,
} from "react-vis";
import AutoSizer from "react-virtualized-auto-sizer";
import styled from "@emotion/styled";
import {
  TimeIntervals,
  buildApiInternalUrl,
  formatNumber,
  monthsInShort,
} from "../Utils/utils";
import Card from "../Components/Card";
import CardInnerWithTime from "../Components/CardInnerWithTime";
import TooltipItem from "../Components/TooltipItem";
import AuthorizedArea from "../Components/AuthorizedArea";

const LegendWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const InnerComponent = ({ data }) => {
  const [hint, setHint] = useState(false);
  const [crosshairValues, setCrosshairValues] = useState([]);
  const [animation, setAnimation] = useState(true);
  const dataMap = new Map();
  const endUserData = data?.endUserData;
  const targetedGroups = [
    "MDM",
    "End User Support DPS",
    "End User Support",
    "Exec Support",
    "Deployment",
  ];

  // Filter only the targeted groups' data.
  const filteredData = endUserData.filter((caseItem) =>
    targetedGroups.includes(caseItem.assignmentGroup)
  );

  // Find the maximum count.
  const maxCountData = filteredData.reduce((prev, current) => {
    return prev && prev.count > current.count ? prev : current;
  });

  const yDomainTopValue = [0, 1.3 * maxCountData.count];

  // Process the filtered data.
  filteredData.forEach((caseData) => {
    const graphData = { x: "", y: "", group: "" };
    graphData.x = `${monthsInShort[caseData?.month - 1]} ${caseData?.year}`;
    graphData.y = caseData?.count;
    graphData.group =
      caseData?.assignmentGroup === "End User Support DPS" ||
      caseData?.assignmentGroup === "End User Support"
        ? "End User"
        : caseData?.assignmentGroup;

    const mapData = dataMap.get(graphData.group);
    if (mapData) {
      let flag = 0;
      if (graphData.group === "End User") {
        mapData.forEach((data) => {
          if (data.x === graphData.x) {
            data.y += graphData.y;
            flag = 1;
            return;
          }
        });

        if (flag === 0) {
          mapData.push(graphData);
          dataMap.set(graphData.group, mapData);
        }
      } else {
        mapData.push(graphData);
        dataMap.set(graphData.group, mapData);
      }
    } else {
      dataMap.set(graphData.group, [graphData]);
    }
  });

  dataMap.forEach((value, key) => {
    dataMap.set(key, value.slice(-12));
  })

  // Return.
  return (
    <Card className="EndUserCasesByMonth card-wrapper">
      <CardInnerWithTime
        loading={!dataMap}
        title="End User - Cases Opened by Month"
        refreshedTime={data?.timeStamp ?? ""}
        mainComponent={() => (
          <>
            <AutoSizer disableHeight>
              {({ width }) => (
                <XYPlot
                  xType="ordinal"
                  colorType="literal"
                  width={width}
                  height={200}
                  margin={{
                    left: 75,
                  }}
                  yDomain={yDomainTopValue}
                  onMouseLeave={() => {
                    setHint(false);
                    setCrosshairValues(false);
                  }}
                >
                  <HorizontalGridLines />
                  <XAxis />
                  <YAxis />
                  <ChartLabel
                    text="Number of Opened Cases"
                    className="alt-y-label"
                    includeMargin={false}
                    style={{
                      transform: "rotate(-90)",
                      textAnchor: "end",
                    }}
                  />
                  <VerticalBarSeries
                    data={dataMap?.get("MDM")}
                    barWidth={0.5}
                    color = {"#7eb5d7"}
                    animation={animation}
                    onValueMouseOver={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                      setCrosshairValues([datapoint]);
                    }}
                  />
                  <VerticalBarSeries
                    data={dataMap?.get("Exec Support")}
                    barWidth={0.5}
                    color = {"#53a8dd"}
                    animation={animation}
                    onValueMouseOver={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                      setCrosshairValues([datapoint]);
                    }}
                  />
                  <VerticalBarSeries
                    data={dataMap?.get("End User")}
                    barWidth={0.5}
                    color = {"#115078"}
                    animation={animation}
                    onValueMouseOver={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                      setCrosshairValues([datapoint]);
                    }}
                  />
                  <VerticalBarSeries
                    data={dataMap?.get("Deployment")}
                    barWidth={0.5}
                    color = {"#15364b"}
                    animation={animation}
                    onValueMouseOver={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                      setCrosshairValues([datapoint]);
                    }}
                  />
                  {crosshairValues && (
                    <Crosshair values={crosshairValues}>
                      <div className="ShowHint">
                        <TooltipItem
                          fill={hint?.color}
                          text={`${hint?.group}: ${formatNumber(hint.y)}`}
                        />
                      </div>
                    </Crosshair>
                  )}
                </XYPlot>
              )}
            </AutoSizer>
            <LegendWrap>
              <TooltipItem fill="#7eb5d7" text="MDM" />
              <TooltipItem fill="#53a8dd" text="Exec Support" />
              <TooltipItem fill="#115078" text="End User" />
              <TooltipItem fill="#15364b" text="Deployment" />
            </LegendWrap>
          </>
        )}
      />
    </Card>
  );
};

const EndUserCasesByMonth = () => (
  <AuthorizedArea
    url={buildApiInternalUrl("is-enduser-caseopened")}
    refreshInterval={TimeIntervals.ONE_DAY}
    innerComponent={({ data, error }) => {
      if (data === undefined) {
        return <></>;
      }
      if (!data && !error && !error?.status) {
        return <></>;
      }
      if (data?.renderCard !== undefined && !data.renderCard) {
        return <></>;
      }
      return <InnerComponent data={data} />;
    }}
  />
);

export default EndUserCasesByMonth;
