import React from 'react';
import Button from '../Components/Button';
import { fetchPDF } from '../Utils/utils';

//to render public on Information Security report page
const InformationSecurityReport = () => { 
    const getPrivateFile = async () => {
        try {
            //const url = buildApiInternalUrl('securitypdf');
            const url = `${process.env.REACT_APP_PUBLIC_STORE}/OMES%20Security%20Intel%20Report.pdf`;
            //const data = await fetcherWithAuth(url, false, true);
            const data = await fetchPDF(url, false, true);
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = window.URL.createObjectURL(file);
            window.open(fileURL);
        } catch (err) {
            console.error("Error in get private file ::", err);
        }
    }

    const privateReport = () => {
        return  <Button
            as="a"
            color="blue"
            size="small"
           // href={`${process.env.REACT_APP_PUBLIC_STORE}/OMES%20Security%20Intel%20Report.pdf`}
            //target="_blank"
            onClick={() => getPrivateFile()}
            rel="noopener noreferrer"
        >
           View the State Daily Security Report
        </Button>
    }
    return (
        <>
            {privateReport()}
        </>
    )

}


export default InformationSecurityReport;