import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';


import InnerBodySection from './InnerBodySection';
import { H2 } from '../Headings';
import { TableStyle } from '../BaseTable';
import Button from '../Button';

const TableWrap = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;
`;

const TitleButtonWrapp = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

const RateTable = ({ data, settings }) => {
  const monthly = data?.filter((x) => x?.frequency === 'Monthly')?.[0]?.rates;
  const oneTime = data?.filter((x) => x?.frequency === 'Once')?.[0]?.rates;
  const appropriated = data?.filter((x) => x?.frequency === 'Appropriated')?.[0]
    ?.rates;

  return ( 
    <>
      <TitleButtonWrapp>
        {/* <H2 as="h3">FY {settings?.shortActiveYear} Rate Table</H2> */}
        <H2 as="h3">App C Rate Table</H2>
        <Button
          as="a"
          color="blue"
          size="small"
          href={`${process.env.REACT_APP_PUBLIC_STORE}/RateTable.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View Rate Table
        </Button>
      </TitleButtonWrapp>
      <p>
        The following rate table lists all monthly or annual billed services as
        well as appropriated services. Includes name, rate details and rate.
      </p>
      <TableWrap>
        <TableStyle color="blue" wrapped>
          {monthly && (
            <InnerBodySection
              header="Monthly"
              data={monthly}
              settings={settings}
            />
          )}
          {oneTime && (
            <InnerBodySection
              header="Annual"
              data={oneTime}
              settings={settings}
            />
          )}
          {appropriated && (
            <InnerBodySection
              header="Appropriated Services"
              data={appropriated}
              settings={settings}
              fullHeadingSupplied
            />
          )}
        </TableStyle>
      </TableWrap>
    </>
  );
};

RateTable.propTypes = {
  data: PropTypes.any,
  settings: PropTypes.any,
};

RateTable.defaultProps = {
  data: null,
  settings: null,
};

export default RateTable;
