import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export const downloadPdfDocument = (rootElementId, downloadFileName ) => {
  const data = document.getElementById(rootElementId);
  html2canvas(data, { scrollY: -window.scrollY, scale: 1.5 }).then(
    (canvas) => {
      // Few necessary setting options
      const contentDataURL = canvas.toDataURL("image/png", 1.0);

      let pdf = new jsPDF("p", "mm", "a3"); // A4 size page of PDF
//let pdf = new jsPDF("p", "mm", "a3");
      let imgWidth = 300;
      let pageHeight = pdf.internal.pageSize.height;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      var margin = 10;

      pdf.addImage(contentDataURL, "PNG", margin , margin, imgWidth - 20, imgHeight - 20);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, "PNG", margin, position, imgWidth - 20, imgHeight - 20);
        heightLeft -= pageHeight;
      }
      //  window.open(
      //    pdf.output("bloburl", { filename: "new-file.pdf" }),
      //    "_blank"
      //  );
      pdf.save(`${downloadFileName}.pdf`);
    }
  );
};


