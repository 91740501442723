import React, { useState, useEffect } from "react";
import useSWR from "swr";
import styled from "@emotion/styled";
import AutoSizer from "react-virtualized-auto-sizer";
import Card from "../Card";
import { TimeIntervals, fetcher } from "../../Utils/utils";
import CardInnerWithTime from "../CardInnerWithTime";
import TooltipItem from "../TooltipItem";
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalBarSeries,
  Crosshair,
} from "react-vis";
import "../../styles/Common.css";

const LegendWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const AOOProject = () => {
  const [hint, setHint] = useState(false);
  const [crosshairValues, setCrosshairValues] = useState([]);
  const [animation, setAnimation] = useState(true);
  const [notStarted, setNotStarted] = useState({});
  const [inProgress, setInProgress] = useState({});
  const [completed, setCompleted] = useState({});
  const [followUp, setFollowUp] = useState({});
  const [yDomainTopValue, setYDomainTopValue] = useState(0);

  const { data } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/stateWideAOOCounts.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  useEffect(() => {
    if (data?.securityRisks) {
      setNotStarted(
        data?.securityRisks?.filter((item) => item.label === "Not Started")[0]
      );
      setInProgress(
        data?.securityRisks?.filter((item) => item.label === "In-Progress")[0]
      );
      setCompleted(
        data?.securityRisks?.filter((item) => item.label === "Completed")[0]
      );
      setFollowUp(
        data?.securityRisks?.filter(
          (item) => item.label === "Follow-up (Provisional)"
        )[0]
      );

      let maxLOW = Math.max(...data.securityRisks.map((o) => o.low));
      let maxMED = Math.max(...data.securityRisks.map((o) => o.medium));
      let maxHIGH = Math.max(...data.securityRisks.map((o) => o.high));

      setYDomainTopValue([0, 1.3 * Math.max(maxLOW, maxMED, maxHIGH)]);
    }
  }, [data]);

  let lowData = [
    {
      x: "Not Started",
      y: notStarted.low,
      color: "green",
    },
    {
      x: "In-Progress",
      y: inProgress.low,
      color: "green",
    },
    {
      x: "Completed",
      y: completed.low,
      color: "green",
    },
    {
      x: "Follow-up (Provisional)",
      y: followUp.low,
      color: "green",
    },
  ];

  let mediumData = [
    {
      x: "Not Started",
      y: notStarted.medium,
      color: "#ff8c00",
    },
    {
      x: "In-Progress",
      y: inProgress.medium,
      color: "#ff8c00",
    },
    {
      x: "Completed",
      y: completed.medium,
      color: "#ff8c00",
    },
    {
      x: "Follow-up (Provisional)",
      y: followUp.medium,
      color: "#ff8c00",
    },
  ];

  let highData = [
    {
      x: "Not Started",
      y: notStarted.high,
      color: "#0066a6",
    },
    {
      x: "In-Progress",
      y: inProgress.high,
      color: "#0066a6",
    },
    {
      x: "Completed",
      y: completed.high,
      color: "#0066a6",
    },
    {
      x: "Follow-up (Provisional)",
      y: followUp.high,
      color: "#0066a6",
    },
  ];

  return (
    <>
      {data?.renderCard && (
        <Card>
          <CardInnerWithTime
            loading={!data}
            title="Statewide AOO Project Risk & Security Status"
            refreshedTime={data?.timeStamp ?? ""}
            mainComponent={() => (
              <>
                <div>
                  <AutoSizer disableHeight>
                    {({ width }) => (
                      <XYPlot
                        xType="ordinal"
                        colorType="literal"
                        width={width}
                        height={200}
                        yDomain={yDomainTopValue}
                        onMouseLeave={() => {
                          setHint(false);
                          setAnimation(true);
                          setCrosshairValues(false);
                        }}
                      >
                        <HorizontalGridLines />
                        <XAxis
                          style={{ fontSize: "11px", wordBreak: "break-all" }}
                        />
                        <YAxis />
                        <VerticalBarSeries
                          data={lowData}
                          barWidth={0.5}
                          animation={animation}
                          onValueMouseOver={(datapoint) => {
                            setAnimation(false);
                            setHint(datapoint);
                            setCrosshairValues([datapoint]);
                          }}
                        />
                        <VerticalBarSeries
                          data={mediumData}
                          barWidth={0.5}
                          animation={animation}
                          onValueMouseOver={(datapoint) => {
                            setAnimation(false);
                            setHint(datapoint);
                            setCrosshairValues([datapoint]);
                          }}
                        />

                        <VerticalBarSeries
                          data={highData}
                          barWidth={0.5}
                          animation={animation}
                          onValueMouseOver={(datapoint) => {
                            setAnimation(false);
                            setHint(datapoint);
                            setCrosshairValues([datapoint]);
                          }}
                        />

                        {crosshairValues && (
                          <Crosshair values={crosshairValues}>
                            <div className="ShowHint">
                              <h3>{hint.x}</h3>
                              <TooltipItem
                                fill={"green"}
                                text={`Low Priority: ${
                                  lowData.filter(
                                    (item) => item.x === hint.x
                                  )?.[0]?.y
                                }`}
                              />
                              <TooltipItem
                                fill={"#ff8c00"}
                                text={`Medium Priority: ${
                                  mediumData.filter(
                                    (item) => item.x === hint.x
                                  )?.[0]?.y
                                }`}
                              />
                              <TooltipItem
                                fill={"#0066a6"}
                                text={`High Priority: ${
                                  highData.filter(
                                    (item) => item.x === hint.x
                                  )?.[0]?.y
                                }`}
                              />
                            </div>
                          </Crosshair>
                        )}
                      </XYPlot>
                    )}
                  </AutoSizer>
                </div>

                <LegendWrap>
                  <TooltipItem fill="green" text="Low Priority" />
                  <TooltipItem fill="#ff8c00" text="Medium Priority" />
                  <TooltipItem fill="#0066a6" text="High Priority" />
                </LegendWrap>
              </>
            )}
          />
        </Card>
      )}
    </>
  );
};

export default AOOProject;
