import React, { useState, useEffect } from "react";
import CardInnerWithTime from "../CardInnerWithTime";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import useSWR from "swr";
import Card from "../Card";
import { fetcher, TimeIntervals } from "../../Utils/utils";
import "../../styles/AverageCPSolicitationTime.css";

const AverageCPSolicitationTime = () => {
  const [uniqueYears, setUniqueYears] = useState([]);
  const [refreshTime, setRefreshTime] = useState([]);

  const { data } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/cp-solicitations.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_MINUTE,
    }
  );

  useEffect(() => {
    let returnedYears = Array.from(
      new Set(data?.list?.map((item) => item?.year))
    )
      .sort()
      .reverse();
    returnedYears.length = 3;
    setUniqueYears(returnedYears);
    setRefreshTime(data?.timeStamp);
  }, [data]);

  const calculateAvg = (currentData) => {
    const reducedLength = currentData.filter((element) => {
      return element !== null;
    }).length;

    return Math.round(
      currentData.reduce((sum, acc) => sum + acc, 0) / reducedLength
    );
  };

  return (
    <>
      {data?.renderCard && (
        <Card className="AverageCPSolicitationTime">
          <CardInnerWithTime
            loading={!data}
            title="Average Active Solicitation Time by Stage"
            refreshedTime={refreshTime ?? ""}
            extraIcons={[
              {
                text: `Average Total Days includes the time spent 
from receiving the requisition to awarding the 
contract, which is inclusive of all stages (i.e. 
vendor wait time, Legal Review, and 
processing by Buyers).

Total Day Averages only reflect solicitations 
that have reached contracting stage.`,
                icon: faInfoCircle,
                style: {
                  whiteSpace: "pre-wrap",
                  overflowWrap: "break-word",
                  textAlign: "justify",
                },
              },
            ]}
            mainComponent={() => (
              <>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="6">Average Days</th>
                    </tr>
                    <tr>
                      <th>Financial Year</th>
                      <th>
                        With Agency
                        <br />
                        <small className="SubHeading">
                          including Evaluation
                        </small>
                      </th>
                      <th>In Legal Review</th>
                      <th>With Vendor</th>
                      <th>
                        Time with OMES
                        <br />
                        <small className="SubHeading">
                          including Legal, Central <br />
                          Purchasing, Signature
                        </small>
                      </th>
                      <th>
                        Total Days in Solicitations
                        <br />
                        <small className="SubHeading">
                          Total Time from Receiving
                          <br />
                          to Awarding
                        </small>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {uniqueYears.map((year, index) => {
                      const currentYear = data.list.filter(
                        (item) => item.year === year
                      );

                      const agencyData = currentYear.map(
                        (item) => item.withAgency
                      );
                      const agencyDataAvg = calculateAvg(agencyData);

                      const inLegalReviewData = currentYear.map(
                        (item) => item.inLegalReview
                      );
                      const inLegalReviewAvg = calculateAvg(inLegalReviewData);

                      const withVendorData = currentYear.map(
                        (item) => item.withVendor
                      );
                      const withVendorDataAvg = calculateAvg(withVendorData);

                      const withOmesData = currentYear.map(
                        (item) => item.withOmes
                      );
                      const withOmesDataAvg = calculateAvg(withOmesData);

                      const todayDaysData = currentYear.map(
                        (item) => item.todayDays
                      );
                      const todayDaysDataAvg = calculateAvg(todayDaysData);

                      return (
                        <tr key={year}>
                          <td className="FinancialYear">
                            {index === 0 ? year + " to date" : year}
                          </td>
                          <td className="AlignRight">
                            {!isNaN(agencyDataAvg) ? agencyDataAvg : "-"}
                          </td>
                          <td className="AlignRight">
                            {!isNaN(inLegalReviewAvg) ? inLegalReviewAvg : "-"}
                          </td>
                          <td className="AlignRight">
                            {!isNaN(withVendorDataAvg)
                              ? withVendorDataAvg
                              : "-"}
                          </td>
                          <td className="AlignRight">
                            {!isNaN(withOmesDataAvg) ? withOmesDataAvg : "-"}
                          </td>
                          <td className="AlignRight">
                            {!isNaN(todayDaysDataAvg) ? todayDaysDataAvg : "-"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}
          />
        </Card>
      )}
    </>
  );
};

export default AverageCPSolicitationTime;
