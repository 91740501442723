import React from "react";
import WeeklyNonTravel from "./WeeklyNonTravel/WeeklyNonTravel";
import ColumnSection from "../../ColumnSection";
import WeeklyTravel from "./WeeklyTravel/WeeklyTravel";
import HigherEd from "./HigherEd";
import "../../../styles/WeeklyNonTravel.css";
import "../../../styles/HigherEd.css";

const CentralReporting = () => {
  return (
    <>
      <section className="WeeklyNonTravel">
        <span className="Heading">Transaction Processing</span>
        <ColumnSection cols={2}>
          <WeeklyNonTravel />
          <WeeklyTravel />
        </ColumnSection>
      </section>

      <section className="HigherEducation">
        <ColumnSection cols={2}>
          <HigherEd />
        </ColumnSection>
      </section>
    </>
  );
};

export default CentralReporting;
