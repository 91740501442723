import {
  Tabs as OgTabs,
  TabList as OgTabList,
  Tab as OgTab,
  TabPanels as OgTabPanels,
  TabPanel as OgTabPanel,
} from "@reach/tabs";
import "@reach/tabs/styles.css";
import styled from "@emotion/styled";

export const Tabs = styled(OgTabs)``;
export const TabList = styled(OgTabList)`
  border: ${(props) => props.theme.width.none};
  background: ${(props) => props.theme.card.background};
  display: inline-block;

  > [data-reach-tab][data-selected] {
    border-bottom-color: ${({ theme }) => theme.buttons.blue.border};
    --fa-primary-color: ${({ theme }) => theme.buttons.blue.hover.icon.primary};
    --fa-secondary-color: ${({ theme }) =>
      theme.buttons.blue.hover.icon.secondary};
    background-color: ${({ theme }) => theme.buttons.blue.hover.background};
    border-color: ${({ theme }) => theme.buttons.blue.hover.border};
    color: ${({ theme }) => theme.buttons.blue.hover.text};
    text-decoration: none;
    opacity: 1;
  }
`;

export const Tab = styled(OgTab)`
  --fa-primary-color: ${({ theme }) => theme.buttons.blue.icon.primary};
  --fa-secondary-color: ${({ theme }) => theme.buttons.blue.icon.secondary};
  background-color: ${({ theme }) => theme.buttons.blue.background};
  border-radius: ${({ theme }) => theme.radius};
  border: ${({ theme }) =>
    `solid ${theme.buttons.blue.border} ${theme.width.px}`};
  color: ${({ theme }) => theme.buttons.blue.text};
  cursor: pointer;
  font-weight: ${({ theme }) => theme.text.weight.medium};
  margin: ${({ theme }) => `${theme.margin.none} ${theme.margin.xs}`};
  padding: ${({ theme }) => theme.padding.sm};
  text-align: center;
  text-decoration: none;
  transition: all 75ms cubic-bezier(0.4, 0, 1, 1);
  opacity: 0.5;

  &:first-of-type {
    margin-left: 0;
  }

  &:hover {
    --fa-primary-color: ${({ theme }) => theme.buttons.blue.hover.icon.primary};
    --fa-secondary-color: ${({ theme }) =>
      theme.buttons.blue.hover.icon.secondary};
    background-color: ${({ theme }) => theme.buttons.blue.hover.background};
    border-color: ${({ theme }) => theme.buttons.blue.hover.border};
    color: ${({ theme }) => theme.buttons.blue.hover.text};
    text-decoration: none;
  }

  &[disabled] {
    text-decoration: none;
  }

  &:focus {
    box-shadow: 0 0 0 3px ${(props) => props.theme.input.focus};
    outline: none;
  }
`;
export const TabEnhanced = styled(OgTab)`
  --fa-primary-color: ${({ theme }) => theme.buttons.blue.icon.primary};
  --fa-secondary-color: ${({ theme }) => theme.buttons.blue.icon.secondary};
  background-color: ${({ theme }) => theme.buttons.blue.background};
  border: ${({ theme }) =>
    `solid ${theme.buttons.blue.border} ${theme.width.px}`};
  color: ${({ theme }) => theme.buttons.blue.text};
  cursor: pointer;
  font-weight: ${({ theme }) => theme.text.weight.medium};
  padding: ${({ theme }) => theme.padding.sm};
  width: 11rem;
  text-align: center;
  text-decoration: none;
  transition: all 75ms cubic-bezier(0.4, 0, 1, 1);
  opacity: 0.5;

  &:first-of-type {
    margin-left: 0;
  }

  &:hover {
    --fa-primary-color: ${({ theme }) => theme.buttons.blue.hover.icon.primary};
    --fa-secondary-color: ${({ theme }) =>
      theme.buttons.blue.hover.icon.secondary};
    background-color: ${({ theme }) => theme.buttons.blue.hover.background};
    border-color: ${({ theme }) => theme.buttons.blue.hover.border};
    color: ${({ theme }) => theme.buttons.blue.hover.text};
    text-decoration: none;
  }

  &[disabled] {
    text-decoration: none;
  }

  &:focus {
    box-shadow: 0 0 0 3px ${(props) => props.theme.input.focus};
    outline: none;
  }
`;
export const TabPanels = styled(OgTabPanels)``;

export const TabPanelsEnhanced = styled(OgTabPanels)`
{
  --fa-primary-color: ${({ theme }) => theme.icon.cardPrimary};
  --fa-secondary-color: ${({ theme }) => theme.icon.cardSecondary};
  background-color: ${({ theme }) => theme.card.background};
  border: ${({ theme }) => `solid ${theme.width.px} ${theme.card.border}`};
  border-radius: ${({ theme }) => theme.radius};
  box-shadow: ${({ theme }) => theme.shadow.lg};
  color: ${({ theme }) => theme.card.text.base};
  padding: ${({ theme }) => theme.padding.sm} ${({ theme }) => theme.padding.sm};
  overflow: hidden;
  position: relative;
}`;
export const TabPanel = styled(OgTabPanel)``;
