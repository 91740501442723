import React from "react";
import { fetcher, TimeIntervals } from "../../Utils/utils";
import SingleCard from "./SingleCard";
import ColumnSection from "../../Components/ColumnSection";
import {
  Tabs,
  TabList,
  TabPanelsEnhanced,
  TabPanel,
  TabEnhanced,
} from "../../Components/Tabs";
import styled from "@emotion/styled";
import "../../styles/OfferedTraining.css";
import AOOProject from "./AOOProject";
import useSWR from "swr";


const ColumnWrap = styled.div`
  margin-bottom: 1rem;
  section > div {
    position: relative;
  }

  section > div > div:first-child {
    position: absolute;
    top: -2.7rem;
  }

  section > div > div:nth-child(2) {
    padding: 0rem;
  }
`;

const Index = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/SecurityReviewsData.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );
  if (!data && !error && !error?.status) {
    return <>No data was found</>;
  }
  if (data?.RenderCard !== undefined && !data.RenderCard) {
    return <></>;
  }
  const uniqueYears = data.YearlyGroups.map((item) => item.Year).reverse();
  uniqueYears.length = 3;

  let year1 = uniqueYears?.[0].toString().slice(2);
  let year2 = uniqueYears?.[1].toString().slice(2);
  let year3 = uniqueYears?.[2]?.toString().slice(2);

  const firstData = data.YearlyGroups.filter(
    (x) => x.Year === uniqueYears[0]
  )[0].Data;
  const secondData = data.YearlyGroups.filter(
    (x) => x.Year === uniqueYears[1]
  )[0].Data;
  let thirdData = null;
  if (uniqueYears[2]) {
    thirdData = data.YearlyGroups.filter(
      (x) => x.Year === uniqueYears[2]
    )[0].Data;
  }

  return (
    <>
      {data?.RenderCard && (
        <>
          <p style={{ gridColumn: "1/-1", marginBottom: "3.2rem" }}>
            Number of third-party security assessments and authority to
            operate orders in which our third-party security team has
            completed. View{" "}
            <a
              href="https://oklahoma.gov/omes/services/information-services/cybercommand/third-party-security.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              third-party security webpage
            </a>{" "}
            for more information.
          </p>

          <ColumnWrap>
            <ColumnSection cols={2}>
              <Tabs>
                <TabList>
                  {firstData && firstData.length > 0 && (
                    <TabEnhanced className="SmallTabs">{`FY${year1}`}</TabEnhanced>
                  )}
                  {secondData && secondData.length > 0 && (
                    <TabEnhanced className="SmallTabs">{`FY${year2}`}</TabEnhanced>
                  )}
                  {thirdData && thirdData.length > 0 && (
                    <TabEnhanced className="SmallTabs">{`FY${year3}`}</TabEnhanced>
                  )}
                </TabList>
                <TabPanelsEnhanced>
                  {firstData && firstData.length > 0 && (
                    <TabPanel>
                      <SingleCard
                        title={`Vendor Security Questionnaire Status (FY${year1})`}
                        data={firstData}
                      />
                    </TabPanel>
                  )}

                  {secondData && secondData.length > 0 && (
                    <TabPanel>
                      <SingleCard
                        title={`Vendor Security Questionnaire Status (FY${year2})`}
                        data={secondData}
                      />
                    </TabPanel>
                  )}

                  {thirdData && thirdData.length > 0 && (
                    <TabPanel>
                      <SingleCard
                        title={`Vendor Security Questionnaire Status (FY${year3})`}
                        data={thirdData}
                      />
                    </TabPanel>
                  )}
                </TabPanelsEnhanced>
              </Tabs>
              
              <AOOProject />
            </ColumnSection>
          </ColumnWrap>
        </>
      )}
    </>
  );
  };

export default Index;
