import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { ThemeProvider } from "emotion-theming";
import { AuthenticationState } from "react-aad-msal";
import { createTheme } from "react-data-table-component";
import Index from "../Pages/InformationServices";
import Reals from "../Pages/Reals";
import Piglet from "../Pages/Piglet";
import RatePacket from "../Pages/RatePacket";
import InformationSecurityReport from "../Pages/InformationSecurityReport";
import { theme } from "../theme";
import { withTracker } from "../Utils/analyticsTracker";
import "../../node_modules/react-vis/dist/style.css";
import BaseLayout from "../Layouts/BaseLayout";
import ProtectedRoute from "./ProtectedRoute";
import SecurityThreatReport from "../Pages/SecurityThreatReport";
import { RoleProvider } from "../Context/RoleContext";
import { buildApiInUrl, fetcherWithAuth } from "../Utils/utils";
import LoggingIn from "./LoggingIn";
import DevOpsTeam from "../Pages/DevOpsTeam";
import About from "../Pages/About";
import CentralPurchase from "../Pages/CentralPurchase";
import BudgetCompliance from "./DivisionItems/BPGC/BudgetCompliance";
import CapitalAssets from "./DivisionItems/CAM/CapitalAssets";
import { TEXTS } from "../Utils/staticText";
import HumanCapital from "./DivisionItems/HumanCapitalManagement/HumanCapital";
// import CallByType from "./DivisionItems/EGID/CallByType";
import LegislativePublicAffairs from "./DivisionItems/LPA/LegislativePublicAffairs";
import InternetTrafficMetrics from "../Pages/InternetTrafficMetrics";
import { CheckPrivateViewRole, PIGPRIVATEVIEW } from "../Utils/Roles";
import GeneralCounsel from "./DivisionItems/GeneralCounsel/GeneralCounsel";
import TimeReporting from "../Pages/TimeResourceReporting";
import Finance from "./DivisionItems/Finance/Finance";
import RiskAssessment from "./DivisionItems/RAC/RiskAssessment";
import CentralReporting from "./DivisionItems/CAR/CentralReporting";

// This allows for a dark mode 😎
const usedTheme = {
  ...theme.colors.light,
  ...theme.general,
};

// This is used for datagrids
createTheme("webeoi", {
  text: {
    primary: usedTheme.grid.color,
    secondary: usedTheme.grid.color,
    disabled: usedTheme.grid.color,
  },
  background: { default: usedTheme.grid.background },
  divider: { default: usedTheme.grid.border },
  selected: {
    default: usedTheme.grid.hover,
    text: usedTheme.grid.color,
  },
  highlightOnHover: {
    default: usedTheme.grid.hover,
    text: usedTheme.grid.color,
  },
  striped: {
    default: usedTheme.grid.striped,
    text: usedTheme.grid.color,
  },
  button: {
    default: usedTheme.grid.color,
    hover: usedTheme.grid.buttonHover,
    focus: usedTheme.grid.buttonHover,
    disabled: "rgba(0,0,0,.12)",
    backgroundColor: usedTheme.grid.button,
  },
});

const InternalApp = ({ authenticationState }) => {
  const [role, setRole] = useState("");
  const check = () => {
    setRole("checking");
    fetcherWithAuth(buildApiInUrl(), true)
      .then((d) => {
        setRole(d);
      })
      .catch(() => {
        setRole("");
      });
  };

  useEffect(() => {
    if (
      authenticationState === AuthenticationState.Authenticated &&
      role === ""
    ) {
      check();
    }
  }, [authenticationState, role]);

  if (role === "checking") {
    return (
      <ThemeProvider theme={usedTheme}>
        <BaseLayout
          heading="Logging In"
          disableNavigation
          disableHeader
          disableFooter
        >
          <LoggingIn />
        </BaseLayout>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={usedTheme}>
      <RoleProvider role={role}>
        <Switch>
          <Route path="/" exact>
            <BaseLayout
              heading="Performance Improvement Graph"
              headingDescription={
                <figure style={{ margin: 0 }}>
                  <blockquote style={{ margin: 0 }}>
                    <p>
                      “The Performance Improvement Graph (PIG) was created in
                      order to provide transparency and be the single pane of
                      glass solution for tracking the metrics and status for
                      OMES' most critical services.”
                    </p>
                  </blockquote>
                  <figcaption style={{ textAlign: "right" }}>
                    <cite>
                      -{TEXTS.NAME}, {TEXTS.STATE_TITLE}
                    </cite>
                  </figcaption>
                </figure>
              }
            >
              <About />
            </BaseLayout>
          </Route>

          <Route path="/budget-policy-gaming-compliance" exact>
            <BaseLayout
              heading="Budget, Policy, Gaming Compliance"
              headingDescription=""
            >
              <BudgetCompliance
                authenticationState={authenticationState}
                role={role}
              />
            </BaseLayout>
          </Route>
          <Route path="/capital-assets-management" exact>
            <BaseLayout
              heading="Capital Assets Management"
              headingDescription=""
            >
              <CapitalAssets
                authenticationState={authenticationState}
                role={role}
              />
            </BaseLayout>
          </Route>
          <Route path="/central-accounting-reporting" exact>
            <BaseLayout
              heading="Central Accounting and Reporting"
              headingDescription=""
            >
              <CentralReporting />
            </BaseLayout>
          </Route>
          <Route path="/central-purchasing" exact>
            <BaseLayout heading="Central Purchasing" headingDescription="">
              <CentralPurchase
                authenticationState={authenticationState}
                role={role}
              />
            </BaseLayout>
          </Route>
          {/* <Route path="/employees-group-insurance-division" exact>
            <BaseLayout
              heading="Employees Group Insurance Division"
              headingDescription=""
            >
              <CallByType />
            </BaseLayout>
          </Route> */}
          <Route path="/finance" exact>
            <BaseLayout
              heading="Finance"
              headingDescription={
                authenticationState === AuthenticationState.Authenticated &&
                CheckPrivateViewRole(role) ? null : (
                  <p>
                    Public metrics to come - please check for ongoing updates.
                    Log in to view secure metrics for this division.
                  </p>
                )
              }
            >
              <Finance authenticationState={authenticationState} role={role} />
            </BaseLayout>
          </Route>
          <ProtectedRoute
            path="/general-counsel"
            adGroup={PIGPRIVATEVIEW.PIGLEADERS}
            exact
          >
            <BaseLayout heading="General Counsel" headingDescription="">
              <GeneralCounsel />
            </BaseLayout>
          </ProtectedRoute>
          <Route path="/human-capital-management" exact>
            <BaseLayout
              heading="Human Capital Management"
              headingDescription=""
            >
              <HumanCapital
                authenticationState={authenticationState}
                role={role}
              />
            </BaseLayout>
          </Route>
          <Route path="/information-services" exact>
            <BaseLayout heading="Information Services" headingDescription="">
              <Index role={role} />
            </BaseLayout>
          </Route>
          <Route path="/outreach" exact>
            <BaseLayout heading="Outreach" headingDescription="">
              <LegislativePublicAffairs />
            </BaseLayout>
          </Route>
          <ProtectedRoute
            path="/grants-management-office"
            adGroup={PIGPRIVATEVIEW.PIGLEADERS}
            exact
          >
            <BaseLayout
              heading="Grants Management Office"
              headingDescription={
                <p>
                  Metrics to come for this division – please check for ongoing
                  updates to the PIG.​
                </p>
              }
            >
              {/* Call new component here */}
            </BaseLayout>
          </ProtectedRoute>
          <Route path="/risk-assessment-compliance" exact>
            <BaseLayout
              heading="Risk, Assessment & Compliance"
              headingDescription=""
            >
              <RiskAssessment authenticationState={authenticationState}
                role={role} />
            </BaseLayout>
          </Route>
          <Route path="/devops-team" exact>
            <BaseLayout
              heading="DevOps Charts"
              disableNavigation
              disableHeader
              disableFooter
            >
              <DevOpsTeam />
            </BaseLayout>
          </Route>
          <ProtectedRoute path="/reals" adGroup="director" exact>
            <BaseLayout heading="Reals">
              <Reals />
            </BaseLayout>
          </ProtectedRoute>
          <ProtectedRoute
            path="/piglet"
            adGroup={PIGPRIVATEVIEW.PIGLEADERS}
            exact
          >
            <BaseLayout
              heading="Piglet"
              disableNavigation
              disableHeader
              disableFooter
            >
              <Piglet />
            </BaseLayout>
          </ProtectedRoute>
          <Route
            path="/agency-rate-packet"
            adGroup={PIGPRIVATEVIEW.PIGLEADERS}
            exact
          >
            <BaseLayout heading="Agency Rate Packet">
              <RatePacket />
            </BaseLayout>
          </Route>
          <Route
            path="/security-threat-metrics"
            adGroup={PIGPRIVATEVIEW.PIGLEADERS}
            exact
          >
            <BaseLayout heading="Security Threat Metrics">
              <InformationSecurityReport
              />
              <SecurityThreatReport />
            </BaseLayout>
          </Route>
          <ProtectedRoute path="/time-reporting" adGroup={PIGPRIVATEVIEW.PIGLEADERS} exact>
            <BaseLayout
              heading="Time Reporting"
              headingDescription=""
            >
              <TimeReporting />
            </BaseLayout>
          </ProtectedRoute>
          <ProtectedRoute
            path="/internet-traffic-metrics"
            adGroup={PIGPRIVATEVIEW.PIGLEADERS}
            exact
          >
            <BaseLayout heading="Internet Traffic Metrics">
              <InternetTrafficMetrics />
            </BaseLayout>
          </ProtectedRoute>
          <Redirect from="/bpg" to="/budget-policy-gaming-compliance" />
          <Redirect from="/cam" to="/capital-assets-management" />
          <Redirect from="/car" to="/central-accounting-reporting" />
          <Redirect from="/cp" to="/central-purchasing" />
          {/* <Redirect from="/egid" to="/employees-group-insurance-division" /> */}
          <Redirect from="/gc" to="/general-counsel" />
          <Redirect from="/hcm" to="/human-capital-management" />
          <Redirect from="/is" to="/information-services" />
          <Redirect from="/oam" to="/outreach" />
          <Redirect from="/gmo" to="/grants-management-office" />
          <Redirect from="/ac" to="/risk-assessment-compliance" />
          <Redirect from="/rate-packet" to="/agency-rate-packet" />
          <Redirect
            from="/info-security-report"
            to="/security-threat-metrics"
          />
        </Switch>
      </RoleProvider>
    </ThemeProvider>
  );
};

InternalApp.propTypes = {
  authenticationState: PropTypes.any,
};

InternalApp.defaultProps = {
  authenticationState: null,
};

export default withTracker(InternalApp);
