import React from "react";
import styled from "@emotion/styled";
import Cell from "../../AppCTable/Cell";
import { TableStyle } from "../../BaseTable";
import "@reach/tooltip/styles.css";
import Card from "../../Card";
import CardInnerWithTime from "../../CardInnerWithTime";

const TableWrap = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;
`;
const TableTitle = styled.tr`
  background-color: #005c95;
  color: #ffffff;
`;

const InternalServiceOffering = ({ data }) => {
  let serviceOffering = data?.monthlyTaskClosedByServiceOffering ?? "";
  return (
    <Card>
      <CardInnerWithTime
        loading={!data}
        title="Monthly Tasks Closed by Service Offering"
        refreshedTime={data?.runTime ?? ""}
        mainComponent={() => (
          serviceOffering && <TableWrap>
            <TableStyle color="blue" wrapped>
              <thead>
                <TableTitle>
                  <Cell as="th" alignment="centre">
                    Service Offering
                  </Cell>
                  <Cell as="th" alignment="centre">
                    Count of Closed
                  </Cell>
                </TableTitle>
              </thead>
              <tbody>
                <tr>
                  <Cell as="td" alignment="left" key={0}>
                    govDelivery
                  </Cell>
                  <Cell as="td" alignment="right">
                    {serviceOffering["govDelivery"]}
                  </Cell>
                </tr>
                <tr>
                  <Cell as="td" alignment="left" key={1}>
                    Publications
                  </Cell>
                  <Cell as="td" alignment="right">
                    {serviceOffering["publcations"]}
                  </Cell>
                </tr>
                <tr>
                  <Cell as="td" alignment="left" key={2}>
                    Technical Documentation
                  </Cell>
                  <Cell as="td" alignment="right">
                    {serviceOffering["techDoc"]}
                  </Cell>
                </tr>
                <tr>
                  <Cell as="td" alignment="left" key={3}>
                    Website Updates
                  </Cell>
                  <Cell as="td" alignment="right">
                    {serviceOffering["webSites"]}
                  </Cell>
                </tr>
              </tbody>
            </TableStyle>
          </TableWrap>
        )}
      />
    </Card>
  );
};

export default InternalServiceOffering;
