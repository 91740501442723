import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const getCol = ({ customColumns, size, ...props }) => {
  const getSize = ({ cols, colsAtSm, colsAtMd, colsAtLg, colsAtXl, s, d }) => {
    switch (s) {
      case 'xl':
        return colsAtXl || colsAtLg || colsAtMd || colsAtSm || cols || d;
      case 'lg':
        return colsAtLg || colsAtMd || colsAtSm || cols || d;
      case 'md':
        return colsAtMd || colsAtSm || cols || d;
      case 'sm':
        return colsAtSm || cols || d;
      default:
        return cols || d;
    }
  };
  const c = getSize({
    ...props,
    s: size,
    d: customColumns ? '1fr' : 1,
  });
  return customColumns ? `${c}` : `repeat(${c}, 1fr)`;
};

const SectionWrap = styled.section`
  --col: ${(props) =>
    getCol({
      ...props,
      size: '',
    })};
  display: grid;
  grid-template-columns: var(--col);
  grid-gap: ${(props) => props.gap || props.theme.width.sm};
  grid-template-rows: auto;
  padding: ${(props) => props.theme.padding.none};

  .wide {
    grid-column: 1 / -1;
  }

  ${(props) => props.theme.breakpoints.sm} {
    --col: ${(props) =>
      getCol({
        ...props,
        size: 'sm',
      })};
  }

  ${(props) => props.theme.breakpoints.md} {
    --col: ${(props) =>
      getCol({
        ...props,
        size: 'md',
      })};
  }

  ${(props) => props.theme.breakpoints.lg} {
    --col: ${(props) =>
      getCol({
        ...props,
        size: 'lg',
      })};
  }

  ${(props) => props.theme.breakpoints.xl} {
    --col: ${(props) =>
      getCol({
        ...props,
        size: 'xl',
      })};
  }
`;

const ColumnSection = ({
  cols,
  colsAtSm,
  colsAtMd,
  colsAtLg,
  colsAtXl,
  customColumns,
  gap,
  children,
  as,
}) => (
  <SectionWrap
    cols={cols}
    colsAtSm={colsAtSm}
    colsAtMd={colsAtMd}
    colsAtLg={colsAtLg}
    colsAtXl={colsAtXl}
    customColumns={customColumns}
    gap={gap}
    as={as}
  >
    {children}
  </SectionWrap>
);

ColumnSection.propTypes = {
  cols: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colsAtSm: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colsAtMd: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colsAtLg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colsAtXl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  customColumns: PropTypes.bool,
  gap: PropTypes.string,
  children: PropTypes.any,
  as: PropTypes.string,
};

ColumnSection.defaultProps = {
  cols: 1,
  customColumns: false,
  children: null,
  as: 'section',
};

export default ColumnSection;
