import React from "react";
import useSWR from "swr";
import Card from "../../Card";
import CardInnerWithTime from "../../CardInnerWithTime";
import { H1 } from "../../Headings";
import {
  TimeIntervals,
  dateFormatWithoutYear,
  fetcher,
  formatNumber,
} from "../../../Utils/utils";
import "../../../styles/HigherEd.css";

const InnerComponent = ({ data }) => {
  let fromDate = dateFormatWithoutYear(data?.fromDate);
  let toDate = dateFormatWithoutYear(data?.toDate);

  return (
    <Card className="HigherEd">
      <CardInnerWithTime
        loading={!data}
        title="Higher Education Payroll Vouchers"
        subtitle={`${fromDate} - ${toDate}`}
        refreshedTime={data?.timeStamp ?? ""}
        mainComponent={() => (
          <section className="HigherEdInfo">
            <div className="TopHeader">
              <div className="HeadCounts">
                <H1 as="span">{formatNumber(data?.count)}</H1>
                <p>Weekly Voucher Count</p>
              </div>
              <div className="TravelDivider"></div>
              <div className="HeadCounts">
                <H1 as="span">
                  {data?.amountPaid &&
                    "$" + formatNumber(data?.amountPaid?.toFixed(2))}
                </H1>
                <p>Weekly Paid Total</p>
              </div>
            </div>
          </section>
        )}
      />
    </Card>
  );
};

const HigherEd = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/higher-ed-payroll-data.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} />;
};

export default HigherEd;
