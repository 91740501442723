import React from "react";
import useSWR from "swr";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import Card from "../Card";
import CardInnerWithTime from "../CardInnerWithTime";
import { H1, H4 } from "../Headings";
import { fetcher, TimeIntervals } from "../../Utils/utils";

const InnerComponent = ({ data }) => {
  return (
    <Card>
      <CardInnerWithTime
        loading={!data}
        title="Call Handle Time"
        refreshedTime={data?.refreshTime ?? ""}
        extraIcons={[
          {
            text: `Average Call Handle Time is the Service Desk’s ability to handle your call in as little time as possible.`,
            icon: faInfoCircle,
          },
        ]}
        mainComponent={() => (
          <H1 as="span">
            {data?.avgHandleTime !== "" ? (
              data?.avgHandleTime
            ) : (
              <H4>No data available at this time</H4>
            )}
          </H1>
        )}
      />
    </Card>
  );
};

const AverageHandleTime = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/AvgHandleTime.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_MINUTE,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} />;
};

export default AverageHandleTime;
