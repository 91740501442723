import React, { useState } from "react";
import { useTheme } from "emotion-theming";
import CardInnerWithTime from "../../CardInnerWithTime";
import ColumnSection from "../../../Components/ColumnSection";
import Card from "../../Card";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import useSWR from "swr";
import {
  TimeIntervals,
  fetcher,
  formatNumber,
  monthsList, monthsInShort
} from "../../../Utils/utils";
import {
  Hint,
  HorizontalGridLines,
  LineSeries,
  XAxis,
  FlexibleWidthXYPlot,
  YAxis,
} from "react-vis";
import TooltipItem from "../../TooltipItem";
import "../../../styles/PayrollReturn.css";

const InnerComponent = ({ data }) => {
  const theme = useTheme();
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);

  let payrollReversal = [];
  if (data?.reversalDates) {
    let d;
    let month;
    data.reversalDates.forEach((x, i) => {
      d = new Date(x.month);
      //month = monthsList[d.getMonth()] + " " + d.getFullYear();
      month = monthsInShort[d.getMonth()] + " " + d.getFullYear();
      payrollReversal.push({
        x: month,
        y: x.value,
      });
    });
  }

  let yDomainTopValue = 0;
  if (data?.reversalDates) {
    yDomainTopValue =
      Math.max(...data?.reversalDates?.map((d) => d.value)) * 1.3;
  }
  return (
    <Card className="PayrollReversalOverTime">
      <CardInnerWithTime
        loading={!data}
        title={`Payroll Reversals Over Time`}
        extraIcons={[
          {
            text: `A reversal occurs when a payroll warrant cancellation 
request is submitted by an agency`,
            icon: faInfoCircle,
            style: {
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              textAlign: "justify",
            },
          },
        ]}
        refreshedTime={data?.timeStamp ?? ""}
        mainComponent={() => (
          <div>
            <FlexibleWidthXYPlot
              height={200}
              xType="ordinal"
              yDomain={[0, yDomainTopValue]}
              onMouseLeave={() => {
                setHint(false);
                setAnimation(true);
              }}
            >
              <HorizontalGridLines />
              <XAxis/>
              <YAxis />
              <LineSeries
                data={payrollReversal}
                animation={animation}
                color={theme.colors.skyBlue.darken}
                onNearestX={(datapoint) => {
                  setAnimation(false);
                  setHint(datapoint);
                }}
              />
              {hint && (
                <Hint value={hint}>
                  <div
                    className="rv-hint__content"
                    style={{ pointerEvents: "none" }}
                  >
                    <TooltipItem
                      fill={hint.color}
                      text={`${hint.x}: ${formatNumber(hint.y, false, true)}`}
                    />
                  </div>
                </Hint>
              )}
            </FlexibleWidthXYPlot>
          </div>
        )}
      />
    </Card>
  );
};

const PayrollReversalOverTime = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/payroll-warrants-and-reversals.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} />;
};

export default PayrollReversalOverTime;
