import React, { useEffect, useState } from "react";
import useSWR from "swr";
import Card from "./Card";
import CardInnerWithTime from "./CardInnerWithTime";
import AutoSizer from "react-virtualized-auto-sizer";
import TooltipItem from "./TooltipItem";
import { TimeIntervals, fetcher, formatNumber } from "../Utils/utils";
import {
  Crosshair,
  XYPlot,
  HorizontalGridLines,
  XAxis,
  YAxis,
  VerticalBarSeries,
} from "react-vis";
import "../styles/Common.css";
import "../styles/EndUserCasesByPercentage.css";

const InnerComponent = ({ data }) => {
  const [hint, setHint] = useState(false);
  const [crosshairValues, setCrosshairValues] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [animation, setAnimation] = useState(true);
  let yDomainTopValue = 0;

  let maxValue = Math.max(...data?.data?.data?.[0].map((d) => d.y));
  yDomainTopValue = [0, 1.2 * maxValue];

  useEffect(() => {
    let temp = data?.data?.data?.[0];
    if (data?.data?.data?.[0].length > 12) {
      temp = data?.data?.data?.[0].reverse();
      temp.length = 12;
      setFilteredData(temp.reverse());
    }
    setFilteredData(temp);

    Object.entries(temp).forEach((x, i) => {
      x[1].color = "#005c95";
    });
  }, [data]);

  return (
    <Card className="EndUserCasesByPercentage">
      <CardInnerWithTime
        loading={!data}
        title="End User - Percentage of Open Cases"
        refreshedTime={data?.timeStamp ?? ""}
        mainComponent={() => (
          <>
            <AutoSizer disableHeight>
              {({ width }) => (
                <XYPlot
                  xType="ordinal"
                  colorType="literal"
                  width={width}
                  height={200}
                  yDomain={yDomainTopValue}
                  onMouseLeave={() => {
                    setHint(false);
                    setCrosshairValues(false);
                  }}
                >
                  <HorizontalGridLines />
                  <XAxis tickLabelAngle={-45} />
                  <YAxis tickFormat={(d) => `${d}%`} />

                  <VerticalBarSeries
                    data={filteredData}
                    barWidth={0.5}
                    color={"#005c95"}
                    animation={animation}
                    onValueMouseOver={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                      setCrosshairValues([datapoint]);
                    }}
                    onValueMouseOut={() => {
                      setHint(false);
                      setAnimation(true);
                    }}
                  />

                  {crosshairValues && (
                    <Crosshair values={crosshairValues}>
                      <div className="ShowHint">
                        <TooltipItem
                          fill={hint?.color}
                          text={`${hint?.x}: ${Number(hint?.y).toFixed(2)}%`}
                        />
                      </div>
                    </Crosshair>
                  )}
                </XYPlot>
              )}
            </AutoSizer>
          </>
        )}
      />
    </Card>
  );
};

const EndUserCasesByPercentage = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/is-percent-enduseropened.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} />;
};

export default EndUserCasesByPercentage;
