import React from "react";
import { AuthenticationState } from "react-aad-msal";
import { CheckPrivateViewRole } from "../../../Utils/Roles";
import ColumnSection from "../../ColumnSection";
import Budget from "./Budget";
import Gaming from "./Gaming";
import CasinoSite from "./CasinoSite";
import "../../../styles/BudgetCompliance.css";

const BudgetCompliance = ({ authenticationState, role }) => {
  return (
    <>
      <section className="Gaming">
        <Gaming />
      </section>

      <section className="CasinoSite">
        <ColumnSection cols={2}>
          {authenticationState === AuthenticationState.Authenticated &&
            CheckPrivateViewRole(role) && <CasinoSite />}
        </ColumnSection>
      </section>

      {authenticationState === AuthenticationState.Authenticated &&
        CheckPrivateViewRole(role) && (
          <Budget authenticationState={authenticationState} role={role} />
        )}
    </>
  );
};

export default BudgetCompliance;
