import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEquals, faTimes } from '@fortawesome/pro-duotone-svg-icons';

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr auto 1fr;
  justify-items: center;
  align-items: center;
  margin: 0 11rem;

  & .with-denominator {
    display: grid;
    grid-template-rows: auto auto;
    justify-items: center;

    & .denominator:before {
      content: '';
      display: block;
      background-color: currentColor;
      height: ${({ theme }) => theme.width['3xs']};
    }
  }
`;

const Formula = ({ numerator, multiplier, equals, denominator = null }) => (
  <Wrap>
    <div className={denominator && 'with-denominator'}>
      <span>{numerator}</span>
      {denominator && <span className="denominator">{denominator}</span>}
    </div>
    <div>
      <FontAwesomeIcon icon={faTimes} fixedWidth />
    </div>
    <div>{multiplier}</div>
    <div>
      <FontAwesomeIcon icon={faEquals} fixedWidth />
    </div>
    <div>{equals}</div>
  </Wrap>
);

export default Formula;
