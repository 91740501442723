import React from "react";
import useSWR from "swr";
import Card from "../../Card";
import CardInnerWithTime from "../../CardInnerWithTime";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { H1, H4 } from "../../Headings";
import { TimeIntervals, fetcher } from "../../../Utils/utils";
import "../../../styles/RiskAssessment.css";

const InnerComponent = ({ data }) => {
  const { number, title } = data?.data?.[0];

  return (
    <Card>
      <CardInnerWithTime
        loading={!data}
        title="584 Annual Security Risk Assessments Received"
        refreshedTime={data?.timeStamp ?? ""}
        extraIcons={[
          {
            text: `584 Risk Assessments - Annual security risk
assessment submissions required from state agencies
to identify vulnerabilities associated with informational
technology systems. Assessments are submitted in
December and contain data for the prior fiscal year.`,
            icon: faInfoCircle,
            style: {
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              textAlign: "justify",
            },
          },
        ]}
        mainComponent={() => (
          <>
            <H1 as="span">
              {number !== "" ? number : <H4>No data available</H4>}
            </H1>
            <div className="YearValue">{title !== "" && title}</div>
          </>
        )}
      />
    </Card>
  );
};

const Data584 = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/rac-584-risk-assessments.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} />;
};

export default Data584;
