import React, { useState } from "react";
import Card from "../../Card";
import CardTopItems from "../../CardTopItems";
import styled from "@emotion/styled";
import AutoSizer from "react-virtualized-auto-sizer";
import AuthorizedArea from "../../AuthorizedArea";
import { H3 } from "../../Headings";
import { faClock } from "@fortawesome/pro-duotone-svg-icons";
import { TimeIntervals, buildApiInternalUrl } from "../../../Utils/utils";
import { Hint, RadialChart } from "react-vis";
import TooltipItem from "../../TooltipItem";
import Legend from "../../BaseCharts/Legend";
import "../../../styles/Budget.css";

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const PieChart = ({ data }) => {
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);

  return (
    <Card className="CasinoSite">
      <CardTopItems
        items={[
          {
            text: `As of ${new Date(data?.timeStamp).toLocaleString()}`,
            icon: faClock,
          },
        ]}
      />
      <H3>{`Casino Site Visits Performed`}</H3>
      <Wrap>
        <div>
          <AutoSizer disableHeight>
            {({ width }) => (
              <RadialChart
                colorType="literal"
                innerRadius={0}
                radius={100}
                data={data?.data}
                animation={animation}
                onValueMouseOver={(datapoint) => {
                  setAnimation(false);
                  setHint(datapoint);
                }}
                onValueMouseOut={() => {
                  setHint(false);
                  setAnimation(true);
                }}
                width={width}
                height={200}
              >
                {hint && (
                  <Hint value={hint}>
                    <div
                      className="rv-hint__content"
                      style={{ pointerEvents: "none" }}
                    >
                      <TooltipItem
                        fill={hint.color}
                        text={`${hint.text}: ${hint.value}%`}
                      />
                    </div>
                  </Hint>
                )}
              </RadialChart>
            )}
          </AutoSizer>
        </div>
        <Legend>
          {data?.data?.map((x, i) => (
            <TooltipItem key={i} fill={x.color} text={`${x.text}`} />
          ))}
        </Legend>
      </Wrap>
    </Card>
  );
};

const CasinoSite = () => (
  <AuthorizedArea
    url={buildApiInternalUrl("bpgc-casinositevisit")}
    refreshInterval={TimeIntervals.ONE_DAY}
    innerComponent={({ data, error }) => {
      if (!data) {
        return <>No data was found</>;
      }

      return <>{data?.renderCard && <PieChart data={data} />}</>;
    }}
  />
);

export default CasinoSite;
