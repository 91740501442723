import React from "react";
import useSWR from "swr";
import { fetcher, TimeIntervals } from "../../../Utils/utils";
import ColumnSection from "../../ColumnSection";
import SectionHeading from "../../../Components/SectionHeading";
import InternalServiceOffering from "./InternalServiceOffering";
import InternalDivisions from "./InternalDivisions";
import "../../../styles/LegislativePublicAffairs.css";
import PublishedStandards from "../../Cards/PublishedStandards";

const InnerComponent = ({ data }) => {
  return (
    <>
      <SectionHeading title="Internal Communications" description="" />
      <ColumnSection cols={1} colsAtMd={2}>
        <InternalServiceOffering data={data} />
        <InternalDivisions data={data} />
      </ColumnSection>

      <ColumnSection cols={1} colsAtMd={2}>
        <PublishedStandards />
      </ColumnSection>
    </>
  );
};
const LPAInternalComm = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/LPA-internal-comm.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (error?.status === 401) {
    return <></>;
  }

  return <InnerComponent data={data} />;
};

export default LPAInternalComm;
