import React from "react";
import { AuthenticationState } from "react-aad-msal";
import { CheckPrivateViewRole } from "../../../Utils/Roles";
import ColumnSection from "../../../Components/ColumnSection";
import WorkByDuration from "./WorkByDuration";
import WorkByPropertyCodeStatusTab from "./WorkPropertyCodeStatusTab";
import "../../../styles/CapitalAssets.css";
import MaintenanceNeeds from "./MaintenanceNeeds";

const InnerComponent = ({ authenticationState, role }) => {
  return (
    <div className="GraphTab">
      <span className="Heading">Facilities Management</span>
      <section className="MaintenanceTab">
      {/* {authenticationState === AuthenticationState.Authenticated &&
          CheckPrivateViewRole(role) && <MaintenanceNeeds />} */}
        <MaintenanceNeeds />
      </section>

      <ColumnSection cols={2}>
        <WorkByPropertyCodeStatusTab />
        {authenticationState === AuthenticationState.Authenticated &&
          CheckPrivateViewRole(role) && <WorkByDuration />}
      </ColumnSection>
    </div>
  );
};

const FacilitiesManagement = ({ authenticationState, role }) => {
  return (
    <InnerComponent authenticationState={authenticationState} role={role} />
  );
};

export default FacilitiesManagement;
