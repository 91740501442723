import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Cell from "../AppCTable/Cell";
import { formatNumber } from "../../Utils/utils";
import { TableStyle } from "../BaseTable";
import "@reach/tooltip/styles.css";
import CardTopItems from "../CardTopItems";
import { H2 } from "../Headings";
import { faClock } from "@fortawesome/pro-duotone-svg-icons";
import CPFeeCollection from "./CPFeeCollection";
import AzureAD, { AuthenticationState } from "react-aad-msal";
import { authProvider } from "../../authProvider";
import { CheckEmployeeViewRole } from "../../Utils/Roles";

const TableWrap = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;
`;
const TableTitle = styled.tr`
  background-color: #005c95;
  color: #ffffff;
`;

const FirstColumn = styled.td`
  text-align: left;
`;

const CPInnerBodySection = ({ role, sheetData, h2Title }) => {
  const runTime = new Date(sheetData[0].runDate);
  const topIcons = [
    {
      text: `As of ${runTime?.toLocaleString()}`,
      icon: faClock,
    },
  ];
  return (
    <>
      <AzureAD provider={authProvider}>
        {({ authenticationState }) => {
          return (
            authenticationState === AuthenticationState.Authenticated &&
            CheckEmployeeViewRole(role) && (
              <CPFeeCollection
                title={h2Title}
                runTime={runTime}
              />
            )
          );
        }}
      </AzureAD>
      <CardTopItems items={topIcons} />
      <H2>Total Dollars Awarded in Statewide Contracts</H2>
      {sheetData?.map((eachSheet, Idx) => (
        <TableWrap>
          <TableStyle color="blue" wrapped>
            <thead>
              <TableTitle>
                <Cell as="th" alignment="left" colSpan="6">
                  {eachSheet.title}
                </Cell>
              </TableTitle>
              <tr>
                {eachSheet.headers?.map((eachHeader, Idx) => (
                  <Cell as="th" alignment="centre" key={Idx}>
                    {eachHeader}
                  </Cell>
                ))}
              </tr>
            </thead>
            <tbody>
              {eachSheet.data?.map((eachData, Idx) =>
                eachData[0].toLowerCase() === "total" ? (
                  <Cell as="tr">
                    {eachData?.map((eachTd, index) =>
                      index === 0 ? (
                        <FirstColumn  key={index}>
                          <b>{eachTd}</b>
                        </FirstColumn>
                      ) : (
                        <Cell as="td"  key={index}>
                          <b>
                            {formatNumber(eachTd, true, false, false, true)}
                          </b>
                        </Cell>
                      )
                    )}
                  </Cell>
                ) : (
                  <Cell as="tr">
                    {eachData?.map((eachTd, index) =>
                      index === 0 ? (
                        <FirstColumn key={index}>{eachTd}</FirstColumn>
                      ) : (
                        <Cell as="td"  key={index}>
                          {formatNumber(eachTd, true, false, false, true)}
                        </Cell>
                      )
                    )}
                  </Cell>
                )
              )}
            </tbody>
          </TableStyle>
        </TableWrap>
      ))}
    </>
  );
};

CPInnerBodySection.propTypes = {
  header: PropTypes.any,
  data: PropTypes.any,
};

CPInnerBodySection.defaultProps = {
  header: null,
  data: null,
};

export default CPInnerBodySection;
