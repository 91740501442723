import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import {
  XYPlot,
  HorizontalGridLines,
  VerticalGridLines,
  VerticalBarSeries,
  XAxis,
  YAxis,
  ChartLabel,
  DiscreteColorLegend,
  Crosshair,
} from "react-vis";
import AutoSizer from "react-virtualized-auto-sizer";
import { useTheme } from "emotion-theming";
import { formatNumber } from "../../Utils/utils";
import "../../styles/Common.css";
import TooltipItem from "../TooltipItem";

const Wrap = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.ledgedLabels ? `1fr auto` : `1fr`};
`;

const VerticalBarChart = ({
  data,
  colorOffset,
  gridLinesVertical,
  gridLinesHorizontal,
  height,
  labelX,
  labelY,
  showX,
  showY,
  showRotateX,
  graphColor,
  ordinalX,
  yAxisFormat,
  xAxisFormat,
  ledgedLabels,
  colorPassed,
  xTicks,
  largeNumberYaxis,
  yDomainTopValue,
  showHint,
}) => {
  const theme = useTheme();

  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);
  const [legendWidth, setLegendWidth] = useState(0);
  const [crosshairValues, setCrosshairValues] = useState([]);

  const topMargin = 20;
  const rightMargin = 20;
  const bottomMargin = showX && labelX ? 45 : 40;
  const leftMargin = showY && largeNumberYaxis ? 48 : showY && labelY ? 45 : 40;

  const legendRef = React.createRef();

  useEffect(() => {
    if (legendRef.current) {
      setLegendWidth(legendRef.current.getBoundingClientRect().width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const usedColorRange = theme.charts;
  if (colorOffset > 0) {
    const shiftedColors = usedColorRange.splice(0, colorOffset);
    usedColorRange.push(...shiftedColors);
  }

  return (
    <Wrap ledgedLabels={ledgedLabels}>
      <AutoSizer disableHeight>
        {({ width }) => (
          <div>
            <XYPlot
              color={graphColor}
              height={height}
              width={width - legendWidth}
              margin={{
                top: topMargin,
                right: rightMargin,
                bottom: bottomMargin,
                left: leftMargin,
              }}
              xType={ordinalX ? "ordinal" : "linear"}
              onMouseLeave={() => {
                setHint(false);
                setAnimation(true);
              }}
              yDomain={yDomainTopValue}
            >
              {/* Building Grid */}
              {gridLinesVertical && <VerticalGridLines />}
              {gridLinesHorizontal && <HorizontalGridLines />}

              {/* Building the X Axis */}
              {showX && (
                <XAxis
                  tickFormat={(d) => (xAxisFormat ? xAxisFormat(d) : d)}
                  tickTotal={xTicks}
                />
              )}
              {showRotateX && (
                <XAxis
                  tickFormat={(d) => (xAxisFormat ? xAxisFormat(d) : d)}
                  tickTotal={xTicks}
                  tickLabelAngle={-90}
                />
              )}
              {showX && labelX && (
                <ChartLabel
                  text={labelX}
                  xPercent={0.5}
                  style={{
                    transform: `translate(0 ${height - 49})`,
                    fontWeight: theme.text.weight.bold,
                    fontFamily: theme.text.family,
                  }}
                />
              )}

              {/* Building the Y Axis */}
              {showY && (
                <YAxis tickFormat={(d) => (yAxisFormat ? yAxisFormat(d) : d)} />
              )}
              {showY && labelY && (
                <ChartLabel
                  text={labelY}
                  style={{
                    transform: "rotate(-90) translate(-50 10)",
                    textAnchor: "middle",
                    fontWeight: theme.text.weight.bold,
                    fontFamily: theme.text.family,
                    letterSpacing: ".1em",
                  }}
                />
              )}

              {/* Building the Series */}
              {data.map((d, i) => (
                <VerticalBarSeries
                  key={i}
                  colorType={colorPassed ? "literal" : "category"}
                  data={d.setData}
                  animation={animation}
                  onValueMouseOver={(datapoint) => {
                    setAnimation(false);
                    setHint(datapoint);
                    setCrosshairValues([datapoint]);
                  }}
                  onValueMouseOut={() => {
                    setHint(false);
                    setAnimation(true);
                    setCrosshairValues(false);
                  }}
                />
              ))}

              {/* Building the Hint */}

              {showHint && (
                <Crosshair values={crosshairValues}>
                  <div className="ShowHint">
                    <TooltipItem
                      fill={graphColor}
                      text={`${hint.x}: ${formatNumber(hint.y)}`}
                    />
                  </div>
                </Crosshair>
              )}
            </XYPlot>
          </div>
        )}
      </AutoSizer>
      {ledgedLabels && (
        <div ref={legendRef}>
          <DiscreteColorLegend
            items={ledgedLabels}
            // colors={usedColorRange}
          />
        </div>
      )}
    </Wrap>
  );
};

VerticalBarChart.propTypes = {
  data: PropTypes.array,
  colorOffset: PropTypes.number,
  gridLinesVertical: PropTypes.bool,
  gridLinesHorizontal: PropTypes.bool,
  height: PropTypes.number,
  hintFormat: PropTypes.func,
  yAxisFormat: PropTypes.func,
  labelX: PropTypes.string,
  labelY: PropTypes.string,
  showX: PropTypes.bool,
  showY: PropTypes.bool,
  ordinalX: PropTypes.bool,
  xAxisFormat: PropTypes.func,
  ledgedLabels: PropTypes.array,
  colorPassed: PropTypes.bool,
  xTicks: PropTypes.number,
  largeNumberYaxis: PropTypes.bool,
  yDomainTopValue: PropTypes.array,
};

VerticalBarChart.defaultProps = {
  data: null,
  colorOffset: 0,
  gridLinesVertical: true,
  gridLinesHorizontal: true,
  height: 200,
  hintFormat: null,
  yAxisFormat: null,
  labelX: null,
  labelY: null,
  showX: false,
  showY: false,
  ordinalX: false,
  xAxisFormat: null,
  ledgedLabels: null,
  colorPassed: false,
  xTicks: null,
  largeNumberYaxis: false,
  yDomainTopValue: null,
};

export default VerticalBarChart;
