import React from "react";
import PayrollReturn from "./PayrollReturn";
import PayrollReversalOverTime from "./PayrollReversalOverTime";
import ColumnSection from "../../ColumnSection";

const PayrollWarrentsandReversal = () => {
  return (
    <>
      <ColumnSection cols={2}>
        <PayrollReturn />
        <PayrollReversalOverTime />
      </ColumnSection>
    </>
  );
};

export default PayrollWarrentsandReversal;
