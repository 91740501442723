import React from "react";
import ColumnSection from "../ColumnSection";
import SectionHeading from "../SectionHeading";
import MajorIncidents from "./MajorIncidents";
import ScheduledMaintenance from "./ScheduledMaintenance";

const ITOps = () => {
  return (
    <>
      <SectionHeading
        title="IT Operations"
        description="Number of major incidents and scheduled maintenance for IT systems completed over the past 30 days."
      />

      <ColumnSection cols={1} colsAtMd={2}>
        <MajorIncidents />
        <ScheduledMaintenance />
      </ColumnSection>
    </>
  );
};

export default ITOps;
