import React from "react";
import { TimeIntervals, fetcher } from "../../Utils/utils";
import styled from "@emotion/styled";
import CardTopItems from "../CardTopItems";
import { faClock, faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import useSWR from "swr";
import Card from "../Card";
import Datagrid from "../Datagrid";

const Heading = styled.div`
  color: #464646;
  font-family: "Open Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: left;
`;

const MapFormat = (d) =>
  d?.map((x) => ({
    contractNumber: `${x[0]}`,
    paper: `${x[1]}`,
    category: `${x[2]}`,
    firstWeek: `${x[3]}`,
    secondWeek: `${x[4]}`,
    thirdWeek: `${x[5]}`,
    forthWeek: `${x[6]}`,
    fifthWeek: `${x[7]}`,
  }));

const ISSolicitationsProcurement = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/procurement-solicitations.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );
  if (!data && !error && !error?.status) {
    return <></>;
  }
  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  const topIcons = [
    {
      text: `• Evaluation = team is working on responses and scoring
• In ePro = solicitation requisition and documents have been
entered into approval workflow for CP to convert to an RFP
• Writing = getting scope/specifications, develop evaluation
tool,eval team members, category codes, suggested vendors
and exhibits for specs and pricing
• Legal = has been assigned and is in active negotiations.
One statewide with multiple awardees may be split among legal staff
• Paused = legal has discontinued negotiation until further notice`,
      icon: faInfoCircle,
      style: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "justify",
      },
    },
    {
      text: `As of ${new Date(data?.runDate).toLocaleString()}`,
      icon: faClock,
    },
  ];

  const columns = [];
  const headers = data?.headers;
  const rows = [
    "contractNumber",
    "paper",
    "category",
    "firstWeek",
    "secondWeek",
    "thirdWeek",
    "forthWeek",
    "fifthWeek",
  ];
  headers.map((item, Idx) =>
    columns.push({
      name: item,
      selector: rows[Idx],
      wrap: true,
    })
  );
  const formattedData = MapFormat(data.data);
  return (
    <Card>
      <CardTopItems items={topIcons} />
      <Heading>IS Solicitations Status</Heading>
      <p>
        Displays the status of IS Solicitations across the current week and
        prior 4 weeks.
      </p>
      <Datagrid
        columns={columns}
        expandable={false}
        data={formattedData}
        subHeader={false}
      />
    </Card>
  );
};
export default ISSolicitationsProcurement;
