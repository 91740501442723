import React from 'react';
import PropTypes from 'prop-types';
import { H2 } from './Headings';

const SectionHeading = ({ title, description }) => (
  <>
    <H2>{title}</H2>
    <p>{description}</p>
  </>
);

SectionHeading.propTypes = { title: PropTypes.any, description: PropTypes.any };

export default SectionHeading;
