import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  XYPlot,
  VerticalBarSeries,
  XAxis,
  YAxis,
  Hint,
  AreaSeries,
  LineSeries,
  MarkSeries,
} from 'react-vis';
import TooltipItem from './TooltipItem';
import { DateShortString } from '../Utils/utils';

const Wrap = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.padding.md};
`;

const Velocity = styled.div`
  --fa-primary-color: ${({ theme }) => theme.icon.cardPrimary};
  --fa-secondary-color: ${({ theme }) => theme.icon.cardSecondary};
  background-color: ${({ theme }) => theme.card.background};
  border: ${({ theme }) => `solid ${theme.width.px} ${theme.card.border}`};
  border-radius: ${({ theme }) => theme.radius};
  box-shadow: ${({ theme }) => theme.shadow.lg};
  color: ${({ theme }) => theme.card.text.base};
  padding: ${({ theme }) => theme.padding.sm} ${({ theme }) => theme.padding.md};
  overflow: hidden;
  position: relative;
  width: 100%;
  grid-template-rows: auto auto 1fr;
  display: grid;
`;
const VelocityTopBar = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  span:nth-of-type(2) {
    justify-self: right;
  }
`;

const Burndown = styled.div`
  --fa-primary-color: ${({ theme }) => theme.icon.cardPrimary};
  --fa-secondary-color: ${({ theme }) => theme.icon.cardSecondary};
  background-color: ${({ theme }) => theme.card.background};
  border: ${({ theme }) => `solid ${theme.width.px} ${theme.card.border}`};
  border-radius: ${({ theme }) => theme.radius};
  box-shadow: ${({ theme }) => theme.shadow.lg};
  color: ${({ theme }) => theme.card.text.base};
  padding: ${({ theme }) => theme.padding.sm} ${({ theme }) => theme.padding.md};
  overflow: hidden;
  position: relative;
  width: 100%;
  grid-template-rows: auto auto 1fr;
  display: grid;
`;

const BurndownTopBar = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  span:nth-of-type(2) {
    justify-self: center;
  }

  span:nth-of-type(3) {
    justify-self: right;
  }
`;

const Legend = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: ${({ theme }) => theme.text.size.sm};
`;

const BuildQueryUrl = (ids, includeHours = false, agency = 'OMES') =>
  `https://dev.azure.com/oklahomadev/${
    agency || 'OMES'
  }/_workitems?_a=query&wiql=SELECT [System.Id], [System.WorkItemType], [System.Title], [System.AssignedTo], [System.State], [System.TeamProject], ${
    includeHours
      ? '[Microsoft.VSTS.Scheduling.OriginalEstimate], [Microsoft.VSTS.Scheduling.RemainingWork], [Microsoft.VSTS.Scheduling.CompletedWork]'
      : '[Microsoft.VSTS.Scheduling.StoryPoints]'
  } FROM workitems WHERE [System.Id] IN (${ids})`;

const DevOpsCharts = ({ data, team, agency }) => {
  const [hint, setHint] = useState(false);
  const [hintB, setHintB] = useState(false);
  const [seriesHovered, setSeriesHovered] = useState(-1);
  const [animation, setAnimation] = useState(true);
  const [legendVelocityRect, setLegendVelocityRect] = useState(0);
  const [legendBurndownRect, setLegendBurndownRect] = useState(0);

  const legendVelocityRef = React.createRef();
  const legendBurndownRef = React.createRef();

  useEffect(() => {
    if (legendVelocityRef.current) {
      setLegendVelocityRect(legendVelocityRef.current.getBoundingClientRect());
    }
    if (legendBurndownRef.current) {
      setLegendBurndownRect(legendBurndownRef.current.getBoundingClientRect());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const velocity = data.teamVelocity.sort((a, b) =>
    new Date(a.startDate) > new Date(b.startDate) ? 1 : -1
  );

  const bdStart = DateShortString(data.teamBurndown[0].data[0].dayOfSprint);
  const bdEnd = DateShortString(data.teamBurndown[0].data[13].dayOfSprint);
  const bdCompleted = data.teamBurndown[0].data[0].completedPerc;
  const bdNotEstIds = data.teamBurndown[0].data[0].notEstimatedIncludedIds;
  const bdNotEst = data.teamBurndown[0].data[0].notEstimated;
  const bdRemainingIds = data.teamBurndown[0].data[0].remainingWorkIncludedIds;
  const bdRemaining = data.teamBurndown[0].data[0].remainingWork;

  const dbTop = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  return (
    <Wrap>
      <Velocity>
        <VelocityTopBar>
          <span>Velocity</span>
          <span>Average Velocity {data.teamVelocity[0].averageCompleted}</span>
        </VelocityTopBar>
        <div>Last 6 sprints</div>
        <div>
          <AutoSizer>
            {({ width, height }) => (
              <XYPlot
                width={width}
                height={height - legendVelocityRect.height}
                stackBy="y"
                xType="ordinal"
                onMouseLeave={() => {
                  setHintB(false);
                  setAnimation(true);
                }}
              >
                <XAxis tickFormat={(d) => `${velocity[d].label}`} />
                <YAxis />

                <VerticalBarSeries
                  cluster="planned"
                  color="#86ccde"
                  style={{ cursor: 'pointer' }}
                  data={velocity.map((x, i) => ({
                    x: i,
                    y: x.planned,
                  }))}
                  onValueMouseOver={(datapoint) => {
                    setAnimation(false);
                    setHintB(datapoint);
                  }}
                  onValueMouseOut={() => {
                    setHintB(false);
                    setAnimation(true);
                  }}
                  onValueClick={(datapoint) => {
                    window.open(
                      BuildQueryUrl(
                        velocity[datapoint.x].plannedIds,
                        false,
                        agency
                      ),
                      '_blank'
                    );
                  }}
                />

                <VerticalBarSeries
                  cluster="completed"
                  color="#107c10"
                  style={{ cursor: 'pointer' }}
                  data={velocity.map((x, i) => ({
                    x: i,
                    y: x.completed,
                  }))}
                  onValueMouseOver={(datapoint) => {
                    setAnimation(false);
                    setHintB(datapoint);
                  }}
                  onValueMouseOut={() => {
                    setHintB(false);
                    setAnimation(true);
                  }}
                  onValueClick={(datapoint) => {
                    window.open(
                      BuildQueryUrl(
                        velocity[datapoint.x].completedIds,
                        false,
                        agency
                      ),
                      '_blank'
                    );
                  }}
                />
                <VerticalBarSeries
                  cluster="completed"
                  color="#0078d4"
                  style={{ cursor: 'pointer' }}
                  data={velocity.map((x, i) => ({
                    x: i,
                    y: x.incomplete,
                  }))}
                  onValueMouseOver={(datapoint) => {
                    setAnimation(false);
                    setHintB(datapoint);
                  }}
                  onValueMouseOut={() => {
                    setHintB(false);
                    setAnimation(true);
                  }}
                  onValueClick={(datapoint) => {
                    window.open(
                      BuildQueryUrl(
                        velocity[datapoint.x].incompleteIds,
                        false,
                        agency
                      ),
                      '_blank'
                    );
                  }}
                />
                <VerticalBarSeries
                  cluster="completed"
                  color="#8dc54b"
                  style={{ cursor: 'pointer' }}
                  data={velocity.map((x, i) => ({
                    x: i,
                    y: x.completedLate,
                  }))}
                  onValueMouseOver={(datapoint) => {
                    setAnimation(false);
                    setHintB(datapoint);
                  }}
                  onValueMouseOut={() => {
                    setHintB(false);
                    setAnimation(true);
                  }}
                  onValueClick={(datapoint) => {
                    window.open(
                      BuildQueryUrl(
                        velocity[datapoint.x].completedLateIds || '',
                        false,
                        agency
                      ),
                      '_blank'
                    );
                  }}
                />

                {hintB && (
                  <Hint value={hintB}>
                    <div
                      className="rv-hint__content"
                      style={{ pointerEvents: 'none' }}
                    >
                      <TooltipItem text={`${velocity[hintB.x].label}`} />
                      <TooltipItem
                        fill="#86ccde"
                        text={`Planned: ${velocity[hintB.x].planned}`}
                      />
                      <TooltipItem
                        fill="#107c10"
                        text={`Completed: ${velocity[hintB.x].completed}`}
                      />
                      <TooltipItem
                        fill="#0078d4"
                        text={`Incomplete: ${velocity[hintB.x].incomplete}`}
                      />
                      <TooltipItem
                        fill="#8dc54b"
                        text={`Completed Late: ${
                          velocity[hintB.x].completedLate
                        }`}
                        noBreak
                      />
                    </div>
                  </Hint>
                )}
              </XYPlot>
            )}
          </AutoSizer>
          <Legend ref={legendVelocityRef}>
            <TooltipItem fill="#86ccde" text="Planned" noBreak />
            {'  '}
            <TooltipItem fill="#107c10" text="Completed" noBreak />
            {'  '}
            <TooltipItem fill="#0078d4" text="Incomplete" noBreak />
            {'  '}
            <TooltipItem fill="#8dc54b" text="Completed Late" noBreak />
          </Legend>
        </div>
      </Velocity>

      <Burndown>
        <div>
          Burndown Trend for {bdStart} to {bdEnd}
        </div>
        <BurndownTopBar>
          <span>Completed {bdCompleted}%</span>
          <span>
            Items not estimated{' '}
            <a
              href={BuildQueryUrl(bdNotEstIds, false, agency)}
              target="_blank"
              rel="noreferrer noopener"
            >
              {bdNotEst}
            </a>
          </span>
          <span>
            Remaining Work hours{' '}
            <a
              href={BuildQueryUrl(bdRemainingIds, false, agency)}
              target="_blank"
              rel="noreferrer noopener"
            >
              {bdRemaining}
            </a>
          </span>
        </BurndownTopBar>
        <div>
          <AutoSizer>
            {({ width, height }) => (
              <XYPlot
                width={width}
                height={height - legendBurndownRect.height}
                xType="ordinal"
                onMouseLeave={() => {
                  setHint(false);
                  setSeriesHovered(-1);
                  setAnimation(true);
                }}
              >
                <XAxis
                  tickFormat={(d) =>
                    DateShortString(data.teamBurndown[0].data[d].dayOfSprint)
                  }
                />
                <YAxis />

                {data.teamBurndown.map((type, index) => (
                  <AreaSeries
                    key={index}
                    curve="linear"
                    color={type.color}
                    data={type.data
                      .filter((x) => new Date(x.dayOfSprint) <= new Date())
                      .map((d, dIndex) => {
                        const ret = {
                          x: dIndex,
                          y: d.storyPoint + dbTop[dIndex],
                          y0: dbTop[dIndex],
                        };
                        dbTop[dIndex] += d.storyPoint;
                        return ret;
                      })}
                    animation={animation}
                    onNearestXY={(datapoint) => {
                      setAnimation(false);
                      setSeriesHovered(datapoint.x);
                      setHint(datapoint);
                    }}
                    onSeriesMouseOut={() => {
                      setHint(false);
                      setSeriesHovered(-1);
                      setAnimation(true);
                    }}
                  />
                ))}

                <MarkSeries
                  data={dbTop.map((x, i) => ({
                    x: i,
                    y: x,
                    size: 10,
                    opacity: seriesHovered === i ? 1 : 0,
                  }))}
                  fill="#9d9d9d"
                  stroke="#fff"
                  style={{ cursor: 'pointer' }}
                  sizeRange={[0, 5]}
                  animation={animation}
                  onNearestXY={(datapoint) => {
                    setAnimation(false);
                    setSeriesHovered(datapoint.x);
                    setHint(datapoint);
                  }}
                  onSeriesMouseOut={() => {
                    setHint(false);
                    setSeriesHovered(-1);
                    setAnimation(true);
                  }}
                  onValueClick={(datapoint) => {
                    window.open(
                      BuildQueryUrl(
                        data.teamBurndown[0].data[datapoint.x].includedIds,
                        false,
                        agency
                      ),
                      '_blank'
                    );
                  }}
                />

                <LineSeries
                  color="#9d9d9d"
                  data={data.teamBurndown[0].data.map((x, i) => ({
                    x: i,
                    y: x.idealStoryPoint,
                  }))}
                  animation={animation}
                />

                {hint && (
                  <Hint value={hint}>
                    <div className="rv-hint__content">
                      <TooltipItem
                        text={DateShortString(
                          data.teamBurndown[0].data[hint.x].dayOfSprint
                        )}
                      />
                      {data.teamBurndown.map((type, index) => (
                        <TooltipItem
                          key={index}
                          fill={type.color}
                          text={`${type.type}: ${
                            type.data[hint.x]?.storyPoint || 0
                          }`}
                        />
                      ))}
                      <TooltipItem
                        fill="#9d9d9d"
                        text={`Ideal Trend: ${
                          data.teamBurndown[0].data[hint.x].idealStoryPoint
                        }`}
                        noBreak
                      />
                    </div>
                  </Hint>
                )}
              </XYPlot>
            )}
          </AutoSizer>
          <Legend ref={legendBurndownRef}>
            {data.teamBurndown.map((type, index) => (
              <React.Fragment key={index}>
                <TooltipItem fill={type.color} text={type.type} noBreak />{' '}
              </React.Fragment>
            ))}
            <TooltipItem fill="#9d9d9d" text="Ideal Trend" noBreak />
          </Legend>
        </div>
      </Burndown>
    </Wrap>
  );
};

DevOpsCharts.propTypes = {
  data: PropTypes.any,
  team: PropTypes.string,
  agency: PropTypes.string,
};

DevOpsCharts.defaultProps = {};

export default DevOpsCharts;
