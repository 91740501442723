import React, { useState, useEffect } from "react";
import CardInnerWithTime from "../CardInnerWithTime";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import useSWR from "swr";
import Card from "../Card";
import { fetcher, TimeIntervals } from "../../Utils/utils";
import "../../styles/AverageARMetric.css";

const AverageARMetric = ({ agencyKey }) => {
  const [uniqueYears, setUniqueYears] = useState([]);

  const { data } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/app-c-ar-payment.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_MINUTE,
    }
  );

  useEffect(() => {
    let returnedYears = Array.from(
      new Set(data?.list?.map((item) => item?.year))
    );
    setUniqueYears(returnedYears.sort().reverse());
  }, [data]);

  if (uniqueYears.length > 5) {
    uniqueYears.length = 5;
  }

  return (
    <>
      {data?.renderCard && (
        <Card className="AverageARMetric">
          <CardInnerWithTime
            loading={!data}
            title="Average AR Payment Turnaround"
            refreshedTime={data?.timeStamp ?? ""}
            extraIcons={[
              { 
                text: `Shows the number of days, averaged by fiscal year, for
an agency to satisfy an Accounts Receivable billing. 
This data is inclusive of one-time project, passthrough,
and monthly billed services and products billed for the
prior month. The fiscal year represents July to June.`,
                icon: faInfoCircle,
                style: {
                  whiteSpace: "pre-wrap",
                  overflowWrap: "break-word",
                  textAlign: "justify",
                },
              },
            ]}
            mainComponent={() => (
              <>
                {uniqueYears.length > 0 && (
                  <table>
                    <tbody>
                      <tr>
                        {uniqueYears.map((year, index) => (
                          <th key={year}>
                            {index === 0 ? year + " to date" : year}
                          </th>
                        ))}
                      </tr>
                      <tr>
                        {uniqueYears.map((year) => {
                          const extractedDays = data.list
                            .filter(
                              (desiredKey) => desiredKey.agencyKey === agencyKey
                            )
                            .filter((desiredYear) => desiredYear.year === year)
                            .map((desiredDays) => desiredDays.daysOpen);

                          const extractedDaysSum = extractedDays.reduce(
                            (partialSum, acc) => partialSum + acc,
                            0
                          );

                          const avg = Math.round(
                            extractedDaysSum / extractedDays.length
                          );

                          return (
                            <td key={year}>
                              {!isNaN(avg) ? avg + " days" : "Not applicable"}
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                  </table>
                )}
              </>
            )}
          />
        </Card>
      )}
    </>
  );
};

export default AverageARMetric;
