import React from "react";
import "../../../styles/CapitalAssets.css";
import CAPProjects from "./CAPProjects";
import ITAssetManagement from "./ITAssetManagement";
import FacilitiesManagement from "./FacilitiesManagement";

const InnerComponent = ({ data, authenticationState, role }) => {
  return (
    <>
      <div>
        <CAPProjects />
      </div>
      <div>
        <ITAssetManagement />
      </div>
      <FacilitiesManagement
        authenticationState={authenticationState}
        role={role}
      />
    </>
  );
};

const CapitalAssets = ({ authenticationState, role }) => {
  return (
    <InnerComponent authenticationState={authenticationState} role={role} />
  );
};

export default CapitalAssets;
