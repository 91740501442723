import React, { useState } from "react";
import styled from "@emotion/styled";
import Card from "../Card";
import CardInnerWithTime from "../CardInnerWithTime";
import AutoSizer from "react-virtualized-auto-sizer";
import AuthorizedArea from "../AuthorizedArea";
import TooltipItem from "../TooltipItem";
import {
  TimeIntervals,
  buildApiInternalUrl,
  formatNumber,
} from "../../Utils/utils";
import {
  Hint,
  HorizontalGridLines,
  LineMarkSeries,
  XAxis,
  XYPlot,
  YAxis,
} from "react-vis";
import "../../styles/Common.css";
import "../../styles/ServiceDeskIntake.css";

const LegendWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 1rem;
`;

const InnerComponent = ({ data }) => {
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);

  return (
    <Card className="Card-Margin-Top ServiceDeskIntake">
      <CardInnerWithTime
        loading={!data}
        title={`Service Desk - Intake`}
        refreshedTime={data?.timeStamp ?? ""}
        mainComponent={() => (
          <div>
            <AutoSizer disableHeight>
              {({ width }) => (
                <XYPlot
                  width={width}
                  height={200}
                  xType="ordinal"
                  margin={{
                    left: 70,
                  }}
                  yDomain={[0, data?.data?.domainMaxValue]}
                  onMouseLeave={() => {
                    setHint(false);
                    setAnimation(true);
                  }}
                >
                  <HorizontalGridLines />
                  <XAxis tickLabelAngle={-45} />
                  <YAxis
                    title="Number of Intake Tickets"
                    tickFormat={(d) => `${formatNumber(d)}`}
                  />

                  <LineMarkSeries
                    style={{
                      strokeWidth: "3px",
                    }}
                    lineStyle={{ stroke: "#002F63" }}
                    markStyle={{ stroke: "#002F63" }}
                    data={data?.data?.data?.chart1}
                    animation={animation}
                    color={`#002F63`}
                    onValueMouseOver={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                    }}
                  />

                  <LineMarkSeries
                    style={{
                      strokeWidth: "3px",
                    }}
                    lineStyle={{ stroke: "#1CA6DF" }}
                    markStyle={{ stroke: "#1CA6DF" }}
                    data={data?.data?.data?.chart2}
                    animation={animation}
                    color={`#1CA6DF`}
                    onValueMouseOver={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                    }}
                  />

                  <LineMarkSeries
                    style={{
                      strokeWidth: "3px",
                    }}
                    lineStyle={{ stroke: "#0066A6" }}
                    markStyle={{ stroke: "#0066A6" }}
                    data={data?.data?.data?.chart3}
                    animation={animation}
                    color={`#0066A6`}
                    onValueMouseOver={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                    }}
                  />

                  {hint && (
                    <Hint value={hint}>
                      <div
                        className="rv-hint__content"
                        style={{ pointerEvents: "none" }}
                      >
                        {hint.color === "#002F63" && (
                          <TooltipItem
                            fill="#002F63"
                            text={`${hint.x}: ${formatNumber(hint.y)}`}
                          />
                        )}

                        {hint.color === "#1CA6DF" && (
                          <TooltipItem
                            fill="#1CA6DF"
                            text={`${hint.x}: ${formatNumber(hint.y)}`}
                          />
                        )}

                        {hint.color === "#0066A6" && (
                          <TooltipItem
                            fill="#0066A6"
                            text={`${hint.x}: ${formatNumber(hint.y)}`}
                          />
                        )}
                      </div>
                    </Hint>
                  )}
                </XYPlot>
              )}
            </AutoSizer>
            <LegendWrap>
              <TooltipItem fill="#002F63" text="Phone" />
              <TooltipItem fill="#1CA6DF" text="Email" />
              <TooltipItem fill="#0066A6" text="SelfService" />
            </LegendWrap>
          </div>
        )}
      />
    </Card>
  );
};

const ServiceDeskIntake = () => (
  <AuthorizedArea
    url={buildApiInternalUrl("is-service-intake")}
    refreshInterval={TimeIntervals.ONE_DAY}
    innerComponent={({ data, error }) => {
      if (!data && !error && !error?.status) {
        return <></>;
      }
      if (data?.renderCard !== undefined && !data.renderCard) {
        return <></>;
      }
      return <InnerComponent data={data} />;
    }}
  />
);

export default ServiceDeskIntake;
