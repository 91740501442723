import React from "react";
import AuthorizedArea from "../../AuthorizedArea";
import styled from "@emotion/styled";
import {
  TimeIntervals,
  buildApiInternalUrl
} from "../../../Utils/utils";
import Card from "../../Card";
import "../../../styles/Budget.css";
import CardInnerWithTime from "../../CardInnerWithTime";
import Datagrid from "../../Datagrid";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import NumberFormat from "react-number-format";

const InnerComponent = ({data, currentYear, nextYear}) => {
    const Heading = styled.div`
    text-align: center;
    color: #ffffff;

    strong {
      display: inline-block;
      padding-bottom: ${({ theme }) => theme.width.xs};
    }
  `;
  
    const Header = ({ prop, label = "" }) => (
        <Heading>
          <strong>{prop}</strong>
          <br />
          {label}
        </Heading>
      );
      const title = "Agency Full-Time Equivalent (FTE): " + nextYear +  " Budgeted vs " + currentYear + " Actual";
    const columns = [
        {
          name: <Header prop="Agency Name" />,
          selector: (row) => (row.agencyName !== null ? row.agencyName : "-"),
          sortable: true,
          grow: 1,
          wrap: true,
          style: { textAlign: "left !important" },
        },
        {
          name: <Header prop="Average FTE" label={currentYear} />,
          selector: (row) => (row.averageFTE !== null ? row.averageFTE : 0),
          cell: (row) =>
            row.averageFTE !== null ? <NumberFormat
            value={row.averageFTE}
            displayType="text"
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator
          /> : "-",
          sortable: true,
          right: true,
         
        },
        {
          name: <Header prop="Budgeted FTE" label={nextYear}/>,
          selector: (row) =>
            row.budgetedFTE !== null ? row.budgetedFTE : 0,
          cell: (row) =>
            row.budgetedFTE !== null ?  <NumberFormat
            value={row.budgetedFTE}
            displayType="text"
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator
          />  : "-",
          sortable: true,
          right: true,
         
        },
        {
          name: <Header prop="Variance"/>,
          selector: (row) =>
          row.variance !== null ? row.variance : 0,
          cell:  row => row.variance !== null ?<NumberFormat
          value={row.variance}
          displayType="text"
          decimalScale={2}
          fixedDecimalScale
          thousandSeparator
        />  : "-",
          sortable: true,
          right: true,
        
        }
      ];

  return (
    <Card className="HumanCapital">
      <CardInnerWithTime
        loading={!data}
        title={title}
        refreshedTime={data?.timeStamp ?? ""}
        extraIcons={[
          {
            text: `"Full-time equivalent" is a calculation used to compare agency employment levels.
FTE is based upon 2,080 hours per year or 173 hours per month.
The annual FTE comparison includes total number of hours worked
(including full-time, part-time, and more than full-time hours),
rather than referring to a per person number of employees (headcount).`,
            icon: faInfoCircle,
            style: {
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              textAlign: "justify",
            },
          },
        ]}
        mainComponent={() => (
          <Datagrid columns={columns} data={data?.bpgcBudgetToActuals} 
          defaultSortField="agencyName"
          earchByKeys={["agencyId", "agencyName"]}
          expandable={false} />
        )}
      />
    </Card>
  );
};

const BudgetdVSActual = () => (
  <AuthorizedArea
    url={buildApiInternalUrl("bpgc-budget-to-actual")}
    refreshInterval={TimeIntervals.ONE_DAY}
    innerComponent={({ data, error }) => {
      if (!data && !error && !error?.status) {
        return <></>;
      }
      if (data?.renderCard !== undefined && !data.renderCard) {
        return <></>;
      }
      return <InnerComponent data={data} currentYear={data?.currentYear} nextYear={data?.nextYear}/>;
    }}
  />
);

export default BudgetdVSActual;
