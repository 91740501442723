import React from 'react';
import styled from '@emotion/styled';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import Clock from 'react-live-clock';
import { authProvider } from '../authProvider';
import Button from '../Components/Button';
import { H1 } from '../Components/Headings';

const Center = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  height: 100%;
`;

const Container = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: 1fr auto;
`;

const InfoBar = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin: ${({ theme }) => `-${theme.margin.md}`};
  padding: ${({ theme }) => theme.padding.md};
  background-color: ${({ theme }) => theme.footer.background};
  color: ${({ theme }) => theme.footer.color};
`;

const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

const Piglet = () => (
  <AzureAD provider={authProvider}>
    {({ authenticationState, login, accountInfo }) => {
      const utcSeconds = parseJwt(accountInfo.jwtIdToken).exp;
      const d = new Date(0); // The 0 there is the key, which sets the date to the epoch
      d.setUTCSeconds(utcSeconds);

      if (authenticationState === AuthenticationState.Authenticated) {
        return (
          <Container>
            <Center>
              <H1>
                Why, you stuck-up, half-witted, scruffy-looking nerf herder!
              </H1>
            </Center>
            <InfoBar>
              <div />
              <Clock format="hh:mm A" ticking />
            </InfoBar>
          </Container>
        );
      }
      return (
        <Center>
          <Button onClick={() => login()}>Login</Button>
        </Center>
      );
    }}
  </AzureAD>
);

export default Piglet;
