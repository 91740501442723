import React, { useState } from "react";
import CardInnerWithTime from "../../CardInnerWithTime";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import Card from "../../Card";
import useSWR from "swr";
import {
  TimeIntervals,
  addTrailingZeroes,
  formatNumber,
  fetcher
} from "../../../Utils/utils";
import { H1, H3 } from "../../Headings";
import Select from "../../Select";
import Datagrid from "../../Datagrid";
import "../../../styles/MaintenanceNeeds.css";

const InnerComponent = ({ data }) => {
  let totalExpenses = data?.totalBuildingExpenses?.sort((a, b) =>
    a.building.toLowerCase() > b.building.toLowerCase() ? 1 : -1
  );

  const defaultData = data?.totalBuildingExpenses?.filter(
    (x) => x.building === totalExpenses?.[0]?.building
  )[0];

  const [buildingName, setBuildingName] = useState(
    totalExpenses?.[0]?.building
  );
  const [buildingData, setBuildingData] = useState(defaultData);

  const MapFormat = (data, buildingName) => {
    if (buildingName !== defaultData.building) {
      return data?.buildingExpenseList
        ?.filter((item) => item.building === buildingName)
        .map((x) => ({
          ...x,
        }));
    } else {
      return data?.buildingExpenseList.map((x) => ({
        ...x,
      }));
    }
  };

  const columns = [
    {
      name: "Building",
      selector: (row) =>
        row.building !== null &&
        row.building !== "" &&
        row.building !== undefined
          ? row.building
          : "-",
      cell: (row) =>
        row.building !== null &&
        row.building !== "" &&
        row.building !== undefined
          ? row.building
          : "-",
      sortable: true,
      wrap: true,
      grow: 2,
    },
    {
      name: "Project",
      selector: (row) =>
        row.project !== null && row.project !== "" && row.project !== undefined
          ? row.project
          : "-",
      cell: (row) =>
        row.project !== null && row.project !== "" && row.project !== undefined
          ? row.project
          : "-",
      wrap: true,
      sortable: true,
      grow: 4,
      style: { textAlign: "left !important" },
    },
    {
      name: "Est. Replacement Cost",
      selector: (row) =>
        row.estReplacementCost !== null &&
        row.estReplacementCost !== "" &&
        row.estReplacementCost !== undefined
          ? row.estReplacementCost
          : 0,
      cell: (row) =>
        row.estReplacementCost !== null &&
        row.estReplacementCost !== "" &&
        row.estReplacementCost !== undefined
          ? `$${formatNumber(Math.round(row.estReplacementCost))}`
          : "-",
      right: true,
      sortable: true,
      grow: 2,
    },
    {
      name: "End of Life Year",
      selector: (row) =>
        row.endOfLifeYear !== null &&
        row.endOfLifeYear !== "" &&
        row.endOfLifeYear !== undefined
          ? row.endOfLifeYear
          : "-",
      cell: (row) =>
        row.endOfLifeYear !== null &&
        row.endOfLifeYear !== "" &&
        row.endOfLifeYear !== undefined
          ? row.endOfLifeYear
          : "-",
      right: true,
      sortable: true,
    },
    {
      name: "Priority",
      selector: (row) =>
        row.priority !== null &&
        row.priority !== "" &&
        row.priority !== undefined
          ? row.priority
          : 6,
      cell: (row) =>
        row.priority !== null &&
        row.priority !== "" &&
        row.priority !== undefined
          ? row.priority
          : "-",
      right: true,
      sortable: true,
    },
  ];

  return (
    <>
      <Card className="MaintenanceNeeds">
        <CardInnerWithTime
          loading={!data}
          title="Capital Expenses for Outstanding Maintenance Needs"
          refreshedTime={data?.timeStamp ?? ""}
          extraIcons={[
            {
              text: `Estimated costs should be considered rough
order of magnitude (ROM) estimates and do not
represent the true replacement cost. 
`,
              icon: faInfoCircle,
              style: {
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
                textAlign: "justify",
              },
            },
          ]}
          mainComponent={() => (
            <>
              <Select
                onChange={(e) => {
                  const val = e.target.value;
                  setBuildingName(val);
                  setBuildingData(
                    data?.totalBuildingExpenses?.filter(
                      (x) => x.building === val
                    )[0]
                  );
                }}
                defaultValue={buildingName}
                aria-label="Select Item to View"
              >
                {data?.totalBuildingExpenses?.map((x, i) => (
                  <option key={i} value={x.building}>
                    {x.building}
                  </option>
                ))}
              </Select>

              {buildingName !== 0 && (
                <>
                  <section className="MaintenanceInfo">
                    <div className="TopHeader">
                      <div className="HeadCounts">
                        <H1 as="span">
                          {buildingData?.totalEstReplacementCost !== null &&
                            `$${addTrailingZeroes(
                              formatNumber(
                                buildingData?.totalEstReplacementCost?.toFixed(
                                  2
                                )
                              )
                            )}`}
                        </H1>
                        <p>Total Estimated Cost</p>
                      </div>
                      <div className="TravelDivider"></div>
                      <div className="HeadCounts">
                        <H1 as="span">
                          {buildingData?.totalPriority &&
                            formatNumber(buildingData?.totalPriority)}
                        </H1>
                        <p>Tier 1 Priority Projects</p>
                      </div>
                      <div className="TravelDivider"></div>
                      <div className="HeadCounts">
                        <H1 as="span">
                          {buildingData?.totalPriority1Cost !== null &&
                            `$${formatNumber(
                              buildingData?.totalPriority1Cost?.toFixed(2)
                            )}`}
                        </H1>
                        <p>Tier 1 Priority Estimated Cost</p>
                      </div>
                    </div>
                  </section>

                  <section className="PriorityInfo">
                    <H3>Priority</H3>
                    <ol type="1">
                      <li>System failed</li>
                      <li>System expected to fail within year</li>
                      <li>
                        System expected to fail within 5 years; parts obsolete
                      </li>
                      <li>Systems exceeded life expectancy; parts available</li>
                      <li>System within life expectancy</li>
                    </ol>
                  </section>

                  <section className="MaintenanceTable">
                    {data && (
                      <Datagrid
                        columns={columns}
                        data={MapFormat(data, buildingName)}
                        expandable={false}
                        defaultSortField="Building"
                        searchByKeys={["building", "project", "endOfLifeYear"]}
                        filterPlaceHolder={
                          "Filter by Building, Project or Year"
                        }
                      />
                    )}
                  </section>
                </>
              )}
            </>
          )}
        />
      </Card>
    </>
  );
};

const MaintenanceNeeds = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/building-capital-expenses.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );
  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }
  return  data?.renderCard && (<InnerComponent data={data} />);
}
export default MaintenanceNeeds;
