import React from "react";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import Card from "../../Card";
import CardInnerWithTimeAndFooter from "../../CardInnerWithTimeAndFooter";
import { H1, H4 } from "../../Headings";
import "../../../styles/PayrollReturn.css";
import ColumnSection from "../../ColumnSection";
import useSWR from "swr";
import { TimeIntervals, fetcher, formatNumber } from "../../../Utils/utils";

const InnerComponent = ({ data }) => {
  return (
    <>
      {/* <p className="SubHeading">
        Reflects payrolls processed for all State of Oklahoma employees. Data
        does not include payroll information for state institutions of higher
        education.
      </p> */}
      <ColumnSection cols={3}>
        <Card className="PayrollWarrantsProcessed">
          <CardInnerWithTimeAndFooter
            loading={!data}
            title="Payroll Warrants Processed"
            refreshedTime={data?.timeStamp || null}
            extraIcons={[
              {
                text: `Warrants are displayed as either EFTs (direct deposit payments) or paper checks.`,
                icon: faInfoCircle,
              },
            ]}
            mainComponent={() => (
              <>
                {" "}
                <H1 as="span">
                  {data.efTs > 0 ? (
                    formatNumber(data?.efTs + data?.checks, false, true)
                  ) : (
                    <H4>No data to display</H4>
                  )}
                </H1>
                <div style={{ fontSize: "0.82rem", marginTop: ".5rem" }}>
                  Month to date
                </div>
              </>
            )}
            footerLeftComponent={() => (
              <div style={{ fontSize: "0.82rem" }}>
                {formatNumber(data?.efTs, false, true)} EFTs
              </div>
            )}
            footerRightComponent={() => (
              <div style={{ fontSize: "0.82rem" }}>
                {formatNumber(data?.checks, false, true)} Checks
              </div>
            )}
          />
        </Card>
      </ColumnSection>
    </>
  );
};

const PayrollWarrantsProcessed = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/payroll-warrants-and-reversals-totals.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} />;
};

export default PayrollWarrantsProcessed;
