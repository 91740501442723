import React, { useState } from "react";
import styled from "@emotion/styled";
import AuthorizedArea from "../AuthorizedArea";
import Card from "../Card";
import CardInnerWithTime from "../CardInnerWithTime";
import {
  TimeIntervals,
  buildApiInternalUrl,
  formatNumber,
  getMonthName,
} from "../../Utils/utils";
import AutoSizer from "react-virtualized-auto-sizer";
import TooltipItem from "../TooltipItem";
import {
  Hint,
  HorizontalGridLines,
  LineMarkSeries,
  XAxis,
  XYPlot,
  YAxis,
} from "react-vis";
import "../../styles/Common.css";
import "../../styles/ClosedCases.css";

const LegendWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 1rem;
`;

const InnerComponent = ({ data }) => {
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);

  let serviceDeskData = data?.isclosedServiceDeskEndUser?.filter(
    (item) =>
      item.assignmentGroup === "Service Desk" &&
      item.month !== -1 &&
      item.year !== -1
  );
  let endUserData = data?.isclosedServiceDeskEndUser?.filter(
    (item) =>
      item.assignmentGroup === "End User" &&
      item.month !== -1 &&
      item.year !== -1
  );

  let yDomainTopValue = 0;
  let serviceDeskArray = [];
  let endUserArray = [];
  let sortedArray = [];

  if (serviceDeskData) {
    let tempArray = serviceDeskData?.sort((a, b) => {
      if (a.year !== b.year) return b.year - a.year;
      return b.month - a.month;
    });

    tempArray = tempArray.reverse();
    if (tempArray.length > 12) {
      tempArray.length = 12;
    }

    tempArray.forEach((ele) => {
      serviceDeskArray.push({
        x: `${getMonthName(ele.month).slice(0, 3)} ${ele.year}`,
        y: ele.count,
        color: "green",
      });
    });
  }

  if (endUserData) {
    let tempArray = endUserData?.sort((a, b) => {
      if (a.year !== b.year) return b.year - a.year;
      return b.month - a.month;
    });

    tempArray = tempArray.reverse();
    if (tempArray.length > 12) {
      tempArray.length = 12;
    }

    tempArray.forEach((ele) => {
      endUserArray.push({
        x: `${getMonthName(ele.month).slice(0, 3)} ${ele.year}`,
        y: ele.count,
        color: "blue",
      });
    });
  }

  let copyArray = [...serviceDeskArray, ...endUserArray];
  sortedArray = copyArray?.sort((a, b) => b.y - a.y);
  yDomainTopValue = sortedArray[0]?.y * 1.3;

  return (
    <Card className="Card-Margin-Top ClosedCases">
      <CardInnerWithTime
        loading={!data}
        title={`Closed Cases - Service Desk vs End User`}
        refreshedTime={data?.timeStamp ?? ""}
        mainComponent={() => (
          <div>
            <AutoSizer disableHeight>
              {({ width }) => (
                <XYPlot
                  width={width}
                  height={200}
                  xType="ordinal"
                  margin={{
                    left: 70,
                  }}
                  yDomain={[0, yDomainTopValue]}
                  onMouseLeave={() => {
                    setHint(false);
                    setAnimation(true);
                  }}
                >
                  <HorizontalGridLines />
                  <XAxis tickLabelAngle={-45} />
                  <YAxis
                    title="Number of Closed Cases"
                    tickFormat={(d) => `${formatNumber(d)}`}
                  />

                  <LineMarkSeries
                    style={{
                      strokeWidth: "3px",
                    }}
                    lineStyle={{ stroke: "green" }}
                    markStyle={{ stroke: "green" }}
                    data={serviceDeskArray}
                    animation={animation}
                    color={`green`}
                    onValueMouseOver={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                    }}
                  />

                  <LineMarkSeries
                    style={{
                      strokeWidth: "3px",
                    }}
                    lineStyle={{ stroke: "blue" }}
                    markStyle={{ stroke: "blue" }}
                    data={endUserArray}
                    animation={animation}
                    color={`blue`}
                    onValueMouseOver={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                    }}
                  />

                  {hint && (
                    <Hint value={hint}>
                      <div
                        className="rv-hint__content"
                        style={{ pointerEvents: "none" }}
                      >
                        {hint.color === "green" && (
                          <TooltipItem
                            fill="green"
                            text={`${hint.x}: ${formatNumber(hint.y)}`}
                          />
                        )}

                        {hint.color === "blue" && (
                          <TooltipItem
                            fill="blue"
                            text={`${hint.x}: ${formatNumber(hint.y)}`}
                          />
                        )}
                      </div>
                    </Hint>
                  )}
                </XYPlot>
              )}
            </AutoSizer>
            <LegendWrap>
              <TooltipItem fill="green" text="Service Desk" />
              <TooltipItem fill="blue" text="End User" />
            </LegendWrap>
          </div>
        )}
      />
    </Card>
  );
};

const ClosedCases = () => (
  <AuthorizedArea
    url={buildApiInternalUrl("is-servicedesk-end")}
    refreshInterval={TimeIntervals.ONE_DAY}
    innerComponent={({ data, error }) => {
      if (!data && !error && !error?.status) {
        return <></>;
      }
      if (data?.renderCard !== undefined && !data.renderCard) {
        return <></>;
      }
      return <InnerComponent data={data} />;
    }}
  />
);

export default ClosedCases;
