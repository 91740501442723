import React from "react";
import ColumnSection from "../../ColumnSection";
import AgencyBudget from "./AgencyBudget";
import EducationBudget from "./EducationBudget";
import BudgetdVSActual from "./BudgetdVSActual";
const Budget = ({ authenticationState, role }) => {
  return (
    <>
      <span className="Heading GamingHeading">Budget</span>
      <ColumnSection cols={2}>
        <AgencyBudget />
        <EducationBudget />
      </ColumnSection>
      <BudgetdVSActual />
    </>
  );
};

export default Budget;
