import React from "react";
import ColumnSection from "../../ColumnSection";
import YearlyProtests from "./YearlyProtests";
import ProtestsSustained from "./ProtestsSustained";

const TotalProtests = () => {
  return (
    <>
      <ColumnSection cols={2}>
        <YearlyProtests />
        <ProtestsSustained />
      </ColumnSection>
    </>
  );
};

export default TotalProtests;
