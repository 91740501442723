import React, { useState } from "react";
import styled from "@emotion/styled";
import useSWR from "swr";
import { fetcher, TimeIntervals } from "../Utils/utils";
import ColumnSection from "../Components/ColumnSection";
import { H1, H2 } from "../Components/Headings";
import Select from "react-select";
import AppCTable from "../Components/AppCTable/Index";
import RateTable from "../Components/RateTable/Index";
import { Tabs, TabList, TabPanelsEnhanced, TabPanel } from "../Components/Tabs";
import BalanceSheet from "../Components/BalanceSheet/Index";
import RateMethodology from "../Components/RateMethodology/Index";
import Button from "../Components/Button";
import OMESSharedService from "../Components/OMESSharedService/OMESSharedService";
import { Tab as OgTab } from "@reach/tabs";
import "@reach/tabs/styles.css";

const getFrequency = (list, freq) =>
  list?.filter((x) => x?.frequency === freq)?.[0]?.rates;

const TitleButtonWrapp = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;
const TabEnhanced = styled(OgTab)`
  --fa-primary-color: ${({ theme }) => theme.buttons.blue.icon.primary};
  --fa-secondary-color: ${({ theme }) => theme.buttons.blue.icon.secondary};
  background-color: ${({ theme }) => theme.buttons.blue.background};
  border: ${({ theme }) =>
    `solid ${theme.buttons.blue.border} ${theme.width.px}`};
  color: ${({ theme }) => theme.buttons.blue.text};
  cursor: pointer;
  font-weight: ${({ theme }) => theme.text.weight.medium};
  padding: ${({ theme }) => theme.padding.sm};
  text-align: center;
  text-decoration: none;
  transition: all 75ms cubic-bezier(0.4, 0, 1, 1);
  opacity: 0.5;

  &:first-of-type {
    margin-left: 0;
  }

  &:hover {
    --fa-primary-color: ${({ theme }) => theme.buttons.blue.hover.icon.primary};
    --fa-secondary-color: ${({ theme }) =>
      theme.buttons.blue.hover.icon.secondary};
    background-color: ${({ theme }) => theme.buttons.blue.hover.background};
    border-color: ${({ theme }) => theme.buttons.blue.hover.border};
    color: ${({ theme }) => theme.buttons.blue.hover.text};
    text-decoration: none;
  }

  &[disabled] {
    text-decoration: none;
  }

  &:focus {
    box-shadow: 0 0 0 3px ${(props) => props.theme.input.focus};
    outline: none;
  }
`;

const InnerComponent = ({ data, error }) => {
  const [agency, setAgency] = useState(0);
  const [agencyAppCFileName, setAgencyAppCFileName] = useState("");
  const [agencyDetailFileName, setAgencyDetailFileName] = useState("");
  const [agencyData, setAgencyData] = useState(null);

  const monthly = getFrequency(agencyData?.frequencies, "Monthly");
  const oneTime = getFrequency(agencyData?.frequencies, "Once");
  const appropriated = getFrequency(agencyData?.frequencies, "Appropriated");
  const allFrequency = monthly?.concat(oneTime ?? [], appropriated ?? []);

  const agenciesList = data?.appC?.agencies?.map((item) => ({
    label: item.agencyName,
    value: item.agencyKey,
    agencyFileName: item.agencyFileName,
  }));

  const sortedData = agenciesList?.sort((a, b) => {
    const value1 = a.label.toLowerCase().split("-")[1].trim();
    const value2 = b.label.toLowerCase().split("-")[1].trim();
    return value1 > value2 ? 1 : -1;
  });

  return (
    <>
      <div style={{ marginBottom: "3rem" }}>
        <p>
          The Agency Rate Packet provides updated annual billing documentation
          for technology services and products to meet our ongoing commitment
          for transparent reporting. By making projections available a full
          quarter earlier than previous budget cycles, OMES aims to spend more
          time working with agencies to receive feedback, make necessary
          adjustments and land on a mutually agreed upon Appendix C which will
          then be updated to the PIG by June 3.
        </p>
        <p>
          <b>Draft FY25 OMES quantities are now available.</b> To view the
          detail file on the PIG:
        </p>
        <ul className="no-bullets">
          <li>1. Select your agency in the drop-down menu.</li>
          <li>2. Choose the App C at a Glance tab.</li>
          <li>3. Select the Export True-Up Detail button.</li>
        </ul>
        <p>
          <b>
            Please review your draft quantities and direct any questions to your
            OMES account representative or{" "}
            <a href="mailto:accountrepresentatives@omes.ok.gov">
              accountrepresentatives@omes.ok.gov
            </a>{" "}
            by April 15.
          </b>
        </p>
      </div>

      <Select
        placeholder="Search by Agency Name"
        options={sortedData}
        onChange={(e) => {
          const a = e.value;
          setAgency(a);
          setAgencyAppCFileName(
            encodeURIComponent(
              `RatePacket/${parseInt(a) < 100 ? "0"+ a : a }/app-c-${
                data?.appC?.agencies.filter((x) => x.agencyKey === a)[0]
                  .agencyFileName
              }.pdf`
            )
          );
          setAgencyDetailFileName(
            encodeURIComponent(
              `${
                data?.appC?.agencies?.filter((x) => x.agencyKey === a)[0]
                  ?.agencyDetailFileName
              }`
            )
          );
          setAgencyData(data?.appC?.data.filter((x) => x.agencyKey === a)[0]);
        }}
        defaultValue={agency}
        aria-label="Select Item to View"
      />
      {agency !== 0 && (
        <ColumnSection cols={1}>
          <H1>{agencyData?.agencyName}</H1>

          <Tabs>
            <TabList>
              <TabEnhanced>OMES Shared Services</TabEnhanced>
              <TabEnhanced>App C at a Glance</TabEnhanced>
              {/* <TabEnhanced>App C Rate Table</TabEnhanced>
              <TabEnhanced>App C Balance Sheet</TabEnhanced>
              <TabEnhanced>App C Rate Methodology</TabEnhanced> */}
            </TabList>
            <TabPanelsEnhanced>
              <TabPanel>
                <OMESSharedService agencyKey={agency}></OMESSharedService>
              </TabPanel>
              <TabPanel>
                <TitleButtonWrapp>
                  <H2>App C at a Glance</H2>
                  <div>
                    <Button
                      as="a"
                      color="blue"
                      size="small"
                      href={`${process.env.REACT_APP_PUBLIC_STORE}/${agencyAppCFileName}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View App C
                    </Button>
                    {agencyDetailFileName !== "null" && (
                      <Button
                        as="a"
                        color="blue"
                        size="small"
                        href={`${process.env.REACT_APP_PUBLIC_STORE}/${agencyDetailFileName}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Export True-Up Detail
                      </Button>
                    )}
                  </div>
                </TitleButtonWrapp>
                <AppCTable
                  monthly={monthly}
                  oneTime={oneTime}
                  appropriated={appropriated}
                  settings={data?.appC?.settings}
                  totals={agencyData?.totals}
                  agencyName={agencyData?.agencyName}
                  agencyKey={agency}
                  allFrequencyData={allFrequency}
                  timeStamp={data.runTime}
                />
                <div className="footer-text">
                  <div>
                    Year-over-year (YOY) changes are driven by agency
                    consumption of services and products.
                  </div>
                  <div>
                    This percentage gains accuracy through the current fiscal
                    year as drafted quantities are updated.
                  </div>
                  <div>
                    *True-up monthly in arrears (ex: July count will be
                    reflected on August invoice).
                  </div>
                </div>
              </TabPanel>
              {/* <TabPanel>
                <RateTable
                  data={data?.rateTable?.data}
                  settings={data?.rateTable?.settings}
                />
              </TabPanel>
              <TabPanel>
                <BalanceSheet
                  data={data?.balanceSheet?.data}
                  settings={data?.balanceSheet?.settings}
                />
              </TabPanel>
              <TabPanel>
                <RateMethodology />
              </TabPanel> */}
            </TabPanelsEnhanced>
          </Tabs>
        </ColumnSection>
      )}
    </>
  );
};

const RatePacket = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/RatePackData.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (error?.status === 401) {
    return <></>;
  }

  return <InnerComponent data={data} error={error} />;
};

export default RatePacket;
