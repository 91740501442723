import React, { useState } from "react";
import styled from "@emotion/styled";
import { TimeIntervals, fetcher, formatNumber } from "../../../Utils/utils";
import Card from "../../Card";
import { faInfoCircle, faClock } from "@fortawesome/pro-duotone-svg-icons";
import useSWR from "swr";
import { H1, H3 } from "../../Headings";
import "../../../styles/ISCompliance.css";
import Select from "../../Select";
import CardTopItems from "../../CardTopItems";

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin-top: 1rem;
`;

const ScaledUp = ({ updatedDateTime, total, dropdownMenu }) => {
  const [lastApproved, setLastApproved] = useState("-");
  const [expireDate, setExpireDate] = useState("-");
  const [agencyID, setAgencyID] = useState(0);

  return (<>
   <Card className="ISCompliance">
    <CardTopItems
      items={[
        {
          text: `IPP - Internal Purchasing Procedures`,
          icon: faInfoCircle,
          style: {
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
            textAlign: "justify",
          },
        },
        {
          text: `As of ${new Date(updatedDateTime).toLocaleString()}`,
          icon: faClock,
        },
      ]}
    />

    <H3>{`Non-IS Compliance & Privacy by Agency`}</H3>
    <Wrap>
    <div className="TopHeader">
                <div className="HeadCounts" style={{marginTop: "1rem", width: "15%"}}>
                  <span >Agencies with IPP </span>
                  <span>requirement</span>
                  <H1 as="span" style={{marginTop: "3rem", fontSize: "1.8rem!important"}} >
                    {formatNumber(total.toFixed(2))}
                  </H1>
                </div>
                <div className="Divider"></div>
                <div style={{width: "55%"}}>
                <Select 
                  onChange={(e) => {
                    const val = e.target.value;
                    setAgencyID(val);
                    setLastApproved(
                      val === "Select an agency" ? "-" : dropdownMenu?.filter(
                        (x) => x.agencyID === parseInt(val)
                      )[0].lastApprovalDate
                    );
                    setExpireDate(
                      val === "Select an agency" ? "-" : dropdownMenu?.filter(
                        (x) => x.agencyID === parseInt(val)
                      )[0].expirationDate
                    );
                  }}
                  defaultValue={agencyID}
                  aria-label="Search by Agency Name"
                >
                  <option>Select an agency</option>
                  {dropdownMenu?.map((x, i) => (
                    <option key={i} value={x.agencyID}>
                      {x.agencyID + " - " + x.agencyName}
                    </option>
                  ))}
                </Select>
                <span className="last-approved">
                  Last IPP Approval Date
                  <H1 className="font-size-2rem">{lastApproved}</H1></span>
                
                <span className="expired-date">
                  IPP Expiration Date
                  <H1 className="font-size-2rem">{expireDate}</H1></span>
               
                </div>
              </div>
    </Wrap>
  </Card>
  </>
    
  );
};

const NONISAgency = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/rac-non-is-ipp.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );
  if (!data && !error && !error?.status) {
    return <>No data was found</>;
  }
  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }
  let isRender = data?.renderCard;
  const dropdownMenu = data?.data;

  return (
    <>
      {isRender && (
        <ScaledUp
          updatedDateTime={data?.timeStamp}
          total={data?.metricData?.total}
          dropdownMenu={dropdownMenu}
        />
      )}
    </>
  );
};

export default NONISAgency;
