import React from "react";
import CardInnerWithTime from "../../../CardInnerWithTime";
import { H1 } from "../../../Headings";
import { dateFormatWithoutYear, formatNumber } from "../../../../Utils/utils";
import "../../../../styles/Common.css";
import "../../../../styles/WeeklyNonTravel.css";
import TPPieChart from "../TPPieChart";

const TPCount = ({ data, isTravel }) => {
  let fromDate = dateFormatWithoutYear(data?.fromDate);
  let toDate = dateFormatWithoutYear(data?.toDate);

  let {
    totalVoucherCount,
    weeklyPaidTotal,
    interAgencyWire,
    eftPayment,
    paperWarrant,
  } = data?.countTab;

  const travelData = [
    {
      angle: eftPayment,
      value: eftPayment * totalVoucherCount,
      color: "#de9027",
      text: "EFT Payment",
    },
    {
      angle: paperWarrant,
      value: paperWarrant * totalVoucherCount,
      color: "#669b41",
      text: "Paper Warrant",
    },
  ];

  const nonTravelData = [
    {
      angle: interAgencyWire,
      value: interAgencyWire * totalVoucherCount,
      color: "#1ca6df",
      text: "Interagency Wire Payment",
    },
    {
      angle: eftPayment,
      value: eftPayment * totalVoucherCount,
      color: "#de9027",
      text: "EFT Payment",
    },
    {
      angle: paperWarrant,
      value: paperWarrant * totalVoucherCount,
      color: "#669b41",
      text: "Paper Warrant",
    },
  ];

  return (
    <div className="TPCount">
      <CardInnerWithTime
        loading={!data}
        title={
          isTravel === "travel"
            ? `Weekly Travel Vouchers`
            : `Weekly Non-Travel Vouchers`
        }
        subtitle={`${fromDate} - ${toDate}`}
        refreshedTime={data?.timeStamp ?? ""}
        mainComponent={() => (
          <>
            <section className="NonTravelInfo">
              <div className="TopHeader">
                <div className="HeadCounts">
                  <H1 as="span">{formatNumber(totalVoucherCount)}</H1>
                  <p>Voucher Count</p>
                </div>
                <div className="TravelDivider"></div>
                <div className="HeadCounts">
                  <H1 as="span">
                    {weeklyPaidTotal && "$" + formatNumber(weeklyPaidTotal)}
                  </H1>
                  <p>Weekly Paid Total</p>
                </div>
              </div>
            </section>

            <TPPieChart
              isTravel={isTravel}
              travelData={travelData}
              nonTravelData={nonTravelData}
              isCount={`TPCount`}
            />
          </>
        )}
      />
    </div>
  );
};

export default TPCount;
