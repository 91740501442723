import React, { useEffect, useState } from "react";
import useSWR from "swr";
import styled from "@emotion/styled";
import Card from "../../Card";
import CardInnerWithTime from "../../CardInnerWithTime";
import {
  TimeIntervals,
  dateFormat,
  buildApiInternalUrl,
  formatNumber,
  fetcherWithAuth,
} from "../../../Utils/utils";
import { H1, H2, H3 } from "../../Headings";
import { TableStyle } from "../../BaseTable";
import Cell from "../../BalanceSheet/Cell";
import "../../../styles/AccountsPayable.css";
import "../../../styles/Common.css";
import ColumnSection from "../../ColumnSection";

const InnerComponent = ({ data, uniqueAgedRange, uniqueDivision, pdfVersion }) => {
  const [customerSum, setCustomersSum] = useState(0);

  let asOfDate = dateFormat(data?.outstanding?.asOfDate);
  let fromDate = dateFormat(data?.totalCashCollection?.fromDate);
  let toDate = dateFormat(data?.totalCashCollection?.toDate);

  const TableWrap = styled.div`
    margin-bottom: 2rem;
    thead tr,
    tbody tr:nth-last-child(1) {
      background-color: #005c95;
      color: #ffffff;
    }
  `;

  const VendorTableWrap = styled.div`
    thead tr,
    tbody tr:nth-last-child(1),
    tbody tr:nth-last-child(2) {
      background-color: #005c95;
      color: #ffffff;
    }
  `;

  useEffect(() => {
    setCustomersSum(
      data?.topCustomers
        .map((item) => item.amount)
        .reduce((acc, item) => acc + item, 0)
    );
  }, [data]);

  const getAmount = (dateRange, division) => {
    return formatNumber(
      data?.outStandTable
        ?.filter((eachItem) => {
          return (
            eachItem.division.toLowerCase() === division.toLowerCase() &&
            eachItem.agedRange.toLowerCase() === dateRange
          );
        })[0]
        ?.amount?.toFixed(2),
      true,
      false,
      false,
      true
    );
  };
  const grandTotal = (division) => {
    const sum = data?.outStandTable
      ?.filter((eachItem) => {
        return eachItem.division.toLowerCase() === division.toLowerCase();
      })
      .reduce(function (prev, current) {
        return prev + +current.amount;
      }, 0);
    return formatNumber(sum?.toFixed(2), true, false, false, true);
  };
  const rangeTotal = (range) => {
    const sum = data?.outStandTable
      ?.filter((eachItem) => {
        return eachItem.agedRange.toLowerCase() === range;
      })
      .reduce(function (prev, current) {
        return prev + +current.amount;
      }, 0);
    return formatNumber(sum?.toFixed(2), true, false, false, true);
  };
  return (
    <>
      {pdfVersion && (
        <>
          <br></br>
          <br></br>
          <br></br>{" "}
        </>
      )}
      <Card className="AccountsPayable">
        <CardInnerWithTime
          loading={!data}
          title="Accounts Receivable"
          subtitle={`As of ${asOfDate}`}
          refreshedTime={data?.timeStamp ?? ""}
          mainComponent={() => (
            <>
              <div className="AccountsPayableHeader">
                <div className="HeadCounts">
                  <H2 as="h3">AR Outstanding</H2>
                  <H1 as="span">
                    {formatNumber(
                      data?.outstanding?.amount.toFixed(2),
                      true,
                      false,
                      false,
                      true
                    )}
                  </H1>
                </div>
                <div className="Divider"></div>
                {data?.keyNotes?.outstanding?.length > 0 && (
                  <div className="KeyNotes">
                    <H3>Key Notes:</H3>
                    <ul>
                      {data?.keyNotes?.outstanding?.map((item) => {
                        return <li>{item}</li>;
                      })}
                    </ul>
                  </div>
                )}
              </div>

              <TableWrap>
                <TableStyle color="blue" wrapped>
                  <thead>
                    <tr>
                      <Cell as="th" alignment="left">
                        Division
                      </Cell>
                      {uniqueAgedRange?.map((agedrange) => {
                        return (
                          <Cell as="th" alignment="left">
                            {agedrange}
                          </Cell>
                        );
                      })}
                      <Cell as="th" alignment="left">
                        Grand Total
                      </Cell>
                    </tr>
                  </thead>
                  <tbody>
                    {uniqueDivision.map((oneDiv) => {
                      return (
                        <tr>
                          <Cell as="td" alignment="left">
                            {oneDiv}
                          </Cell>
                          <Cell as="td" alignment="right">
                            {getAmount("0 - 45 days", oneDiv)}
                          </Cell>
                          <Cell as="td" alignment="right">
                            {getAmount("46 - 60 days", oneDiv)}
                          </Cell>
                          <Cell as="td" alignment="right">
                            {getAmount("61 - 90 days", oneDiv)}
                          </Cell>
                          <Cell as="td" alignment="right">
                            {getAmount("91 - 120 days", oneDiv)}
                          </Cell>
                          <Cell as="td" alignment="right">
                            {getAmount("> 120 days", oneDiv)}
                          </Cell>
                          <Cell as="td" alignment="right">
                            {grandTotal(oneDiv)}
                          </Cell>
                        </tr>
                      );
                    })}
                    <tr>
                      <Cell as="th" alignment="left">
                        Total
                      </Cell>
                      {uniqueAgedRange.map((r) => {
                        return (
                          <Cell as="td" alignment="right">
                            {rangeTotal(r)}
                          </Cell>
                        );
                      })}
                      <Cell as="th" alignment="right">
                        {formatNumber(
                          data?.outstanding?.amount.toFixed(2),
                          true,
                          false,
                          false,
                          true
                        )}
                      </Cell>
                    </tr>
                  </tbody>
                </TableStyle>
              </TableWrap>

              <ColumnSection cols={2}>
                <div>
                  <div>
                    <H2 as="h3">AR Collections</H2>
                    <H1 as="span">
                      {formatNumber(
                        data?.totalCashCollection.amount.toFixed(2),
                        true,
                        false,
                        false,
                        true
                      )}
                    </H1>
                    <p>{`(${fromDate} - ${toDate})`}</p>
                  </div>
                  <hr className="HorizontalDivider" />
                  {data?.keyNotes?.collection?.length > 0 && (
                    <div className="KeyNotes">
                      <H3>Key Notes:</H3>
                      <ul>
                        {data?.keyNotes?.collection?.map((item) => {
                          return <li>{item}</li>;
                        })}
                      </ul>
                    </div>
                  )}
                </div>
                <div>
                  <VendorTableWrap>
                    <TableStyle color="blue" wrapped>
                      <thead>
                        <tr>
                          <Cell as="th" alignment="left">
                            Top 10 Customers
                          </Cell>
                          <Cell as="th" alignment="left">
                            Receipt
                          </Cell>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.topCustomers?.map((item) => {
                          let { customerName, amount } = item;
                          return (
                            <tr>
                              <Cell as="td" alignment="left">
                                {customerName}
                              </Cell>
                              <Cell as="td" alignment="right">
                                {formatNumber(
                                  amount.toFixed(2),
                                  true,
                                  false,
                                  false,
                                  true
                                )}
                              </Cell>
                            </tr>
                          );
                        })}
                        <tr>
                          <Cell as="th" alignment="right">
                            Total
                          </Cell>
                          <Cell as="th" alignment="right">
                            {formatNumber(
                              customerSum.toFixed(2), // change this
                              true,
                              false,
                              false,
                              true
                            )}
                          </Cell>
                        </tr>

                        <tr>
                          <Cell as="th" alignment="right">
                            Percentage of Grand Total
                          </Cell>
                          <Cell as="th" alignment="right">
                            {`${formatNumber(
                              (
                                (customerSum /
                                  data?.totalCashCollection?.amount) *
                                100
                              ).toFixed(2)
                            )}%`}
                          </Cell>
                        </tr>
                      </tbody>
                    </TableStyle>
                  </VendorTableWrap>
                </div>
              </ColumnSection>
            </>
          )}
        />
      </Card>
    </>
  );
};

const AccountsReceivable = ({pdfVersion}) => {
  const { data, error } = useSWR(
    buildApiInternalUrl("fin-acc-receivable"),
    fetcherWithAuth,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (error?.status === 401) {
    return <></>;
  }
  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }
  const uniqueAgedRange = [
    ...new Set(data?.outStandTable?.map((item) => item.agedRange)),
  ];
  const uniqueDivision = [
    ...new Set(data?.outStandTable?.map((item) => item.division)),
  ];
  uniqueDivision.sort();
  uniqueAgedRange.sort();
  return (
    <InnerComponent
      data={data}
      uniqueAgedRange={uniqueAgedRange}
      uniqueDivision={uniqueDivision}
      pdfVersion={pdfVersion}
    />
  );
};

export default AccountsReceivable;
