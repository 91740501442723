import React from "react";
import useSWR from "swr";
import {
  TimeIntervals,
  fetcher,
} from "../../../../Utils/utils";
import LiabilityClaimPercent from "./LiabilityClaimPercent";

const LiabilityClaimPercentPublic = () => {
  const { data: racclaimspublic, error: racclaimserror } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/rac-claims-close.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );
  let finalData = racclaimspublic;
  
  if (!finalData && !racclaimserror && !racclaimserror?.status) {
    return <></>;
  }

  if (finalData?.renderCard !== undefined && !finalData.renderCard) {
    return <></>;
  }

  return <LiabilityClaimPercent data={finalData} />;
};

export default LiabilityClaimPercentPublic;
