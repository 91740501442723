import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { faTimesSquare } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ColumnSection from './ColumnSection';
import './FilterInput.css';

const TextField = styled.input`
  background-color: white;
  border-radius: ${(props) =>
    `${props.theme.radius} 0 0 ${props.theme.radius}`};
  border: 1px solid ${(props) => props.theme.input.border};
  cursor: pointer;
  padding: ${(props) => `${props.theme.padding.sm} ${props.theme.padding.xs}`};
  width:55%
  &:focus {
    box-shadow: 0 0 0 3px ${(props) => props.theme.input.focus};
    outline: none;
  }
`;

const ClearButton = styled.button`
  --fa-primary-color: ${(props) => props.theme.buttons.blue.icon.primary};
  --fa-secondary-color: ${(props) => props.theme.buttons.blue.icon.secondary};
  background-color: ${(props) => props.theme.buttons.blue.background};
  border-radius: ${(props) =>
    `0 ${props.theme.radius} ${props.theme.radius} 0`};
  border: ${(props) =>
    `solid ${props.theme.buttons.blue.border} ${props.theme.width.px}`};
  color: ${(props) => props.theme.buttons.blue.text};
  cursor: pointer;
  font-weight: ${(props) => props.theme.text.weight.medium};
  margin: ${(props) => `${props.theme.margin.none}`};
  padding: ${(props) => props.theme.padding.sm};
  text-align: center;
  text-decoration: none;
  transition: all 75ms cubic-bezier(0.4, 0, 1, 1);

  &:hover {
    background-color: ${(props) => props.theme.buttons.blue.hover.background};
    border: ${(props) =>
      `solid ${props.theme.buttons.blue.hover.border} ${props.theme.width.px}`};
    color: ${(props) => props.theme.buttons.blue.hover.text};
  }

  &:focus {
    box-shadow: 0 0 0 3px ${(props) => props.theme.buttons.focus};
    outline: none;
  }

  span {
    display: none;
  }
`;

const FilterInput = ({ filterText, onFilter, onClear, filterPlaceHolder }) => (
  <div className="filterInput">
    <ColumnSection
      cols="1fr auto"
      as="div"
      customColumns
      gap="0"
      alignment="unset"
    >
      <TextField
        id="search"
        type="text"
        placeholder= {filterPlaceHolder ?? "Filter by agency name or ID"}
        value={filterText}
        onChange={onFilter}
        aria-label="Filter Table"
      />
      <ClearButton onClick={onClear}>
        <FontAwesomeIcon icon={faTimesSquare} fixedWidth />
        <span>Clear Filter</span>
      </ClearButton>
    </ColumnSection>
  </div>
);

FilterInput.propTypes = {
  filterText: PropTypes.string,
  onFilter: PropTypes.func,
  onClear: PropTypes.func,
};

FilterInput.defaultProps = {
  filterText: '',
  onFilter: null,
  onClear: null,
};

export default FilterInput;
