import React from "react";
import { H2 } from "../Headings";
import styled from "@emotion/styled";
import IFrame from "react-iframe";

const TimeReporting = () => {
  const Wrap = styled.div`
    margin-bottom: 2rem;
  `;

  const ParaWrap = styled.div`
    margin-bottom: 1.5rem;
  `;

  const ListWrap = styled.div`
    margin-left: -1.5rem;
    li {
      margin-bottom: 0.5rem;
    }
  `;

  const IFrameWrap = styled.div`
    display: none;
    grid-column: 1 / -1;

    ${({ theme }) => theme.breakpoints.md} {
      display: initial;
    }
  `;

  const Frame = styled(IFrame)`
    height: 550px;
  `;

  return (
    <Wrap>
      <H2>OMES Resources – Time Reporting​</H2>
      <ParaWrap>
        This PowerBI report is built from Workday time-coding data for
        non-dedicated, dedicated, and contracted OMES employees.​
      </ParaWrap>
      <ParaWrap>
        Use the filters to search the time allocation for OMES employees, such
        as:
      </ParaWrap>
      <ListWrap>
        <ol>
          <li>
            Select a name from the Select Manager(s) filter to view all
            non-dedicated, dedicated, and contractor time by team. The Overview
            page of the report details both non-dedicated and dedicated employee
            time, but currently excludes contractor time.​
          </li>
          <li>
            Select an agency from the Select Agency(s) filter to view all
            non-dedicated, dedicated, and contractor time coded to the agency’s
            initiatives. ​
          </li>
        </ol>
      </ListWrap>

      <IFrameWrap>
        <Frame
          url="https://app.powerbigov.us/reportEmbed?reportId=4f5573f3-7586-4651-9740-cd657cf291ae&autoAuth=true&ctid=9a307864-3e98-4f08-b90a-728b62cf32c5"
          width="100%"
          height="100%"
        />
      </IFrameWrap>
    </Wrap>
  );
};

export default TimeReporting;
