import React, { useState, useEffect } from "react";
import useSWR from "swr";
import styled from "@emotion/styled";
import Card from "../Card";
import CardInnerWithTime from "../CardInnerWithTime";
import { H1, H3 } from "../../Components/Headings";
import ColumnSection from "../../Components/ColumnSection";
import {
  fetcher,
  formatNumber,
  monthsList,
  TimeIntervals,
} from "../../Utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/pro-duotone-svg-icons";
import "../../styles/OmesVisits.css";

const OmesVisits = () => {
  const [visitsData, setVisitsData] = useState({});

  const { data } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/top-web-visits.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  useEffect(() => {
    if (data) {
      let compareVisitCount;
      const monthsSorted = data?.visits
        ?.sort((a, b) => {
          if (a.year !== b.year) return a.year - b.year;
          return monthsList.indexOf(a.month) - monthsList.indexOf(b.month);
        })
        .filter((item) => item.visits !== null);

      const {
        year,
        month,
        visits,
        topWebPage1,
        topWebPage2,
        topWebPage3,
        topWebPageUrl1,
        topWebPageUrl2,
        topWebPageUrl3,
      } = monthsSorted?.[monthsSorted?.length - 1];

      const prevVisits = monthsSorted?.[monthsSorted?.length - 2]?.visits;
      if (prevVisits === undefined) {
        compareVisitCount = "Increased";
      } else {
        compareVisitCount = prevVisits < visits ? "Increased" : "Decreased";
      }

      setVisitsData({
        year,
        month,
        visits,
        topWebPage1,
        topWebPage2,
        topWebPage3,
        topWebPageUrl1,
        topWebPageUrl2,
        topWebPageUrl3,
        compareVisitCount,
      });
    }
  }, [data]);

  return (
    <>
      <div className="OMESContents">
        <H3>OMES Website Visits</H3>
        <p>
          Displays Monthly Unique Visits to the OMES Website and the most
          visited OMES webpages last month. Arrow indicates trend (up or down)
          from prior month.
        </p>

        <ColumnSection cols={3}>
          <Card>
            <CardInnerWithTime
              loading={!data}
              title="Monthly Unique Visits"
              refreshedTime={data?.timeStamp ?? ""}
              mainComponent={() => (
                <>
                  <H1 as="span">
                    {formatNumber(visitsData?.visits)}
                    {visitsData?.compareVisitCount === "Increased" ? (
                      <FontAwesomeIcon
                        className="UpArrow"
                        icon={faArrowUp}
                        fixedWidth
                        style={{ transform: "rotate(45deg)" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="DownArrow"
                        icon={faArrowDown}
                        fixedWidth
                        style={{ transform: "rotate(-45deg)" }}
                      />
                    )}
                  </H1>
                  <div
                    style={{ marginTop: "1rem" }}
                  >{`${visitsData.month} ${visitsData.year}`}</div>
                </>
              )}
            />
          </Card>

          <Card>
            <CardInnerWithTime
              loading={!data}
              title="Top Webpages Visited"
              refreshedTime={data?.timeStamp ?? ""}
              mainComponent={() => (
                <>
                  <div className="URLLinks">
                    <a href={visitsData.topWebPageUrl1} target="_blank">
                      {visitsData.topWebPage1}
                    </a>
                    <a href={visitsData.topWebPageUrl2} target="_blank">
                      {visitsData.topWebPage2}
                    </a>
                    <a href={visitsData.topWebPageUrl3} target="_blank">
                      {visitsData.topWebPage3}
                    </a>
                  </div>
                  <div
                    style={{ marginTop: "1rem" }}
                  >{`${visitsData.month} ${visitsData.year}`}</div>
                </>
              )}
            />
          </Card>
        </ColumnSection>
      </div>
    </>
  );
};

export default OmesVisits;
