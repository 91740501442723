import React from "react";
import IFrame from "react-iframe";
import styled from "@emotion/styled";
import useSWR from "swr";
import { TimeIntervals, fetcher } from "../../Utils/utils";
import Card from "../Card";
import { H2 } from "../Headings";

const Wrap = styled.div`
  display: none;
  grid-column: 1 / -1;

  ${({ theme }) => theme.breakpoints.md} {
    display: initial;
  }
`;

const Frame = styled(IFrame)`
  height: 550px;
`;

const Link = styled.a`
  display: block;
  text-align: center;
  padding: ${({ theme }) => theme.padding.md};

  ${({ theme }) => theme.breakpoints.md} {
    display: none;
  }
`;

const EpmoCard = ({ heading }) => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/epmo.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (error) {
    return <div>There was an error please try again later..</div>;
  }

  return (
    <>
      {heading && <H2 style={{ gridColumn: "1/-1" }}>{heading}</H2>}

      <p style={{ gridColumn: "1/-1" }}>
        The Enterprise Program Management Office dashboard provides transparent
        reporting of all IT projects managed by OMES IS. Showcases overall
        health, budget, completion status and more. Select your agency name to
        see your agency project metrics.
      </p>
      <Card wide>
        <Wrap>
          <Frame url={data[0]?.value.url} width="100%" height="100%" />
        </Wrap>
        <Link href={data[0]?.value.url} target="_blank" rel="noreferrer">
          Open in seperate window
        </Link>
      </Card>
      {/* <Wrap>
        <Frame
          url="https://app.powerbigov.us/reportEmbed?reportId=cd2607bd-cb87-49b3-974b-0ea3a181ea45&autoAuth=true&ctid=9a307864-3e98-4f08-b90a-728b62cf32c5"
          width="100%"
          height="100%"
        />
      </Wrap> */}
    </>
  );
};

export default EpmoCard;
