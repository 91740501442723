import React, { useState } from "react";
import { Hint, RadialChart } from "react-vis";
import AutoSizer from "react-virtualized-auto-sizer";
import styled from "@emotion/styled";
import { TimeIntervals, fetcher } from "../../../Utils/utils";
import Card from "../../Card";
import TooltipItem from "../../TooltipItem";
import { faClock, faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import Legend from "../../BaseCharts/Legend";
import useSWR from "swr";
import { H3 } from "../../Headings";
import "../../../styles/ISCompliance.css";
import CardTopItems from "../../CardTopItems";

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin-top: 2.25rem;
`;

const PieChart = ({ data, updatedDateTime}) => {
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);

  return (<>
    <Card className="ISCompliance">
    <CardTopItems
      items={[
        {
          text: `IPP - Internal Purchasing Procedures`,
          icon: faInfoCircle,
          style: {
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
            textAlign: "justify",
          },
        },
        {
          text: `As of ${new Date(updatedDateTime).toLocaleString()}`,
          icon: faClock,
        },
      ]}
    />

    <H3>{`Non-IS Compliance & Privacy`}</H3>
    <Wrap>
      <div>
        <AutoSizer disableHeight>
          {({ width }) => (
            <RadialChart
              colorType="literal"
              innerRadius={0}
              radius={100}
              data={data}
              animation={animation}
              onValueMouseOver={(datapoint) => {
                setAnimation(false);
                setHint(datapoint);
              }}
              onValueMouseOut={() => {
                setHint(false);
                setAnimation(true);
              }}
              width={width}
              height={200}
            >
              {hint && (
                <Hint value={hint}>
                  <div
                    className="rv-hint__content"
                    style={{ pointerEvents: "none" }}
                  >
                    <TooltipItem
                      fill={hint.color}
                      text={`${hint.text}: ${hint.value}%`}
                    />
                  </div>
                </Hint>
              )}
            </RadialChart>
          )}
        </AutoSizer>
      </div>
      <Legend>
        {data.map((x, i) => (
          <TooltipItem
            key={i}
            fill={x.color}
            text={`${x.text}`}
          />
        ))}
      </Legend>
    </Wrap>
  </Card>
    </>
  
  );
};

const NONISCompliance = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/rac-non-is-ipp.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );
  if (!data && !error && !error?.status) {
    return <>No data was found</>;
  }
  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }
  let isRender = data?.renderCard;
  const actualData = data?.metricData?.data;

  return (
    <>
      {isRender && (
        <PieChart
          data={actualData}
          updatedDateTime={data?.timeStamp}
        />
      )}
    </>
  );
};

export default NONISCompliance;
