import React from "react";
import EpmoCard from "../Components/Cards/EpmoCard";
import ProjectStatusReports from "../Components/Cards/ProjectStatusReports";
import ColumnSection from "../Components/ColumnSection";

const Epmo = () => (
  <ColumnSection cols={1}>
    <EpmoCard />
    <ProjectStatusReports />
  </ColumnSection>
);

export default Epmo;
