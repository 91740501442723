import React from "react";
import CAPProjectsReviewed from "./CAPProjectsReviewed";
import CAPProjectsTabs from "./CAPProjectsTabs/CAPProjectsTabs";

const ProjectsReviewed = () => {
  return (
    <>
      <p>CAP Projects Reviewed</p>
      <CAPProjectsReviewed />
      <CAPProjectsTabs />
    </>
  );
};

export default ProjectsReviewed;
