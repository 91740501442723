import React from "react";
import useSWR from "swr";
import styled from "@emotion/styled";
import Card from "../Card";
import CardInnerWithTime from "../CardInnerWithTime";
import { H1 } from "../Headings";
import { TimeIntervals, fetcher } from "../../Utils/utils";
import NumberFormat from "react-number-format";

const Wrapper = styled.div`
  text-align: center;
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
`;

const Bottom = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.width.xs};
  text-align: center;
  margin: ${({ theme }) =>
    `${theme.padding.sm} -${theme.margin.md} -${theme.margin.sm}`};
  padding: ${({ theme }) => `${theme.padding.sm} ${theme.padding.md}`};
  background-color: ${({ theme }) => theme.card.well.background};
  border-top: ${({ theme }) => `${theme.width.px} solid ${theme.card.border}`};
`;

const InnerComponent = ({ data, title }) => {
  return (
    <Card>
      <Wrapper>
        <CardInnerWithTime
          title={title}
          refreshedTime={data?.timeStamp ?? ""}
          mainComponent={() => (
            <NumberFormat
              value={
                title === "Servers with Monitoring"
                  ? data?.serversWithMonitoring * 100
                  : data?.agenciesOnStateDomain * 100
              }
              displayType="text"
              renderText={(value) => <H1 as="span">{value}</H1>}
              suffix="%"
              decimalScale={2}
            />
          )}
          loading={data === null}
        />
        <Bottom>{`SLA Goal: ${data?.slaGoal * 100}%`}</Bottom>
      </Wrapper>
    </Card>
  );
};

const ServerMetrics = ({ which, title }) => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}${which}`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} title={title} />;
};

export default ServerMetrics;
