import React, { useEffect, useState } from "react";
import CardInnerWithTime from "../CardInnerWithTime";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import TooltipItem from "../TooltipItem";
import "../../styles/CashReporting.css";
import "../../styles/Common.css";
import { TimeIntervals, buildApiInternalUrl, formatNumber } from "../../Utils/utils";
import {
  Crosshair,
  XYPlot,
  HorizontalGridLines,
  XAxis,
  YAxis,
  VerticalBarSeries,
} from "react-vis";
import AutoSizer from "react-virtualized-auto-sizer";
import styled from "@emotion/styled";
import AuthorizedArea from "../AuthorizedArea";

const LegendWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 3rem;
`;

const InnerComponent = ({ data, title, runTime }) => {
  const [hint, setHint] = useState(false);
  const [crosshairValues, setCrosshairValues] = useState([]);
  const [yDomainTopValue, setYDomainTopValue] = useState(0);
  const [formattedData, setFormattedData] = useState({});
  useEffect(() => {
    if (data) {
      let currentYear = new Date().getFullYear();
      let thisYear = "FY" + (currentYear % 100);
      let nextYear = "FY" + ((currentYear + 1) % 100);
      const nextYearLength = data.filter((x) => x.name.includes(nextYear)).length;
     if(nextYearLength == 0)
     {
      currentYear = currentYear - 1;
      thisYear = "FY" + (currentYear % 100);
      nextYear = "FY" + ((currentYear + 1) % 100);
     }
      let lastYear = "FY" + ((currentYear - 1) % 100);
      let forData = [];
      data.map((item) => {
        forData.push({
          whichYear: item.name.includes(thisYear)
            ? thisYear
            : item.name.includes(lastYear)
            ? lastYear
            : nextYear,
          q1: isNaN(parseFloat(item.data[0][1]))
            ? 0
            : parseFloat(item.data[0][1]).toFixed(2),
          q2: isNaN(parseFloat(item.data[0][2]))
            ? 0
            : parseFloat(item.data[0][2]).toFixed(2),
          q3: isNaN(parseFloat(item.data[0][3]))
            ? 0
            : parseFloat(item.data[0][3]).toFixed(2),
          q4: isNaN(parseFloat(item.data[0][4]))
            ? 0
            : parseFloat(item.data[0][4]).toFixed(2),
          title: item.name.includes("Fees Collected NON-IT")
            ? "NON-IT"
            : item.name.includes("Fees Collected IT")
            ? "IT"
            : "STATE USE",
          total: isNaN(parseFloat(item.data[0][5]))
            ? 0
            : parseFloat(item.data[0][5]).toFixed(2),
          maxQuater: Math.max(
            item.data[0][1],
            item.data[0][2],
            item.data[0][3],
            item.data[0][4]
          ),
        });
      });
      const sortList = [lastYear, thisYear, nextYear];

      const sortedData = forData.sort((a, b) => {
        return sortList.indexOf(a.whichYear) - sortList.indexOf(b.whichYear);
      });
      const maxVal = Math.max(...sortedData?.map((o) => o.maxQuater));
      setYDomainTopValue([0, 1.2 * maxVal]);
      setFormattedData(sortedData);
    }
  }, [data]);
  return (
    <>
      <div className="CashReporting">
        <CardInnerWithTime
          loading={!formattedData}
          title="Contract Management Fees Collected"
          refreshedTime={runTime ?? ""}
          extraIcons={[
            {
              text: `Pursuant to 74 O.S. § 85.33A, the State assesses 
a contract management fee on all transactions 
under a statewide contract.`,
              icon: faInfoCircle,
              style: {
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
                textAlign: "justify",
              },
            },
          ]}
          mainComponent={() => (
            <>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <XYPlot
                    xType="ordinal"
                    colorType="literal"
                    width={width}
                    height={200}
                    margin={{
                      left: 75,
                    }}
                    yDomain={yDomainTopValue}
                    onMouseLeave={() => {
                      setHint(false);
                      setCrosshairValues(false);
                    }}
                  >
                    <HorizontalGridLines />
                    <XAxis
                      style={{ fontSize: "11px", wordBreak: "break-all" }}
                    />
                    <YAxis tickFormat={(d) => "$" + formatNumber(d)} />
                    <VerticalBarSeries
                      data={formattedData
                        .filter((temp) => temp.title === title)
                        .map((item) => ({
                          x: item.whichYear,
                          y: item.q1,
                          color: "#7eb5d7",
                        }))}
                      barWidth={0.2}
                      onValueMouseOver={(datapoint) => {
                        setHint(datapoint);
                        setCrosshairValues([datapoint]);
                      }}
                    />
                    <VerticalBarSeries
                      data={formattedData
                        .filter((temp) => temp.title === title)
                        .map((item) => ({
                          x: item.whichYear,
                          y: item.q2,
                          color: "#53a8dd",
                        }))}
                      barWidth={0.2}
                      onValueMouseOver={(datapoint) => {
                        setHint(datapoint);
                        setCrosshairValues([datapoint]);
                      }}
                    />

                    <VerticalBarSeries
                      data={formattedData
                        .filter((temp) => temp.title === title)
                        .map((item) => ({
                          x: item.whichYear,
                          y: item.q3,
                          color: "#115078",
                        }))}
                      barWidth={0.2}
                      onValueMouseOver={(datapoint) => {
                        setHint(datapoint);
                        setCrosshairValues([datapoint]);
                      }}
                    />
                    <VerticalBarSeries
                      data={formattedData
                        .filter((temp) => temp.title === title)
                        .map((item) => ({
                          x: item.whichYear,
                          y: item.q4,
                          color: "#15364b",
                        }))}
                      barWidth={0.2}
                      onValueMouseOver={(datapoint) => {
                        setHint(datapoint);
                        setCrosshairValues([datapoint]);
                      }}
                    />
                    {crosshairValues && (
                      <Crosshair values={crosshairValues}>
                        <div className="ShowHint">
                          <h3>
                            {hint.x +
                              " : $" +
                              formatNumber(
                                formattedData.filter(
                                  (temp) =>
                                    temp.title === title &&
                                    temp.whichYear === hint.x
                                )[0]?.total
                              )}
                          </h3>
                          <TooltipItem
                            fill="#7eb5d7"
                            text={`Q1 : $${formatNumber(
                              formattedData.filter(
                                (temp) =>
                                  temp.title === title &&
                                  temp.whichYear === hint.x
                              )[0]?.q1
                            )}`}
                          />
                          <TooltipItem
                            fill="#53a8dd"
                            text={`Q2 : $${formatNumber(
                              formattedData.filter(
                                (temp) =>
                                  temp.title === title &&
                                  temp.whichYear === hint.x
                              )[0]?.q2
                            )}`}
                          />
                          <TooltipItem
                            fill="#115078"
                            text={`Q3 : $${formatNumber(
                              formattedData.filter(
                                (temp) =>
                                  temp.title === title &&
                                  temp.whichYear === hint.x
                              )[0]?.q3
                            )}`}
                          />
                          <TooltipItem
                            fill="#15364b"
                            text={`Q4 : $${formatNumber(
                              formattedData.filter(
                                (temp) =>
                                  temp.title === title &&
                                  temp.whichYear === hint.x
                              )[0]?.q4
                            )}`}
                          />
                        </div>
                      </Crosshair>
                    )}
                  </XYPlot>
                )}
              </AutoSizer>

              <LegendWrap>
                <TooltipItem fill="#7eb5d7" text="Q1" />
                <TooltipItem fill="#53a8dd" text="Q2" />
                <TooltipItem fill="#115078" text="Q3" />
                <TooltipItem fill="#15364b" text="Q4" />
              </LegendWrap>
            </>
          )}
        />
      </div>
    </>
  );
};

const CPFeeCollection = ({ title, runTime }) => (
  <AuthorizedArea
    url={buildApiInternalUrl("cp-contract-fees")}
    refreshInterval={TimeIntervals.ONE_DAY}
    innerComponent={({ data, error }) => {
      if (!data && !error && !error?.status) {
        return <></>;
      }
      if (data?.renderCard !== undefined && !data.renderCard) {
        return <></>;
      }

      if (error?.status === 401) {
        return <></>;
      }
      return <InnerComponent data={data} title={title} runTime={runTime} />;
    }}
  />
);

export default CPFeeCollection;
