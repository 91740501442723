import React, { useState } from "react";
import useSWR from "swr";
import styled from "@emotion/styled";
import Card from "../Card";
import CardTopItems from "../CardTopItems";
import { faClock } from "@fortawesome/pro-duotone-svg-icons";
import AutoSizer from "react-virtualized-auto-sizer";
import { H3 } from "../Headings";
import { TimeIntervals, fetcher, formatNumber } from "../../Utils/utils";
import { Hint, RadialChart } from "react-vis";
import TooltipItem from "../TooltipItem";
import Legend from "../BaseCharts/Legend";

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const Bottom = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.width.xs};
  text-align: center;
  margin: ${({ theme }) =>
    `${theme.padding.sm} -${theme.margin.md} -${theme.margin.sm}`};
  padding: ${({ theme }) => `${theme.padding.sm} ${theme.padding.md}`};
  background-color: ${({ theme }) => theme.card.well.background};
  border-top: ${({ theme }) => `${theme.width.px} solid ${theme.card.border}`};
`;

const InnerComponent = ({ data }) => {
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);

  const envData = [
    {
      angle: data.virtualPerc,
      value: data.virtualNumber,
      color: "#1ca6df",
      text: "Virtual",
    },
    {
      angle: data.cloudPerc,
      value: data.cloudNumber,
      color: "#de9027",
      text: "Cloud",
    },
    {
      angle: data.physicalPerc,
      value: data.physicalNumber,
      color: "#669b41",
      text: "Physical",
    },
  ];

  return (
    <Card className="ServerEnv">
      <CardTopItems
        items={[
          {
            text: `As of ${new Date(data?.timeStamp).toLocaleString()}`,
            icon: faClock,
          },
        ]}
      />
      <H3>{`Server Environments`}</H3>
      <Wrap>
        <div>
          <AutoSizer disableHeight>
            {({ width }) => (
              <RadialChart
                colorType="literal"
                innerRadius={0}
                radius={100}
                data={envData}
                animation={animation}
                onValueMouseOver={(datapoint) => {
                  setAnimation(false);
                  setHint(datapoint);
                }}
                onValueMouseOut={() => {
                  setHint(false);
                  setAnimation(true);
                }}
                width={width}
                height={200}
              >
                {hint && (
                  <Hint value={hint}>
                    <div
                      className="rv-hint__content"
                      style={{ pointerEvents: "none" }}
                    >
                      <TooltipItem
                        fill={hint.color}
                        text={`${hint.text}: ${formatNumber(hint.value)}`}
                      />
                    </div>
                  </Hint>
                )}
              </RadialChart>
            )}
          </AutoSizer>
        </div>
        <Legend>
          {envData.map((x, i) => (
            <TooltipItem
              key={i}
              fill={x.color}
              text={`${x.text} (${x.angle * 100}%)`}
            />
          ))}
        </Legend>
      </Wrap>
      <Bottom>{`SLA Goal: 100% cloud-based servers`}</Bottom>
    </Card>
  );
};

const ServerEnv = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/is-server-data-card3.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} />;
};

export default ServerEnv;
