import React from "react";
import useSWR from "swr";
import Card from "../../../Card";
import CardInnerWithTime from "../../../CardInnerWithTime";
import { H1, H2 } from "../../../Headings";
import { TimeIntervals, fetcher, formatNumber } from "../../../../Utils/utils";
import "../../../../styles/Common.css";
import "../../../../styles/ProjectsReviewed.css";

const InnerComponent = ({ data, data2 }) => {
  return (
    <>
      <Card className="CAPProjectsReviewed">
        <CardInnerWithTime
          loading={!data}
          refreshedTime={data?.timeStamp ?? ""}
          mainComponent={() => (
            <>
              <div className="TopHeader">
                <div className="HeadCounts">
                  <H2 as="h3"># of CAP Projects Reviewed</H2>
                  <H1 as="span">
                    {data?.statusCount?.length > 0
                      ? formatNumber(data?.statusCount?.[0]?.count)
                      : "-"}
                  </H1>
                </div>
                <div className="Divider"></div>
                <div className="HeadCounts">
                  <H2 as="h3">CAP Project Value Reviewed</H2>
                  <H1 as="span">
                    {data2?.statusAmount?.length > 0
                      ? formatNumber(data2?.statusAmount?.[0]?.amount, true)
                      : "-"}
                  </H1>
                </div>
              </div>
            </>
          )}
        />
      </Card>
    </>
  );
};

const CAPProjectsReviewed = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/rac-cap-count.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  const { data2, error2 } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/rac-cap-cost.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  if (
    !data &&
    !error &&
    !error?.status &&
    !data2 &&
    !error2 &&
    !error2?.status
  ) {
    return <></>;
  }

  if (
    data?.renderCard !== undefined &&
    !data.renderCard &&
    data2?.renderCard !== undefined &&
    !data2.renderCard
  ) {
    return <></>;
  }

  return <InnerComponent data={data} data2={data2} />;
};

export default CAPProjectsReviewed;
