import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';

import List from './List';
import IdeaLink from './IdeaLink';

const Navigation = ({ open, isAuthenticatedUser }) => {
  const theme = useTheme();
  const NavWrap = styled.nav`
    display: ${open ? 'grid' : 'none'};

    ${theme.breakpoints.xl} {
      display: grid;
      grid-template-rows: auto 1fr;
    }
  `;

  return (
      <NavWrap>
          <List isAuthenticatedUser={isAuthenticatedUser}/>
      <IdeaLink />
    </NavWrap>
  );
};

Navigation.propTypes = {
    open: PropTypes.bool,
    isAuthenticatedUser:PropTypes.bool,
};

export default Navigation;
