import React, { useState } from "react";
import { Hint, RadialChart } from "react-vis";
import AutoSizer from "react-virtualized-auto-sizer";
import styled from "@emotion/styled";
import { TimeIntervals, fetcher } from "../../../Utils/utils";
import Card from "../../Card";
import CardTopItems from "../../CardTopItems";
import { H3 } from "../../Headings";
import TooltipItem from "../../TooltipItem";
import { faClock, faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import Legend from "../../BaseCharts/Legend";
import useSWR from "swr";
import "../../../styles/ISCompliance.css";

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin-top: 1rem;
`;

const PieChart = ({ data, updatedDateTime }) => {
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);

  if (data.length > 0) {
    data = data.filter(
      (item) => item.text === "Closed" || item.text === "In Progress"
    );
  }

  return (
    <Card className="ISCompliance">
      <CardTopItems
        items={[
          {
            text: `eDiscovery - Requests for electronically stored
information (ESI) through ServiceNow tickets`,
            icon: faInfoCircle,
            style: {
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              textAlign: "justify",
            },
          },
          {
            text: `As of ${new Date(updatedDateTime).toLocaleString()}`,
            icon: faClock,
          },
        ]}
      />

      <H3>{`IS Compliance & Privacy`}</H3>
      <span>{`Number of requests received over the past 30 days by eDiscovery.`}</span>
      <Wrap>
        <div>
          <AutoSizer disableHeight>
            {({ width }) => (
              <RadialChart
                colorType="literal"
                innerRadius={0}
                radius={100}
                data={data}
                animation={animation}
                onValueMouseOver={(datapoint) => {
                  setAnimation(false);
                  setHint(datapoint);
                }}
                onValueMouseOut={() => {
                  setHint(false);
                  setAnimation(true);
                }}
                width={width}
                height={200}
              >
                {hint && (
                  <Hint value={hint}>
                    <div
                      className="rv-hint__content"
                      style={{ pointerEvents: "none" }}
                    >
                      <TooltipItem
                        fill={hint.color}
                        text={`${hint.text}: ${hint.value}`}
                      />
                    </div>
                  </Hint>
                )}
              </RadialChart>
            )}
          </AutoSizer>
        </div>
        <Legend>
          {data.map((x, i) => (
            <TooltipItem
              key={i}
              fill={x.color}
              text={`${x.text}: ${x.value}`}
            />
          ))}
        </Legend>
      </Wrap>
    </Card>
  );
};

const getColor = (label) => {
  switch (label) {
    case "Closed":
      return "#00386E";
    case "In Progress":
      return "#53a8dd";
    case "On Hold":
      return "#d15420";
    case "Resolved":
      return "#187bc0";
    case "Canceled":
      return "#464646";
    default:
      return "#1ca6df";
  }
};

const ISCompliance = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/EDiscoveryMetricsData.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.THIRTY_MINUTES,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );
  if (!data && !error && !error?.status) {
    return <>No data was found</>;
  }
  if (data?.RenderCard !== undefined && !data.RenderCard) {
    return <></>;
  }
  let isRender = data?.RenderCard;
  var helper = {};
  var result = data?.NintyDayRolling.reduce(function (r, o) {
    var key = o.Label;
    if (!helper[key]) {
      helper[key] = Object.assign({}, o); // create a copy of o
      r.push(helper[key]);
    } else {
      helper[key].Count += o.Count;
    }

    return r;
  }, []);
  const actualData = result.map((x) => ({
    angle: x.Count,
    value: x.Count,
    text: x.Label,
    color: getColor(x.Label),
  }));

  return (
    <>
      {isRender && (
        <PieChart data={actualData} updatedDateTime={data?.Reported} />
      )}
    </>
  );
};

export default ISCompliance;
