import React, { useState } from "react";
import useSWR from "swr";
import styled from "@emotion/styled";
import Card from "../Card";
import { H3 } from "../Headings";
import CardInnerWithTime from "../CardInnerWithTime";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { TimeIntervals, fetcher } from "../../Utils/utils";
import { TableStyle } from "../BaseTable";
import Select from "../Select";
import Cell from "../AppCTable/Cell";
import "../../styles/COOPScore.css";

const InnerComponent = ({ data }) => {
  const TableWrap = styled.div`
    height: 100%;
    overflow: auto;
    width: 100%;

    table {
      display: block;
      overflow-x: auto;
    }

    thead tr {
      background-color: #005c95;
      color: #ffffff;
    }

    thead th {
      overflow-wrap: break-word;
      white-space: pre-wrap !important;
    }
  `;

  const applyColor = (stage) => {
    switch (stage) {
      case "Complete":
        return <div style={{ color: "green" }}>Complete</div>;
      case "In Progress":
        return <div style={{ color: "black" }}>In Progress</div>;
      case "Outdated":
        return <div style={{ color: "red" }}>Outdated</div>;
      case "No Plan":
        return <div style={{ color: "red" }}>No Plan</div>;
      default:
        return <div style={{ color: "black" }}>{stage}</div>;
    }
  };

  const [agency, setAgency] = useState(0);
  const [agencyData, setAgencyData] = useState(null);

  return (
    <>
      <Card className="COOPScore">
        <CardInnerWithTime
          loading={!data}
          title="Continuity of Operations Planning (COOP) Score"
          refreshedTime={data?.timeStamp ?? ""}
          extraIcons={[
            {
              text: `A COOP plan is evaluated based on 7 criteria
items once the agency provides updated COOP
documentation to OMES. 
`,
              icon: faInfoCircle,
              style: {
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
                textAlign: "justify",
              },
            },
          ]}
          mainComponent={() => (
            <>
              <H3>Criteria for COOP Scoring:</H3>
              <ol type="1">
                <li>Contact Information/Call Tree/Alert Systems</li>
                <li>
                  Critical Functions - Recovery Time Objective (RTO)/Recovery
                  Point Objective (RPO)
                </li>
                <li>Orders of Succession/Delegation of Authority</li>
                <li>
                  Alternate Site Operations Center Locations/Resources/Offsite
                  Procedures
                </li>
                <li>Vital Records</li>
                <li>Critical Forms and Supplies</li>
                <li>Critical Recovery Tasks List with Workaround Procedures</li>
              </ol>
              <Select
                onChange={(e) => {
                  const val = e.target.value;
                  setAgency(val);
                  setAgencyData(
                    data?.coopScores?.filter((x) => x.agencyKey === val)[0]
                  );
                }}
                defaultValue={agency}
                aria-label="Select Item to View"
              >
                <option>Select an agency</option>
                {data?.coopScores?.map((x, i) => (
                  <option key={i} value={x.agencyKey}>
                    {x.agencyName}
                  </option>
                ))}
              </Select>

              {agency !== 0 && (
                <TableWrap>
                  <TableStyle color="blue" wrapped>
                    <thead>
                      <tr>
                        <Cell as="th" alignment="left">
                          Agency Number
                        </Cell>
                        <Cell as="th" alignment="left">
                          Agency Name
                        </Cell>
                        <Cell as="th" alignment="left">
                          Critical Infrastructure CORE Apps
                        </Cell>
                        <Cell as="th" alignment="left">
                          Agency COOP Planner
                        </Cell>
                        <Cell as="th" alignment="left">
                          Date Received
                        </Cell>
                        <Cell as="th" alignment="left">
                          COOP Plan Criteria Stage
                        </Cell>
                        <Cell as="th" alignment="left">
                          Critical Area Completion Score (x/7)
                        </Cell>
                        <Cell as="th" alignment="left">
                          Comments
                        </Cell>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <Cell as="td" alignment="left">
                          {agencyData?.agencyKey !== ""
                            ? agencyData?.agencyKey
                            : ""}
                        </Cell>
                        <Cell as="td" alignment="left">
                          {agencyData?.agencyName !== ""
                            ? agencyData?.agencyName
                            : ""}
                        </Cell>
                        <Cell as="td" alignment="left">
                          {agencyData?.coreApps !== ""
                            ? agencyData?.coreApps
                            : ""}
                        </Cell>
                        <Cell as="td" alignment="left">
                          {agencyData?.coopPlanner !== ""
                            ? agencyData?.coopPlanner
                            : ""}
                        </Cell>
                        <Cell as="td" alignment="left">
                          {agencyData?.dateReceived !== ""
                            ? agencyData?.dateReceived
                            : ""}
                        </Cell>
                        <Cell as="td" alignment="left">
                          {agencyData?.planCriteriaStage !== ""
                            ? applyColor(agencyData?.planCriteriaStage)
                            : ""}
                        </Cell>
                        <Cell as="td" alignment="right">
                          {agencyData?.criticalAreaCompletionScore !== ""
                            ? agencyData?.criticalAreaCompletionScore
                            : ""}
                        </Cell>
                        <Cell as="td" alignment="left">
                          {agencyData?.comments !== ""
                            ? agencyData?.comments
                            : ""}
                        </Cell>
                      </tr>
                    </tbody>
                  </TableStyle>
                </TableWrap>
              )}
            </>
          )}
        />
      </Card>
    </>
  );
};

const COOPScore = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/OMES-PIG-COOP.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (error?.status === 401) {
    return <></>;
  }
  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} />;
};

export default COOPScore;
