import React from "react";
import HorizontalBarChart from "../../BaseCharts/HorizontalBarChart";
import CardInnerWithTime from "../../CardInnerWithTime";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { useTheme } from "emotion-theming";
import { formatNumber } from "../../../Utils/utils";
import TooltipItem from "../../TooltipItem";
import { H1, H2 } from "../../Headings";
import "../../../styles/Common.css";
import "../../../styles/CapitalAssets.css";

const WorkByCode = ({
  data,
  uniqueWorkCode,
  closedWorkOrdersCount,
  totalWorkOrders,
  timeStamp,
}) => {
  const theme = useTheme();
  const workCode = [];

  //uniqueWorkCode = uniqueWorkCode.filter((item) => item !== "");

  data.map((item) => {
    //const dataCount = data.filter((it) => it.c3 === item).length;
    workCode.push({
      x: item.count,
      y: item.workCode ? item.workCode : "Other",
    });
  });
  const barChartData = [
    {
      set: "workCodeSet",
      setData: workCode,
      color: "#005c95"
    },
  ];

  const maxValue = workCode.length > 0 && Math.max(...workCode.map((d) => d.x));
  let xDomainTopValue = [0, 1.1 * maxValue];

  return (
    <div className="WorkByCode">
      <div className="TopHeader">
        <div className="HeadCounts">
          <H2 as="h3">Closed Work Orders</H2>
          <H1 as="span">{formatNumber(closedWorkOrdersCount)}</H1>
        </div>
        <div className="Divider"></div>
        <div className="HeadCounts">
          <H2 as="h3">Total Work Orders</H2>
          <H1 as="span">{formatNumber(totalWorkOrders)}</H1>
        </div>
      </div>
      <CardInnerWithTime
        loading={!data}
        title="Monthly Work Orders by Work Code"
        refreshedTime={timeStamp ?? ""}
        extraIcons={[
          {
            text: `Data reflects work orders logged this previous month.`,
            icon: faInfoCircle,
          },
        ]}
        mainComponent={() => (
          <>
            {barChartData && (
              <HorizontalBarChart
                data={barChartData}
                showX
                showY
                ordinalY
                leftPadding={120}
                xAxisFormat={(d) => formatNumber(d)}
                hintFormat={(h) => (
                  <div className="HintWrap">
                    <TooltipItem
                      fill={"#005c95"}
                      text={`${h.y}: ${formatNumber(h.x)}`}
                    />
                  </div>
                )}
                gridLinesHorizontal={false}
                gridLinesVertical={true}
                xDomain={xDomainTopValue}
                graphColor={theme.colors.skyBlue.darken}
                height={1200}
              />
            )}
          </>
        )}
      />
    </div>
  );
};

export default WorkByCode;
