import React from "react";
import useSWR from "swr";
import Card from "../../Card";
import CardInnerWithTime from "../../CardInnerWithTime";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { H1, H4 } from "../../Headings";
import { TimeIntervals, fetcher } from "../../../Utils/utils";
import "../../../styles/RiskAssessment.css";

const InnerComponent = ({ data }) => {
  const { number, title } = data?.data?.[0];

  return (
    <Card>
      <CardInnerWithTime
        loading={!data}
        title="Insurance Survey Response Rate"
        refreshedTime={data?.timeStamp ?? ""}
        extraIcons={[
          {
            text: `Asset reporting from state agencies for various programs.
Due to the numerous surveys conducted throughout the
year, the response rate is taken as an average of responses
and updated as surveys are completed.`,
            icon: faInfoCircle,
            style: {
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              textAlign: "justify",
            },
          },
        ]}
        mainComponent={() => (
          <>
            <H1 as="span">
              {number !== "" ? number : <H4>No data available</H4>}
            </H1>
            <div className="YearValue">{title !== "" && title}</div>
          </>
        )}
      />
    </Card>
  );
};

const InsuranceSurvey = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/rac-insurance-survey.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} />;
};

export default InsuranceSurvey;
