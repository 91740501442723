import React from "react";
import useSWR from "swr";
import Card from "../../Card";
import CardInnerWithTime from "../../CardInnerWithTime";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { TimeIntervals, fetcher, formatNumber } from "../../../Utils/utils";
import "../../../styles/Common.css";
import "../../../styles/BudgetCompliance.css";
import Datagrid from "../../Datagrid";

const InnerComponent = ({ data, setIsTribeRender }) => {
  setIsTribeRender(data?.renderCard);

  let extractedData = [];
  let years = [];

  const changeYear = (year) => {
    let yearString = "" + year;
    yearString = yearString.substring(2);
    return "FY " + yearString;
  };

  for (let key in data?.tribeData?.[0]?.fees) {
    years.push(key);
    extractedData.push(changeYear(key));
  }

  const renderTableData = (item, thisYear, index) => {
    return (
      <td className="AlignRight">
        {index === 0 && item.fees[thisYear] !== null ? (
          <div className="GreenColor">
            {formatNumber(item.fees[thisYear], true, false, false, true)}
          </div>
        ) : item.fees[thisYear] !== null ? (
          <div
            className={`${
              item.fees[thisYear] > item.fees[thisYear - 1]
                ? "GreenColor"
                : "RedColor"
            }`}
          >
            {formatNumber(item.fees[thisYear], true, false, false, true)}
          </div>
        ) : (
          "-"
        )}
      </td>
    );
  };
  const MapFormat = (d, years) =>
    d?.map((x, i) => ({
      tribeName: `${x.tribeName}`,
      firstYear: x.fees[years[0]] !== null && x.fees[years[0]] !== 0 && x.fees[years[0]] != "null" ? x.fees[years[0]] : 0,
      secondYear: x.fees[years[1]] !== null && x.fees[years[1]] !== 0 && x.fees[years[1]] != "null" ? x.fees[years[1]] : 0,
      thirdYear: x.fees[years[2]] !== null && x.fees[years[2]] !== 0 && x.fees[years[2]] != "null" ? x.fees[years[2]] : 0,
      forthYear: x.fees[years[3]] !== null && x.fees[years[3]] !== 0 && x.fees[years[3]] != "null" ? x.fees[years[3]] : 0,
      fifthYear: x.fees[years[4]] !== null && x.fees[years[4]] !== 0 && x.fees[years[4]] != "null" ? x.fees[years[4]] : 0,
      sixthYear: x.fees[years[5]] !== null && x.fees[years[5]] !== 0 && x.fees[years[5]] != "null" ? x.fees[years[5]] : 0,
      firstYearColor: "GreenColor",
      secondYearColor:
        x.fees[years[1]] > x.fees[years[0]] ? "GreenColor" : "RedColor",
      thirdYearColor:
        x.fees[years[2]] > x.fees[years[1]] ? "GreenColor" : "RedColor",
      forthYearColor:
        x.fees[years[3]] > x.fees[years[2]] ? "GreenColor" : "RedColor",
      fifthYearColor:
        x.fees[years[4]] > x.fees[years[3]] ? "GreenColor" : "RedColor",
      sixthYearColor:
        x.fees[years[5]] > x.fees[years[4]] ? "GreenColor" : "RedColor",
    }));
  const headers = extractedData.map((item, index) =>
    index === extractedData.length - 1 ? item + " to date" : item
  );
  const formattedData = MapFormat(data?.tribeData, years);
  const rows = [
    "firstYear",
    "secondYear",
    "thirdYear",
    "forthYear",
    "fifthYear",
    "sixthYear",
  ];
  const rowColors = [
    "firstYearColor",
    "secondYearColor",
    "thirdYearColor",
    "forthYearColor",
    "fifthYearColor",
    "sixthYearColor",
  ];
  const columns = [
    { name: "Tribes", selector: "tribeName", wrap: true, grow: 3, sortable: true },
  ];

  headers.map((item, Idx) =>
    columns.push({
      name: item,
      selector: (row) => row[rows[Idx]],
      cell: (row) =>
        row[rows[Idx]] !== null &&
        row[rows[Idx]] !== "null" &&
        row[rows[Idx]] !== "" ? (
          <div className={row[rowColors[Idx]]}>
            {" "}
            {formatNumber(row[rows[Idx]], true, false, false, true)}
          </div>
        ) : (
          "-"
        ),
      wrap: true,
      grow: 2,
      right: true,
      sortable: true
    })
  );
  return (
    <Card className="FeesByTribe">
      <CardInnerWithTime
        loading={!data}
        title="Exclusivity Fees by Tribe"
        legend={
          <>
            <p style={{ color: "green" }}>
              Increase relative to previous fiscal year
            </p>
            <p style={{ color: "red" }}>
              Decrease relative to previous fiscal year
            </p>
          </>
        }
        refreshedTime={data?.timeStamp ?? ""}
        extraIcons={[
          {
            text: `Gaming Compliance oversees and monitors the collection of exclusivity fees from tribes to the state pursuant to the Tribal Gaming Compact.`,
            icon: faInfoCircle,
            style: {
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              textAlign: "justify",
            },
          },
        ]}
        mainComponent={() => (
          <div>
            <Datagrid
              columns={columns}
              expandable={false}
              data={formattedData}
              subHeader={false}
              defaultSortField="tribeName"
            />
          </div>
        )}
      />
    </Card>
  );
};

const FeesByTribe = ({ setIsTribeRender }) => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/tribe-fees.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} setIsTribeRender={setIsTribeRender} />;
};

export default FeesByTribe;
