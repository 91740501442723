import React, { useState } from "react";
import styled from "@emotion/styled";
import { H2 } from "../../Headings";
import Select from "../../Select";
import { TableStyle } from "../../BaseTable";
import Cell from "../../AppCTable/Cell";
import { formatNumber } from "../../../Utils/utils";
import CardTopItems from "../../CardTopItems";
import { faClock } from "@fortawesome/pro-duotone-svg-icons";

const TableWrap = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;

  thead tr {
    background-color: #005c95;
    color: #ffffff;
  }

  thead th {
    overflow-wrap: break-word;
    white-space: pre-wrap !important;
  }
`;

const AgencyPO = ({ data }) => {
  const [agency, setAgency] = useState("");
  const [agencyData, setAgencyData] = useState(null);
  const topIcons = [
    {
      text: `As of ${new Date(data?.timeStamp).toLocaleString()}`,
      icon: faClock,
    },
  ];
  return (
    <>
    <CardTopItems items={topIcons} />
      <H2>Inbound POs by Agency - Past 90 days</H2>
      <Select
        onChange={(e) => {
          const val = e.target.value;
          setAgency(val);
          setAgencyData(data?.agencyPO?.filter((x) => x.agency === val)[0]);
        }}
        defaultValue={agency}
        aria-label="Select Item to View"
      >
        <option>Select an agency</option>
        {data?.agencyPO
          ?.filter((item) => item.agency !== "")
          .map((x, i) => (
            <option key={i} value={x.agency}>
              {x.agency}
            </option>
          ))}
      </Select>

      {agency !== "" && (
        <TableWrap>
          <TableStyle color="blue" wrapped>
            <thead>
              <tr>
                <Cell as="th" alignment="left">
                  Agency Name
                </Cell>
                <Cell as="th" alignment="left">
                  Number of Inbound POs
                </Cell>
                <Cell as="th" alignment="left">
                  Order Quantity
                </Cell>
              </tr>
            </thead>
            <tbody>
              <tr>
                <Cell as="td" alignment="left">
                  {agencyData?.agency !== "" ? agencyData?.agency : ""}
                </Cell>
                <Cell as="td" alignment="right">
                  {agencyData?.totalOrders !== ""
                    ? formatNumber(agencyData?.totalOrders)
                    : ""}
                </Cell>
                <Cell as="td" alignment="right">
                  {agencyData?.quantity !== ""
                    ? formatNumber(agencyData?.quantity)
                    : ""}
                </Cell>
              </tr>
            </tbody>
          </TableStyle>
        </TableWrap>
      )}
    </>
  );
};

export default AgencyPO;
