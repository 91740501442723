import React from 'react';
import styled from '@emotion/styled';

import Loader from './Loader';
import { H1 } from './Headings';

const Center = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  height: 100%;

  > div {
    text-align: center;
  }
`;

const LoggingIn = () => (
  <Center>
    <div>
      <Loader size="3x" />
      <H1>Logging in please wait</H1>
    </div>
  </Center>
);

export default LoggingIn;
