import React from 'react';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { fetcher, buildTeamBurndownUrl, TimeIntervals } from '../Utils/utils';
import DevOpsCharts from '../Components/DevOpsCharts';
import Loader from '../Components/Loader';

const Container = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: 1fr;
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DevOpsTeam = () => {
  const query = useQuery();
  const { data } = useSWR(
    buildTeamBurndownUrl(query.get('team'), query.get('agency')),
    fetcher,
    {
      refreshInterval: TimeIntervals.THIRTY_MINUTES,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );
  if (!data) return <Loader />;

  return (
    <Container>
      <DevOpsCharts
        team={query.get('team')}
        data={data}
        agency={query.get('agency')}
      />
    </Container>
  );
};

export default DevOpsTeam;
