import React from 'react';
import PropTypes from 'prop-types';
import useSWR from 'swr';
import Loader from "./Loader";
import styled from "@emotion/styled";
import { fetcherWithAuth } from '../Utils/utils';
const Wrapper = styled.div`
  text-align: center;
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  justify-items: ${({ load }) => (load ? "center" : `inherit`)};
  --fa-primary-color: #353535;
--fa-secondary-color: #464646;
`;
const AuthorizedArea = ({
  innerComponent: InnerComponent,
  url,
  refreshInterval,
  loader = false
}) => {
  const { data, error } = useSWR(url, fetcherWithAuth, {
    refreshInterval,
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });
  if (loader && !data) { 
    return (
      <Wrapper load>
        <Loader />
      </Wrapper>
    );
  }
  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (error?.status === 401) {
    return <></>;
  }

  return <InnerComponent data={data} error={error} />;
};

AuthorizedArea.propTypes = {
  innerComponent: PropTypes.any,
  url: PropTypes.string,
  refreshInterval: PropTypes.number,
};

AuthorizedArea.defaultProps = {
  refreshInterval: 0,
};

export default AuthorizedArea;
