import React, { useState } from "react";
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalBarSeries,
  Crosshair,
} from "react-vis";
import "../../../styles/OfferedTraining.css";
import "../../../styles/Common.css";
import TooltipItem from "../../TooltipItem";
import { faClock } from "@fortawesome/pro-duotone-svg-icons";
import AutoSizer from "react-virtualized-auto-sizer";
import styled from "@emotion/styled";
import { H3 } from "../../Headings";
import CardTopItems from "../../CardTopItems";

const Bottom = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.width.xs};
  text-align: center;
  margin: ${({ theme }) =>
    `${theme.padding.sm} -${theme.margin.md} -${theme.margin.sm}`};
  padding: ${({ theme }) => `${theme.padding.sm} ${theme.padding.md}`};
  background-color: ${({ theme }) => theme.card.well.background};
  border-top: ${({ theme }) => `${theme.width.px} solid ${theme.card.border}`};
`;

const LegendWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const DataTab = ({ data, quarterData, quarter }) => {
  const [hint, setHint] = useState(false);
  const [crosshairValues, setCrosshairValues] = useState([]);

  let sls = quarterData?.filter((item) => item.ldArea === "SLS");
  let totalSLS = sls && sls[0]?.total.toFixed(1);
  let inPersonSLSTemp = sls && sls[0]?.inPerson;
  let virtualSLSTemp = sls && sls[0]?.virtual;

  let oedp = quarterData?.filter((item) => item.ldArea === "OEDP");
  let totalOEDP = oedp && oedp[0]?.total.toFixed(1);
  let inPersonOEDPTemp = oedp && oedp[0]?.inPerson;
  let virtualOEDPTemp = oedp && oedp[0]?.virtual;

  const maxValue = Math.max(
    inPersonSLSTemp + virtualSLSTemp,
    inPersonOEDPTemp + virtualOEDPTemp
  );
  let yDomainTopValue = [0, 1.3 * maxValue];

  let slsDataSet = [
    {
      x: "Statewide Learning Services",
      y: inPersonSLSTemp,
      color: "#0066a6",
    },
    {
      x: "Org. & Employee Development Partners",
      y: inPersonOEDPTemp,
      color: "#0066a6",
    },
  ];

  let oedpDataSet = [
    {
      x: "Statewide Learning Services",
      y: virtualSLSTemp,
      color: "#ff8c00",
    },
    {
      x: "Org. & Employee Development Partners",
      y: virtualOEDPTemp,
      color: "#ff8c00",
    },
  ];

  return (
    <>
      <CardTopItems
        items={[
          {
            text: data?.timeStamp
              ? `As of ${new Date(data?.timeStamp).toLocaleString()}`
              : "",
            icon: faClock,
          },
        ]}
      />
      <H3>{"Number of Trainings Offered by L&D Area"}</H3>
      <div>
        <AutoSizer disableHeight>
          {({ width }) => (
            <XYPlot
              xType="ordinal"
              colorType="literal"
              stackBy="y"
              width={width}
              height={200}
              yDomain={yDomainTopValue}
              onMouseLeave={() => {
                setCrosshairValues(false);
              }}
            >
              <HorizontalGridLines />
              <XAxis style={{ fontSize: "11px", wordBreak: "break-all" }} />
              <YAxis />
              <VerticalBarSeries
                data={slsDataSet}
                barWidth={0.5}
                onValueMouseOver={(datapoint) => {
                  //setAnimation(false);
                  setHint(datapoint);
                  setCrosshairValues([datapoint]);
                }}
                onValueMouseOut={() => {
                  setHint(false);
                  //setAnimation(true);
                  setCrosshairValues(false);
                }}
              />
              <VerticalBarSeries
                data={oedpDataSet}
                barWidth={0.5}
                onValueMouseOver={(datapoint) => {
                  //setAnimation(false);
                  setHint(datapoint);
                  setCrosshairValues([datapoint]);
                }}
                onValueMouseOut={() => {
                  setHint(false);
                  // setAnimation(true);
                  setCrosshairValues(false);
                }}
              />

              {crosshairValues && (
                <Crosshair values={crosshairValues}>
                  <div className="ShowHint">
                    <TooltipItem
                      fill="#0066a6"
                      text={`${hint.x}: ${
                        slsDataSet.filter((item) => item.x === hint.x)?.[0]?.y
                      }`}
                    />
                    <TooltipItem
                      fill="#ff8c00"
                      text={`${hint.x}: ${
                        oedpDataSet.filter((item) => item.x === hint.x)?.[0]?.y
                      }`}
                    />
                  </div>
                </Crosshair>
              )}
            </XYPlot>
          )}
        </AutoSizer>
      </div>
      <LegendWrap>
        <TooltipItem fill="#0066a6" text="In Person" />
        <TooltipItem fill="#ff8c00" text="Virtual" />
      </LegendWrap>

      <Bottom>
        {quarter && totalSLS && totalOEDP && (
          <div className="TotalHours">
            <div>
              {`Total Q${quarter} SLS Trainings`}
              <div className="SLSHours">{`${totalSLS} hours`}</div>
            </div>
            <div className="ODCSTotalHours">
              <div>{`Total Q${quarter} OEDP Trainings`} </div>
              <div className="ODCSHours">{`${totalOEDP} hours`}</div>
            </div>
          </div>
        )}
      </Bottom>
    </>
  );
};

export default DataTab;
