import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { faClock } from "@fortawesome/pro-duotone-svg-icons";

import CardTopItems from "./CardTopItems";
import Loader from "./Loader";
import { H3 } from "./Headings";
import "../styles/Common.css";

const Wrapper = styled.div`
  text-align: center;
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  justify-items: ${({ load }) => (load ? "center" : `inherit`)};
`;

const LegendWrap = styled.div`
  font-size: 10px;
  text-align: left;
  margin: -1rem 0px 0.5rem 0px;
`;

const AgencyNameWrap = styled.div`
  margin-bottom: 0.75rem;
  font-weight: 600;
`;

const CardInnerWithTime = ({
  customClass,
  title,
  subtitle,
  agencyName,
  legend = false,
  refreshedTime,
  adjustHeight = false,
  mainComponent: MainComponent,
  loading,
  extraIcons,
}) => {
  if (loading) {
    return (
      <Wrapper load>
        <Loader />
      </Wrapper>
    );
  }

  let icons = [
    refreshedTime && {
      text: `As of ${new Date(refreshedTime).toLocaleString()}`,
      icon: faClock,
    },
  ];
  if (extraIcons) {
    icons = [
      ...extraIcons,
      refreshedTime && {
        text: `As of ${new Date(refreshedTime).toLocaleString()}`,
        icon: faClock,
      },
    ];
  }

  return (
    <>
      <CardTopItems items={icons} />
      <Wrapper>
        <H3 as="h3" style={adjustHeight ? { minHeight: "5.175rem" } : {}}>
          {title}
        </H3>
        {agencyName && <AgencyNameWrap>{agencyName}</AgencyNameWrap>}
        {subtitle && <span>{subtitle}</span>}
        {legend && <LegendWrap>{legend}</LegendWrap>}
        <div className={adjustHeight ? "AdjustHeight" : customClass ? customClass : ''}>
          <MainComponent />
        </div>
      </Wrapper>
    </>
  );
};

CardInnerWithTime.propTypes = {
  title: PropTypes.string,
  refreshedTime: PropTypes.any,
  mainComponent: PropTypes.any,
  loading: PropTypes.bool,
  extraIcons: PropTypes.array,
};

export default CardInnerWithTime;
