import React from "react";
import styled from "@emotion/styled";
import NumberFormat from "react-number-format";
import useSWR from "swr";
import { faClock } from "@fortawesome/pro-duotone-svg-icons";
import Card from "../Card";
import { H3 } from "../Headings";
import ColumnSection from "../ColumnSection";
import { fetcher, TimeIntervals } from "../../Utils/utils";
import CardTopItems from "../CardTopItems";

const Wrapper = styled.div`
  display: grid;
  height: 100%;
  justify-items: ${({ load }) => (load ? "center" : `inherit`)};
`;

const InnerComponent = ({ data }) => {
  const topIcons = [
    {
      text: `As of ${new Date(data?.reported).toLocaleString()}`,
      icon: faClock,
    },
  ];

  return (
    <Card>
      <Wrapper>
        <CardTopItems items={topIcons} />
        <H3 centered>Scheduled Maintenance</H3>
        <div>
          <ColumnSection cols="1fr auto auto" customColumns>
            <strong>All Change Records - Rolling 30 Days:</strong>
            <span>{data?.changeAll}</span>
            <span />

            <strong>All Successful Change Records - Rolling 30 Days:</strong>
            <span>{data?.changeSuccessful}</span>
            <span>
              <NumberFormat
                value={data?.changeSuccessfulPerc * 100}
                displayType="text"
                suffix="%"
                decimalScale={2}
                fixedDecimalScale
                thousandSeparator
              />
            </span>

            <strong>All Emergency Change Records - Rolling 30 Days:</strong>
            <span>{data?.changeEmergency}</span>
            <span>
              <NumberFormat
                value={data?.changeEmergencyPerc * 100}
                displayType="text"
                suffix="%"
                decimalScale={2}
                fixedDecimalScale
                thousandSeparator
              />
            </span>
          </ColumnSection>
        </div>
      </Wrapper>
    </Card>
  );
};

const ScheduledMaintenance = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/ITOps_changes.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.TEN_MINUTES,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (data?.renderCard !== undefined && !data?.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} />;
};

export default ScheduledMaintenance;
