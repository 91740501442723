import React from "react";
import { H1, H2 } from "../../Headings";
import { formatNumber, removePercentageSymbol } from "../../../Utils/utils";
import "../../../styles/SocialMediaAccount.css";

const MediaData = ({
  heading,
  mediaName,
  mediaCount,
  mediaTrend,
  month,
  currentDays,
  currentMonth,
  currentYear,
}) => {
  return (
    <div>
      <H2>{heading}</H2>
      <H1 as="span">{formatNumber(mediaCount)}</H1>
      <div
        className={`PastMonths ${
          removePercentageSymbol(mediaTrend) === 0
            ? "Black"
            : removePercentageSymbol(mediaTrend) > 0
            ? "Green"
            : "Red"
        }`}
      >
        {`${
          removePercentageSymbol(mediaTrend) >= 0
            ? `+${mediaTrend}`
            : mediaTrend
        } in past 6 months`}
      </div>
      {currentDays ? (
        <div className="RecentMonth">{`${currentMonth} ${currentDays}, ${currentYear}`}</div>
      ) : (
        <div className="RecentMonth">{`${month}`}</div>
      )}
    </div>
  );
};

export default MediaData;
