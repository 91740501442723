import React from "react";
import useSWR from "swr";
import {
  TimeIntervals,
  downloadExcelFile,
  fetcher,
} from "../../../../Utils/utils";
import {
  Tabs,
  TabList,
  TabPanelsEnhanced,
  TabPanel,
  TabEnhanced,
} from "../../../Tabs";
import SoleSourcePO from "./SoleSourcePO";
import SoleSourcePOSpend from "./SoleSourcePOSpend";
import "../../../../styles/SoleSource.css";
import Button from "../../../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-duotone-svg-icons";

const InnerComponent = ({ data, agency }) => {
  return (
    <div className="SoleSource">
      <Tabs>
        <TabList>
          <TabEnhanced>{`Sole Source POs`}</TabEnhanced>
          <TabEnhanced>{`Sole Source PO Spend`}</TabEnhanced>
        </TabList>
        <Button
          className="button-align--right"
          as="a"
          color="blue"
          size="small"
          onClick={() => {
            downloadExcelFile(
              `${process.env.REACT_APP_PUBLIC_STORE}/sole-source.xlsx`,
              "sole-source.xlsx"
            );
          }}
        >
          EXPORT DATA{" "}
          <FontAwesomeIcon icon={faDownload} style={{ paddingLeft: "2px" }} />
        </Button>{" "}
        <TabPanelsEnhanced>
          <TabPanel>
            <SoleSourcePO data={data} agency={agency} />
          </TabPanel>
          <TabPanel>
            <SoleSourcePOSpend data={data} agency={agency} />
          </TabPanel>
        </TabPanelsEnhanced>
      </Tabs>
    </div>
  );
};

const SoleSource = ({ agency }) => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/sole-source.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} agency={agency} />;
};

export default SoleSource;
