import React, { useEffect, useState } from "react";
import useSWR from "swr";
import styled from "@emotion/styled";
import Card from "../../Card";
import CardInnerWithTime from "../../CardInnerWithTime";
import { TimeIntervals, dateFormat, fetcherWithAuth, formatNumber, buildApiInternalUrl } from "../../../Utils/utils";
import { H1, H2, H3 } from "../../Headings";
import { TableStyle } from "../../BaseTable";
import Cell from "../../BalanceSheet/Cell";
import "../../../styles/AccountsPayable.css";
import "../../../styles/Common.css";
import ColumnSection from "../../ColumnSection";

const InnerComponent = ({ data }) => {
  const [restrictedSum, setRestrictedSum] = useState(0);
  const [unrestrictedSum, setUnrestrictedSum] = useState(0);
  const [grandTotalSum, setGrandTotalSum] = useState(0);
  const [vendorSum, setVendorSum] = useState(0);

  let asOfDate = dateFormat(data?.outstanding?.asOfDate);
  let fromDate = dateFormat(data?.paidInvoices?.fromDate);
  let toDate = dateFormat(data?.paidInvoices?.toDate);

  const TableWrap = styled.div`
    margin-bottom: 2rem;
    thead tr,
    tbody tr:nth-last-child(1) {
      background-color: #005c95;
      color: #ffffff;
    }
  `;

  const VendorTableWrap = styled.div`
    thead tr,
    tbody tr:nth-last-child(1),
    tbody tr:nth-last-child(2) {
      background-color: #005c95;
      color: #ffffff;
    }
  `;

  useEffect(() => {
    setRestrictedSum(
      data?.aging
        .map((item) => item.restricted)
        .reduce((acc, item) => acc + item, 0)
    );
    setUnrestrictedSum(
      data?.aging
        .map((item) => item.unRestricted)
        .reduce((acc, item) => acc + item, 0)
    );
    setGrandTotalSum(
      data?.aging
        .map((item) => item.grandTotal)
        .reduce((acc, item) => acc + item, 0)
    );
    setVendorSum(
      data?.topVendors
        .map((item) => item.amount)
        .reduce((acc, item) => acc + item, 0)
    );
  }, [data]);

  return (
    <>
      <Card className="AccountsPayable">
        <CardInnerWithTime
          loading={!data}
          title="Accounts Payable"
          subtitle={`As of ${asOfDate}`}
          refreshedTime={data?.timeStamp ?? ""}
          mainComponent={() => (
            <>
              <div className="AccountsPayableHeader">
                <div className="HeadCounts">
                  <H2 as="h3">Outstanding AP</H2>
                  <H1 as="span">
                    {formatNumber(
                      data?.outstanding?.amount.toFixed(2),
                      true,
                      false,
                      false,
                      true
                    )}
                  </H1>
                </div>
                <div className="Divider"></div>
                {data?.keyNotes?.outstanding?.length > 0 && (
                  <div className="KeyNotes">
                    <H3>Key Notes:</H3>
                    <ul>
                      {data?.keyNotes?.outstanding?.map((item) => {
                        return <li>{item}</li>;
                      })}
                    </ul>
                  </div>
                )}
              </div>

              <TableWrap>
                <TableStyle color="blue" wrapped>
                  <thead>
                    <tr>
                      <Cell as="th" alignment="left">
                        Aging
                      </Cell>
                      <Cell as="th" alignment="left">
                        Restricted
                      </Cell>
                      <Cell as="th" alignment="left">
                        Unrestricted
                      </Cell>
                      <Cell as="th" alignment="left">
                        Grand Total
                      </Cell>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.aging.map((item) => {
                      let { agingDays, restricted, unRestricted, grandTotal } =
                        item;
                      return (
                        <tr>
                          <Cell as="td" alignment="right">
                            {agingDays}
                          </Cell>
                          <Cell as="td" alignment="right">
                            {formatNumber(
                              restricted.toFixed(2),
                              true,
                              false,
                              false,
                              true
                            )}
                          </Cell>
                          <Cell as="td" alignment="right">
                            {formatNumber(
                              unRestricted.toFixed(2),
                              true,
                              false,
                              false,
                              true
                            )}
                          </Cell>
                          <Cell as="td" alignment="right">
                            {formatNumber(
                              grandTotal.toFixed(2),
                              true,
                              false,
                              false,
                              true
                            )}
                          </Cell>
                        </tr>
                      );
                    })}
                    <tr>
                      <Cell as="th" alignment="right">
                        Total
                      </Cell>
                      <Cell as="th" alignment="right">
                        {formatNumber(
                          restrictedSum.toFixed(2),
                          true,
                          false,
                          false,
                          true
                        )}
                      </Cell>
                      <Cell as="th" alignment="right">
                        {formatNumber(
                          unrestrictedSum.toFixed(2),
                          true,
                          false,
                          false,
                          true
                        )}
                      </Cell>
                      <Cell as="th" alignment="right">
                        {formatNumber(
                          grandTotalSum.toFixed(2),
                          true,
                          false,
                          false,
                          true
                        )}
                      </Cell>
                    </tr>
                  </tbody>
                </TableStyle>
              </TableWrap>

              <ColumnSection cols={2}>
                <div>
                  <H2 as="h3">Paid Invoices</H2>
                  <H1 as="span">
                    {formatNumber(
                      data?.paidInvoices.amount.toFixed(2),
                      true,
                      false,
                      false,
                      true
                    )}
                  </H1>
                  <p>{`(${fromDate} - ${toDate})`}</p>
                  <hr className="HorizontalDivider" />
                  {data?.keyNotes?.invoices?.length > 0 && (
                    <div className="KeyNotes">
                      <H3>Key Notes:</H3>
                      <ul>
                        {data?.keyNotes?.invoices?.map((item) => {
                          return <li>{item}</li>;
                        })}
                      </ul>
                    </div>
                  )}
                </div>
                <div>
                  <VendorTableWrap>
                    <TableStyle color="blue" wrapped>
                      <thead>
                        <tr>
                          <Cell as="th" alignment="left">
                            Top 10 Vendors
                          </Cell>
                          <Cell as="th" alignment="left">
                            Expenditure
                          </Cell>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.topVendors.map((item) => {
                          let { vendorName, amount } = item;
                          return (
                            <tr>
                              <Cell as="td" alignment="left">
                                {vendorName}
                              </Cell>
                              <Cell as="td" alignment="right">
                                {formatNumber(
                                  amount.toFixed(2),
                                  true,
                                  false,
                                  false,
                                  true
                                )}
                              </Cell>
                            </tr>
                          );
                        })}
                        <tr>
                          <Cell as="th" alignment="right">
                            Total
                          </Cell>
                          <Cell as="th" alignment="right">
                            {formatNumber(
                              vendorSum.toFixed(2), // change this
                              true,
                              false,
                              false,
                              true
                            )}
                          </Cell>
                        </tr>

                        <tr>
                          <Cell as="th" alignment="right">
                            Percentage of Grand Total
                          </Cell>
                          <Cell as="th" alignment="right">
                            {`${formatNumber(
                              (
                                (vendorSum / data?.paidInvoices?.amount) *
                                100
                              ).toFixed(2)
                            )}%`}
                          </Cell>
                        </tr>
                      </tbody>
                    </TableStyle>
                  </VendorTableWrap>
                </div>
              </ColumnSection>
            </>
          )}
        />
      </Card>
    </>
  );
};

const AccountsPayable = () => {
  const { data, error } = useSWR(buildApiInternalUrl('ap-reporting'), fetcherWithAuth, {
    refreshInterval: TimeIntervals.ONE_DAY,
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (error?.status === 401) {
    return <></>;
  }
  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} error={error} />;
};

export default AccountsPayable;
