import React from "react";
import styled from "@emotion/styled";
import Cell from "../../AppCTable/Cell";
import { TableStyle } from "../../BaseTable";
import "@reach/tooltip/styles.css";
import Card from "../../Card";
import CardInnerWithTime from "../../CardInnerWithTime";

const TableWrap = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;
`;
const TableTitle = styled.tr`
  background-color: #005c95;
  color: #ffffff;
`;

const InternalDivisions = ({ data }) => {
  let unordered = data?.monthlyTasksClosedByDivision ?? "";
  const sortedObject = Object.keys(data?.monthlyTasksClosedByDivision)
    .sort()
    .reduce((obj, key) => {
      obj[key] = unordered[key];
      return obj;
    }, {});
  return (
    <Card>
      <CardInnerWithTime
        loading={!data}
        title="Monthly Tasks Closed by Division"
        refreshedTime={data?.runTime ?? ""}
        mainComponent={() =>
          sortedObject && (
            <TableWrap>
              <TableStyle color="blue" wrapped>
                <thead>
                  <TableTitle>
                    <Cell as="th" alignment="centre">
                      OMES Division
                    </Cell>
                    <Cell as="th" alignment="centre">
                      Count of Closed
                    </Cell>
                  </TableTitle>
                </thead>
                <tbody>
                  {Object.keys(sortedObject).map((key, i) => (
                    <tr>
                      <Cell as="td" alignment="left" key={i}>
                        {key}
                      </Cell>
                      <Cell as="td" alignment="right">
                        {sortedObject[key]}
                      </Cell>
                    </tr>
                  ))}
                </tbody>
              </TableStyle>
            </TableWrap>
          )
        }
      />
    </Card>
  );
};

export default InternalDivisions;
