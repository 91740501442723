import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Wrap = styled.div`
  font-size: ${({ theme }) => theme.text.size.sm};
`;

const Legend = forwardRef(({ children }, ref) => (
  <Wrap ref={ref} data-legend="">
    {children}
  </Wrap>
));

Legend.displayName = 'Legend';

Legend.propTypes = {
  children: PropTypes.any,
};

Legend.defaultProps = {};

export default Legend;
