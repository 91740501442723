import React from "react";
import useSWR from "swr";
import Card from "../../Card";
import styled from "@emotion/styled";
import CardInnerWithTime from "../../CardInnerWithTime";
import { H1, H2 } from "../../Headings";
import { TimeIntervals, fetcher, formatNumber } from "../../../Utils/utils";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import Datagrid from "../../Datagrid";
import "../../../styles/Common.css";
import "../../../styles/CAPProjects.css";
import CAPPieCharts from "./CAPPieCharts";

const Heading = styled.div`
  font-weight: bold;
`;

const InnerComponent = ({ data }) => {
  const { c1, c2, c3, c4, c5, c6 } = data.report.data[0].report_header;

  const project_number = c1.name;
  const project_name = c2.name;
  const project_type = c3.name;
  const project_status = c4.name;
  const city = c5.name;
  const project_manager = c6.name;

  let parsedData = [
    ...new Map(
      data.report.data[0].report_row
        .sort((a, b) => (a.c1 > b.c1 ? 1 : -1))
        .map((item) => [item["c1"], item])
    ).values(),
  ];

  let total_active_projects = Array.from(
    new Set(
      data.report.data[0].report_row
        .filter(
          (item) =>
            item.c4 === "Execution Phase" ||
            item.c4 === "Monitor & Control Phase" ||
            item.c4 === "Close-Out Phase"
        )
        .map((item) => item.c1)
    )
  ).length;

  let total_active_contract_amount = data.report.data[0].report_row
    .filter(
      (item) =>
        item.c4 === "Execution Phase" ||
        item.c4 === "Monitor & Control Phase" ||
        item.c4 === "Close-Out Phase"
    )
    .reduce((acc, item) => acc + Number(item.c7.replace(/,/g, "")), 0);

  const columns = [
    {
      name: <Heading>{project_number}</Heading>,
      selector: (row) => (row.c1 !== "" ? row.c1 : "-"),
      sortable: true,
      grow: 3,
      wrap: true,
      style: { textAlign: "left !important" },
    },
    {
      name: <Heading>{project_name}</Heading>,
      selector: (row) => (row.c2 !== "" ? row.c2 : "-"),
      sortable: true,
      grow: 7,
      wrap: true,
      style: { textAlign: "left !important" },
    },
    {
      name: <Heading>{project_type}</Heading>,
      selector: (row) => (row.c3 !== "" ? row.c3 : "-"),
      sortable: true,
      grow: 3,
      wrap: true,
      style: { textAlign: "left !important" },
    },
    {
      name: <Heading>{project_status}</Heading>,
      selector: (row) => (row.c4 !== "" ? row.c4 : "-"),
      sortable: true,
      grow: 4,
      wrap: true,
      style: { textAlign: "left !important" },
    },
    {
      name: <Heading>{city}</Heading>,
      selector: (row) => (row.c5 !== "" ? row.c5 : "-"),
      sortable: true,
      grow: 3,
      wrap: true,
      style: { textAlign: "left !important" },
    },
    {
      name: <Heading>{project_manager}</Heading>,
      selector: (row) => (row.c6 !== "" ? row.c6 : "-"),
      sortable: true,
      grow: 3,
      wrap: true,
      style: { textAlign: "left !important" },
    },
    {
      name: <Heading>Estimated Contract Amount ($)</Heading>,
      selector: (row) => (row.c7 !== "" ? `$${row.c7}` : "-"),
      sortable: false,
      grow: 3,
      wrap: true,
      right: true,
      style: { textAlign: "left !important" },
    },
  ];

  return (
    <>
      <span className="Heading">Construction and Properties</span>
      <Card className="ConstructionTab">
        <CardInnerWithTime
          loading={!data}
          title="CAP Active Projects"
          refreshedTime={data?.timeStamp ?? ""}
          extraIcons={[
            {
              text: `A zero value in the Total Contract Amount column
denotes contract information not yet entered.

Where project involves multiple locations, no city is 
listed.
`,
              icon: faInfoCircle,
              style: {
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
                textAlign: "justify",
              },
            },
          ]}
          mainComponent={() => (
            <>
              <div className="TopHeader">
                <div className="HeadCounts">
                  <H2 as="h3">Total Active Projects</H2>
                  <H1 as="span">
                    {formatNumber(total_active_projects.toFixed(2))}
                  </H1>
                </div>
                <div className="Divider"></div>
                <div className="HeadCounts">
                  <H2 as="h3">Total Estimated Contract Amount</H2>
                  <H1 as="span">
                    ${formatNumber(total_active_contract_amount.toFixed(2))}
                  </H1>
                </div>
              </div>
              <Datagrid
                columns={columns}
                data={parsedData}
                expandable={false}
                defaultSortField=""
                searchByKeys={["c1"]}
                filterPlaceHolder={"Filter by project number or name"}
              />

              <CAPPieCharts data={data} />
            </>
          )}
        />
      </Card>
    </>
  );
};

const CAPProjects = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/PIG - CAP All Active Projects - V4.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.TWELVE_HOURS,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (error?.status === 401) {
    return <></>;
  }
  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} error={error} />;
};

export default CAPProjects;
