import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { useRoleContext } from "../Context/RoleContext";

export const can = (which, role, reqPath) => {
  //Handle here for allowing pages without login
  //if (!role && (reqPath = '/about' || reqPath == '/rate-packet')) {
  //    return true;
  //}

  switch (which) {
    case "admin":
      return ["admin"].includes(role);
    case "director":
      return ["director", "admin"].includes(role);
    case "internal":
      return ["internal", "director", "admin"].includes(role);
    case "pigleader":
      return ["pigleader", "director", "admin"].includes(role);
    default:
      return false;
  }
};

// eslint-disable-next-line react/display-name
const In = forwardRef(({ yes: Yes, no: No, which, reqPath }, ref) => {
  const { role } = useRoleContext();
  return can(which, role, reqPath) ? <Yes ref={ref} /> : <No ref={ref} />;
});

In.propTypes = {
  yes: PropTypes.func,
  no: PropTypes.func,
  which: PropTypes.string,
};

In.defaultProps = {
  yes: () => null,
  no: () => null,
  which: "",
};

export default In;
