import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { H2 } from '../../Headings';
import {
  TimeIntervals,
  groupBy,
  sum,
  formatNumber,
  DateShortString,
  buildApiDirectorUrl,
} from '../../../Utils/utils';
import Loader from '../../Loader';
import Select from '../../Select';
import HorizontalBarChart from '../../BaseCharts/HorizontalBarChart';
import RadialChart from '../../BaseCharts/RadialChart';
import Button from '../../Button';
import SimpleTable from '../../SimpleTable';
import AuthorizedCard from '../../AuthorizedCard';

const View = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.margin.sm};
  grid-template-columns: 1fr;
`;

const Inner = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.margin.sm};
  grid-template-columns: 1fr;

  ${({ theme }) => theme.breakpoints.md} {
    grid-template-columns: 1fr 1fr;
  }
`;

const BGroup = styled.div`
  text-align: right;
`;

const InnerComponent = ({ data, error }) => {
  const [project, setProject] = useState(-1);
  const [viewDetails, setViewDetails] = useState(false);
  const totalCost = sum(data?.[project]?.expenses || [], 'cost');

  const groupedExpenses = groupBy(data?.[project]?.expenses, 'category');
  return (
    <>
      <H2 as="h3">REALS {!data && <Loader size="1x" />}</H2>
      {error && <>There was an error getting the data.</>}

      {data && (
        <>
          <Select
            onChange={(e) => setProject(parseInt(e.target.value))}
            defaultValue={-1}
            aria-label="Select Item to View"
          >
            <option value="-1">Select an agency</option>
            {data?.map((x, i) => (
              <option key={i} value={i}>
                {x.settings.title}
              </option>
            ))}
          </Select>
          {project !== -1 && (
            <View>
              {!viewDetails && (
                <Inner>
                  <div>
                    <HorizontalBarChart
                      data={[
                        {
                          set: 'data',
                          setData: data[project].dates?.map((x, i) => ({
                            ...x,
                            x: x.daysRemaining,
                            y: i,
                          })),
                        },
                      ]}
                      showX
                      showY
                      hintFormat={(h) => <>{h.daysRemaining}</>}
                      yTicks={data?.[project]?.dates?.length}
                      yAxisFormat={(d) =>
                        data?.[project]?.dates?.[d].description
                      }
                      leftPadding={75}
                      labelX="Days Remaining"
                    />
                  </div>
                  <div>
                    <RadialChart
                      data={groupedExpenses?.map((x) => {
                        const t = sum(x.items, 'cost');
                        return {
                          value: t / totalCost,
                          label: x.key,
                          total: t,
                        };
                      })}
                      hintFormat={(h) => (
                        <>
                          {h.label} <br />
                          {formatNumber(h.total, true)}
                        </>
                      )}
                      padding={0}
                      legendLabels={groupedExpenses.map((x) => x.key)}
                    />
                  </div>
                </Inner>
              )}
              {viewDetails && (
                <SimpleTable
                  columns={[
                    {
                      title: 'Date',
                      component: (d) => DateShortString(d.date),
                    },
                    { title: 'Category', selector: 'category' },
                    { title: 'Description', selector: 'description' },
                    {
                      title: 'Cost',
                      component: (d) => formatNumber(d.cost, true),
                      isNumber: true,
                      align: 'right',
                    },
                  ]}
                  data={data?.[project]?.expenses}
                  maxHeight="20vh"
                />
              )}
              <BGroup>
                <Button
                  color="blue"
                  size="small"
                  onClick={() => setViewDetails(!viewDetails)}
                >
                  {viewDetails ? 'View Charts' : 'View Details'}
                </Button>
              </BGroup>
            </View>
          )}
        </>
      )}
    </>
  );
};

InnerComponent.propTypes = {
  data: PropTypes.any,
  error: PropTypes.any,
};

const Reality = () => (
  <AuthorizedCard
    url={buildApiDirectorUrl('RealsProjectData')}
    refreshInterval={TimeIntervals.ONE_DAY}
    innerComponent={({ data, error }) => (
      <InnerComponent data={data} error={error} />
    )}
  />
);

export default Reality;
