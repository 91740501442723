import React, { useState } from "react";
import AuthorizedArea from "../../AuthorizedArea";
import AutoSizer from "react-virtualized-auto-sizer";
import styled from "@emotion/styled";
import {
  TimeIntervals,
  buildApiInternalUrl,
  removePercentageSymbol,
} from "../../../Utils/utils";
import Card from "../../Card";
import { Hint, RadialChart } from "react-vis";
import TooltipItem from "../../TooltipItem";
import Legend from "../../BaseCharts/Legend";
import "../../../styles/Budget.css";
import { faClock } from "@fortawesome/pro-duotone-svg-icons";
import CardTopItems from "../../CardTopItems";
import { H3 } from "../../Headings";

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const PieChart = ({ data }) => {
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);

  const agencyData = [
    {
      angle: removePercentageSymbol(data.bwpReceived.percent),
      value: data.bwpReceived.count,
      color: "#669b41",
      text: "BWP Packages Received",
    },
    {
      angle: removePercentageSymbol(data.managementReview.percent),
      value: data.managementReview.count,
      color: "#1ca6df",
      text: "Ready for Management Review",
    },
    {
      angle: removePercentageSymbol(data.approvedByManagement.percent),
      value: data.approvedByManagement.count,
      color: "#d15420",
      text: "Approved by Management",
    },
    {
      angle: removePercentageSymbol(data.budgetPosted.percent),
      value: data.budgetPosted.count,
      color: "#de9027",
      text: "Budget Posted",
    },
  ];

  return (
    <Card className="Budget">
      <CardTopItems
        items={[
          {
            text: `As of ${new Date(data?.timeStamp).toLocaleString()}`,
            icon: faClock,
          },
        ]}
      />
      <H3>{`${data?.year} Agency Budget Approval Status`}</H3>
      <Wrap>
        <div>
          <AutoSizer disableHeight>
            {({ width }) => (
              <RadialChart
                colorType="literal"
                innerRadius={0}
                radius={100}
                data={agencyData}
                animation={animation}
                onValueMouseOver={(datapoint) => {
                  setAnimation(false);
                  setHint(datapoint);
                }}
                onValueMouseOut={() => {
                  setHint(false);
                  setAnimation(true);
                }}
                width={width}
                height={200}
              >
                {hint && (
                  <Hint value={hint}>
                    <div
                      className="rv-hint__content"
                      style={{ pointerEvents: "none" }}
                    >
                      <TooltipItem
                        fill={hint.color}
                        text={`${hint.text}: ${hint.value}`}
                      />
                    </div>
                  </Hint>
                )}
              </RadialChart>
            )}
          </AutoSizer>
        </div>
        <Legend>
          {agencyData.map((x, i) => (
            <TooltipItem
              key={i}
              fill={x.color}
              text={`${x.text}: ${x.value}`}
            />
          ))}
        </Legend>
      </Wrap>
    </Card>
  );
};

const AgencyBudget = () => (
  <AuthorizedArea
    url={buildApiInternalUrl("agency-approval-status")}
    refreshInterval={TimeIntervals.ONE_DAY}
    innerComponent={({ data, error }) => {
      if (!data) {
        return <>No data was found</>;
      }

      return <>{data?.renderCard && <PieChart data={data} />}</>;
    }}
  />
);

export default AgencyBudget;
