import React from "react";
import ActiveLitigations from "./ActiveLitigations";
import TotalProtests from "./TotalProtests";

const GeneralCounsel = () => {
  return (
    <>
      <ActiveLitigations />
      <TotalProtests />
    </>
  );
};

export default GeneralCounsel;
