import React from 'react';
import PropTypes from 'prop-types';

const TooltipItem = ({ text, fill = null, noBreak = false }) => (
  <span>
    {fill && (
      <svg
        width="12"
        height="12"
        className="tooltip-color"
        style={{
          marginRight: '5px',
          marginTop: '2px',
          verticalAlign: 'middle',
        }}
      >
        <rect fill={fill} width="12" height="12" />
      </svg>
    )}
    <span style={{ verticalAlign: 'middle' }}>{text}</span>
    {!noBreak && <br />}
  </span>
);

TooltipItem.propTypes = {
  text: PropTypes.string,
  fill: PropTypes.string,
  noBreak: PropTypes.bool,
};

export default TooltipItem;
