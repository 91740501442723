import React, { useState } from "react";
import styled from "@emotion/styled";
import AutoSizer from "react-virtualized-auto-sizer";
import Card from "../../Card";
import CardInnerWithTime from "../../CardInnerWithTime";
import AuthorizedArea from "../../AuthorizedArea";
import { TimeIntervals, buildApiInternalUrl } from "../../../Utils/utils";
import TooltipItem from "../../TooltipItem";
import Legend from "../../BaseCharts/Legend";
import { Hint, RadialChart } from "react-vis";
import "../../../styles/ProvisioningResolutionTime.css";

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const getColor = (label) => {
  switch (label) {
    case "0-4 hrs":
      return "#669b41";
    case "5-8 hrs":
      return "#1ca6df";
    case "9-12 hrs":
      return "#de9027";
    case "13-24 hrs":
      return "#115078";
    case "25-48 hrs":
      return "#d15420";
    default:
      return "purple";
  }
};

const InnerComponent = ({ data }) => {
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);

  const totalCount = data?.actualResolution
    ?.filter((item) => item.label !== "")
    .reduce((acc, item) => acc + item.count, 0);

  const actualResData = data?.actualResolution
    ?.filter((item) => item.label !== "")
    .map((x) => ({
      angle: x.count,
      value: x.count,
      text: x.label,
      color: getColor(x.label),
    }));

  return (
    <Card className="ActualResTime">
      <CardInnerWithTime
        loading={!data}
        title={`Provisioning | Actual Resolution Time`}
        subtitle={`Data represents the last 30 days.`}
        refreshedTime={data?.timeStamp ?? ""}
        mainComponent={() => (
          <div className="ActualChart">
            <Wrap>
              <div>
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <RadialChart
                      colorType="literal"
                      innerRadius={0}
                      radius={100}
                      data={actualResData}
                      animation={animation}
                      onValueMouseOver={(datapoint) => {
                        setAnimation(false);
                        setHint(datapoint);
                      }}
                      onValueMouseOut={() => {
                        setHint(false);
                        setAnimation(true);
                      }}
                      width={width}
                      height={200}
                    >
                      {hint && (
                        <Hint value={hint}>
                          <div
                            className="rv-hint__content"
                            style={{ pointerEvents: "none" }}
                          >
                            <TooltipItem
                              fill={hint.color}
                              text={`${hint.text}: ${(
                                (hint.value / totalCount) *
                                100
                              ).toFixed(2)}%`}
                            />
                          </div>
                        </Hint>
                      )}
                    </RadialChart>
                  )}
                </AutoSizer>
              </div>
              <Legend>
                <TooltipItem fill="#669b41" text="0-4 hrs" />
                <TooltipItem fill="#1ca6df" text="5-8 hrs" />
                <TooltipItem fill="#de9027" text="9-12 hrs" />
                <TooltipItem fill="#115078" text="13-24 hrs" />
                <TooltipItem fill="#d15420" text="25-48 hrs" />
                <TooltipItem fill="purple" text="49+ hrs" />
              </Legend>
            </Wrap>
          </div>
        )}
      />
    </Card>
  );
};

const ActualResTime = () => (
  <AuthorizedArea
    url={buildApiInternalUrl("is-resolution-time-actual")}
    refreshInterval={TimeIntervals.ONE_DAY}
    innerComponent={({ data, error }) => {
      if (!data && !error && !error?.status) {
        return <></>;
      }
      if (data?.renderCard !== undefined && !data.renderCard) {
        return <></>;
      }
      return <InnerComponent data={data} />;
    }}
  />
);

export default ActualResTime;
