import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { faClock } from '@fortawesome/pro-duotone-svg-icons';

import CardTopItems from './CardTopItems';
import Loader from './Loader';
import { H2 } from './Headings';

const Wrapper = styled.div`
  text-align: center;
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  justify-items: ${({ load }) => (load ? 'center' : `inherit`)};
`;

const Bottom = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.width.xs};
  text-align: center;
  margin: ${({ theme }) =>
    `${theme.padding.sm} -${theme.margin.md} -${theme.margin.sm}`};
  padding: ${({ theme }) => `${theme.padding.sm} ${theme.padding.md}`};
  background-color: ${({ theme }) => theme.card.well.background};
  border-top: ${({ theme }) => `${theme.width.px} solid ${theme.card.border}`};

  ${({ theme }) => theme.breakpoints.md} {
    grid-template-columns: 1fr 1px 1fr;
  }
`;

const Divider = styled.span`
  background-color: ${({ theme }) => theme.card.border};
  height: 1px;
  ${({ theme }) => theme.breakpoints.md} {
    height: 100%;
  }
`;

const CardInnerWithTimeAndFooter = ({
  title,
  refreshedTime,
  mainComponent: MainComponent,
  footerLeftComponent: FooterLeftComponent,
  footerRightComponent: FooterRightComponent,
  loading,
  extraIcons,
}) => {
  if (loading) {
    return (
      <Wrapper load>
        <Loader />
      </Wrapper>
    );
  }

  let icons = [
    refreshedTime && {
      text: `As of ${new Date(refreshedTime).toLocaleString()}`,
      icon: faClock,
    },
  ];
  if (extraIcons) {
    icons = [
      ...extraIcons,
      refreshedTime && {
        text: `As of ${new Date(refreshedTime).toLocaleString()}`,
        icon: faClock,
      },
    ];
  }

  return (
    <>
      <CardTopItems items={icons} />
      <Wrapper>
        <H2 as="h3">{title}</H2>
        <div>
          <MainComponent />
        </div>
        <Bottom>
          <span>
            <FooterLeftComponent />
          </span>
          <Divider />
          <span>
            <FooterRightComponent />
          </span>
        </Bottom>
      </Wrapper>
    </>
  );
};

CardInnerWithTimeAndFooter.propTypes = {
  title: PropTypes.string,
  refreshedTime: PropTypes.any,
  mainComponent: PropTypes.any,
  footerLeftComponent: PropTypes.any,
  footerRightComponent: PropTypes.any,
  loading: PropTypes.bool,
  extraIcons: PropTypes.array,
};

export default CardInnerWithTimeAndFooter;
