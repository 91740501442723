import React from "react";
import ColumnSection from "../../ColumnSection";
import ActualResTime from "./ActualResTime";
import MonthlyResTime from "./MonthlyResTime";
import "../../../styles/ProvisioningResolutionTime.css";

const ProvisioningResolutionTime = () => {
  return (
    <div className="ProvisioningResolutionTime">
      <ColumnSection cols={2}>
        <ActualResTime />
        <MonthlyResTime />
      </ColumnSection>
    </div>
  );
};

export default ProvisioningResolutionTime;
