import React from "react";
import SectionHeading from "../Components/SectionHeading";
import EProTimeline from "../Pages/EProTimeLine";
import AverageCPSolicitationTime from "../Components/CentralPurchasing/AverageCPSolicitationTime";
import CPSpotlightDashBoard from "../Components/CentralPurchasing/CPSpotlightDashBoard";
import { AuthenticationState } from "react-aad-msal";
import { CheckEmployeeViewRole } from "../Utils/Roles";
import CentralPurchasingTabs from "../Components/CentralPurchasing/CentralPurchasingTabs";
import ISSolicitationsProcurement from "../Components/Cards/ISSolicitationsProcurement";

const InnerComponent = ({ authenticationState, role }) => {
  return (
    <>
      <section style={{ marginBottom: "1rem" }}>
        <i>
          For a comprehensive list of all IT Solicitations by status of closed,
          awarded or cancelled, visit the{" "}
          <a
            href="https://oklahoma.gov/omes/divisions/central-purchasing/solicitations/solicitation-search/previous-solicitations.html"
            target="_blank"
          >
            IT Solicitations
          </a>{" "}
          webpage for more information.
        </i>
      </section>
      {authenticationState === AuthenticationState.Authenticated &&
        CheckEmployeeViewRole(role) && <CPSpotlightDashBoard />}
      <SectionHeading title="Procurement" description="" />
      <AverageCPSolicitationTime />
      <CentralPurchasingTabs role={role} />
      <EProTimeline />
      <ISSolicitationsProcurement />
    </>
  );
};

const CentralPurchase = ({ authenticationState, role }) => {
  return (
    <InnerComponent authenticationState={authenticationState} role={role} />
  );
};

export default CentralPurchase;
