import React from "react";
import {
  Tabs,
  TabList,
  TabPanelsEnhanced,
  TabPanel,
  TabEnhanced,
} from "../../../Components/Tabs";
import SocialMediaAccount from "./SocialMediaAccount";

const DataTrends = ({ data }) => {
  let xData = data?.socialMedia?.filter((item) => item.name === "Twitter")[0];
  let facebookData = data?.socialMedia?.filter(
    (item) => item.name === "Facebook"
  )[0];
  let instagramData = data?.socialMedia?.filter(
    (item) => item.name === "Instagram"
  )[0];
  let linkedInData = data?.socialMedia?.filter(
    (item) => item.name === "LinkedIn"
  )[0];
  let youtubeData = data?.socialMedia?.filter(
    (item) => item.name === "YouTube"
  )[0];

  return (
    <>
      <Tabs>
        <TabList>
          <TabEnhanced>{`X`}</TabEnhanced>
          <TabEnhanced>{`Facebook`}</TabEnhanced>
          <TabEnhanced>{`Instagram`}</TabEnhanced>
          <TabEnhanced>{`LinkedIn`}</TabEnhanced>
          <TabEnhanced>{`YouTube`}</TabEnhanced>
        </TabList>
        <TabPanelsEnhanced>
          <TabPanel>
            <SocialMediaAccount data={data} mediaData={xData} mediaName="X" />
          </TabPanel>
          <TabPanel>
            <SocialMediaAccount
              data={data}
              mediaData={facebookData}
              mediaName="Facebook"
            />
          </TabPanel>
          <TabPanel>
            <SocialMediaAccount
              data={data}
              mediaData={instagramData}
              mediaName="Instagram"
            />
          </TabPanel>
          <TabPanel>
            <SocialMediaAccount
              data={data}
              mediaData={linkedInData}
              mediaName="LinkedIn"
            />
          </TabPanel>
          <TabPanel>
            <SocialMediaAccount
              data={data}
              mediaData={youtubeData}
              mediaName="YouTube"
            />
          </TabPanel>
        </TabPanelsEnhanced>
      </Tabs>
    </>
  );
};

export default DataTrends;
