import React from 'react';
import PropTypes from 'prop-types';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import './style.css';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import DocumentTitle from 'react-document-title';
import { authProvider } from '../authProvider';
import Sidebar from '../Components/Sidebar';
import Main from '../Components/Main';
import Header from '../Components/Header/Index';
import Content from '../Components/Content';
import Footer from '../Components/Footer';

const LayoutWrap = styled.div`
  --fa-primary-color: ${({ theme }) => theme.icon.primary};
  --fa-secondary-color: ${({ theme }) => theme.icon.secondary};
  background-color: ${({ theme }) => theme.main.background};
  display: grid;
  font-family: ${({ theme }) => theme.text.family};
  font-size: ${({ theme }) => theme.text.size.base};
  font-weight: ${({ theme }) => theme.text.weight.normals};
  grid-template-rows: auto 1fr;
  min-height: 100vh;
  max-height: 100%;

  ${({ theme }) => theme.breakpoints.xl} {
    grid-template-columns: ${({ theme, childCount }) =>
      childCount === 1 ? '1fr' : `${theme.width['1/5']} 1fr`};
    grid-template-rows: 1fr;
  }

  a {
    color: ${({ theme }) => theme.main.link};
    &:hover,
    &:visited,
    &:active {
      color: ${({ theme }) => theme.main.linkHover};
    }
  }

  iframe {
    border: none;
  }
`;

const BaseLayout = ({
  children,
  disableNavigation,
  disableHeader,
  disableFooter,
  heading,
  headingDescription,
}) => {
  const theme = useTheme();
  return (
    <AzureAD provider={authProvider}>
      {({ authenticationState, login, logout, accountInfo }) => {
              const withSidebar = !disableNavigation;
              //To enable the sidebar for non logged in users have removed the authentication check
         //authenticationState === AuthenticationState.Authenticated &&
              const isAuthenticatedUser = authenticationState === AuthenticationState.Authenticated;

        return (
          <>
          <LayoutWrap childCount={withSidebar ? 2 : 1}>
            <DocumentTitle title={`${heading} | PIG | OMES`} />
            <Global
              styles={css`
                html,
                body {
                  box-sizing: border-box;
                  height: 100%;
                }
                *,
                *:before,
                *:after {
                  box-sizing: border-box;
                  min-width: 0;
                }
                [data-reach-tooltip] {
                  font-family: ${theme.text.family};
                  font-size: ${theme.text.size.xs};
                }
              `}
            />
                {withSidebar && <Sidebar isAuthenticatedUser={isAuthenticatedUser} />}
            <Main disableHeader={disableHeader}>
              {!disableHeader && (
                <Header
                  heading={heading}
                  login={login}
                  logout={logout}
                  accountInfo={accountInfo}
                  authenticationState={authenticationState}
                  includeLogo={!withSidebar}
                  description={headingDescription}
                />
              )}
              <Content>{children}</Content>
              {!disableFooter && <Footer />}
            </Main>
          </LayoutWrap>
        
          </>
        );
      }}
    </AzureAD>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.any,
  disableNavigation: PropTypes.bool,
  disableHeader: PropTypes.bool,
  disableFooter: PropTypes.bool,
  heading: PropTypes.string,
};

BaseLayout.defaultProps = {
  children: null,
  disableNavigation: false,
  disableHeader: false,
  disableFooter: false,
  heading: 'Performance Improvement Graph',
};

export default BaseLayout;
