import React from "react";
import useSWR from "swr";
import { fetcher } from "../../Utils/utils";
import { TimeIntervals } from "../../Utils/utils";
import "./StateWebsiteVisits.css";
import SectionHeading from "../SectionHeading";
import CardTopItems from "../CardTopItems";
import { faClock } from "@fortawesome/pro-duotone-svg-icons";

const StateWebsiteVisits = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/StateWebsiteVisits.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.TWELVE_HOURS,
    }
  );
  return (
    <>
      {data?.renderCard && (
        <>
          {/* <SectionHeading
            title="State Website Visits"
            description="Displays Average Monthly Unique Visits to the State Website.
                Arrow indicates trend from prior month."
          /> */}
          <div className="MonthlyUniqueVisitsCard">
            <CardTopItems
              items={[
                {
                  text: `As of ${new Date(data.refreshTime).toLocaleString()}`,
                  icon: faClock,
                },
              ]}
            />
            <div className="SwvTitle">Monthly Unique Visits</div>
            <div className="SwvNumberCard">
              <div className="SwvNumberBlock">
                <div className="SwvNumber">{data?.visitorCount}</div>
                {data?.trend === "up" ? (
                  <svg
                    className="fas"
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="30"
                    viewBox="0 0 23 30"
                    fill="none"
                  >
                    <path
                      d="M20.2442 1.73227C20.0835 0.885096 19.3215 0.318197 18.5422 0.466063L5.84421 2.87568C5.06499 3.02354 4.56364 3.83018 4.7244 4.67735C4.88516 5.52452 5.64716 6.09142 6.42637 5.94355L17.7135 3.80168L20.0422 16.0732C20.203 16.9204 20.965 17.4873 21.7442 17.3394C22.5234 17.1915 23.0247 16.3849 22.864 15.5377L20.2442 1.73227ZM3.20348 29.9787L20.0368 2.89534L17.6299 1.10466L0.79652 28.188L3.20348 29.9787Z"
                      fill="#568C10"
                    />
                  </svg>
                ) : (
                  <svg
                    className="fas"
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="30"
                    viewBox="0 0 24 30"
                    fill="none"
                  >
                    <path
                      d="M18.4482 29.4673C19.279 29.6982 20.1246 29.266 20.337 28.5018L23.7981 16.0492C24.0105 15.285 23.5091 14.4784 22.6783 14.2475C21.8475 14.0166 21.0019 14.4488 20.7895 15.213L17.713 26.282L5.67867 22.9372C4.84787 22.7063 4.00221 23.1386 3.78982 23.9027C3.57743 24.6669 4.07875 25.4735 4.90955 25.7044L18.4482 29.4673ZM0.664975 1.68236L17.4971 28.7664L20.1683 27.401L3.33622 0.316897L0.664975 1.68236Z"
                      fill="#BD4B32"
                    />
                  </svg>
                )}
              </div>
              <div className="SwvArrowIcon"></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default StateWebsiteVisits;
