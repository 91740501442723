import React from "react";
import CardInnerWithTime from "../CardInnerWithTime";
import useSWR from "swr";
import Card from "../Card";
import { fetcher, TimeIntervals } from "../../Utils/utils";
import IFrame from "react-iframe";
import styled from "@emotion/styled";

const Wrap = styled.div`
  display: none;
  grid-column: 1 / -1;

  ${({ theme }) => theme.breakpoints.md} {
    display: initial;
  }
`;

const Frame = styled(IFrame)`
  height: 550px;
`;

const InnerComponent = ({ data, url, runTime }) => {
  return (
    <>
      <Card>
        <CardInnerWithTime
          loading={!data}
          title=""
          refreshedTime={runTime ?? ""}
          mainComponent={() => (
            <>
              <Wrap>
                <Frame url={url} width="100%" height="100%" />
              </Wrap>
            </>
          )}
        />
      </Card>
    </>
  );
};
const CPSpotlightDashBoard = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/cp-spotlight-powerbiurl.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );
  
  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }
  
  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (error?.status === 401) {
    return <></>;
  }
  return (
    <InnerComponent
      data={data}
      url={data.list[0].powerBiLink}
      runTime={data.runTimeStamp}
    />
  );
};
export default CPSpotlightDashBoard;
