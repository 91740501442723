import React from "react";
import TopSearchTerms from "../Components/InternetTraffic/TopSearchTerms";

const InternetTrafficMetrics = () => {
  return (
    <>
      {/* <TopOutboundTraffic /> */}
      <TopSearchTerms />
    </>
  );
};

export default InternetTrafficMetrics;
