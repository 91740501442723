import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';

const Content = ({ children }) => {
  const theme = useTheme();
  const SectionWrap = styled.section`
    padding: ${theme.padding.md};
  `;

  return <SectionWrap>{children}</SectionWrap>;
};

Content.propTypes = { children: PropTypes.any };

export default Content;
