import React from "react";
import useSWR from "swr";
import { TimeIntervals, fetcher } from "../../../../Utils/utils";
import {
  Tabs,
  TabList,
  TabPanelsEnhanced,
  TabPanel,
  TabEnhanced,
} from "../../../Tabs";
import TPCount from "../WeeklyNonTravel/TPCount";
import TPAmountPaid from "../WeeklyNonTravel/TPAmountPaid";
import "../../../../styles/WeeklyNonTravel.css";

const InnerComponent = ({ data, isTravel }) => {
  return (
    <div className="WeeklyTravel">
      <Tabs>
        <TabList>
          <TabEnhanced>{`Count`}</TabEnhanced>
          <TabEnhanced>{`Amount Paid`}</TabEnhanced>
        </TabList>
        <TabPanelsEnhanced>
          <TabPanel>
            <TPCount data={data} isTravel={isTravel} />
          </TabPanel>
          <TabPanel>
            <TPAmountPaid data={data} isTravel={isTravel} />
          </TabPanel>
        </TabPanelsEnhanced>
      </Tabs>
    </div>
  );
};

const WeeklyTravel = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/weekly-travel-data.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} isTravel="travel" />;
};

export default WeeklyTravel;
