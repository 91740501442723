import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import InnerBodySection, { getTotal } from './InnerBodySection';
import { formatNumber } from '../../Utils/utils';
import { TableStyle } from '../BaseTable';
import TurnaroundMetric from './TurnaroundMetric';
import AverageARMetric from './AverageARMetric';
import AverageYoYChange from './AverageYoYChange';

const TableWrap = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;
`;

const MetricsWrap = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
`;

const TH1 = styled.th`
  width: 19.8rem;
  text-align : left;
`;
const TH2 = styled.th`
  width: 9rem;
  text-align : right;
`;
const TH3 = styled.th`
  width: 9rem;
  text-align : right;
`;
const TH4 = styled.th`
  width: 15rem;
  text-align : right;
`;

const AppCTable = ({ monthly, oneTime, appropriated, settings, totals, agencyKey, allFrequencyData, timeStamp }) => (
  <>
    <p>
    Includes your agency service agreement information, also known as Appendix C, providing total annual costs for billed services and products. Drafted rates are updated quarterly and gain accuracy throughout the fiscal year.
    </p>

    <MetricsWrap>
      <TurnaroundMetric agencyKey={agencyKey} />
      <AverageARMetric agencyKey={agencyKey} />
      {/* <AverageYoYChange allFrequencyData={allFrequencyData} settings={settings} timeStamp={timeStamp}/> */}
    </MetricsWrap>
    
    <TableWrap>
      <TableStyle color="green" wrapped>
        {monthly && (
          <InnerBodySection
            header="Monthly"
            data={monthly}
            settings={settings}
            totals={totals}
            whichTotal="monthly"
          />
        )}
        {oneTime && (
          <InnerBodySection
            header="Annual"
            data={oneTime}
            settings={settings}
            totals={totals}
            whichTotal="once"
          />
        )}
        {appropriated && (
          <InnerBodySection
            header="Appropriated and CARES Act Funding"
            data={appropriated}
            settings={settings}
            totals={totals}
            fullHeadingSupplied
            whichTotal="appropriated"
          />
        )}
        <table style={{maxWidth: "67.5rem"}}>
        <thead>
          <tr>
            <TH1 as="th">
              Total
            </TH1>
            {settings?.previousYearEnabled && (
              <TH2 as="th">
                {formatNumber(
                  getTotal(totals, settings.shortPreviousYear, 'total'),
                  true,
                  true
                )}
              </TH2>
            )}
            <TH3 as="th">
              {formatNumber(
                getTotal(totals, settings.shortActiveYear, 'total'),
                true,
                true
              )}
            </TH3>
            {settings?.nextYearEnabled && (
              <TH4 as="th">
                {formatNumber(
                  getTotal(totals, settings.shortNextYear, 'total'),
                  true,
                  true
                )}
              </TH4>
            )}
          </tr>
          <tr>
            <TH1 as="th">
              Minus Appropriated and CARES Act Total
            </TH1>
            {settings?.previousYearEnabled && (
              <TH2 as="th">
                {formatNumber(
                  getTotal(totals, settings.shortPreviousYear, 'appropriated'),
                  true,
                  true,
                  true
                )}
              </TH2>
            )}
            <TH3 as="th">
              {formatNumber(
                getTotal(totals, settings.shortActiveYear, 'appropriated'),
                true,
                true,
                true
              )}
            </TH3>
            {settings?.nextYearEnabled && (
              <TH4 as="th">
                {formatNumber(
                  getTotal(totals, settings.shortNextYear, 'appropriated'),
                  true,
                  true,
                  true
                )}
              </TH4>
            )}
          </tr>
          <tr className="alt">
            <TH1 as="th">
              Grand Total
            </TH1>
            {settings?.previousYearEnabled && (
              <TH2 as="th">
                {formatNumber(
                  getTotal(totals, settings.shortPreviousYear, 'grandTotal'),
                  true,
                  true
                )}
              </TH2>
            )}
            <TH3 as="th">
              {formatNumber(
                getTotal(totals, settings.shortActiveYear, 'grandTotal'),
                true,
                true
              )}
            </TH3>
            {settings?.nextYearEnabled && (
              <TH4 as="th">
                {formatNumber(
                  getTotal(totals, settings.shortNextYear, 'grandTotal'),
                  true,
                  true
                )}
              </TH4>
            )}
          </tr>
        </thead>
        </table>
       
      </TableStyle>
    </TableWrap>
  </>
);

AppCTable.propTypes = {
  monthly: PropTypes.any,
  oneTime: PropTypes.any,
  appropriated: PropTypes.any,
  settings: PropTypes.any,
  totals: PropTypes.any,
};

AppCTable.defaultProps = {
  monthly: null,
  oneTime: null,
  appropriated: null,
  settings: null,
  totals: null,
};

export default AppCTable;
