import React, { useState, useEffect } from "react";
import useSWR from "swr";
import { fetcher, TimeIntervals } from "../../../Utils/utils";
import ColumnSection from "../../../Components/ColumnSection";
import {
  Tabs,
  TabList,
  TabPanelsEnhanced,
  TabPanel,
  TabEnhanced,
} from "../../../Components/Tabs";
import WorkByProperty from "./WorkByProperty";
import WorkByCode from "./WorkByCode";
import WorkByStatus from "./WorkByStatus";
import "../../../styles/CapitalAssets.css";

const InnerComponent = ({ data }) => {
  const currentDate = new Date();
  const previousMonth = currentDate.getMonth();

  // let filteredMonthlyData = data.data[0].report_row.filter(
  //   (item) =>
  //     new Date(item.c2).getMonth() === previousMonth - 1 &&
  //     new Date(item.c2).getFullYear() === currentDate.getFullYear()
  // );

  // let closedWorkOrdersCount = filteredMonthlyData.filter(
  //   (item) => item.c4 === "Work Complete"
  // ).length;
  let closedWorkOrdersCount = data?.closedWorkOrdersCount;
  let totalWorkOrders = data?.workOrdersCount;

 // const { c1, c3, c4 } = data.data[0].report_header;

  const work_code = "Work Code";
  const work_property = "Property Name";
  const work_status = "Work Status";

  const [uniquePropertyNames, setUniquePropertyNames] = useState([]);
  const [uniqueWorkCode, setUniqueWorkCode] = useState([]);
  const [uniqueWorkStatus, setUniqueWorkStatus] = useState([]);

  // useEffect(() => {
  //   let returnedPropertyNames = Array.from(
  //     new Set(filteredMonthlyData?.map((item) => item?.c1))
  //   );
  //   let returnedWorkCode = Array.from(
  //     new Set(filteredMonthlyData?.map((item) => item?.c3))
  //   );
  //   let returnedWorkStatus = Array.from(
  //     new Set(filteredMonthlyData?.map((item) => item?.c4))
  //   );

  //   setUniquePropertyNames(returnedPropertyNames);
  //   setUniqueWorkCode(returnedWorkCode);
  //   setUniqueWorkStatus(returnedWorkStatus);
  // }, [data]);
  return (
    <>
      <ColumnSection>
        <Tabs>
          <TabList>
            <TabEnhanced>{work_property}</TabEnhanced>
            <TabEnhanced>{work_code}</TabEnhanced>
            <TabEnhanced>{work_status}</TabEnhanced>
          </TabList>
          <TabPanelsEnhanced>
            <TabPanel>
              <WorkByProperty
                data={data?.workOrder}
                uniquePropertyNames={uniquePropertyNames}
                closedWorkOrdersCount={closedWorkOrdersCount}
                totalWorkOrders={totalWorkOrders}
                timeStamp={data?.timeStamp}
              />
            </TabPanel>
            <TabPanel>
              <WorkByCode
                data={data?.workOrderCode}
                uniqueWorkCode={uniqueWorkCode}
                closedWorkOrdersCount={closedWorkOrdersCount}
                totalWorkOrders={totalWorkOrders}
                timeStamp={data?.timeStamp}
              />
            </TabPanel>
            <TabPanel>
              <WorkByStatus
                data={data?.workStatus}
                uniqueWorkStatus={uniqueWorkStatus}
                closedWorkOrdersCount={closedWorkOrdersCount}
                totalWorkOrders={totalWorkOrders}
                timeStamp={data?.timeStamp}
              />
            </TabPanel>
          </TabPanelsEnhanced>
        </Tabs>
      </ColumnSection>
    </>
  );
};

const WorkByPropertyCodeStatusTab = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/work-order.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );
  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  if (!data && !error && !error?.status) {
    return <></>;
  }
  return <InnerComponent data={data} />;
};

export default WorkByPropertyCodeStatusTab;
