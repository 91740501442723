import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { AuthenticationState } from 'react-aad-msal';

import Button from '../Button';
import { H1 } from '../Headings';

import logo from '../../Images/OK_Logo_Symbol_FullColour_RGB.svg';
import Banner from '../Banner/Banner';

const Header = ({
  heading,
  accountInfo,
  login,
  logout,
  authenticationState,
  includeLogo,
  description,
}) => {
  const theme = useTheme();

  const HeadWrap = styled.header`
    align-items: center;
    background-color: ${theme.header.background};
    padding: ${theme.padding.md};
    border-bottom: ${theme.width.px} ${theme.colors.slateGray.base} solid;

    h1 {
      display: inline-block;
      grid-area: title;

      img {
        width: 4rem;
        vertical-align: middle;
        margin-right: ${theme.padding.sm};
      }
    }

    div.msg {
      text-align: right;
      grid-area: msg;
    }
    div.Banner-Style
    {
      margin-left: -${theme.padding.md};
      margin-right: -${theme.padding.md};
      margin-bottom: -${theme.padding.md};
      margin-top: 0.5rem;
    }
  `;

  const TitleInfoWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;

  const TimeMessage = () => {
    const curHr = new Date().getHours();

    if (curHr < 12) {
      return 'morning';
    }
    if (curHr < 18) {
      return 'afternoon';
    }
    return 'evening';
  };

  const displayUserInformation = () => {
    switch (authenticationState) {
      case AuthenticationState.Authenticated:
        return (
          <div className="msg">
            <span>
              Good {TimeMessage()},{' '}
              {accountInfo.account.idTokenClaims.given_name}
            </span>
            <Button onClick={() => logout()}>Log out</Button>
          </div>
        );
      case AuthenticationState.InProgress:
        return <div className="msg">Logging in...</div>;
      default:
        return (
          <div className="msg">
            <Button onClick={() => login()}>Login</Button>
          </div>
        );
    }
  };

  return (
    <><HeadWrap>
      <TitleInfoWrap>
        <H1>
          {includeLogo && (
            <img
              src={logo}
              alt="Office of Management and Enterprise Services"
            />
          )}
          {heading}
        </H1>
        {displayUserInformation()}
      </TitleInfoWrap>
      {description && <div className="desc">{description}</div>}
      <Banner bannerFlag={heading}/>
    </HeadWrap>
     </>
  );
};

Header.propTypes = {
  heading: PropTypes.string,
  accountInfo: PropTypes.any,
  login: PropTypes.func,
  logout: PropTypes.func,
  authenticationState: PropTypes.string,
  includeLogo: PropTypes.bool,
};

export default Header;
