import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {PIGPRIVATEVIEW} from "../../Utils/Roles";

import {
  faBookOpen,
  faBuilding,
  faAngleDoubleDown,
  faFileContract,
  faShield,
  faAnalytics,
} from "@fortawesome/pro-duotone-svg-icons";
import ListItem from "./ListItem";
import In from "../In";
import DivisionsItem from "./DivisionsItem";

const UlWrap = styled.ul`
  grid-template-columns: 1fr;
  display: grid;
  grid-gap: ${({ theme }) => theme.width.sm};
  list-style: none;
  margin: ${({ theme }) => theme.margin.none};
  padding: ${({ theme }) => theme.padding.none};

  ${({ theme }) => theme.breakpoints.xl} {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: ${({ theme }) => theme.width.none};
  }
`;

const DivisionHeadingWrap = styled.div`
  padding: ${({ theme }) => theme.padding.base};
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: ${({ theme }) => theme.padding.sm};
  cursor: pointer;
  justify-self: center;

  &:hover {
    background-color: ${({ theme }) => theme.sidebar.hover};
  }

  ${({ theme }) => theme.breakpoints.xl} {
    justify-self: self-start;
    width: 100%;
  }
`;

const DivisionWrap = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.padding.xs};
  margin-top: ${({ theme }) => theme.margin.xs};
  margin-bottom: ${({ theme }) => theme.margin.sm};
`;

const List = ({ isAuthenticatedUser }) => {
  return (
    <UlWrap>
      <DivisionHeadingWrap>
        <FontAwesomeIcon icon={faAngleDoubleDown} />
        <span>OMES Divisions</span>
      </DivisionHeadingWrap>
      <DivisionWrap>
        
        <DivisionsItem
          path="/budget-policy-gaming-compliance"
          heading="Budget, Policy, Gaming Compliance"
          isMetricActive={true}
        />
        <DivisionsItem
          path="/capital-assets-management"
          heading="Capital Assets Management"
          isMetricActive={true}
        />
         <DivisionsItem
          path="/central-accounting-reporting"
          heading="Central Accounting and Reporting"
          isMetricActive={true}
        />
        <DivisionsItem
          path="/central-purchasing"
          heading="Central Purchasing"
          isMetricActive={true}
        />
        {/* <DivisionsItem
          path="/employees-group-insurance-division"
          heading="Employees Group Insurance Division"
          isMetricActive={true}
        /> */}
        <DivisionsItem
          path="/finance"
          heading="Finance"
          isMetricActive={true}
        />
        <In
          which={PIGPRIVATEVIEW.PIGLEADERS}
          yes={() => (
            <DivisionsItem
              path="/general-counsel"
              heading="General Counsel"
              isMetricActive={true}
            />
          )}
        />
        <DivisionsItem
          path="/human-capital-management"
          heading="Human Capital Management"
          isMetricActive={true}
        />
        <DivisionsItem
          path="/information-services"
          heading="Information Services"
          isMetricActive={true}
        />
        <DivisionsItem
          path="/outreach"
          heading="Outreach"
          isMetricActive={true}
        />
        <In
          which={PIGPRIVATEVIEW.PIGLEADERS}
          yes={() => (
            <DivisionsItem
              path="/grants-management-office"
              heading="Grants Management Office"
              isMetricActive={false}
            />
          )}
        />
        <DivisionsItem
          path="/risk-assessment-compliance"
          heading="Risk, Assessment & Compliance"
          isMetricActive={true}
        />
      </DivisionWrap>
      {isAuthenticatedUser && (
        <In
          which="director"
          yes={() => (
            <ListItem path="/reals" heading="Reals" icon={faBuilding} />
          )}
        />
      )}
      <ListItem
        path="/agency-rate-packet"
        heading="Agency Rate Packet - <em>new look!</em>"
        icon={faBookOpen}
        redirection= {true}
      />
      <ListItem
              path="/security-threat-metrics"
              heading="Security Threat Metrics"
              // icon={faFileContract}
              icon={faShield}
            />
      {isAuthenticatedUser && (
        <In
          which={PIGPRIVATEVIEW.PIGLEADERS}
          yes={() => (
            <ListItem
              path="/time-reporting"
              heading="Time & Resource Reporting"
              icon={faAnalytics}
            />
          )}
        />
      )}
      {isAuthenticatedUser && (
        <In
          which={PIGPRIVATEVIEW.PIGLEADERS}
          yes={() => (
            <ListItem
              path="/internet-traffic-metrics"
              heading="Internet Traffic Metrics"
              icon={faFileContract}
            />
          )}
        />
      )}
    </UlWrap>
  );
};

List.propTypes = {};

export default List;
