import React, { useState } from "react";
import useSWR from "swr";
import styled from "@emotion/styled";
import Card from "../Card";
import CardInnerWithTime from "../CardInnerWithTime";
import { TimeIntervals, fetcher } from "../../Utils/utils";
import Select from "../Select";
import { TableStyle } from "../BaseTable";
import Cell from "../AppCTable/Cell";
import "../../styles/DisasterRecovery.css";

const TableWrap = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;

  thead tr {
    background-color: #005c95;
    color: #ffffff;
  }

  thead th {
    overflow-wrap: break-word;
    white-space: pre-wrap !important;
  }
`;

const InnerComponent = ({ data }) => {
  const [agency, setAgency] = useState(0);
  const [agencyData, setAgencyData] = useState(null);

  return (
    <Card className="DisasterRecovery">
      <CardInnerWithTime
        loading={!data}
        title="Disaster Recovery (DR) Coverage by Agency"
        refreshedTime={data?.timeStamp ?? ""}
        mainComponent={() => (
          <div className="StaticText">
            <p>There are three types of DR coverage:</p>
            <ul>
              <li>
                CARES (Vmware): CARES is the federal government money received
                during COVID used for DR planning and upgrades (TX1 data center
                contract for DR services).
              </li>
              <li>
                TSM (Physical): Tivoli Storage Management for tape recovery of
                data. IBM Tivoli Storage Manager is an enterprise-wide storage
                management application (tape libraries, and other forms of
                random-access and sequential-access).
              </li>
              <li>
                Cloud Data Management: A way to manage data across cloud
                platforms, either with or instead of on-premises storage (AWS,
                etc).
              </li>
            </ul>

            <Select
              onChange={(e) => {
                const val = e.target.value;
                setAgency(val);
                setAgencyData(
                  data?.sdrList?.filter((x) => x.agencyKey === val)[0]
                );
              }}
              defaultValue={agency}
              aria-label="Select Item to View"
            >
              <option>Select an agency</option>
              {data?.sdrList?.map((x, i) => (
                <option key={i} value={x.agencyKey}>
                  {x.agencyName}
                </option>
              ))}
            </Select>

            {agency !== 0 && (
              <>
                <TableWrap>
                  <TableStyle color="blue" wrapped>
                    <thead>
                      <tr>
                        <Cell as="th" alignment="left">
                          Number
                        </Cell>
                        <Cell as="th" alignment="left">
                          Agency Name
                        </Cell>
                        <Cell as="th" alignment="left">
                          CARES DR (Vmware)
                        </Cell>
                        <Cell as="th" alignment="left">
                          TSM DR (Physical)
                        </Cell>
                        <Cell as="th" alignment="left">
                          Cloud DR
                        </Cell>
                        <Cell as="th" alignment="left">
                          DR/Backup not provided by OMES
                        </Cell>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <Cell as="td" alignment="left">
                          {agencyData?.agencyKey !== ""
                            ? agencyData?.agencyKey
                            : ""}
                        </Cell>
                        <Cell as="td" alignment="left">
                          {agencyData?.agencyName !== ""
                            ? agencyData?.agencyName
                            : ""}
                        </Cell>
                        <Cell as="td" alignment="right">
                          {agencyData?.caresDR !== ""
                            ? agencyData?.caresDR
                            : "-"}
                        </Cell>
                        <Cell as="td" alignment="right">
                          {agencyData?.tsmdr !== "" ? agencyData?.tsmdr : "-"}
                        </Cell>
                        <Cell as="td" alignment="right">
                          {agencyData?.cloud !== "" ? agencyData?.cloud : "-"}
                        </Cell>
                        <Cell as="td" alignment="right">
                          {agencyData?.notProvidedOMES !== ""
                            ? agencyData?.notProvidedOMES
                            : "-"}
                        </Cell>
                      </tr>
                    </tbody>
                  </TableStyle>
                </TableWrap>

                {agencyData?.notes && (
                  <>
                    <strong>Notes: </strong>
                    <span>{agencyData?.notes}</span>
                  </>
                )}
              </>
            )}
          </div>
        )}
      />
    </Card>
  );
};

const DisasterRecovery = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/systems-with-disaster-recovery.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} />;
};

export default DisasterRecovery;
