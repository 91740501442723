import React, { useState } from "react";
import useSWR from "swr";
import { TimeIntervals, fetcher } from "../../../Utils/utils";
import ISCompliance from "./ISCompliance";
import ColumnSection from "../../ColumnSection";
import "../../../styles/Common.css";
import Select from "react-select";
import SoleSource from "./SoleSource/SoleSource";
import DirectPOTrendline from "./DirectPOTrendline/DirectPOTrendline";
import { AuthenticationState } from "react-aad-msal";
import LiabilityClaimPercentInternal from "./GeneralClaims/LiabilityClaimPercentInternal";
import LiabilityClaimPercentPublic from "./GeneralClaims/LiabilityClaimPercentPublic";
import { CheckPrivateViewRole } from "../../../Utils/Roles";
import OpenRecords from "./OpenRecords";
import Data584 from "./Data584";
import ProjectsReviewed from "./ProjectsReviewed/ProjectsReviewed";
import "../../../styles/RiskAssessment.css";
import "../../../styles/ProjectsReviewed.css";
import NONISCompliance from "./NONISCompliance";
import NONISAgency from "./NONISAgency";
import InsuranceSurvey from "./InsuranceSurvey";

const RiskAssessment = ({ authenticationState, role }) => {
  const [agency, setAgency] = useState("");

  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/agency-names-for-rac.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );
  const firstElement = { value: "All Agencies", label: "All Agencies" };
  const agencyFirstList = data?.sort().map((item) => ({
    value: item,
    label: item,
  }));
  const agencyList = [firstElement].concat(agencyFirstList);

  return (
    <>
      <section>
        <span className="Heading">Risk</span>
        {authenticationState === AuthenticationState.Authenticated &&
        CheckPrivateViewRole(role) ? (
          <LiabilityClaimPercentInternal />
        ) : (
          <LiabilityClaimPercentPublic />
        )}
      </section>

      <section className="InsuranceSurvey">
        <ColumnSection cols={3}>
          <InsuranceSurvey />
        </ColumnSection>
      </section>

      <section>
        {data?.length === 0 ? (
          <></>
        ) : !data && !error && !error?.status ? (
          <></>
        ) : (
          <>
            <span className="Heading">Assessment</span>
            <Select
              className="SelectAssessmentAgency"
              placeholder="Search by Agency Name"
              options={agencyList}
              onChange={(e) => {
                setAgency(e.label);
              }}
              defaultValue=""
            />

            <DirectPOTrendline agency={agency} />
            <SoleSource agency={agency} />
          </>
        )}
      </section>

      <section className="ProjectsReviewed">
        <ProjectsReviewed />
      </section>

      <section className="OpenRecords">
        {authenticationState === AuthenticationState.Authenticated &&
          CheckPrivateViewRole(role) && <OpenRecords />}
      </section>

      <section className="Compliance">
        <span className="Heading">Compliance</span>
        <ColumnSection cols={2}>
          <ISCompliance />
          <NONISCompliance />
        </ColumnSection>
      </section>
      <section className="Compliance">
        <NONISAgency />
      </section>

      <section className="Data584">
        <ColumnSection cols={3}>
          <Data584 />
        </ColumnSection>
      </section>
    </>
  );
};

export default RiskAssessment;
