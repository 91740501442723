/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const H1Style = styled.h1`
  color: ${({ theme }) => theme.colors.slateGray.base};
  font-family: 'Open Sans', sans-serif;
  font-size: ${({ theme }) => theme.text.size['4xl']};
  font-weight: ${({ theme }) => theme.text.weight.semibold};
  text-align: ${({ centered }) => (centered ? 'center' : 'inherit')};
`;

const H2Style = styled.h2`
  color: ${({ theme }) => theme.colors.slateGray.base};
  font-family: 'Open Sans', sans-serif;
  font-size: ${({ theme }) => theme.text.size['2xl']};
  font-weight: ${({ theme }) => theme.text.weight.semibold};
  text-align: ${({ centered }) => (centered ? 'center' : 'inherit')};
`;
const H2StyleWhite = styled.h2`
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: ${({ theme }) => theme.text.size['2xl']};
  font-weight: ${({ theme }) => theme.text.weight.semibold};
  text-align: ${({ centered }) => (centered ? 'center' : 'inherit')};
`;

const H3Style = styled.h3`
  color: ${({ theme }) => theme.colors.slateGray.base};
  font-family: 'Open Sans', sans-serif;
  font-size: ${({ theme }) => theme.text.size.xl};
  font-weight: ${({ theme }) => theme.text.weight.semibold};
  text-align: ${({ centered }) => (centered ? 'center' : 'inherit')};
`;

const H4Style = styled.h4`
  color: ${({ theme }) => theme.colors.slateGray.base};
  font-family: 'Open Sans', sans-serif;
  font-size: ${({ theme }) => theme.text.size.lg};
  font-weight: ${({ theme }) => theme.text.weight.semibold};
  text-align: ${({ centered }) => (centered ? 'center' : 'inherit')};
`;

export const H1 = ({ children, centered = false, ...rest }) => (
  <H1Style centered={centered} {...rest}>
    {children}
  </H1Style>
);

export const H2 = ({ children, centered = false, ...rest }) => (
  <H2Style centered={centered} {...rest}>
    {children}
  </H2Style>
);
export const H2White = ({ children, centered = false, ...rest }) => (
  <H2StyleWhite centered={centered} {...rest}>
    {children}
  </H2StyleWhite>
);

export const H3 = ({ children, centered = false, ...rest }) => (
  <H3Style centered={centered} {...rest}>
    {children}
  </H3Style>
);

export const H4 = ({ children, centered = false, ...rest }) => (
  <H4Style centered={centered} {...rest}>
    {children}
  </H4Style>
);

H1.propTypes = { children: PropTypes.any, centered: PropTypes.bool };
H2.propTypes = { children: PropTypes.any, centered: PropTypes.bool };
H3.propTypes = { children: PropTypes.any, centered: PropTypes.bool };
H4.propTypes = { children: PropTypes.any, centered: PropTypes.bool };
