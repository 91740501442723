import React from "react";
import useSWR from "swr";
import { TimeIntervals, fetcher } from "../../../Utils/utils";
import CrossPlatform from "./CrossPlatform";
import DataTrends from "./DataTrends";
import "../../../styles/LegislativePublicAffairs.css";

const InnerComponent = ({ data }) => {
  return (
    <>
      <div className="PlatformTrends">
        <CrossPlatform data={data} />
        <DataTrends data={data} />
      </div>
    </>
  );
};

const SocialMedia = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/social-media.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (error?.status === 401) {
    return <></>;
  }
  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} />;
};

export default SocialMedia;
