import React, { useState } from "react";
import useSWR from "swr";
import Card from "../../Card";
import CardInnerWithTime from "../../CardInnerWithTime";
import TooltipItem from "../../TooltipItem";
import { H1, H2, H3 } from "../../Headings";
import {
  TimeIntervals,
  fetcher,
  formatNumber,
  monthsList,
} from "../../../Utils/utils";
import {
  Crosshair,
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  VerticalBarSeries,
  XAxis,
  YAxis,
} from "react-vis";
import "../../../styles/Common.css";
import "../../../styles/ITAssetManagement.css";
import {
  TabEnhanced,
  TabList,
  TabPanel,
  TabPanelsEnhanced,
  Tabs,
} from "../../Tabs";
import AgencyPO from "./AgencyPO";
import ProductPO from "./ProductPO";
import FulfillmentRate from "./FulfillmentRate";

const InnerComponent = ({ data }) => {
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);
  const [crosshairValues, setCrosshairValues] = useState([]);
  let inBoundArray = [],
    yDomainTopValue = 0,
    inboundOrderStatusSorted;

  const maxValue =
    data?.inboundOrderStatus?.length > 0 &&
    Math.max(...data?.inboundOrderStatus?.map((d) => d.total));
  yDomainTopValue = [0, 1.2 * maxValue];

  const sortList = [
    "Received by Dell",
    "Confirmed",
    "In Production",
    "Build Complete",
    "Shipped",
    "Delivered",
    "Replaced",
  ];

  if (data) {
    inboundOrderStatusSorted = data?.inboundOrderStatus.sort((a, b) => {
      return sortList.indexOf(a.status) - sortList.indexOf(b.status);
    });
  }

  if (inboundOrderStatusSorted) {
    inboundOrderStatusSorted
      .filter((item) => item.status !== null || item.total !== null)
      .filter(
        (item) => item.status !== "Available" && item.status !== "Cancelled"
      )
      .forEach((item) => {
        inBoundArray.push({
          x: item.status,
          y: item.total,
          color: "#0066a6",
        });
      });
  }

  return (
    <div className="ITAssetWrap">
      <span className="Heading">IT Asset Management</span>
      <H3>Inbound Device Purchase Orders (POs)</H3>
      <p>
        {monthsList[new Date(data?.maxDate).getMonth()]}{" "}
        {new Date(data?.maxDate).getDate()},{" "}
        {new Date(data?.maxDate).getFullYear()}
      </p>

      <Card className="ITAssetManagement">
        <CardInnerWithTime
          loading={!data}
          refreshedTime={data?.timeStamp ?? ""}
          mainComponent={() => (
            <>
              <section className="Margin-Top-3">
                <div className="TopHeader">
                  <div className="HeadCounts">
                    <H2 as="h3">Daily Inbound Orders</H2>
                    <H1 as="span">{formatNumber(data?.inboundOrders)}</H1>
                  </div>
                  <div className="Divider"></div>
                  <div className="HeadCounts">
                    <H2 as="h3">Total Products out for Shipment</H2>
                    <H1 as="span">{formatNumber(data?.productsForShipment)}</H1>
                  </div>
                </div>
              </section>

              <section className="ProductStatus">
                <H2>Status of Inbound Products - Past 90 Days</H2>
                <FlexibleWidthXYPlot
                  height={200}
                  xType="ordinal"
                  yDomain={yDomainTopValue}
                  onMouseLeave={() => {
                    setCrosshairValues(false);
                  }}
                >
                  <HorizontalGridLines />
                  <XAxis />
                  <YAxis />
                  <VerticalBarSeries
                    barWidth={0.5}
                    color="#0066a6"
                    data={inBoundArray}
                    animation={animation}
                    onValueMouseOver={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                      setCrosshairValues([datapoint]);
                    }}
                    onValueMouseOut={() => {
                      setHint(false);
                      setAnimation(true);
                    }}
                  />

                  {crosshairValues && (
                    <Crosshair values={crosshairValues}>
                      <div className="ShowHint">
                        <TooltipItem
                          fill="#0066a6"
                          text={`${hint.x}: ${hint.y}`}
                        />
                      </div>
                    </Crosshair>
                  )}
                </FlexibleWidthXYPlot>
              </section>
            </>
          )}
        />
      </Card>
      
      <section className="InboundPO">
        <Tabs>
          <TabList>
            <TabEnhanced>By Agency</TabEnhanced>
            <TabEnhanced>By Product</TabEnhanced>
          </TabList>
          <TabPanelsEnhanced>
            <TabPanel>
              <AgencyPO data={data} /> 
            </TabPanel>
            <TabPanel>
              <ProductPO data={data} />
            </TabPanel>
          </TabPanelsEnhanced>
        </Tabs>
      </section>
      <Card className="Title-Left">
        <CardInnerWithTime
          loading={!data}
        title={"Overall Fulfillment Window"}
          refreshedTime={data?.timeStamp ?? ""}
          mainComponent={() => (
            <section className="FulfillmentRate">
              <p>
                Window of shipment delivery from order placement to shipment
                arrival. Fulfillment rate is not managed by OMES and subject to
                vendor's delivery schedule.
              </p>
              <FulfillmentRate data={data} />
            </section>
          )}
        />
      </Card>
    </div>
  );
};

const ITAssetManagement = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/PO-inbound-tracking.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} />;
};

export default ITAssetManagement;
