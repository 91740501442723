import React, { useState } from "react";
import { H2 } from "../Headings";
import Select from "../Select";
import GoogleSearchTable from "./GoogleSearchTable";
import { TimeIntervals, buildApiInternalUrl, formatNumber } from "../../Utils/utils";
import {
  Tabs,
  TabList,
  TabEnhanced,
  TabPanelsEnhanced,
  TabPanel,
} from "../../Components/Tabs";
import ColumnSection from "../../Components/ColumnSection";
import AuthorizedArea from "../AuthorizedArea";

const getData = (data, agencyNum) => {
  let thisData = [];
  let filteredData = [];
  let newData = data?.forEach((x, i) => {
    if (x!== null && x[0]?.agencyNumber === agencyNum) {
      filteredData = x;
    }
  });
  newData = filteredData?.forEach((a, i) => {
      thisData.push({
        clicks: formatNumber(a.clicks),
        impressions: formatNumber(a.impressions),
        query: a.query,
        rank: i + 1,
      });
  });
  return thisData;
};

const TopSearchTerms = () => {
  const [agencyNumber, setAgencyNumber] = useState(-1);
  const [agencyDetails, setAgencyDetails] = useState(null);
  const [agencyName, setAgencyName] = useState();

  const setSelectText = element => {
    if(element) {
      const selectEle = element.querySelector("#top-search-select");
      if(selectEle) {
        const selectedText = selectEle.options[selectEle.selectedIndex].text;
        setAgencyName(selectedText);   
      }
    }
  };

  const onChangeHandler = (e, data) => {
    const selectEle = e.target;
    const selectedVal = parseInt(selectEle.value);;
    const selectedText = selectEle?.options[selectEle.selectedIndex].text;
    setAgencyName(selectedText);      
    setAgencyNumber(selectedVal);
    setAgencyDetails(
      data?.agencyDetails?.filter((x) => x.agencyNumber === selectedVal)[0]
    );
  }

  return (
    <AuthorizedArea
      url={buildApiInternalUrl("gcptopsearchterms")}
      refreshInterval={TimeIntervals.ONE_DAY}
      isFullWidth
      loader = {true}
      innerComponent={({ data, error }) => {
        const sortedData = data?.agencyDetails?.sort((a, b) => {
          const value1 = a.agencyName.toLowerCase().trim();
          const value2 = b.agencyName.toLowerCase().trim();
          return value1 > value2 ? 1 : -1
        });
        const yesterdayData = getData(data?.yesterdayData, agencyNumber);
        const thisWeekData = getData(data?.weekData, agencyNumber);
        const monthData = getData(data?.results, agencyNumber);
        return (
          <>
            <div ref={setSelectText}>
              <Select
                onChange={(e) => onChangeHandler(e, data)}
                defaultValue={agencyNumber}
                aria-label="Select Item to View"
                id="top-search-select"
              >
                <option value={-1}>* All Oklahoma.gov domains</option>
                <option value={-2}>* All Ok.gov domains</option>
                {sortedData?.map((x, i) => (
                  <option key={i} value={x.agencyNumber}>
                    {x.agencyName}
                  </option>
                ))}
              </Select>
            </div>
            {agencyNumber !== 0 && (
              <ColumnSection cols={1}>
                <div className="title-wrapper">
                  <H2 className="title">{agencyName?.replace(/\*/g, '')}</H2>
                  <a href={agencyDetails?.agencyURL} target="_blank">
                    {agencyDetails?.agencyURL}
                  </a>
                </div>
                <Tabs>
                  <TabList>
                    <TabEnhanced>This Month</TabEnhanced>
                    <TabEnhanced>This Week</TabEnhanced>
                    <TabEnhanced>Yesterday</TabEnhanced>
                  </TabList>
                  <TabPanelsEnhanced>
                  <TabPanel>
                      <GoogleSearchTable
                        responseData={monthData}
                        mainTitle="Monthly Top 100 Search Terms"
                        subTitle="Data reflects inbound searches made by users on Oklahoma sites."
                        refreshedTime={data?.timeStamp}
                      ></GoogleSearchTable>
                    </TabPanel>
                    <TabPanel>
                      <GoogleSearchTable
                        responseData={thisWeekData}
                        mainTitle="Weekly Top 100 Search Terms"
                        subTitle="Data reflects inbound searches made by users on Oklahoma sites."
                        refreshedTime={data?.timeStamp}
                      ></GoogleSearchTable>
                    </TabPanel>
                    <TabPanel>
                      <GoogleSearchTable
                        responseData={yesterdayData}
                        mainTitle="Daily Top 100 Search Terms"
                        subTitle="Data reflects inbound searches made by users on Oklahoma sites."
                        refreshedTime={data?.timeStamp}
                      ></GoogleSearchTable>
                    </TabPanel>
                  </TabPanelsEnhanced>
                </Tabs>
              </ColumnSection>
            )}
          </>
        );
      }}
    />
  );
};

export default TopSearchTerms;
