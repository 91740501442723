import React from 'react';
import PropTypes from 'prop-types';

import { formatNumber } from '../../Utils/utils';
import Cell from './Cell';

const Row = ({ idx, name, description, expenseItems, price }) => {
  const rowClass = (idx + 1) % 2 === 1 ? 'alt' : '';

  return (
    <>
      <tr className={rowClass}>
        <Cell alignment="left">{name}</Cell>
        <Cell alignment="left">{description}</Cell>
        <Cell alignment="left">
          <ul>
            {expenseItems?.split('\n').map((x, i) => (
              <li key={i}>{x}</li>
            ))}
          </ul>
        </Cell>
        <Cell>{!isNaN(price) ? formatNumber(price, true) : price}</Cell> 
      </tr>
    </>
  );
};

Row.propTypes = {
  idx: PropTypes.number,
  description: PropTypes.string,
};

Row.defaultProps = {
  idx: 0,
  description: '',
};

export default Row;
