import React from "react";
import Card from "../../Card";
import CardInnerWithTime from "../../CardInnerWithTime";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import "../../../styles/CrossPlatform.css";
import { H1 } from "../../Headings";
import { formatNumber } from "../../../Utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/pro-duotone-svg-icons";

const showTrend = (trendName) => {
  if (trendName === "More") {
    return (
      <FontAwesomeIcon
        className="UpArrow"
        icon={faArrowUp}
        fixedWidth
        style={{ transform: "rotate(45deg)" }}
      />
    );
  } else {
    return (
      <FontAwesomeIcon
        className="DownArrow"
        icon={faArrowDown}
        fixedWidth
        style={{ transform: "rotate(-45deg)" }}
      />
    );
  }
};

const CrossPlatform = ({ data }) => {
  let {
    followers,
    followersTrend,
    impressions,
    impressionsTrend,
    engagement,
    engagementTrend,
    videoViews,
    videoViewsTrend,
  } = data?.total;

  return (
    <Card className="CrossPlatform">
      <CardInnerWithTime
        loading={!data}
        title="Cross-Platform Social Media"
        refreshedTime={data?.timeStamp ?? ""}
        extraIcons={[
          {
            text: `• Followers reflect the size of audience on account/profile.
• Impressions indicate the number of content views.
• Engagement represents the number of interactions/clicks
  with the content.
• Video views are the amount of views video content has.`,
            icon: faInfoCircle,
            style: {
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              textAlign: "justify",
            },
          },
        ]}
        mainComponent={() => (
          <>
            <H1 as="span">
              {formatNumber(followers)}
              {showTrend(followersTrend)}
            </H1>
            <p>Total Followers</p>
            <H1 as="span">
              {formatNumber(impressions)}
              {showTrend(impressionsTrend)}
            </H1>
            <p>Monthly Impressions</p>
            <H1 as="span">
              {formatNumber(engagement)}
              {showTrend(engagementTrend)}
            </H1>

            <p>Monthly Engagements</p>
            <H1 as="span">
              {formatNumber(videoViews)}
              {showTrend(videoViewsTrend)}
            </H1>
            <p>Monthly Video Views</p>
          </>
        )}
      />
    </Card>
  );
};

export default CrossPlatform;
