import React from "react";
import {
  TabEnhanced,
  TabList,
  TabPanel,
  TabPanelsEnhanced,
  Tabs,
} from "../../../../Tabs";
import Under10M from "./Under10M";
import Between10to25M from "./Between10to25M";
import Over25M from "./Over25M";

const CAPProjectsTabs = () => {
  return (
    <section className="InboundPO">
      <Tabs>
        <TabList>
          <TabEnhanced>Less than $10M</TabEnhanced>
          <TabEnhanced>$10M to $25M</TabEnhanced>
          <TabEnhanced>Greater than $25M</TabEnhanced>
        </TabList>
        <TabPanelsEnhanced>
          <TabPanel>
            <Under10M />
          </TabPanel>
          <TabPanel>
            <Between10to25M />
          </TabPanel>
          <TabPanel>
            <Over25M />
          </TabPanel>
        </TabPanelsEnhanced>
      </Tabs>
    </section>
  );
};

export default CAPProjectsTabs;
