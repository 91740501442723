/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { TableStyle, getCellClass } from './BaseTable';

const Wrap = styled.div`
  overflow-y: auto;
  max-height: ${({ maxHeight }) => maxHeight};

  thead tr th {
    background-color: #005c95;
    color: #ffffff;
  }
`;

const SimpleTable = ({ columns, data, maxHeight, ...props }) => (
  <Wrap maxHeight={maxHeight}>
    <TableStyle {...props} className="striped">
      <thead>
        <tr>
          {columns.map((x, i) => (
            <th key={i} className={getCellClass(i, columns)}>
              {x.title || x.selector}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.map((x, i) => (
          <tr key={i}>
            {columns.map((y, j) => (
              <td key={j} className={getCellClass(j, columns)}>
                {y.selector !== null && x[y.selector]}
                {y.component && y.component(x)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </TableStyle>
  </Wrap>
);

SimpleTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  maxHeight: PropTypes.string,
};

SimpleTable.defaultProps = {
  columns: null,
  data: null,
  maxHeight: 'inherit',
};

export default SimpleTable;
