import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  XYPlot,
  HorizontalGridLines,
  VerticalGridLines,
  HorizontalBarSeries,
  XAxis,
  YAxis,
  ChartLabel,
  Hint,
  DiscreteColorLegend,
  LabelSeries,
} from 'react-vis';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useTheme } from 'emotion-theming';

const Wrap = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.ledgedLabels ? `1fr auto` : `1fr`};
`;

const HorizontalBarChart = ({
  data,
  colorOffset,
  gridLinesVertical,
  gridLinesHorizontal,
  graphColor,
  colorType,
  height,
  hintFormat,
  labelX,
  labelY,
  showX,
  showY,
  ordinalX,
  ordinalY,
  yAxisFormat,
  xAxisFormat,
  ledgedLabels,
  labelData,
  labelFormat,
  hintDisabled,
  yTicks,
  leftPadding,
  xDomain,
}) => {
  const theme = useTheme();

  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);
  const [legendWidth, setLegendWidth] = useState(0);

  const topMargin = 20;
  const rightMargin = 20;
  const bottomMargin = showX && labelX ? 45 : 40;
  const leftMargin = (showY && labelY ? 45 : 40) + leftPadding;

  const legendRef = React.createRef();

  useEffect(() => {
    if (legendRef.current) {
      setLegendWidth(legendRef.current.getBoundingClientRect().width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const usedColorRange = theme.charts;
  if (colorOffset > 0) {
    const shiftedColors = usedColorRange.splice(0, colorOffset);
    usedColorRange.push(...shiftedColors);
  }

  return (
    <Wrap ledgedLabels={ledgedLabels}>
      <AutoSizer disableHeight>
        {({ width }) => (
          <div>
            <XYPlot
              color={graphColor}
              colorType={colorType}
              height={height}
              width={width - legendWidth}
              margin={{
                top: topMargin,
                right: rightMargin,
                bottom: bottomMargin,
                left: leftMargin,
              }}
              xType={ordinalX ? 'ordinal' : 'linear'}
              yType={ordinalY ? 'ordinal' : 'linear'}
              onMouseLeave={() => {
                setHint(false);
                setAnimation(true);
              }}
              xDomain={xDomain}
              // stackBy="x"
            >
              {/* Building Grid */}
              {gridLinesVertical && <VerticalGridLines />}
              {gridLinesHorizontal && <HorizontalGridLines />}

              {/* Building the X Axis */}
              {showX && (
                <XAxis tickFormat={(d) => (xAxisFormat ? xAxisFormat(d) : d)} />
              )}
              {showX && labelX && (
                // <ChartLabel
                //   text={labelX}
                //   xPercent={0.5}
                //   style={{
                //     transform: `translate(0 ${height - 49})`,
                //     fontWeight: theme.text.weight.bold,
                //     fontFamily: theme.text.family,
                //   }}
                // />
                <ChartLabel
                  text={labelX}
                  includeMargin
                  xPercent={0.5}
                  yPercent={0.4}
                  style={{
                    fontWeight: theme.text.weight.bold,
                    fontFamily: theme.text.family,
                  }}
                />
              )}

              {/* Building the Y Axis */}
              {showY && (
                <YAxis
                  tickFormat={(d) => (yAxisFormat ? yAxisFormat(d) : d)}
                  tickTotal={yTicks}
                />
              )}
              {showY && labelY && (
                <ChartLabel
                  text={labelY}
                  style={{
                    transform: 'rotate(-90) translate(-50 10)',
                    textAnchor: 'middle',
                    fontWeight: theme.text.weight.bold,
                    fontFamily: theme.text.family,
                    letterSpacing: '.1em',
                  }}
                />
              )}

              {/* Building the Series */}
              {data?.map((d, i) => {
                if (d.color) {
                  return (
                    <HorizontalBarSeries
                      key={i}
                      cluster="bar"
                      color={d.color}
                      data={d.setData}
                      animation={animation}
                      barWidth={0.5}
                      onValueMouseOver={(datapoint) => {
                        setAnimation(false);
                        setHint(datapoint);
                      }}
                      onValueMouseOut={() => {
                        setHint(false);
                        setAnimation(true);
                      }}
                      t
                    />
                  );
                }
                return (
                  <HorizontalBarSeries
                    key={i}
                    cluster="bar"
                    // color={d.color ? theme.charts[d.color] : theme.charts[(i + colorOffset) % theme.charts.length]}
                    data={d.setData}
                    animation={animation}
                    barWidth={0.5}
                    onValueMouseOver={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                    }}
                    onValueMouseOut={() => {
                      setHint(false);
                      setAnimation(true);
                    }}
                  />
                );
              })}
              {labelData && (
                <LabelSeries
                  data={labelData}
                  getLabel={(d) => (labelFormat ? labelFormat(d) : d.x)}
                  labelAnchorY="middle"
                  labelAnchorX="start"
                />
              )}

              {/* Building the Hint */}
              {!hintDisabled && hint && !hintFormat && <Hint value={hint} />}
              {!hintDisabled && hint && hintFormat && (
                <Hint value={hint}>
                  <div className="rv-hint__content">{hintFormat(hint)}</div>
                </Hint>
              )}
            </XYPlot>
          </div>
        )}
      </AutoSizer>
      {ledgedLabels && (
        <div ref={legendRef}>
          <DiscreteColorLegend
            items={ledgedLabels}
            // colors={usedColorRange}
          />
        </div>
      )}
    </Wrap>
  );
};

HorizontalBarChart.propTypes = {
  data: PropTypes.array,
  colorOffset: PropTypes.number,
  gridLinesHorizontal: PropTypes.bool,
  gridLinesVertical: PropTypes.bool,
  height: PropTypes.number,
  hintFormat: PropTypes.func,
  yAxisFormat: PropTypes.func,
  labelX: PropTypes.string,
  labelY: PropTypes.string,
  showX: PropTypes.bool,
  showY: PropTypes.bool,
  ordinalX: PropTypes.bool,
  ordinalY: PropTypes.bool,
  xAxisFormat: PropTypes.func,
  ledgedLabels: PropTypes.array,
  labelData: PropTypes.array,
  labelFormat: PropTypes.func,
  hintDisabled: PropTypes.bool,
  yTicks: PropTypes.number,
  leftPadding: PropTypes.number,
};

HorizontalBarChart.defaultProps = {
  data: null,
  colorOffset: 0,
  gridLinesHorizontal: true,
  gridLinesVertical: true,
  height: 200,
  hintFormat: null,
  yAxisFormat: null,
  labelX: null,
  labelY: null,
  showX: false,
  showY: false,
  ordinalX: false,
  ordinalY: false,
  xAxisFormat: null,
  ledgedLabels: null,
  labelData: null,
  labelFormat: null,
  hintDisabled: false,
  yTicks: null,
  leftPadding: 0,
};

export default HorizontalBarChart;
