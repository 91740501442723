import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { isIE } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';

const Wrap = styled.div`
  display: grid;
  grid-template-rows: ${({ disableHeader }) =>
    disableHeader ? '1fr auto' : 'auto 1fr auto'};
  min-height: 100vh;
  max-height: 100%;
`;

const Notification = styled.div`
  position: absolute;
  z-index: 1000;
  width: 50vw;
  left: 25vw;
  top: ${({ theme }) => theme.width.sm};
  --fa-primary-color: ${({ theme }) => theme.colors.clayRed.darker};
  --fa-secondary-color: ${({ theme }) => theme.colors.clayRed.dark};
  background-color: ${({ theme }) => theme.colors.clayRed.lightest};
  border-radius: ${({ theme }) => theme.radius};
  border: ${({ theme }) =>
    `solid ${theme.colors.clayRed.darker} ${theme.width.px}`};
  color: ${({ theme }) => theme.colors.clayRed.darker};
  margin: ${({ theme }) => theme.margin.none} ${({ theme }) => theme.margin.xxs};
  padding: ${({ theme }) => theme.padding.sm};
`;

const Main = ({ children, disableHeader }) => {
  if (isIE)
    return (
      <Notification>
        <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth /> IE is not
        supported. Please Use or Download Chrome, Edge or Firefox
      </Notification>
    );
  return (
    <>
      <Wrap disableHeader={disableHeader}>{children}</Wrap>
    </>
  );
};

Main.propTypes = {
  children: PropTypes.any,
  disableHeader: PropTypes.bool,
};

Main.defaultProps = {
  children: null,
  disableHeader: false,
};
export default Main;
