import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  faMinusSquare,
  faPlusSquare,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VisuallyHidden from '@reach/visually-hidden';

import { formatNumber } from '../../Utils/utils';
import Cell from './Cell';

const ExpandButton = styled.button`
  --fa-primary-color: ${({ theme }) => theme.buttons.green.border};
  --fa-secondary-color: ${({ theme }) => theme.buttons.green.hover.border};
  background-color: transparent;
  border-radius: ${({ theme }) => theme.radius};
  border: none;
  color: ${({ theme }) => theme.buttons.green.background};
  cursor: pointer;
  margin: ${({ theme }) => theme.margin.none};
  padding: ${({ theme }) => theme.padding.none};
  text-align: left;
  text-decoration: underline;
  transition: all 75ms cubic-bezier(0.4, 0, 1, 1);

  &:hover {
    color: ${({ theme }) => theme.buttons.green.hover.background};
  }
`;

const tag = (list, partNum, prop) =>
  list?.filter((x) => x.partNumber === partNum)?.[0]?.[prop];

const flatten = (arr) =>
  arr?.reduce(function (flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
    );
  }, []);

const parentCellText = (skip, amt, asCurrency = true) =>
  skip ? amt : formatNumber(amt, asCurrency, true);

const childCellText = (data, item, prop, asCurrency = true) =>
  formatNumber(tag(data, item, prop), asCurrency, true);

const Row = ({ 
  idx,
  description,
  prevQty,
  prevPrice,
  prevTotal,
  currQty,
  currPrice,
  currTotal,
  currYoY,
  nextQty,
  nextPrice,
  nextTotal,
  nextYoY,
  skipFormat,
  withExpansion,
  prevData,
  currData,
  nextData,
  settings,
}) => {
  const [open, setOpen] = useState(false);

  let fullList;
  if (withExpansion) {
    fullList = [
      ...new Set(
        flatten([prevData, currData, nextData])
          .filter((x) => x?.partNumber > 0)
          .map((x) => x?.partNumber)
      ),
    ];
  }

  const rowClass = (idx + 1) % 2 === 1 ? 'alt' : '';

  return (
    <>
      <tr className={rowClass}>
        <Cell alignment="left">
          {withExpansion ? (
            <ExpandButton type="button" onClick={() => setOpen(!open)}>
              <FontAwesomeIcon
                icon={open ? faMinusSquare : faPlusSquare}
                fixedWidth
              />
              <VisuallyHidden>{open ? "Collapse" : "Expand"}</VisuallyHidden>
              {description}
            </ExpandButton>
          ) : (
            description
          )}
        </Cell>
        {settings?.previousYearEnabled && (
          <>
            <Cell>{parentCellText(skipFormat, prevQty, false)}</Cell>
            <Cell>{parentCellText(skipFormat, prevPrice)}</Cell>
            <Cell divider>{parentCellText(skipFormat, prevTotal)}</Cell>
          </>
        )}
        <Cell>{parentCellText(skipFormat, currQty, false)}</Cell>
        <Cell>{parentCellText(skipFormat, currPrice)}</Cell>
        {currYoY ? (
          <Cell>{parentCellText(skipFormat, currTotal)}</Cell>
        ) : (
          <Cell divider>{parentCellText(skipFormat, currTotal)}</Cell>
        )}
        {currYoY && <Cell divider>{(currYoY)}</Cell>}
        {settings?.nextYearEnabled && (
          <>
            <Cell>{parentCellText(skipFormat, nextQty, false)}</Cell>
            <Cell>{parentCellText(skipFormat, nextPrice)}</Cell>
            {currYoY ? (
              <Cell>{parentCellText(skipFormat, nextTotal)}</Cell>
            ) : (
              <Cell divider>{parentCellText(skipFormat, nextTotal)}</Cell>
            )}
            {currYoY && <Cell>{(nextYoY)}</Cell>}
          </>
        )}
      </tr>
      {open &&
        fullList.map((x, fdx) => (
          <tr key={`${idx}-${fdx}`} className={rowClass}>
            <Cell>Part: {x}</Cell>
            {settings?.previousYearEnabled && (
              <>
                <Cell lined>{childCellText(prevData, x, "qty", false)}</Cell>
                <Cell lined>{childCellText(prevData, x, "price")}</Cell>
                <Cell lined divider>
                  {childCellText(prevData, x, "total")}
                </Cell>
              </>
            )}
            <Cell lined>{childCellText(currData, x, "qty", false)}</Cell>
            <Cell lined>{childCellText(currData, x, "price")}</Cell>
            <Cell lined divider>
              {childCellText(currData, x, "total")}
            </Cell>
            {settings?.nextYearEnabled && (
              <>
                <Cell lined>{childCellText(nextData, x, "qty", false)}</Cell>
                <Cell lined>{childCellText(nextData, x, "price")}</Cell>
                <Cell lined>{childCellText(nextData, x, "total")}</Cell>
              </>
            )}
          </tr>
        ))}
    </>
  );
};

Row.propTypes = {
  idx: PropTypes.number,
  description: PropTypes.string,
  prevQty: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  prevPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  prevTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currQty: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currYoY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nextQty: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nextPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nextTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currYoY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  skipFormat: PropTypes.bool,
  withExpansion: PropTypes.bool,
  prevData: PropTypes.any,
  currData: PropTypes.any,
  nextData: PropTypes.any,
  settings: PropTypes.any,
};

Row.defaultProps = {
  idx: 0,
  description: '',
  prevQty: null,
  prevPrice: null,
  prevTotal: null,
  currQty: null,
  currPrice: null,
  currTotal: null,
  currYoY: null,
  nextQty: null,
  nextPrice: null,
  nextTotal: null,
  nextYoY: null,
  skipFormat: false,
  withExpansion: false,
  prevData: null,
  currData: null,
  nextData: null,
  settings: null,
};

export default Row;
