import React from "react";
import AuthorizedCard from "../AuthorizedCard";
import "../../styles/Common.css";
import { H1, H2 } from "../Headings";
import {
  TimeIntervals,
  buildApiInternalUrl,
  formatNumber,
} from "../../Utils/utils";
import styled from "@emotion/styled";
import { TableStyle } from "../BaseTable";
import Cell from "../AppCTable/Cell";
import { faClock, faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import CardTopItems from "../CardTopItems";

const TableWrap = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;

  thead tr,
  tbody tr:nth-last-child(1) {
    background-color: #005c95;
    color: #ffffff;
  }

  thead th {
    overflow-wrap: break-word;
    white-space: pre-wrap !important;
  }
`;

const COBITMaturityTasksBreakdown = () => (
  <AuthorizedCard
    url={buildApiInternalUrl("cobit-internal")}
    refreshInterval={TimeIntervals.ONE_DAY}
    isFullWidth
    innerComponent={({ data, error }) => {
      if (!data) return <></>;
      if (data?.renderCard !== undefined && !data.renderCard) {
        return <></>;
      }

      const sortList = [
        "HIRT",
        "Defense Operations",
        "Defense Engineering",
        "Cyber Operations",
      ];
      let cardSorted = [];
      if (data) {
        cardSorted = data?.card2?.sort((a, b) => {
          return sortList.indexOf(a.teams) - sortList.indexOf(b.teams);
        });
      } else return null;
      const totaltools = cardSorted?.reduce(
        (acc, item) => acc + item.numberOfTools,
        0
      );
      const totalDocumentationComplete = cardSorted?.reduce(
        (acc, item) => acc + item.documentationComplete,
        0
      );
      const totalProcessOptimizationComplete = cardSorted?.reduce(
        (acc, item) => acc + item.processOptimizationComplete,
        0
      );
      const totalImplementationComplete = cardSorted?.reduce(
        (acc, item) => acc + item.implementationComplete,
        0
      );
      return (
        <>
          <CardTopItems
            items={[
              {
                text: `HIRT refers to the Hunting and Incident Response Team, which is 
responsible for proactively hunting for malicious cyber incidents. `,
                icon: faInfoCircle,
                style: {
                  whiteSpace: "pre-wrap",
                  overflowWrap: "break-word",
                  textAlign: "justify",
                },
              },
              {
                text: `As of ${new Date(data?.timeStamp).toLocaleString()}`,
                icon: faClock,
              },
            ]}
          />
          <H2 as="h3">COBIT Maturity Task Breakdown by Tool</H2>
          <section>
            <div className="Center-align">
              <H2 as="h4">Total Completed Maturity Tasks</H2>
            </div>
            <div className="TopHeader-Tasks">
              <div style={{ marginRight: "2rem" }}>
                <H1 as="span">
                  {formatNumber(data?.totalCompletedMaturityTasksCount)}
                </H1>
              </div>
              <div className="Small-Divider Divider"></div>
              <div style={{ marginLeft: "2rem" }}>
                <H1 as="span">
                  {(data?.totalCompletedMaturityTasksPercentage *
                    100)?.toFixed(1)}{" "}
                  %
                </H1>
              </div>
            </div>
          </section>
          <section>
            <TableWrap>
              <TableStyle color="blue" wrapped>
                <thead>
                  <tr>
                    <th className="No-Color"></th>
                    <th className="No-Color"> </th>
                    <Cell as="th" colspan="3" alignment="centre">
                      Maturity Tasks by Tool
                    </Cell>
                  </tr>
                  <tr>
                    <Cell as="th" alignment="left">
                      Teams
                    </Cell>
                    <Cell as="th" alignment="left">
                      Tools in Use
                    </Cell>
                    <Cell as="th" alignment="left">
                      Documentation Completed
                    </Cell>
                    <Cell as="th" alignment="left">
                      Process Optimatization Completed
                    </Cell>
                    <Cell as="th" alignment="left">
                      Implementation Completed
                    </Cell>
                  </tr>
                </thead>
                <tbody>
                  {cardSorted?.map((item) => {
                    return (
                      <tr>
                        <Cell as="td" alignment="left">
                          {item?.teams !== "" ? item?.teams : "-"}
                        </Cell>
                        <Cell as="td" alignment="right">
                          {formatNumber(
                            item?.numberOfTools,
                            false,
                            false,
                            false,
                            true
                          )}
                        </Cell>
                        <Cell as="td" alignment="right">
                          {formatNumber(
                            item?.documentationComplete,
                            false,
                            false,
                            false,
                            true
                          )}
                        </Cell>
                        <Cell as="td" alignment="right">
                          {formatNumber(
                            item?.processOptimizationComplete,
                            false,
                            false,
                            false,
                            true
                          )}
                        </Cell>
                        <Cell as="td" alignment="right">
                          {formatNumber(
                            item?.implementationComplete,
                            false,
                            false,
                            false,
                            true
                          )}
                        </Cell>
                      </tr>
                    );
                  })}
                  <tr>
                    <Cell as="td" alignment="right">
                      Total
                    </Cell>
                    <Cell as="td" alignment="right">
                      {formatNumber(totaltools, false, false, false, true)}
                    </Cell>
                    <Cell as="td" alignment="right">
                      {formatNumber(
                        totalDocumentationComplete,
                        false,
                        false,
                        false,
                        true
                      )}
                    </Cell>
                    <Cell as="td" alignment="right">
                      {formatNumber(
                        totalProcessOptimizationComplete,
                        false,
                        false,
                        false,
                        true
                      )}
                    </Cell>
                    <Cell as="td" alignment="right">
                      {formatNumber(
                        totalImplementationComplete,
                        false,
                        false,
                        false,
                        true
                      )}
                    </Cell>
                  </tr>
                </tbody>
              </TableStyle>
            </TableWrap>
          </section>
        </>
      );
    }}
  />
);

export default COBITMaturityTasksBreakdown;
