import React from 'react';
import { useTheme } from 'emotion-theming';

import AuthorizedCard from '../AuthorizedCard';
import Datagrid from '../Datagrid';
import Loader from '../Loader';

import { TimeIntervals, buildApiInternalUrl } from '../../Utils/utils';
import { H3 } from '../Headings';

const ProjectStatusReports = () => {
  const theme = useTheme();

  const YesNoCell = (field) => [
    {
      when: (row) => row[field] === 'Yes',
      style: {
        backgroundColor: theme.grid.green.background,
        color: theme.grid.green.text,
      },
    },
    {
      when: (row) => row[field] === 'No',
      style: {
        backgroundColor: theme.grid.red.background,
        color: theme.grid.red.text,
      },
    },
  ];

  const ColorCell = (field) => [
    {
      when: (row) => row[field] === 'Green',
      style: {
        backgroundColor: theme.grid.green.background,
        color: theme.grid.green.text,
      },
    },
    {
      when: (row) => row[field] === 'Red',
      style: {
        backgroundColor: theme.grid.red.background,
        color: theme.grid.red.text,
      },
    },
    {
      when: (row) => row[field] === 'Yellow',
      style: {
        backgroundColor: theme.grid.yellow.background,
        color: theme.grid.yellow.text,
      },
    },
  ];

  return (
    <AuthorizedCard
      url={buildApiInternalUrl('ProjectStatusReports')}
      refreshInterval={TimeIntervals.ONE_DAY}
      isFullWidth
      innerComponent={({ data, error }) => {
        if (!data) return <></>;
        if (data?.renderCard !== undefined && !data.renderCard) {
          return <></>;
        }
        const columns = [
          {
            name: 'Project',
            selector: 'LinkTitle',
            sortable: true,
            width: '400px',
          },
          {
            name: 'P Code',
            selector: 'PCode',
            sortable: true,
            width: '150px',
          },
          {
            name: 'Project Manager',
            selector: 'field_Project_x0020_Manager',
            sortable: true,
            width: '150px',
          },
          {
            name: 'Program Manager',
            selector: 'field_Program_x0020_Manager',
            sortable: true,
            width: '150px',
          },
          {
            name: 'Frequency',
            selector: 'Frequency',
            sortable: true,
            width: '100px',
          },
          {
            name: 'Date',
            selector: 'field_Date',
            sortable: true,
            width: '100px',
          },
          {
            name: 'Highlights This Period',
            selector: 'field_Highlights_x0020_This_x0020_Period',
            sortable: true,
            width: '170px',
            conditionalCellStyles: YesNoCell(
              'field_Highlights_x0020_This_x0020_Period'
            ),
          },
          {
            name: 'Milestone Tracker',
            selector: 'field_Milestone_x0020_Tracker',
            sortable: true,
            width: '170px',
            conditionalCellStyles: YesNoCell('field_Milestone_x0020_Tracker'),
          },
          {
            name: 'Project Health',
            selector: 'field_Project_x0020_Health',
            sortable: true,
            width: '170px',
            conditionalCellStyles: YesNoCell('field_Project_x0020_Health'),
          },
          {
            name: 'Last 5 Completed Tasks',
            selector: 'field_Last_x0020_5_x0020_Completed_x0020_Tasks',
            sortable: true,
            width: '200px',
            conditionalCellStyles: YesNoCell(
              'field_Last_x0020_5_x0020_Completed_x0020_Tasks'
            ),
          },
          {
            name: 'Next 5 Upcoming Tasks',
            selector: 'field_Next_x0020_5_x0020_Upcoming_x0020_Tasks',
            sortable: true,
            width: '200px',
            conditionalCellStyles: YesNoCell(
              'field_Next_x0020_5_x0020_Upcoming_x0020_Tasks'
            ),
          },
          {
            name: 'Upcoming Activities',
            selector: 'field_Upcoming_x0020_Activities',
            sortable: true,
            width: '200px',
            conditionalCellStyles: YesNoCell('field_Milestone_x0020_Tracker'),
          },
          {
            name: '5 Weeks Ago',
            selector: '_x0035_WeeksAgo',
            sortable: true,
            width: '200px',
            conditionalCellStyles: ColorCell('_x0035_WeeksAgo'),
          },
          {
            name: '4 Weeks Ago',
            selector: '_x0034_WeeksAgo',
            sortable: true,
            width: '200px',
            conditionalCellStyles: ColorCell('_x0034_WeeksAgo'),
          },
          {
            name: '3 Weeks Ago',
            selector: '_x0033_WeeksAgo',
            sortable: true,
            width: '200px',
            conditionalCellStyles: ColorCell('_x0033_WeeksAgo'),
          },
          {
            name: '2 Weeks Ago',
            selector: '_x0032_WeeksAgo',
            sortable: true,
            width: '200px',
            conditionalCellStyles: ColorCell('_x0032_WeeksAgo'),
          },
          {
            name: '1 Week Ago',
            selector: '_x0031_WeekAgo',
            sortable: true,
            width: '200px',
            conditionalCellStyles: ColorCell('_x0031_WeekAgo'),
          },
          {
            name: 'Current Status',
            selector: 'CurrentStatus1',
            sortable: true,
            width: '200px',
            conditionalCellStyles: ColorCell('CurrentStatus1'),
          },
          {
            name: 'Go Live',
            selector: 'field_Go_x0020_Live',
            sortable: true,
            width: '150px',
          },
          {
            name: 'Status Color in Email',
            selector: 'field_Status_x0020_Color_x0020_in_x0020_Email',
            sortable: true,
            width: '200px',
          },
          {
            name: 'Notes',
            selector: 'field_Notes',
            sortable: true,
            width: '400px',
          },
        ];

        return (
          <>
            <H3>Project Status Reports</H3>
            {error && <>There was an issue getting the data</>}
            {!data && !error && (
              <div style={{ textAlign: 'center' }}>
                <Loader />
              </div>
            )}
            {data && (
              <Datagrid
                columns={columns}
                data={data.items}
                expandable
                defaultSortField="agencyName"
                title="Project Status Reports"
              />
            )}
          </>
        );
      }}
    />
  );
};

export default ProjectStatusReports;
