import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const SelectStyle = styled.select`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.input.border};
  border-radius: ${({ theme }) => theme.radius};
  margin-bottom: ${({ theme }) => theme.padding.xs};
  padding: ${({ theme }) => `${theme.padding.sm} ${theme.padding.xs}`};
  width: 100%;

  &:focus {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.input.focus};
    outline: none;
  }
`;

const Select = ({ children, ...props }) => (
  <SelectStyle {...props}>{children}</SelectStyle>
);

Select.propTypes = { children: PropTypes.any };

export default Select;
