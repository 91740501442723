import React from 'react';
import styled from '@emotion/styled';

const FooterWrap = styled.footer`
  background-color: ${({ theme }) => theme.footer.background};
  color: ${({ theme }) => theme.footer.color};
  display: grid;
  grid-template-columns: 1fr;
  padding: ${({ theme }) => theme.width.md};
  grid-gap: ${({ theme }) => theme.width.md};
  ${(props) => props.theme.breakpoints.md} {
    grid-template-columns: 1fr 1fr;
  }
`;

const Left = styled.div`
  ${({ theme }) => theme.breakpoints.md} {
    justify-self: left;
  }

  ul {
    margin: 0;
    padding: 0;
    display: grid;
    grid-gap: ${({ theme }) => theme.width.sm};
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: baseline;

    ${({ theme }) => theme.breakpoints.md} {
      grid-template-columns: auto auto auto;
    }

    li {
      display: inline;
      list-style: none;

      &:first-of-type {
        grid-column: 1 / -1;
        font-size: ${({ theme }) => theme.text.size.lg};

        ${({ theme }) => theme.breakpoints.md} {
          grid-column: revert;
        }
      }

      a {
        color: ${({ theme }) => theme.footer.color};
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

        &:hover,
        &:visited,
        &:active {
          color: ${({ theme }) => theme.footer.color};
        }
      }
    }
  }
`;
const Right = styled.div`
  justify-self: center;

  ${(props) => props.theme.breakpoints.md} {
    justify-self: right;
  }
`;

const Footer = () => (
  <FooterWrap>
    <Left>
      <ul>
        <li>
          <a
            href="https://oklahoma.gov"
            target="_blank"
            rel="noopener noreferrer"
          >
            Oklahoma.gov
          </a>
        </li>
        <li>
          <a
            href="https://oklahoma.gov/about/policy-disclaimers.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Policies
          </a>
        </li>
        <li>
          <a
            href="https://oklahoma.gov/about/accessibility-policy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Accessibility
          </a>
        </li>
      </ul>
    </Left>
    <Right>Copyright © {new Date().getFullYear()} State of Oklahoma</Right>
  </FooterWrap>
);

Footer.propTypes = {};

export default Footer;
