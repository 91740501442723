import React from "react";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { CheckEmployeeViewRole } from "../../Utils/Roles";
import { authProvider } from "../../authProvider";
import ServerMetrics from "./ServerMetrics";
import ServerEnv from "./ServerEnv";
import ColumnSection from "../ColumnSection";
import CloudSpend from "./CloudSpend";
import AzureCost from "../AzureCostHistory/AzureCost";
import "../../styles/ServerData.css";

const ServerData = ({ role }) => {
  return (
    <div className="ServerData">
      <section className="ServerMetrics">
        <ColumnSection cols={3}>
          <ServerMetrics
            which="/is-server-data-card1.json"
            title="Servers with Monitoring"
          />

          <ServerMetrics
            which="/is-server-data-card2.json"
            title="Agencies on State Domain"
          />
        </ColumnSection>
      </section>

      <section className="ServerCloudSpend">
        <ColumnSection cols={2}>
          <ServerEnv />
          <AzureAD provider={authProvider}>
            {({ authenticationState }) => {
              return (
                authenticationState === AuthenticationState.Authenticated &&
                CheckEmployeeViewRole(role) && <CloudSpend />
              );
            }}
          </AzureAD>
        </ColumnSection>
      </section>

      <section className="AzureCost">
        <ColumnSection cols={2}>
        <AzureAD provider={authProvider}>
            {({ authenticationState }) => {
              return (
                authenticationState === AuthenticationState.Authenticated &&
                CheckEmployeeViewRole(role) &&  <AzureCost />
              );
            }}
          </AzureAD>
         
        </ColumnSection>
      </section>
    </div>
  );
};

export default ServerData;
