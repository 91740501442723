import React from 'react';
import PropTypes from 'prop-types';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Loader = ({ size }) => (
  <FontAwesomeIcon icon={faSpinner} fixedWidth pulse size={size} />
);

Loader.propTypes = { size: PropTypes.string };

Loader.defaultProps = { size: '10x' };

export default Loader;
