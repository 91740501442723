import React from "react";
import styled from "@emotion/styled";
import { H2, H3, H4 } from "../Headings";
import Formula from "./Formula";
import Button from "../Button";

const TitleButtonWrapp = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

const RateSection = ({
  title,
  text,
  multiplier,
  numerator = "Total Cost",
  denominator = "Total Quantity",
  equals = "Billed Amount",
  equalsAppService = "Appropriated Amount",
  disclaimer = null,
  noFormula = false,
  appServices = false,
}) => (
  <>
    {title && <H4>{title}</H4>}
    {text && <p>{text}</p>}
    {!noFormula && (
      <Formula
        numerator={numerator}
        multiplier={multiplier}
        equals={appServices ? equalsAppService : equals}
        denominator={denominator}
      />
    )}
    {disclaimer && (
      <p>
        <em>{disclaimer}</em>
      </p>
    )}
  </>
);

const RateMethodology = () => (
  <>
    <TitleButtonWrapp>
      <H2>App C Rate Methodology</H2>
      <Button
        as="a"
        color="blue"
        size="small"
        href={`${process.env.REACT_APP_PUBLIC_STORE}/RateMethodology.pdf`}
        target="_blank"
        rel="noopener noreferrer"
      >
        View Rate Methodology
      </Button>
    </TitleButtonWrapp>

    <H3>Billed Services</H3>
    <RateSection
      title="FTE Budgeted Average*"
      text="Essential products and services billed by full time employee budgeted average. Includes desktop support, Microsoft Office, email, storage, backup, connectivity, human capital management, etc."
      multiplier="FTE Budgeted Averaged*"
      disclaimer={
        <>∗Some job families may be exempt from count for services.</>
      }
    />
    <RateSection
      title="Named User"
      text="Additional, non-essential products and services billed per user. Includes licensing"
      multiplier="Named User Quantity"
    />
    <RateSection
      title="Pass Thru"
      text="Services and products delivered at cost with no markup. Includes services directly from vendor such as cabling, online transactions, etc."
      multiplier="Quantity"
    />
    <RateSection
      title="Physical Address"
      text="Additional, products and services billed by location. Includes network connectivity."
      multiplier="Physical Address Quantity"
    />
    <RateSection
      title="Professional Services"
      text="Services billed hourly based on skill level."
      multiplier="Billable Hours Worked"
      numerator="Hourly Rate"
      denominator={null}
    />
    <RateSection
      title="Unit"
      text="Additional products and services billed by tangible count."
      multiplier="Unit Quantity"
    />
    <RateSection
      title="Other"
      text="Services and products delivered to maintain various agency applications and platforms."
      multiplier="Other Quantity"
    />
    <H3>Appropriated Services*</H3>
    <RateSection
      title="Device as a Service"
      text="End user support services appropriated at a total of $15.8 million. Includes PC support, Tech Cafes, computer orders and deployments, mobile device management, software installs and print support."
      multiplier="FTE Budgeted Average*"
      appServices={true}
    />
    <RateSection
      title="Disaster Recovery"
      text="Business continuity services appropriated at a total of $18.1 million. Includes backup, recovery, failover site, network redundancy, and monitoring."
      multiplier="FTE Budgeted Average*"
      appServices={true}
    />
    <RateSection
      title="Financials"
      text="Cloud-based financial supply chain services software appropriated at a total of $8.1 million. Includes ability to procure, invoice, bill, collect and store financial data as well as maintenance and support."
      multiplier="FTE Budgeted Average*"
      appServices={true}
    />
    <RateSection
      title="State Data Center Facilities"
      text="Facilities maintenance and support services appropriated at a total of $6.7 million. Includes data center, warehouse, related utilities, uninterruptible power supply units, water towers, generators, etc."
      multiplier="FTE Budgeted Average*"
      appServices={true}
    />
    <RateSection
      title="Security"
      text="Safeguarding and protecting state data and applications appropriated at a total of $6.8 million. Includes cybersecurity operations, compliance checks, risk assessment, security review, etc."
      multiplier="FTE Budgeted Average*"
      appServices={true}
    />
    <RateSection
      title="Service Desk Support"
      text="Technology support services appropriated at a total of $3.2 million. Includes email and phone support, chat support, support portal, ticketing system, call system, etc."
      multiplier="FTE Budgeted Average*"
      appServices={true}
    />
  </>
);

export default RateMethodology;
