import React from "react";
import useSWR from "swr";
import {
  TimeIntervals,
  fetcherWithAuth,
  buildApiInternalUrl,
} from "../../../../Utils/utils";
import LiabilityClaimPercent from "./LiabilityClaimPercent";

const LiabilityClaimPercentInternal = () => {
    const { data : racclaimsinternal, error : racclaimserror } = useSWR(buildApiInternalUrl('rac-claims-close'), fetcherWithAuth, {
        refreshInterval: TimeIntervals.ONE_DAY,
        shouldRetryOnError: false,
        revalidateOnFocus: false,
      });
  let finalData = racclaimsinternal;
  
  if (!finalData && !racclaimserror && !racclaimserror?.status) {
    return <></>;
  }

  if (finalData?.renderCard !== undefined && !finalData.renderCard) {
    return <></>;
  }

  return <LiabilityClaimPercent data={finalData} />;
};

export default LiabilityClaimPercentInternal;
