import React from "react";
import SectionHeading from "../SectionHeading";
import ColumnSection from "../ColumnSection";
import QAProjects from "./QAProjects";
import QAAccessibility from "./QAAccessibility";

const QAMetrics = () => {
  return (
    <>
      <SectionHeading
        title="Quality Assurance"
        description="Number of current fiscal year agency projects and accessibility reviews in which OMES QA services are engaged."
      />
      <ColumnSection cols={2}>
        <QAProjects />
        <QAAccessibility />
      </ColumnSection>
    </>
  );
};

export default QAMetrics;
