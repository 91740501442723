import React from "react";
import styled from "@emotion/styled";
import IFrame from "react-iframe";
import AuthorizedCard from '../../AuthorizedCard';
import { TimeIntervals, buildApiInternalUrl} from "../../../Utils/utils";

const IFrameWrap = styled.div`
  display: none;
  grid-column: 1 / -1;

  ${({ theme }) => theme.breakpoints.md} {
    display: initial;
  }
`;

const Frame = styled(IFrame)`
  height: 550px;
`;

const OpenRecords = () => {
  return (
    <>
     <AuthorizedCard
      url={buildApiInternalUrl('rac-power-bi')} 
      refreshInterval={TimeIntervals.ONE_DAY}
      isFullWidth
      innerComponent={({ data, error }) => {
        if (!data) return <></>;
        if (data?.renderCard !== undefined && !data.renderCard) {
          return <></>;
        }
        return (
          <IFrameWrap>
        <Frame
          url="https://app.powerbigov.us/view?r=eyJrIjoiNDJlN2Y0MWYtZTdiMC00MzM1LWIwNmQtYjUxMjA0YWNjODFjIiwidCI6IjlhMzA3ODY0LTNlOTgtNGYwOC1iOTBhLTcyOGI2MmNmMzJjNSJ9"
          width="100%"
          height="100%"
        />
      </IFrameWrap> 
        )
      
      }} 
      />

    </>
  );
};

export default OpenRecords;
