import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { RadialChart as VisRadialChart, Hint } from 'react-vis';
import AutoSizer from 'react-virtualized-auto-sizer';
import Legend from './Legend';
import ToolTipLegendItem from './ToolTipLegendItem';

const Wrap = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: ${({ hasLabels }) => (hasLabels ? '1fr auto' : '1fr')};
  grid-gap: ${({ theme }) => theme.padding.md};

  ${({ theme }) => theme.breakpoints.lg} {
    grid-template-rows: 1fr;
    grid-template-columns: ${({ hasLabels }) =>
      hasLabels ? '1fr auto' : '1fr'};

    [data-legend] > span {
      display: block;
    }
  }
`;

const RadialChart = ({
  data,
  hintFormat,
  innerRadius,
  padding,
  radius,
  legendLabels,
}) => {
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);
  const defaultColors = ['#12939A', '#79C7E3', '#1A3177', '#FF9833', '#EF5D28'];

  return (
    <Wrap hasLabels={legendLabels.length > 0}>
      <div>
        <AutoSizer disableHeight>
          {({ width }) => (
            <VisRadialChart
              innerRadius={innerRadius}
              radius={radius}
              getAngle={(d) => d.value}
              data={data}
              animation={animation}
              onValueMouseOver={(datapoint) => {
                setAnimation(false);
                setHint(datapoint);
              }}
              onValueMouseOut={() => {
                setHint(false);
                setAnimation(true);
              }}
              width={width}
              height={200}
              padAngle={padding}
            >
              {/* Building the Hint */}
              {hint && !hintFormat && <Hint value={hint} />}
              {hint && hintFormat && (
                <Hint value={hint}>
                  <div className="rv-hint__content">{hintFormat(hint)}</div>
                </Hint>
              )}
            </VisRadialChart>
          )}
        </AutoSizer>
      </div>
      {legendLabels && (
        <Legend>
          {legendLabels.map((x, i) => (
            <ToolTipLegendItem
              key={i}
              text={x}
              fill={defaultColors[i % defaultColors.length]}
              noBreak
            />
          ))}
        </Legend>
      )}
    </Wrap>
  );
};

const isBetween = function (props, propName, componentName) {
  const min = 0;
  const max = 1;
  if (props[propName] < 0 || props[propName] > 1) {
    // eslint-disable-next-line max-len
    return new Error(
      `Invalid prop ${propName} passed to ${componentName}. Expected a number between ${min} and ${max}.`
    );
  }
};

RadialChart.propTypes = {
  data: PropTypes.array,
  hintFormat: PropTypes.func,
  innerRadius: PropTypes.number,
  padding: isBetween,
  radius: PropTypes.number,
  legendLabels: PropTypes.array,
};

RadialChart.defaultProps = {
  data: null,
  hintFormat: null,
  innerRadius: 0,
  padding: 0.04,
  radius: 100,
  legendLabels: null,
};

export default RadialChart;
