import React, { useState } from "react";
import useSWR from "swr";
import { useTheme } from "emotion-theming";
import CardInnerWithTime from "../Components/CardInnerWithTime";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  TimeIntervals,
  buildApiInternalUrl,
  getMonthName,
} from "../Utils/utils.js";
import {
  ChartLabel,
  Hint,
  HorizontalGridLines,
  LineMarkSeries,
  XAxis,
  XYPlot,
  YAxis,
} from "react-vis";
import TooltipItem from "../Components/TooltipItem";
import Card from "../Components/Card";
import CardTopItems from "../Components/CardTopItems";
import { faClock } from "@fortawesome/pro-duotone-svg-icons";
import "../styles/CustomerSatisfactionScore.css";
import AuthorizedArea from "../Components/AuthorizedArea";

const InnerComponent = ({ data }) => {
  const theme = useTheme();
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);
  const csatData = data?.iscsatData;
  const dataMap = new Map();
  let xValue;

  // To consolidate the no. of satisfied and unsatisfaied responses in a month.
  csatData.forEach((data) => {
    let graphData = {
      y: 0,
      date: "",
      satisfiedResponse: 0,
      otherResponse: 0,
      totalResponse: 0,
    };
    const month = getMonthName(data.month);
    const year = data.year;

    graphData.date = `${month} ${year}`;
    xValue = `${month?.slice(0, 3)} ${year}`;
    
    const rating = data?.satisfiedorVerySatisfied;
    const val = dataMap.get(xValue);

    switch (rating) {
      case "SatisfiedorVerySatisfiedRating":
        if (val) {
          const satisfiedResponse = data?.count;
          graphData.y = (
            (satisfiedResponse / (satisfiedResponse + val?.otherResponse)) *
            100
          ).toFixed(2);
        } else graphData.satisfiedResponse = data?.count;
        break;
      case "OtherRating":
        if (val) {
          const otherResponse = data?.count;
          graphData.y = (
            (val?.satisfiedResponse /
              (val?.satisfiedResponse + otherResponse)) *
            100
          ).toFixed(2);
        } else graphData.totalResponse = data?.count;
        break;
    }

    dataMap.set(xValue, graphData);
  });

  // Convert the map to array.
  const dataArr = Array.from(dataMap, ([x, { y, date }]) => ({
    x,
    y,
    date,
  })).slice(-12);

  // Return.
  return (
    <Card className="Card-Margin-Top csat-metric--card">
      <CardTopItems
        items={[
          {
            text: `As of ${new Date(data?.timeStamp).toLocaleString()}`,
            icon: faClock,
          },
        ]}
      />
      <div className="csat-metric--wrapper">
        <CardInnerWithTime
          loading={!data}
          title="IS Customer Satisfaction Score"
          refreshedTime={data?.reported ?? ""}
          mainComponent={() => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <XYPlot
                  width={width}
                  height={200}
                  xType="ordinal"
                  margin={{
                    left: 65,
                  }}
                  yDomain={[0, 100]}
                  onMouseLeave={() => {
                    setHint(false);
                    setAnimation(true);
                  }}
                  style={{ marginTop: "1.25rem" }}
                >
                  <HorizontalGridLines />
                  <XAxis tickLabelAngle={-45} />
                  <YAxis />
                  <ChartLabel
                    text="Satisfaction Score in (%)"
                    className="alt-y-label"
                    includeMargin={false}
                    style={{
                      transform: "rotate(-90)",
                      textAnchor: "end",
                    }}
                  />
                  <LineMarkSeries
                    data={dataArr}
                    animation={animation}
                    color={theme.colors.skyBlue.darken}
                    onNearestX={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                    }}
                    onValueMouseOut={() => {
                      setHint(false);
                    }}
                  />
                  {hint && (
                    <Hint value={hint}>
                      <div
                        className="rv-hint__content"
                        style={{ pointerEvents: "none" }}
                      >
                        <TooltipItem
                          fill={hint.color}
                          text={`${hint.date}: ${hint.y}%`}
                        />
                      </div>
                    </Hint>
                  )}
                </XYPlot>
              )}
            </AutoSizer>
          )}
        />
      </div>
    </Card>
  );
};

const CustomerSatisfactionScore = () => (
  <AuthorizedArea
    url={buildApiInternalUrl("is-csat-opt")}
    refreshInterval={TimeIntervals.ONE_DAY}
    innerComponent={({ data, error }) => {
      if (data === undefined) {
        return <></>;
      }
      if (!data && !error && !error?.status) {
        return <></>;
      }
      if (data?.renderCard !== undefined && !data.renderCard) {
        return <></>;
      }
      return <InnerComponent data={data} />;
    }}
  />
);

export default CustomerSatisfactionScore;
