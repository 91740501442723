import React from "react";
import CardInnerWithTime from "../../CardInnerWithTime";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import "../../../styles/SocialMediaAccount.css";
import MediaData from "./MediaData";

function daysInMonth(month, year) {
  var monthNum = new Date(Date.parse(month + " 1," + year)).getMonth() + 1;
  return new Date(year, monthNum, 0).getDate();
}

const SocialMediaAccount = ({ data, mediaData, mediaName }) => {
  let {
    followers,
    followersTrend,
    impressions,
    impressionsTrend,
    engagement,
    engagementTrend,
    videoViews,
    videoViewsTrend,
    month,
    profile,
  } = mediaData;

  let currentMonth = month?.split(" ")[0];
  let currentYear = month?.split(" ")[1];
  let currentDays = daysInMonth(currentMonth, currentYear);

  return (
    <div className="SocialMediaAccount">
      <CardInnerWithTime
        loading={!data}
        title={`${mediaName} Data & Trends`}
        subtitle={profile && `Profile: ${profile}`}
        refreshedTime={data?.timeStamp ?? ""}
        extraIcons={[
          {
            text: `• Followers reflect the size of audience on account/profile.
• Impressions indicate the number of content views.
• Engagement represents the number of interactions/clicks
  with the content.
• Video views are the amount of views video content has.`,
            icon: faInfoCircle,
            style: {
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              textAlign: "justify",
            },
          },
        ]}
        mainComponent={() => (
          <>
            <div class="MediaGrid">
              <div class="MediaGridItem">
                <MediaData
                  heading={
                    mediaName === "YouTube" ? "Subscribers" : "Followers"
                  }
                  mediaName={mediaName}
                  mediaCount={followers}
                  mediaTrend={followersTrend}
                  month={month}
                  currentDays={currentDays}
                  currentMonth={currentMonth}
                  currentYear={currentYear}
                />
              </div>
              <div class="MediaGridItem">
                <MediaData
                  heading={`Impressions`}
                  mediaName={mediaName}
                  mediaCount={impressions}
                  mediaTrend={impressionsTrend}
                  month={month}
                />
              </div>
              <div class="MediaGridItem">
                <MediaData
                  heading={`Engagement (Clicks)`}
                  mediaName={mediaName}
                  mediaCount={engagement}
                  mediaTrend={engagementTrend}
                  month={month}
                />
              </div>
              <div class="MediaGridItem">
                <MediaData
                  heading={`Video Views`}
                  mediaName={mediaName}
                  mediaCount={videoViews}
                  mediaTrend={videoViewsTrend}
                  month={month}
                />
              </div>
            </div>
          </>
        )}
      />
    </div>
  );
};

export default SocialMediaAccount;
