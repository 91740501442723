import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Card from '../Card';
import CardInnerWithTime from '../CardInnerWithTime';
import { H1 } from '../Headings';

const SingleCard = ({ title, data, which }) => {
  // const singlePoint = data.CyberOperationsClosedYesterday.filter(
  //   (x) => x.Label === which
  // )[0];
  const singlePoint = data;
  return (
    <Card>
      <CardInnerWithTime
        title={title}
        refreshedTime={singlePoint.reported}
        mainComponent={() => (
          <H1 as="span">
            <NumberFormat
              value={singlePoint.count}
              displayType="text"
              thousandSeparator
            />
          </H1>
        )}
      />
    </Card>
  );
};

SingleCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      Label: PropTypes.string,
      Reported: PropTypes.any,
      Count: PropTypes.number,
    })
  ),
  which: PropTypes.string,
};

export default SingleCard;
