import React from 'react';
import Reality from '../Components/Cards/Reality';
import SpaceReduction from '../Components/Cards/SpaceReduction';
import ColumnSection from '../Components/ColumnSection';

const Reals = () => (
  <ColumnSection cols={1}>
    <Reality />
    <SpaceReduction />
  </ColumnSection>
);

export default Reals;
