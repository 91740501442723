import React from "react";
import NumberFormat from "react-number-format";
import styled from "@emotion/styled";

import { H1 } from "../Headings";
import CardInnerWithTime from "../CardInnerWithTime";

import { TimeIntervals, fetcher } from "../../Utils/utils";
import useSWR from "swr";
import Card from "../Card";

const Wrapper = styled.div`
  text-align: center;
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
`;

const Bottom = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.width.xs};
  text-align: center;
  margin: ${({ theme }) =>
    `${theme.padding.sm} -${theme.margin.md} -${theme.margin.sm}`};
  padding: ${({ theme }) => `${theme.padding.sm} ${theme.padding.md}`};
  background-color: ${({ theme }) => theme.card.well.background};
  border-top: ${({ theme }) => `${theme.width.px} solid ${theme.card.border}`};
`;

const NetworkUptime = ({ which, title, goal }) => {
  const { data } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}${which}`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  return (
    <>
      {data?.RenderCard && (
        <Card>
          <Wrapper>
            <CardInnerWithTime
              title={title}
              mainComponent={() => (
                <NumberFormat
                  value={data?.Uptime * 100}
                  displayType="text"
                  renderText={(value) => <H1 as="span">{value}</H1>}
                  suffix="%"
                  decimalScale={2}
                />
              )}
              loading={data === null}
              refreshedTime={data?.Reported}
            />
            <Bottom>{goal}</Bottom>
          </Wrapper>
        </Card>
      )}
    </>
  );
};

export default NetworkUptime;
