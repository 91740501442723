import React  from 'react';

import './Microsoft365UsageChart.css';
import { H1 } from '../Headings';

const UsageChartComponent = ({data,label}) => {
    
    return (

        <div className='UsageComponent'>
        <h4 className='UsageLabel'>{label}</h4>
            <H1 className='UsageContent' as="span">{data?.Count}</H1>
            <h4 className='UsageTimeStamp'> last 7 days</h4>
        </div>
    );
};

export default UsageChartComponent;
