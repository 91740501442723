import React, { useEffect, useState } from "react";
import Card from "../../Card";
import CardInnerWithTime from "../../CardInnerWithTime";
import AuthorizedArea from "../../AuthorizedArea";
import { TimeIntervals, buildApiInternalUrl } from "../../../Utils/utils";
import AutoSizer from "react-virtualized-auto-sizer";
import TooltipItem from "../../TooltipItem";
import { formatNumber, monthsList } from "../../../Utils/utils";
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalBarSeries,
  Crosshair,
} from "react-vis";
import "../../../styles/Common.css";
import "../../../styles/ProvisioningResolutionTime.css";

const changeDateFormat = (month, year) => {
  let newDate = `${monthsList[month - 1]} ${year}`;
  return newDate;
};

const InnerComponent = ({ data }) => {
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);
  const [crosshairValues, setCrosshairValues] = useState([]);
  const [monthlyArray, setMonthlyArray] = useState([]);

  useEffect(() => {
    var past12months = [];
    var currentDate = new Date();

    // to get previous 12 months
    for (var i = 0; i <= 11; i++) {
      let changedDateFormat = `${
        monthsList[currentDate.getMonth()]
      } ${currentDate.getFullYear()}`;

      past12months.push(changedDateFormat);
      currentDate.setMonth(currentDate.getMonth() - 1);
    }

    if (past12months.length > 0) {
      past12months = past12months.reverse();
      for (var i = 0; i < past12months.length; i++) {
        let filteredData = data?.monthlyResolution
          .filter(
            (item) =>
              changeDateFormat(item.month, item.year) === past12months[i]
          )
          .reduce((acc, item) => acc + item.count, 0);

        let newObj = {
          x: `${past12months[i].slice(0, 3)} ${past12months[i].slice(
            past12months[i].length - 4
          )}`,
          y: filteredData,
          monthName: past12months[i].split(" ")[0],
        };
        setMonthlyArray((prevArr) => [...prevArr, newObj]);
      }
    }
  }, [data]);

  let copyMonthlyArray = [...monthlyArray];
  let sortedArray = copyMonthlyArray?.sort((a, b) => b.y - a.y);
  let highestValue = sortedArray[0]?.y * 1.4;

  return (
    <Card className="ActualResTime">
      <CardInnerWithTime
        loading={!data}
        title={`Provisioning | Monthly Average Resolution Time`}
        refreshedTime={data?.timeStamp ?? ""}
        mainComponent={() => (
          <div className="MonthlyChart">
            <AutoSizer disableHeight>
              {({ width }) => (
                <XYPlot
                  width={width}
                  height={200}
                  margin={{
                    left: 70,
                  }}
                  xType="ordinal"
                  yDomain={[0, highestValue]}
                  onMouseLeave={() => {
                    setCrosshairValues(false);
                  }}
                >
                  <HorizontalGridLines />
                  <XAxis tickLabelAngle={-45} />
                  <YAxis
                    title="Average duration (Hours)"
                    tickFormat={(d) => `${formatNumber(d)}`}
                  />
                  <VerticalBarSeries
                    color={"#005c95"}
                    data={monthlyArray}
                    animation={animation}
                    onValueMouseOver={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                      setCrosshairValues([datapoint]);
                    }}
                    onValueMouseOut={() => {
                      setHint(false);
                      setAnimation(true);
                    }}
                  />

                  {crosshairValues && (
                    <Crosshair values={crosshairValues}>
                      <div className="ShowHint">
                        <TooltipItem
                          fill="#005c95"
                          text={`${hint.x}: ${formatNumber(hint.y)}`}
                        />
                      </div>
                    </Crosshair>
                  )}
                </XYPlot>
              )}
            </AutoSizer>
          </div>
        )}
      />
    </Card>
  );
};

const MonthlyResTime = () => (
  <AuthorizedArea
    url={buildApiInternalUrl("is-resolution-time-monthly")}
    refreshInterval={TimeIntervals.ONE_DAY}
    innerComponent={({ data, error }) => {
      if (!data && !error && !error?.status) {
        return <></>;
      }
      if (data?.renderCard !== undefined && !data.renderCard) {
        return <></>;
      }
      return <InnerComponent data={data} />;
    }}
  />
);

export default MonthlyResTime;
