import React, { useState } from "react";
import useSWR from "swr";
import Card from "../../Card";
import CardInnerWithTime from "../../CardInnerWithTime";
import TooltipItem from "../../TooltipItem";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { TimeIntervals, fetcher, formatNumber } from "../../../Utils/utils";
import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  VerticalBarSeries,
  DiscreteColorLegend,
  Crosshair,
  HorizontalGridLines,
} from "react-vis";
import "../../../styles/Common.css";
import "../../../styles/BudgetCompliance.css";

const InnerComponent = ({ data, setIsFiscalYearRender }) => {
  setIsFiscalYearRender(data?.renderCard);

  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);
  const [crosshairValues, setCrosshairValues] = useState([]);

  let extractedData = [];
  let years = [];

  const changeYear = (year) => {
    let yearString = "" + year;
    yearString = yearString.substring(2);
    return "FY " + yearString;
  };

  for (let i = 0; i < data?.tribeTotals.length; i++) {
    years.push(data.tribeTotals[i].year);
    extractedData.push({
      x: changeYear(data.tribeTotals[i].year),
      y: data.tribeTotals[i].total,
    });
  }

  let dataWithColor = extractedData.map((d, i) => ({
    ...d,
    color: extractedData[i - 1]?.y > d.y ? "red" : "green",
  }));

  if (dataWithColor.length > 6) {
    dataWithColor.reverse().length = 6;
    dataWithColor = dataWithColor.reverse();

    extractedData.reverse().length = 6;
    extractedData = extractedData.reverse();

    years.reverse().length = 6;
    years = years.reverse();
  }

  const maxValue =
    data?.tribeTotals?.length > 0 &&
    Math.max(...data?.tribeTotals?.map((d) => d.total));
  let yDomainTopValue = [0, 1.2 * maxValue];

  return (
    <Card className="TotalFeesChart">
      <CardInnerWithTime
        loading={!data}
        title="Total Exclusivity Fees by Fiscal Year"
        refreshedTime={data?.timeStamp ?? ""}
        extraIcons={[
          {
            text: `Gaming Compliance oversees and monitors the collection of exclusivity  
fees from tribes to the state pursuant to the Tribal Gaming Compact.`,
            icon: faInfoCircle,
            style: {
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              textAlign: "justify",
            },
          },
        ]}
        mainComponent={() => (
          <FlexibleWidthXYPlot
            height={200}
            xType="ordinal"
            colorType="literal"
            margin={{
              left: 60,
            }}
            onMouseLeave={() => {
              setCrosshairValues(false);
            }}
            yDomain={yDomainTopValue}
          >
            <HorizontalGridLines />
            <XAxis />
            <YAxis tickFormat={(d) => formatNumber(d / 1000) + "k"} />
            <VerticalBarSeries
              data={dataWithColor}
              animation={animation}
              onValueMouseOver={(datapoint) => {
                setAnimation(false);
                setHint(datapoint);
                setCrosshairValues([datapoint]);
              }}
              onValueMouseOut={() => {
                setHint(false);
                setAnimation(true);
                setCrosshairValues(false);
              }}
            />

            {crosshairValues && (
              <Crosshair values={crosshairValues}>
                <div className="ShowHint">
                  <TooltipItem
                    fill={hint.color}
                    text={`${hint.x}: $${formatNumber(hint.y?.toFixed(2))}`}
                  />
                </div>
              </Crosshair>
            )}
          </FlexibleWidthXYPlot>
        )}
      />
      <DiscreteColorLegend
        items={[
          {
            title: "Increase relative to previous fiscal year",
            color: "green",
          },
          {
            title: "Decrease relative to previous fiscal year",
            color: "red",
          },
        ]}
        orientation="vertical"
      />
    </Card>
  );
};

const FeesByFiscalYear = ({ setIsFiscalYearRender }) => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/tribe-totals.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return (
    <InnerComponent data={data} setIsFiscalYearRender={setIsFiscalYearRender} />
  );
};

export default FeesByFiscalYear;
