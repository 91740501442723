import React from "react";
import { H2 } from "../Headings";
import styled from "@emotion/styled";
import Button from "../Button";

const ResourceReporting = () => {
  const Wrap = styled.div`
    margin-bottom: 2rem;
  `;

  const ParaWrap = styled.div`
    margin-bottom: 1.5rem;
  `;

  return (
    <Wrap>
      <H2>Resource Reports</H2>
      <ParaWrap>
        Use this ServiceNow dashboard to view the forecasted hours &
        availability for various report types, groups, and members across the
        State.​
      </ParaWrap>

      <Button
        as="a"
        color="blue"
        size="small"
        href="https://oklahoma.service-now.com/now/nav/ui/classic/params/target/resource_reports.do%3Fsysparm_filters_enabled%3Dtrue%26sysparm_report_type%3Davailability"
        target="_blank"
        rel="noopener noreferrer"
      >
        View Resource Reports
      </Button>
    </Wrap>
  );
};

export default ResourceReporting;
