import React from 'react';
import PropTypes from 'prop-types';

const cnw = (cn, w) => (w ? `${cn} ` : '');

const Cell = ({
  children,
  as: As,
  lined,
  number,
  divider,
  alignment,
  bottomLined,
  ...rest
}) => (
  <As
    className={`${cnw('top-lined', lined)}${cnw('number', number)}${cnw(
      'right-divide',
      divider
    )}
    ${cnw('bottom-lined', bottomLined)}${alignment}`}
    {...rest}
  >
    {children}
  </As>
);

Cell.propTypes = {
  children: PropTypes.any,
  as: PropTypes.string,
  lined: PropTypes.bool,
  number: PropTypes.bool,
  divider: PropTypes.bool,
  alignment: PropTypes.string,
  bottomLined: PropTypes.bool,
};

Cell.defaultProps = {
  children: null,
  as: 'td',
  lined: false,
  number: true,
  divider: false,
  alignment: 'right',
  bottomLined: false,
};
export default Cell;
