import React from "react";
import styled from "@emotion/styled";
import NumberFormat from "react-number-format";
import useSWR from "swr";
import { faClock } from "@fortawesome/pro-duotone-svg-icons";
import Card from "../Card";
import { H3 } from "../Headings";
import ColumnSection from "../ColumnSection";
import { fetcher, TimeIntervals } from "../../Utils/utils";
import CardTopItems from "../CardTopItems";

const Wrapper = styled.div`
  display: grid;
  height: 100%;
  justify-items: ${({ load }) => (load ? "center" : `inherit`)};
`;

const InnerComponent = ({ data }) => {
  const topIcons = [
    {
      text: `As of ${new Date(data?.reported).toLocaleString()}`,
      icon: faClock,
    },
  ];

  return (
    <Card>
      <Wrapper>
        <CardTopItems items={topIcons} />
        <H3 centered>Major Incidents</H3>
        <div>
          <ColumnSection cols="1fr auto" customColumns>
            <strong>All:</strong>
            <span>{data?.majorCount}</span>
            <strong>Responded:</strong>
            <span>{data?.majorResolvedCount}</span>
            <hr style={{ gridColumn: "1 / -1", width: "100%" }} />
            <strong>Response Under 15 Min:</strong>
            <span>{data?.majorResolvedUnder15Count} </span>
            <strong>Percentage:</strong>
            <span>
              <NumberFormat
                value={data?.majorResolvedUnder15Perc * 100}
                displayType="text"
                suffix="%"
                decimalScale={2}
                fixedDecimalScale
                thousandSeparator
              />
            </span>
            <strong>Response Over 15 Min:</strong>
            <span>{data?.majorResolvedOver15Count}</span>
            <hr style={{ gridColumn: "1 / -1", width: "100%" }} />
            <strong>Resolved Under 240 Min:</strong>
            <span>{data?.majorResolvedUnder240Count}</span>
            <strong>Percentage:</strong>
            <span>
              <NumberFormat
                value={data?.majorResolvedUnder240Perc * 100}
                displayType="text"
                suffix="%"
                decimalScale={2}
                fixedDecimalScale
                thousandSeparator
              />
            </span>
            <strong>Resolved Over 240 Min:</strong>
            <span>{data?.majorResolvedOver240Count}</span>
          </ColumnSection>
        </div>
      </Wrapper>
    </Card>
  );
};

const MajorIncidents = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/ITOps.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.TEN_MINUTES,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (data?.renderCard !== undefined && !data?.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} />;
};

export default MajorIncidents;
