import React from "react";
import { TableStyle } from "../../BaseTable";
import Cell from "../../BalanceSheet/Cell";
import styled from "@emotion/styled";
import { formatNumber } from "../../../Utils/utils";
import CardInnerWithTime from "../../CardInnerWithTime";
import "../../../styles/CashReporting.css";

const FundsTable = ({
  heading,
  data,
  restrictedSum,
  unrestrictedSum,
  weekDate,
  timeStamp,
}) => {
  const TableWrap = styled.div`
    height: 100%;
    overflow: auto;
    width: 100%;

    thead tr,
    tbody tr:nth-last-child(1) {
      background-color: #005c95;
      color: #ffffff;
    }
  `;

  return (
    <div className="FundsTab">
      <CardInnerWithTime
        loading={!data}
        title={heading}
        refreshedTime={timeStamp ?? ""}
        mainComponent={() => (
          <>
            <TableWrap>
              <TableStyle color="blue" wrapped>
                <thead>
                  <tr>
                    <Cell as="th" alignment="left">
                      Fund
                    </Cell>
                    <Cell as="th" alignment="left">
                      Fund Name
                    </Cell>
                    <Cell as="th" alignment="left">
                      Balance ({weekDate})
                    </Cell>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item) => {
                    let { fundId, fundName, cashBalance } = item;
                    return (
                      <tr>
                        <Cell as="td" alignment="right">
                          {fundId}
                        </Cell>
                        <Cell as="td" alignment="left">
                          {fundName}
                        </Cell>
                        <Cell as="td" alignment="right">
                          {formatNumber(
                            cashBalance.toFixed(2),
                            true,
                            false,
                            false,
                            true
                          )}
                        </Cell>
                      </tr>
                    );
                  })}
                  <tr>
                    <Cell as="th" alignment="left"></Cell>
                    <Cell as="th" alignment="right">
                      Total
                    </Cell>
                    <Cell as="th" alignment="right">
                      {`${
                        heading === "Unrestricted Funds"
                          ? formatNumber(
                              unrestrictedSum?.toFixed(2),
                              true,
                              false,
                              false,
                              true
                            )
                          : formatNumber(
                              restrictedSum?.toFixed(2),
                              true,
                              false,
                              false,
                              true
                            )
                      }`}
                    </Cell>
                  </tr>
                </tbody>
              </TableStyle>
            </TableWrap>
          </>
        )}
      />
    </div>
  );
};

export default FundsTable;
