/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';

export const initializeGA = () => {
  ReactGA.initialize('UA-152953802-3');
};

export const logPageView = (history) => {
  history.listen((location) => {
    const page = location.pathname || window.location.pathname;
    ReactGA.set({ page });
    ReactGA.pageview(page);
  });
};

export const withTracker = (WrappedComponent) => (props) => {
  const history = useHistory();
  useEffect(() => {
    logPageView(history);
  }, [history]);

  return <WrappedComponent {...props} />;
};
