import React from "react";
import CyberOpsIncident from "./CyberOpsIncident";
import CyberOpsTasks from "./CyberOpsTasks";
import CyberOpsResults from "./CyberOpsResults";
const Index = () => {
  return (
        <>
          <p style={{ gridColumn: "1/-1", marginBottom: "0px" }}>
            Total incidents and tasks resolved by Cyber Operations over the
            past 24 hours.
          </p>
          <CyberOpsIncident />
          <CyberOpsTasks />
          <CyberOpsResults />
        </>
  );
  };

export default Index;
