import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Hint, RadialChart } from 'react-vis';
import { faClock } from '@fortawesome/pro-duotone-svg-icons';
import Card from '../Card';
import CardTopItems from '../CardTopItems';
import { H2 } from '../Headings';
import TooltipItem from '../TooltipItem';
import Legend from '../BaseCharts/Legend';
import { formatNumber } from '../../Utils/utils';

function capitalizeFirstLetter(string, onlylowercase) {
  if (onlylowercase) {
    return string;
  } else {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}

const Wrap = styled.div`
  display: grid;
`;

function generateColorForLabel(label) {
  // Generate a hash code for the label
  let hash = 0;
  for (let i = 0; i < label.length; i++) {
    hash = label.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Generate a color based on the hash code
  const hue = Math.abs(hash % 360);
  const saturation = 70;
  const lightness = 60;
  const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

  // Convert the color to RGB and reduce the saturation and lightness values
  const rgbColor = color
    .match(/\d+/g)
    .map(Number)
    .map((value, index) => (index === 1 || index === 2 ? Math.round(value * 0.7) : value));

  // Convert the RGB color back to a CSS color string
  return `rgb(${rgbColor.join(', ')})`;
}
const getNetworkTrafficcolors=[
  '#4C78A8', // blue
  '#D1495B', // pink
  '#FFA07A', // salmon
  '#A97C50', // brown
  '#F58518', // orange
  '#9EC1CF', // light blue
  '#E45756', // red
  '#7F7F7F', // teal
  '#46C7C7', // turquoise
  '#DC7EC0' // magenta
]


const getColor = (key) => {
  switch (key) {
    case 'Completed':
      return '#187bc0';
    case 'Not Started':
    case 'Not Started Medium':
      return '#de9027';
    case 'Follow-up Questions':
    case 'Completed Low':
      return '#d15420';
    case 'Completed High':
    case 'Rejected':
      return '#669b41';
    case 'Canceled':
    case 'Not Started Low':
      return '#1ca6df';
    case 'In-Progress':
      return '#787878';
    case 'Kick Back for Corrections':
    case 'Not Started High':
      return '#004e9a';
    case 'Completed Medium':
      return '#a96728';
    case 'In-Progress High':
      return '#914115';
    case 'In-Progress Medium':
      return '#326820';
    default:
      return generateColorForLabel(key);
  }
};

const SingleCard = ({ title, data, networkTrafficcolors, onlylowercase }) => {
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);

  const chartData = data
    ?.filter(
      (item) =>
        item.Label[0].toLowerCase() >= "a" && item.Label[0].toLowerCase() <= "z"
    )
    .map((x, index) => ({
      angle: x.Count,
      value: x.Count,
      color: networkTrafficcolors
        ? getNetworkTrafficcolors[index]
        : getColor(x.Label),
      text: capitalizeFirstLetter(x.Label, onlylowercase),
    }));

  return (
      <Card>
        <CardTopItems
          items={[
            {
              text: `As of ${new Date(data?.[0].Reported).toLocaleString()}`,
              icon: faClock,
            },
          ]}
        />
        <H2>{title}</H2>
        {(title === "Inbound Network Traffic" ||
        title === "Inbound Threat Traffic Dropped by Source") && (
        <i>
          Data reflects the prior 30 days. Due to scale, some pie segments may
          not appear on your browser. Chart legends will reflect true values.
        </i>
        )}
        {(title === "Outbound Internet Traffic by Category" ||
          title === "Outbound Internet Traffic by Destination") && (
          <i>Data reflects the prior 30 days.</i>
        )}
        <Wrap>
          <div>
            <AutoSizer disableHeight>
              {({ width }) => (
                <RadialChart
                  colorType="literal"
                  innerRadius={0}
                  radius={100}
                  data={chartData}
                  animation={animation}
                  onValueMouseOver={(datapoint) => {
                    setAnimation(false);
                    setHint(datapoint);
                  }}
                  onValueMouseOut={() => {
                    setHint(false);
                    setAnimation(true);
                  }}
                  width={width}
                  height={200}
                >
                  {hint && (
                    <Hint value={hint}>
                      <div
                        className="rv-hint__content"
                        style={{ pointerEvents: 'none' }}
                        >
                        <TooltipItem
                          fill={hint.color}
                          text={`${hint.text}: ${formatNumber(hint.value)}`}
                        />
                      </div>
                    </Hint>
                  )}
                </RadialChart>
              )}
            </AutoSizer>
          </div>
          <Legend>
            {/* eslint-disable-next-line react/prop-types */}
            {chartData?.map((x, i) => (
              <TooltipItem
                key={i}
                fill={x.color}
                text={`${x.text}: ${formatNumber(`${x.value}`)}`}
              />
            ))}
          </Legend>
        </Wrap>
      </Card>
  );
};

SingleCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      Label: PropTypes.string,
      Reported: PropTypes.any,
      Count: PropTypes.number,
    })
  ),
};

export default SingleCard;
