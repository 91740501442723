import React, { useState } from "react";
import styled from "@emotion/styled";
import AutoSizer from "react-virtualized-auto-sizer";
import { Hint, RadialChart } from "react-vis";
import { formatNumber } from "../../../Utils/utils";
import TooltipItem from "../../TooltipItem";
import Legend from "../../BaseCharts/Legend";
import "../../../styles/Common.css";
import "../../../styles/WeeklyNonTravel.css";

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;
const countDecimals = (value) => {
  if (Math.floor(value) === value) return 0;
  return value.toString().split(".")[1].length || 0;
};
const TPPieChart = ({ isTravel, travelData, nonTravelData, isCount }) => {
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);

  return (
    <Wrap>
      <div>
        <AutoSizer disableHeight>
          {({ width }) => (
            <RadialChart
              colorType="literal"
              innerRadius={0}
              radius={100}
              data={isTravel === "travel" ? travelData : nonTravelData}
              animation={animation}
              onValueMouseOver={(datapoint) => {
                setAnimation(false);
                setHint(datapoint);
              }}
              onValueMouseOut={() => {
                setHint(false);
                setAnimation(true);
              }}
              width={width}
              height={200}
            >
              {hint && (
                <Hint
                  value={hint}
                  align={{ horizontal: "right", vertical: "right" }}
                >
                  <div
                    className="rv-hint__content"
                    style={{ pointerEvents: "none" }}
                  >
                    <TooltipItem
                      fill={hint.color}
                      text={`${hint.text}: ${
                        isCount === "TPCount"
                          ? `${formatNumber(hint.value.toFixed(2))}`
                          : `$${
                              countDecimals(
                                formatNumber(hint.value.toFixed(2))
                              ) === 1
                                ? formatNumber(hint.value.toFixed(2)) + "0"
                                : formatNumber(hint.value.toFixed(2))
                            }`
                      }  `}
                    />
                  </div>
                </Hint>
              )}
            </RadialChart>
          )}
        </AutoSizer>
      </div>
      <Legend>
        {isTravel === "travel" &&
          travelData.map((x, i) => (
            <TooltipItem
              key={i}
              fill={x.color}
              text={`${x.text} (${(x.angle * 100).toFixed(1)}%)`}
            />
          ))}

        {isTravel === "nonTravel" &&
          nonTravelData.map((x, i) => (
            <TooltipItem
              key={i}
              fill={x.color}
              text={`${x.text} (${(x.angle * 100).toFixed(1)}%)`}
            />
          ))}
      </Legend>
    </Wrap>
  );
};

export default TPPieChart;
