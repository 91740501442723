import React, { useState } from "react";
import TooltipItem from "../../TooltipItem";
import CardInnerWithTime from "../../CardInnerWithTime";
import AutoSizer from "react-virtualized-auto-sizer";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { formatNumber, monthsList } from "../../../Utils/utils";
import { useTheme } from "emotion-theming";
import {
  XAxis,
  YAxis,
  HorizontalGridLines,
  Hint,
  LineMarkSeries,
  XYPlot,
} from "react-vis";

const MediaStories = ({ data }) => {
  const theme = useTheme();
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);

  let yDomainTopValue = 0;
  let mediaArray = [];

  if (data) {
    data.visits
      .sort((a, b) => {
        if (a.year !== b.year) return a.year - b.year;
        return monthsList.indexOf(a.month) - monthsList.indexOf(b.month);
      })
      .forEach((ele) => {
        mediaArray.push({
          x: `${ele.month} ${ele.year}`,
          y: ele.mediaMentions,
        });
      });

    yDomainTopValue = Math.round(
      data.visits.sort((a, b) => {
        if (a.year !== b.year) return a.year - b.year;
        return monthsList.indexOf(a.month) - monthsList.indexOf(b.month);
      })?.[0].mediaMentions * 1.3
    );
  }

  return (
    <div className="MediaStories">
      <CardInnerWithTime
        loading={!data}
        title="Monthly OMES Mentions in Media Stories"
        refreshedTime={data?.timeStamp ?? ""}
        extraIcons={[
          {
            text: `Media stories include any mentions of OMES in the
news, on the internet and on social media.`,
            icon: faInfoCircle,
            style: {
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              textAlign: "justify",
            },
          },
        ]}
        mainComponent={() => (
          <AutoSizer disableHeight>
            {({ width }) => (
              <XYPlot
                width={width}
                height={200}
                xType="ordinal"
                margin={{
                  left: 70,
                }}
                yDomain={[0, yDomainTopValue]}
                onMouseLeave={() => {
                  setHint(false);
                  setAnimation(true);
                }}
              >
                <HorizontalGridLines />
                <XAxis tickLabelAngle={-45} tickPadding={20} />
                <YAxis
                  title="Number of Closed Cases"
                  tickFormat={(d) => `${formatNumber(d)}`}
                />

                <LineMarkSeries
                  data={mediaArray}
                  animation={animation}
                  getNull={(d) => d.y !== null}
                  color={theme.colors.skyBlue.darken}
                  onNearestX={(datapoint) => {
                    setAnimation(false);
                    datapoint.y !== null && setHint(datapoint);
                  }}
                />
                {hint && (
                  <Hint value={hint}>
                    <div
                      className="rv-hint__content"
                      style={{ pointerEvents: "none" }}
                    >
                      <TooltipItem
                        fill={hint.color}
                        text={`${hint.x}: ${hint.y}`}
                      />
                    </div>
                  </Hint>
                )}
              </XYPlot>
            )}
          </AutoSizer>
        )}
      />
    </div>
  );
};

export default MediaStories;
