import React from 'react';
import PropTypes from 'prop-types';

import AuthorizedArea from './AuthorizedArea';
import Card from './Card';

const AuthorizedCard = ({
  innerComponent: InnerComponent,
  url,
  refreshInterval,
  isFullWidth,
  isBlueCard
}) => (

  <AuthorizedArea
    url={url}
    refreshInterval={refreshInterval}
  
    innerComponent={({ data, error }) => (
       data?.renderCard ? <Card wide={isFullWidth} redCard = {isBlueCard}>
<InnerComponent data={data} error={error} />
      </Card>  : null
    )}
  />
);

AuthorizedCard.propTypes = {
  innerComponent: PropTypes.any,
  url: PropTypes.string,
  refreshInterval: PropTypes.number,
  isFullWidth: PropTypes.bool,
  isBlueCard: PropTypes.bool,
};

AuthorizedCard.defaultProps = {
  refreshInterval: 0,
  isFullWidth: false,
  isBlueCard: false
};

export default AuthorizedCard;
