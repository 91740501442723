import React from "react";
import Card from "../Card";
import CardInnerWithTime from "../CardInnerWithTime";
import { H1 } from "../Headings";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import useSWR from "swr";
import { fetcher, TimeIntervals } from "../../Utils/utils";
import "../../styles/TurnAroundMetric.css";

const TurnaroundMetric = ({ agencyKey }) => {
  const { data } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/app-c-execution-turnaround.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_MINUTE,
    }
  );

  let subtitle, returnedMonth;
  let executedDays = data?.list?.filter((x) => x.agencyKey === agencyKey)?.[0]
    ?.daysToExecute;

  let filteredDate = data?.list?.filter((x) => x.agencyKey === agencyKey)?.[0]
    ?.executed;
  let returnedDate = new Date(filteredDate);

  if (filteredDate !== null && filteredDate !== undefined) {
    returnedMonth = returnedDate.getMonth() + 1;
    if (returnedMonth >= 1 && returnedMonth <= 4) {
      subtitle = returnedDate.getFullYear().toString().slice(2);
    } else {
      subtitle = (returnedDate.getFullYear() + 1).toString().slice(2);
    }
  }

  return (
    <>
      {data?.renderCard && (
        <Card className="TurnAroundMetric">
          <CardInnerWithTime
            loading={!data}
            title="App C Execution Turnaround"
            subtitle={executedDays !== null && subtitle && `FY${subtitle}`}
            refreshedTime={data?.timeStamp ?? ""}
            extraIcons={[
              {
                text: `Shows the number of days elapsed from App C initiation to final approval and execution.`,
                icon: faInfoCircle,
              },
            ]}
            mainComponent={() => (
              <H1>
                {executedDays !== "0" &&
                executedDays !== null &&
                executedDays !== undefined
                  ? `${executedDays} days`
                  : "No data available"}
              </H1>
            )}
          />
        </Card>
      )}
    </>
  );
};

export default TurnaroundMetric;
