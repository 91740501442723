import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import AuthorizedArea from "../AuthorizedArea";
import {
  TimeIntervals,
  buildApiInternalUrl,
  formatNumber,
  monthsInShort,
} from "../../Utils/utils";
import AutoSizer from "react-virtualized-auto-sizer";
import Card from "../Card";
import CardInnerWithTime from "../CardInnerWithTime";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalBarSeries,
  Crosshair,
} from "react-vis";
import TooltipItem from "../TooltipItem";
import "../../styles/CloudSpend.css";

const InnerComponent = ({ data }) => {
  const [azureData, setAzureData] = useState([]);
  const [googleData, setGoogleData] = useState([]);
  const [oracleData, setOracleData] = useState([]);
  const [awsData, setAwsData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);
  const [crosshairValues, setCrosshairValues] = useState([]);
  let totalArray = [];
  let yDomainTopValue, maxValue;

  const LegendWrap = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-top: 1.5rem;
  `;

  useEffect(() => {
    let azureData = data?.data?.filter(
      (item) => item.cloud === "Microsoft Azure"
    );
    let googleData = data?.data?.filter((item) => item.cloud === "Google");
    let oracleData = data?.data?.filter(
      (item) => item.cloud === "Oracle Cloud"
    );
    let awsData = data?.data?.filter((item) => item.cloud === "AWS");
    let totalData = data?.data?.filter((item) => item.cloud === "Total");

    for (let [key, value] of Object.entries(azureData[0])) {
      if (key !== "cloud" && key !== "total" && key !== "month12") {
        let newObj = {
          x: data?.headersFormatted[key],
          y: value,
          color: "#f8cbad",
        };
        setAzureData((prevArr) => [...prevArr, newObj]);
      }
    }

    for (let [key, value] of Object.entries(googleData[0])) {
      if (key !== "cloud" && key !== "total" && key !== "month12") {
        let newObj = {
          x: data?.headersFormatted[key],
          y: value,
          color: "#ed7d31",
        };
        setGoogleData((prevArr) => [...prevArr, newObj]);
      }
    }

    for (let [key, value] of Object.entries(oracleData[0])) {
      if (key !== "cloud" && key !== "total" && key !== "month12") {
        let newObj = {
          x: data?.headersFormatted[key],
          y: value,
          color: "#c6e0b4",
        };
        setOracleData((prevArr) => [...prevArr, newObj]);
      }
    }

    for (let [key, value] of Object.entries(awsData[0])) {
      if (key !== "cloud" && key !== "total" && key !== "month12") {
        let newObj = {
          x: data?.headersFormatted[key],
          y: value,
          color: "#9bc2e6",
        };
        setAwsData((prevArr) => [...prevArr, newObj]);
      }
    }

    for (let [key, value] of Object.entries(totalData[0])) {
      if (key !== "cloud" && key !== "total" && key !== "month12") {
        totalArray.push(value);
      }
      setTotalData(totalArray);
    }
  }, [data]);

  if (totalData.length > 0) {
    maxValue = Math.max(...totalData);
    yDomainTopValue = [0, 1.3 * maxValue];
  }

  const showHint = (val) => {
    let azureVal = azureData?.filter((item) => item.x === val)?.[0]?.y;
    let googleVal = googleData?.filter((item) => item.x === val)?.[0]?.y;
    let oracleVal = oracleData?.filter((item) => item.x === val)?.[0]?.y;
    let awsVal = awsData?.filter((item) => item.x === val)?.[0]?.y;

    return (
      <>
        <TooltipItem
          fill="#f8cbad"
          text={`Microsoft Azure: ${
            azureVal
              ? formatNumber(azureVal?.toFixed(2), true)
              : "No data available"
          }`}
        />
        <TooltipItem
          fill="#ed7d31"
          text={`Google Cloud Platform: ${
            googleVal
              ? formatNumber(googleVal?.toFixed(2), true)
              : "No data available"
          }`}
        />
        <TooltipItem
          fill="#c6e0b4"
          text={`Oracle Cloud: ${
            oracleVal
              ? formatNumber(oracleVal?.toFixed(2), true)
              : "No data available"
          }`}
        />
        <TooltipItem
          fill="#9bc2e6"
          text={`Amazon Web Services: ${
            awsVal
              ? formatNumber(awsVal?.toFixed(2), true)
              : "No data available"
          }`}
        />
      </>
    );
  };

  const changeDateFormat = (d) => {
    let date = new Date(d);
    let newDate = ` ${monthsInShort[date.getMonth()]} ${date.getFullYear()}`;
    return newDate;
  };

  return (
    <Card className="CloudSpend">
      <CardInnerWithTime
        loading={!data}
        title="Multi-Cloud Spend History"
        refreshedTime={data?.timeStamp ?? ""}
        extraIcons={[
          {
            text: `Cost history of multiple cloud platforms over the past 12
months is compiled from CloudHealth.

Oracle Cloud data may be partial as the integration with
CloudHealth is not fully implemented.`,
            icon: faInfoCircle,
            style: {
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              textAlign: "justify",
            },
          },
        ]}
        mainComponent={() => (
          <>
            <AutoSizer disableHeight>
              {({ width }) => (
                <XYPlot
                  width={width}
                  height={200}
                  xType="ordinal"
                  colorType="literal"
                  stackBy="y"
                  margin={{
                    left: 60,
                  }}
                  yDomain={yDomainTopValue}
                  onMouseLeave={() => {
                    setCrosshairValues(false);
                    setHint(false);
                    setAnimation(true);
                  }}
                >
                  <HorizontalGridLines />
                  <XAxis
                    tickLabelAngle={-45}
                    tickFormat={(d) => changeDateFormat(d)}
                  />
                  <YAxis tickFormat={(d) => `$${formatNumber(d / 1000)}K`} />

                  <VerticalBarSeries
                    data={azureData}
                    barWidth={0.5}
                    animation={animation}
                    onValueMouseOver={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                      setCrosshairValues([datapoint]);
                    }}
                    onValueMouseOut={() => {
                      setHint(false);
                      setAnimation(true);
                    }}
                  />
                  <VerticalBarSeries
                    data={googleData}
                    barWidth={0.5}
                    animation={animation}
                    onValueMouseOver={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                      setCrosshairValues([datapoint]);
                    }}
                    onValueMouseOut={() => {
                      setHint(false);
                      setAnimation(true);
                    }}
                  />

                  <VerticalBarSeries
                    data={oracleData}
                    barWidth={0.5}
                    animation={animation}
                    onValueMouseOver={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                      setCrosshairValues([datapoint]);
                    }}
                    onValueMouseOut={() => {
                      setHint(false);
                      setAnimation(true);
                    }}
                  />

                  <VerticalBarSeries
                    data={awsData}
                    barWidth={0.5}
                    animation={animation}
                    onValueMouseOver={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                      setCrosshairValues([datapoint]);
                    }}
                    onValueMouseOut={() => {
                      setHint(false);
                      setAnimation(true);
                    }}
                  />

                  {crosshairValues && (
                    <Crosshair values={crosshairValues}>
                      <div className="ShowHint">{showHint(hint.x)}</div>
                    </Crosshair>
                  )}
                </XYPlot>
              )}
            </AutoSizer>
            <LegendWrap>
              <TooltipItem fill="#f8cbad" text="Microsoft Azure" />
              <TooltipItem fill="#ed7d31" text="Google Cloud Platform" />
              <TooltipItem fill="#c6e0b4" text="Oracle Cloud" />
              <TooltipItem fill="#9bc2e6" text="Amazon Web Services" />
            </LegendWrap>
          </>
        )}
      />
    </Card>
  );
};

const CloudSpend = () => (
  <AuthorizedArea
    url={buildApiInternalUrl("is-multi-cloudspend")}
    refreshInterval={TimeIntervals.ONE_DAY}
    innerComponent={({ data, error }) => {
      if (data === undefined) {
        return <></>;
      }
      if (!data && !error && !error?.status) {
        return <></>;
      }
      if (data?.renderCard !== undefined && !data.renderCard) {
        return <></>;
      }
      return <InnerComponent data={data} />;
    }}
  />
);

export default CloudSpend;
