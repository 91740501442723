import React, { useState } from "react";
import useSWR from "swr";
import styled from "@emotion/styled";
import AutoSizer from "react-virtualized-auto-sizer";
import { TimeIntervals, fetcher } from "../../../Utils/utils";
import Card from "../../Card";
import CardTopItems from "../../CardTopItems";
import { faClock, faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { Hint, RadialChart } from "react-vis";
import TooltipItem from "../../TooltipItem";
import { getCurrentFY } from "../../../Utils/utils";
import { H2 } from "../../Headings";

const LegendWrap = styled.div`
  text-align: left;
  margin: 0.75rem;
`;

const Bottom = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.width.xs};
  text-align: center;
  margin: ${({ theme }) =>
    `${theme.padding.sm} -${theme.margin.md} -${theme.margin.sm}`};
  padding: ${({ theme }) => `${theme.padding.sm} ${theme.padding.md}`};
  background-color: ${({ theme }) => theme.card.well.background};
  border-top: ${({ theme }) => `${theme.width.px} solid ${theme.card.border}`};
`;

const InnerComponent = ({ data }) => {
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);
  let currentFY = getCurrentFY();

  let totalDeniedCount = 0,
    totalSustainedCount = 0,
    filteredSustainedCount = 0,
    totalCount = 0,
    deniedValue = 0,
    sustainedValue = 0;

  if (data?.data) {
    let getYears = data.data.filter((item) => item.year === currentFY);
    if (getYears.length === 0) {
      currentFY--;
    }

    totalDeniedCount = data.data.filter(
      (item) => item.year === currentFY && item.metric === "Denied"
    )[0]?.count;
    filteredSustainedCount = data.data.filter(
      (item) =>
        item.year === currentFY &&
        item.metric === "Sustained/Contract Award Vacated"
    )[0]?.count;

    totalSustainedCount =
      filteredSustainedCount === undefined ? 0 : filteredSustainedCount;
  }

  totalCount = totalDeniedCount + totalSustainedCount;
  deniedValue = (totalDeniedCount / totalCount) * 100;
  sustainedValue = (totalSustainedCount / totalCount) * 100;

  const sustainedData = [
    {
      angle: deniedValue,
      color: "#669b41",
      value: totalDeniedCount,
      text: "Denied",
    },
    {
      angle: sustainedValue,
      color: "#de9027",
      value: totalSustainedCount,
      text: "Sustained/Contract Award Vacated",
    },
  ];

  return (
    <>
      <Card>
        <CardTopItems
          items={[
            {
              text: `Types of sustained protests:
1. Sustained by the State Purchasing Director
2. Sustained by the OMES Director or an Administrative Law
Judge
3. Sustained on Appeal by a District Court Judge or the
Oklahoma Supreme Court`,
              icon: faInfoCircle,
              style: {
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
                textAlign: "justify",
              },
            },
            {
              text: `As of ${new Date(data?.timeStamp).toLocaleString()}`,
              icon: faClock,
            },
          ]}
        />
        <H2>Total Protests Sustained</H2>
        <div>
          <AutoSizer disableHeight>
            {({ width }) => (
              <RadialChart
                colorType="literal"
                innerRadius={0}
                radius={100}
                data={sustainedData}
                animation={animation}
                onValueMouseOver={(datapoint) => {
                  setAnimation(false);
                  setHint(datapoint);
                }}
                onValueMouseOut={() => {
                  setHint(false);
                  setAnimation(true);
                }}
                width={width}
                height={200}
              >
                {hint && (
                  <Hint value={hint}>
                    <div
                      className="rv-hint__content"
                      style={{ pointerEvents: "none" }}
                    >
                      <TooltipItem
                        fill={hint.color}
                        text={`${hint.text}: ${hint.value}`}
                      />
                    </div>
                  </Hint>
                )}
              </RadialChart>
            )}
          </AutoSizer>

          <LegendWrap>
            <TooltipItem
              fill="#669b41"
              text={`Denied (${deniedValue?.toFixed(2)}%)`}
            />
            <TooltipItem
              fill="#de9027"
              text={`Sustained/Contract Award Vacated (${sustainedValue?.toFixed(
                2
              )}%)`}
            />
          </LegendWrap>
        </div>
        <Bottom>{`SLA Goal: 0% of protests sustained`}</Bottom>
      </Card>
    </>
  );
};

const ProtestsSustained = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/protest-sustained.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} />;
};

export default ProtestsSustained;
