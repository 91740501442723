import React from "react";
import HorizontalBarChart from "../../BaseCharts/HorizontalBarChart";
import CardInnerWithTime from "../../CardInnerWithTime";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { useTheme } from "emotion-theming";
import { formatNumber } from "../../../Utils/utils";
import TooltipItem from "../../TooltipItem";
import { H1, H2 } from "../../Headings";
import "../../../styles/Common.css";
import "../../../styles/CapitalAssets.css";

const WorkByStatus = ({
  data,
  uniqueWorkStatus,
  closedWorkOrdersCount,
  totalWorkOrders,
  timeStamp,
}) => {
  const theme = useTheme();
  const workStatus = [];
  const sortList = [
    "Work Order Created",
    "Issued",
    "Estimated",
    "In Progress",
    "Awaiting Parts",
    "Parts Received",
    "Work Complete",
    "Cancel",
  ];
  // sortList.map((item) => {
  //   const dataCount = data?.filter((it) => it.workStatus === item).length;
  //   workStatus.push({
  //     x: dataCount,
  //     y: item ? item :  "Work Order Created",
  //   });
  // }); 
  data.map((item) => {
    workStatus.push({
      x: item.count,
      y: item.workStatus ? item.workStatus : "Work Order Created",
    });
  });
  const workStatusSorted = workStatus.sort((a, b) => {
    return sortList.indexOf(a.y) - sortList.indexOf(b.y);
  });

  workStatusSorted.reverse(); 
  const barChartData = [
    {
      set: "workStatusSet",
      setData: workStatusSorted,
    },
  ];

  const maxValue =
    workStatusSorted.length > 0 &&
    Math.max(...workStatusSorted.map((d) => d.x));
  let xDomainTopValue = [0, 1.2 * maxValue];

  return (
    <div className="WorkByStatus">
      <div className="TopHeader">
        <div className="HeadCounts">
          <H2 as="h3">Closed Work Orders</H2>
          <H1 as="span">{formatNumber(closedWorkOrdersCount)}</H1>
        </div>
        <div className="Divider"></div>
        <div className="HeadCounts">
          <H2 as="h3">Total Work Orders</H2>
          <H1 as="span">{formatNumber(totalWorkOrders)}</H1>
        </div>
      </div>
      <CardInnerWithTime
        loading={!data}
        title="Monthly Work Orders by Work Status"
        refreshedTime={timeStamp ?? ""}
        extraIcons={[
          {
            text: `Data reflects work orders logged this previous month.`,
            icon: faInfoCircle,
          },
        ]}
        mainComponent={() => (
          <>
            {barChartData && (
              <HorizontalBarChart
                data={barChartData}
                showX
                showY
                ordinalY
                leftPadding={90}
                xAxisFormat={(d) => formatNumber(d)}
                hintFormat={(h) => (
                  <div className="HintWrap">
                    <TooltipItem
                      fill={"#005c95"}
                      text={`${h.y}: ${formatNumber(h.x)}`}
                    />
                  </div>
                )}
                gridLinesHorizontal={false}
                gridLinesVertical={true}
                xDomain={xDomainTopValue}
                graphColor={theme.colors.skyBlue.darken}
                height={200}
              />
            )}

            <section>
              <p>
                Some statuses do not apply to all work orders. Data is not
                inclusive of closed work orders.
              </p>
              <ul>
                <li>
                  Estimated – used with complex or large efforts, or when a
                  third-party vendor is used
                </li>
                <li>
                  Awaiting Parts – used if a work order needs ordered parts not
                  in current inventory
                </li>
                <li>
                  Cancel – used if a work order is deemed no longer needed
                </li>
              </ul>
            </section>
          </>
        )}
      />
    </div>
  );
};

export default WorkByStatus;
