import React from "react";
import useSWR from "swr";
import Card from "../../Card";
import styled from "@emotion/styled";
import Datagrid from "../../Datagrid";
import CardInnerWithTime from "../../CardInnerWithTime";
import { fetcher, TimeIntervals } from "../../../Utils/utils";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import "../../../styles/HumanCapital.css";
import "../../../styles/OpenJob.css";
import OpenJob from "./OpenJob";
import OfferedTraining from "./OfferedTraining";
import { H3 } from "../../Headings";
import CentralPayrollGroup from "./CentralPayrollGroup";

const HumanCapital = ({ authenticationState, role }) => {
  const { data } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/supervisortraining.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  let yearOne = "CY 20" + data?.yearOne?.slice(2);
  let yearTwo = "CY 20" + data?.yearTwo?.slice(2);
  let yearThree = "CY 20" + data?.yearThree?.slice(2);

  const Heading = styled.div`
    text-align: center;
    color: #ffffff;

    strong {
      display: inline-block;
      padding-bottom: ${({ theme }) => theme.width.xs};
    }
  `;

  const Header = ({ prop, label = "" }) => (
    <Heading>
      <strong>{prop}</strong>
      <br />
      {label}
    </Heading>
  );
  const MapFormat = (d) =>
  d?.map((x) => ({
    ...x,
    year1PercNum: x.year1Perc?.includes("%") ? Number(x.year1Perc?.replace('%', '')) : x.year1Perc?.includes("NA") ? -1 : 0,
    year2PercNum: x.year2Perc?.includes("%") ? Number(x.year2Perc?.replace('%', '')) : x.year2Perc?.includes("NA") ? -1 : 0,
    year3PercNum: x.year3Perc?.includes("%") ? Number(x.year3Perc?.replace('%', '')) : x.year3Perc?.includes("NA") ? -1 : 0,
  }));
  const formattedData = MapFormat(data?.agencies);
  const columns = [
    {
      name: <Header prop="Agency Name" />,
      selector: (row) => (row.agencyName !== null ? row.agencyName : "-"),
      sortable: true,
      grow: 3,
      wrap: true,
      style: { textAlign: "left !important" },
    },
    {
      name: <Header prop="Agency Key" />,
      selector: (row) => (row.agencyKey !== null ? row.agencyKey : "-"),
      sortable: true,
      omit: true,
    },
    {
      name: <Header prop={yearOne} label="Total Supervisors" />,
      selector: (row) =>
        row.year1TotalSupervisors !== null ? row.year1TotalSupervisors : 0,
      cell: (row) =>
        row.year1TotalSupervisors !== null ? row.year1TotalSupervisors : "-",
      sortable: true,
      right: true,
      style: { backgroundColor: "#e6f0f6 !important" },
    },
    {
      name: <Header prop={yearOne} label="Completion (%)" />,
      selector: "year1PercNum",
      cell:  row => row.year1Perc !== "NA" ? row.year1Perc : "-",
      sortable: true,
      right: true,
      style: { backgroundColor: "#e6f0f6 !important" },
    },
    {
      name: <Header prop={yearTwo} label="Total Supervisors" />,
      selector: (row) =>
        row.year2TotalSupervisors !== null ? row.year2TotalSupervisors : 0,
      cell: (row) =>
        row.year2TotalSupervisors !== null ? row.year2TotalSupervisors : "-",
      sortable: true,
      right: true,
    },
    {
      name: <Header prop={yearTwo} label="Completion (%)" />,
      selector: "year2PercNum",
      cell:  row => row.year2Perc !== "NA" ? row.year2Perc : "-",
      sortable: true,
      right: true,
    },
    {
      name: <Header prop={yearThree} label="Total Supervisors" />,
      selector: (row) =>
        row.year3TotalSupervisors !== null ? row.year3TotalSupervisors : 0,
      cell: (row) =>
        row.year3TotalSupervisors !== null ? row.year3TotalSupervisors : "-",
      sortable: true,
      right: true,
      style: { backgroundColor: "#e6f0f6 !important" },
    },
    {
      name: <Header prop={yearThree} label="Completion (%)" />,
      selector: "year3PercNum",
      cell: (row) => (row.year3Perc !== "NA" ? row.year3Perc : "-"),
      sortable: true,
      right: true,
      style: { backgroundColor: "#e6f0f6 !important" },
    },
  ];

  return (
    <>
      <OpenJob />
      <OfferedTraining />
      {data?.renderCard && (
          <>
            <H3>{""}</H3>
            <Card className="HumanCapital">
              <CardInnerWithTime
                loading={!data}
                title="Calendar Year Supervisor Training Completion Rate by Agency"
                refreshedTime={data?.timeStamp ?? ""}
                extraIcons={[
                  {
                    text: `Data is reliant on agency self-reporting and reflects both internal and external learning sources.`,
                    icon: faInfoCircle,
                  },
                ]}
                mainComponent={() => ( 
                  <Datagrid
                    columns={columns}
                    data={formattedData}
                    expandable={false}
                    defaultSortField="agencyName"
                    searchByKeys={["agencyKey", "agencyName"]}
                  />
                )}
              />
            </Card>
          </>
        )}

      <CentralPayrollGroup />
    </>
  );
};

export default HumanCapital;
