import React, { useState, useMemo } from 'react';
import styled from '@emotion/styled';
import Datatable from 'react-data-table-component';

import FilterInput from './FilterInput';
import ExpandedGridItem from './ExpandedGridItem';

const SubHead = styled.div`
  display: grid;
  grid-gap: ${(props) => props.theme.width.md};
  grid-template-columns: 1fr;
  justify-items: center;
  width: 100%;

  & > button {
    grid-row: 1;
    margin: ${(props) => props.theme.margin.none};
  }

  ${(props) => props.theme.breakpoints.md} {
    grid-gap: ${(props) => props.theme.width.none};
    grid-template-columns: 1fr;
    justify-items: right;

    & > button {
      grid-row: auto;
    }
  }
`;

const customStyles = { subHeader: { style: { padding: '0px' } } };

const Datagrid = ({
  columns,
  data,
  expandable,
  defaultSortField,
  searchByKeys = ["agency", "agencyKey", "agencyName", "query", "Agency", "AgencyName", "agencyId","workCode"],
  filterPlaceHolder,
  subHeader = true
}) => {
  const [filterText, setFilterText] = useState('');
  let filteredItems = data;
  let allowedKeys = searchByKeys;

  if (filterText !== "") {
    if (data !== null) {
      filteredItems = data.filter((o) =>
        Object.keys(o)
          .filter((key) => allowedKeys.includes(key))
          .some(
            (k) =>
              o[k] &&
              o[k].toString().toLowerCase().includes(filterText.toLowerCase())
          )
      );
    }
  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText('');
      }
    };

    return (
      <SubHead>
        <FilterInput
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          searchByKeys={searchByKeys}
          filterPlaceHolder={filterPlaceHolder}
        />
      </SubHead>
    );
  }, [filterText]);

  return (
    <>
      <Datatable
        columns={columns}
        data={filteredItems}
        pagination
        highlightOnHover
        noHeader
        subHeader ={subHeader}
        theme="webeoi"
        defaultSortField={defaultSortField}
        expandableRowsComponent={<ExpandedGridItem columns={columns} />}
        subHeaderComponent={subHeaderComponentMemo}
        expandableRows={expandable}
        expandOnRowClicked
        customStyles={customStyles}
      />
    </>
  );
};

Datagrid.defaultProps = {
  columns: null,
  data: null,
  expandable: true,
  defaultSortField: '',
};

export default Datagrid;
