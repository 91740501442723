import React, { useEffect, useState } from "react";
import useSWR from "swr";
import Card from "../../Card";
import styled from "@emotion/styled";
import Datagrid from "../../Datagrid";
import CardInnerWithTime from "../../CardInnerWithTime";
import { fetcher, TimeIntervals } from "../../../Utils/utils";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { H2 } from "../../Headings";
import "../../../styles/OpenJob.css";

const OpenJob = () => {
  const [uniqueKeys, setUniqueKeys] = useState([]);
  const [showData, setShowData] = useState([]);

  const { data } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/cr-gcp-int003-rtp00244-wd-to-gcp.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  const Heading = styled.div`
    font-weight: bold;
  `;

  useEffect(() => {
    setUniqueKeys(
      Array.from(
        new Set(
          data?.report_Entry
            ?.filter((item) => item.agencyKey !== null)
            .map((item) => item.agencyKey)
        )
      )
    );
  }, [data]);

  useEffect(() => {
    for (let i = 0; i < uniqueKeys.length; i++) {
      let agencyKeyValue = uniqueKeys[i];

      let nameColumn = data.report_Entry.filter(
        (item) => item.agencyKey === uniqueKeys[i]
      )[0].agencyName;

      let notFilledData = data.report_Entry
        .filter((item) => item.agencyKey === uniqueKeys[i])
        .filter((item) => item.job_Requisition_Status !== "Filled");

      let openJobColumn = notFilledData.length;

      let avgDays = Math.round(
        data.report_Entry
          .filter((item) => item.agencyKey === uniqueKeys[i])
          .filter((item) => item.job_Requisition_Filled_Date === null)
          .map((item) => item.date_Request_Entered)
          .reduce(
            (accumulator, currentValue) =>
              accumulator +
              Math.ceil(
                Math.abs(new Date() - new Date(currentValue)) /
                  (1000 * 60 * 60 * 24)
              ),
            0
          ) /
          data.report_Entry
            .filter((item) => item.agencyKey === uniqueKeys[i])
            .filter((item) => item.job_Requisition_Filled_Date === null).length
      );

      let averageDaysColumn = isNaN(avgDays) ? "-" : avgDays;

      if (openJobColumn !== 0 && avgDays !== 0) {
        let sortedObj = {
          nameColumn,
          openJobColumn,
          averageDaysColumn,
          agencyKeyValue,
        };
        setShowData((prevArr) => [...prevArr, sortedObj]);
      }
    }
  }, [uniqueKeys]);

  const sortedData = showData.sort((a, b) =>
    a.nameColumn > b.nameColumn ? 1 : -1
  );

  const columns = [
    {
      name: <Heading>Agency Name</Heading>,
      selector: (row) => row.nameColumn !== null && row.nameColumn,
      sortable: true,
      grow: 1,
      wrap: true,
      style: { textAlign: "left !important" },
    },
    {
      name: <Heading>Open Job Requisitions</Heading>,
      selector: (row) => (row.openJobColumn !== null ? row.openJobColumn : "-"),
      sortable: true,
      grow: 1,
      right: true,
    },
    {
      name: <Heading>Average Days Open</Heading>,
      selector: (row) =>
        row.averageDaysColumn !== null ? row.averageDaysColumn : "-",
      sortable: true,
      grow: 1,
      right: true,
    },
  ];

  return (
    <>
      {data?.renderCard && (
        <>
          <H2>{"Talent Management"}</H2>
          <Card className="OpenJob">
            <CardInnerWithTime
              loading={!data}
              title="Open Job Requisitions and Length of Time by Agency"
              refreshedTime={data?.timeStamp ?? ""}
              extraIcons={[
                {
                  text: `"Open" denotes the time across various Talent stages, from
reviewing applications to point of hire. The HR system, Workday,
went live on 08/15/2022. Prior months' data may not be available.`,
                  icon: faInfoCircle,
                  style: {
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                    textAlign: "justify",
                  },
                },
              ]}
              mainComponent={() => (
                <Datagrid
                  columns={columns}
                  data={sortedData}
                  expandable={false}
                  defaultSortField=""
                  searchByKeys={["nameColumn", "agencyKeyValue"]}
                />
              )}
            />
          </Card>
        </>
      )}
    </>
  );
};

export default OpenJob;
