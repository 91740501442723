import React from 'react';
import styled from '@emotion/styled';

import NeedHelp from '../NeedHelp';

const SpanWrap = styled.span`
  display: grid;
  align-items: end;
`;

const IdeaLink = () => (
  <SpanWrap>
    <NeedHelp />
  </SpanWrap>
);

IdeaLink.propTypes = {};

export default IdeaLink;
