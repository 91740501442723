import React, { useEffect, useState } from "react";
import useSWR from "swr";
import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import AutoSizer from "react-virtualized-auto-sizer";
import { TimeIntervals, fetcher } from "../../../Utils/utils";
import Card from "../../Card";
import CardInnerWithTime from "../../CardInnerWithTime";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import {
  Hint,
  HorizontalGridLines,
  LineSeries,
  XAxis,
  XYPlot,
  YAxis,
} from "react-vis";
import TooltipItem from "../../TooltipItem";

const Wrapper = styled.div`
  text-align: center;
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  h3 {
    text-align: left;
  }
`;

const Bottom = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.width.xs};
  text-align: center;
  margin: ${({ theme }) =>
    `${theme.padding.sm} -${theme.margin.md} -${theme.margin.sm}`};
  padding: ${({ theme }) => `${theme.padding.sm} ${theme.padding.md}`};
  background-color: ${({ theme }) => theme.card.well.background};
  border-top: ${({ theme }) => `${theme.width.px} solid ${theme.card.border}`};
`;

const InnerComponent = ({ data }) => {
  const theme = useTheme();
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);
  const [uniqueYears, setUniqueYears] = useState([]);

  let yDomainTopValue = 0;
  let yearlyProtestsArray = [];
  let sortedArray = [];

  useEffect(() => {
    let returnedYears = Array.from(
      new Set(data?.data?.map((item) => item?.year))
    );
    setUniqueYears(returnedYears.reverse());
  }, [data]);

  for (let i = 0; i < uniqueYears.length; i++) {
    let totalSum = data?.data
      ?.filter((item) => item?.year === uniqueYears[i])
      .reduce((acc, it) => acc + it.count, 0);

    yearlyProtestsArray.push({
      x: uniqueYears[i],
      y: totalSum,
    });

    sortedArray.push({
      x: uniqueYears[i],
      y: totalSum,
    });
  }

  if (sortedArray) {
    yDomainTopValue = Math.round(
      sortedArray.sort((a, b) => b.y - a.y)?.[0]?.y * 1.2
    );
  }

  yearlyProtestsArray.reverse().length = 5;
  yearlyProtestsArray = yearlyProtestsArray.reverse();

  return (
    <>
      <Card>
        <Wrapper>
          <CardInnerWithTime
            loading={!data}
            title="Total Yearly Protests by FY"
            refreshedTime={data?.timeStamp ?? ""}
            extraIcons={[
              {
                text: `These are the total number of protests received by OMES Central
Purchasing for the year in question.`,
                icon: faInfoCircle,
                style: {
                  whiteSpace: "pre-wrap",
                  overflowWrap: "break-word",
                  textAlign: "justify",
                },
              },
            ]}
            mainComponent={() => (
              <div>
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <XYPlot
                      width={width}
                      height={200}
                      xType="ordinal"
                      yDomain={[0, yDomainTopValue]}
                      onMouseLeave={() => {
                        setHint(false);
                        setAnimation(true);
                      }}
                    >
                      <HorizontalGridLines />
                      <XAxis />
                      <YAxis />

                      <LineSeries
                        data={yearlyProtestsArray}
                        animation={animation}
                        color={theme.colors.skyBlue.darken}
                        onNearestX={(datapoint) => {
                          setAnimation(false);
                          setHint(datapoint);
                        }}
                      />
                      {hint && (
                        <Hint value={hint}>
                          <div
                            className="rv-hint__content"
                            style={{ pointerEvents: "none" }}
                          >
                            <TooltipItem
                              fill={hint.color}
                              text={`${hint.x}: ${hint.y}`}
                            />
                          </div>
                        </Hint>
                      )}
                    </XYPlot>
                  )}
                </AutoSizer>
              </div>
            )}
          />
          <Bottom>{`SLA Goal: 10 Protests per FY`}</Bottom>
        </Wrapper>
      </Card>
    </>
  );
};

const YearlyProtests = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/protest-data.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return <InnerComponent data={data} />;
};

export default YearlyProtests;
