import React from "react";
import styled from "@emotion/styled";
import { TableStyle } from "../../BaseTable";
import Cell from "../../AppCTable/Cell";
import { formatNumber, monthsList } from "../../../Utils/utils";

const TableWrap = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;

  thead tr,
  tbody tr:nth-last-child(1) {
    background-color: #005c95;
    color: #ffffff;
  }

  thead th {
    overflow-wrap: break-word;
    white-space: pre-wrap !important;
  }
`;

const FulfillmentRate = ({ data }) => {
  const grandTotal = data?.fulfillment?.reduce(
    (acc, item) => acc + item.orderCount,
    0
  );

  return (
    <>
      <TableWrap>
        <TableStyle color="blue" wrapped>
          <thead>
            <tr>
              <Cell as="th" alignment="left">
                Month
              </Cell>
              <Cell as="th" alignment="left">
                Order Count
              </Cell>
              <Cell as="th" alignment="left">
                Average Days to Fulfill
              </Cell>
            </tr>
          </thead>
          <tbody>
            {data?.fulfillment
              ?.sort((a, b) => {
                return (
                  monthsList.indexOf(a.month) - monthsList.indexOf(b.month)
                );
              })
              ?.map((item) => {
                return (
                  <tr>
                    <Cell as="td" alignment="left">
                      {item?.month !== "" ? item?.month : ""}
                    </Cell>
                    <Cell as="td" alignment="right">
                      {item?.orderCount !== ""
                        ? formatNumber(item?.orderCount)
                        : ""}
                    </Cell>
                    <Cell as="td" alignment="right">
                      {item?.deliveryDays !== ""
                        ? `${formatNumber(item.deliveryDays)} days`
                        : ""}
                    </Cell>
                  </tr>
                );
              })}
            <tr>
              <Cell as="td" alignment="left">
                Grand Total
              </Cell>
              <Cell as="td" alignment="right">
                {grandTotal ? formatNumber(grandTotal) : ""}
              </Cell>
              <Cell as="td" alignment="right"></Cell>
            </tr>
          </tbody>
        </TableStyle>
      </TableWrap>
    </>
  );
};

export default FulfillmentRate;
