import React, { createContext } from 'react';
import PropTypes from 'prop-types';

const RoleContext = createContext();

export const useRoleContext = () => React.useContext(RoleContext);

export const RoleProvider = ({ role, children }) => (
  <RoleContext.Provider value={{ role }}>{children}</RoleContext.Provider>
);

RoleProvider.propTypes = {
  role: PropTypes.any,
  children: PropTypes.any,
};
