import React, { useEffect, useState } from "react";
import Card from "../Card";
import CardInnerWithTime from "../CardInnerWithTime";
import AuthorizedArea from "../AuthorizedArea";
import {
  TimeIntervals,
  buildApiInternalUrl,
  monthsInShort,
} from "../../Utils/utils";
import Select from "../Select";
import AutoSizer from "react-virtualized-auto-sizer";
import TooltipItem from "../TooltipItem";
import { formatNumber } from "../../Utils/utils";
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalBarSeries,
  Crosshair,
} from "react-vis";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import "../../styles/AzureCost.css";

const changeDateFormat = (d) => {
  let date = new Date(d);
  let newDate = ` ${monthsInShort[date.getMonth()]} ${date.getFullYear()}`;
  return newDate;
};

const InnerComponent = ({ data }) => {
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);
  const [crosshairValues, setCrosshairValues] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [selectedProject, setSelectedProject] = useState("All Agencies");
  let chartArr = [];
  let yDomainTopValue, maxValue;

  useEffect(() => {
    let projectData;
    if (selectedProject === "All Agencies") {
      projectData = data?.data?.filter((item) => item.agencyName === "Total");
    } else {
      projectData = data?.data?.filter(
        (item) => item.agencyName === selectedProject
      );
    }

    for (let [key, value] of Object.entries(projectData[0])) {
      if (key !== "agencyName" && key !== "total") {
        let newObj = {
          x: data?.headers[key],
          y: value,
          color: "#005c95",
        };
        chartArr.push(newObj);
      }
      setChartData(chartArr);
    }
  }, [selectedProject]);

  maxValue = chartData?.length > 0 && Math.max(...chartData?.map((d) => d.y));
  yDomainTopValue = [0, 1.3 * maxValue];

  return (
    <Card className="AzureCost">
      <CardInnerWithTime
        loading={!data}
        title={`Azure Cost History by Agency`}
        refreshedTime={data?.timeStamp ?? ""}
        extraIcons={[
          {
            text: `Azure Cloud Costs by Agency including Commercial and Government tenants.`,
            icon: faInfoCircle,
            style: {
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              textAlign: "justify",
            },
          },
        ]}
        mainComponent={() => (
          <div>
            <Select
              onChange={(e) => {
                setSelectedProject(e.target.value);
              }}
              defaultValue={selectedProject}
              aria-label="Select Item to View"
            >
              <option>All Agencies</option>
              {data?.dropDown
                ?.filter((item) => item !== "Total")
                .map((x, i) => (
                  <option key={i} value={x}>
                    {x}
                  </option>
                ))}
            </Select>

            <AutoSizer disableHeight>
              {({ width }) => (
                <XYPlot
                  width={width}
                  height={200}
                  xType="ordinal"
                  colorType="literal"
                  stackBy="y"
                  margin={{
                    left: 70,
                  }}
                  yDomain={yDomainTopValue}
                  onMouseLeave={() => {
                    setCrosshairValues(false);
                    setHint(false);
                    setAnimation(true);
                  }}
                >
                  <HorizontalGridLines />
                  <XAxis
                    tickLabelAngle={-45}
                    tickFormat={(d) => changeDateFormat(d)}
                  />
                  <YAxis
                    title="Total Cost ($)"
                    tickFormat={(d) => `$${formatNumber(d / 1000)}K`}
                  />

                  <VerticalBarSeries
                    data={chartData}
                    barWidth={0.5}
                    animation={animation}
                    onValueMouseOver={(datapoint) => {
                      setAnimation(false);
                      setHint(datapoint);
                      setCrosshairValues([datapoint]);
                    }}
                    onValueMouseOut={() => {
                      setHint(false);
                      setAnimation(true);
                    }}
                  />

                  {crosshairValues && (
                    <Crosshair values={crosshairValues}>
                      <div className="ShowHint">
                        <TooltipItem
                          fill="#005c95"
                          text={`${changeDateFormat(hint.x)}: ${formatNumber(
                            hint.y,
                            true
                          )}`}
                        />
                      </div>
                    </Crosshair>
                  )}
                </XYPlot>
              )}
            </AutoSizer>
          </div>
        )}
      />
    </Card>
  );
};

const AzureCost = () => (
  <AuthorizedArea
    url={buildApiInternalUrl("is-costhistory")}
    refreshInterval={TimeIntervals.ONE_DAY}
    innerComponent={({ data, error }) => {
      if (data === undefined) {
        return <></>;
      }
      if (!data && !error && !error?.status) {
        return <></>;
      }
      if (data?.renderCard !== undefined && !data.renderCard) {
        return <></>;
      }
      return <InnerComponent data={data} />;
    }}
  />
);

export default AzureCost;
