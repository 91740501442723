import React from "react";
import useSWR from "swr";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import Card from "../Card";
import CardInnerWithTimeAndFooter from "../CardInnerWithTimeAndFooter";
import FormatPercent from "../FormatPercent";
import { fetcher, TimeIntervals } from "../../Utils/utils";
import { H1, H4 } from "../Headings";

const FirstCallResolution = () => {
  const { data } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/first-call-resolution.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_MINUTE,
    }
  );
  const checkDate = () => {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return day >= 9 && month >= 7 && year >= 2024 ? true : false;
    /** This hard coding should not be changed because we are checking for 9th July 2024**/
  };
  return (
    <>
      {data?.renderCard && (
        <Card>
          <CardInnerWithTimeAndFooter
            loading={!data}
            title="First Call Resolution"
            refreshedTime={data?.reported || null}
            extraIcons={[
              {
                text: `First Call Resolution showcases how often we can solve your problem during the first interaction. `,
                icon: faInfoCircle,
              },
            ]}
            mainComponent={() => (
              <>
                {" "}
                <H1 as="span">
                  {data?.fcrPercentage > 0 ? (
                    <FormatPercent value={data?.fcrPercentage} />
                  ) : (
                    <H4>No data to display</H4>
                  )}
                </H1>
                <div style={{ fontSize: "0.82rem" }}>Running Daily Average</div>
              </>
            )}
            footerLeftComponent={() => (
              <div style={{ fontSize: "0.82rem" }}>
                Past 30 days{" "}
                <FormatPercent value={data?.fcR30DaysPercentage || null} />
              </div>
            )}
            footerRightComponent={() => (
              <div style={{ fontSize: "0.82rem" }}>
                YTD{" "}
                {checkDate() ? (
                  <FormatPercent value={data?.yearPercentage || null} />
                ) : (
                  "No data"
                )}
              </div>
            )}
          />
        </Card>
      )}
    </>
  );
};

export default FirstCallResolution;
