import React from "react";
import { AuthenticationState } from "react-aad-msal";
import { CheckPrivateViewRole } from "../../../Utils/Roles";
import CashReporting from "./CashReporting";
import AccountsPayable from "./AccountsPayable";
import AccountsReceivable from "./AccountsReceivable";
import { downloadPdfDocument } from "../../GenericPdfDownloader";
import { Link, useLocation } from "react-router-dom";
import Button from "../../Button";
import {
  faUndo
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../../../styles/Common.css";

const Finance = ({ authenticationState, role }) => {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  return (
    <>
      {authenticationState === AuthenticationState.Authenticated &&
        CheckPrivateViewRole(role) && (
          <>
            <Child pdfVersion={query.get("pdfVersion")} />
          </>
        )}
    </>
  );
};
function Child({ pdfVersion }) {
  return (
    <div>
      {!pdfVersion && (
        <Link to="/finance?pdfVersion=true">Format as PDF</Link>
      )}
      {pdfVersion && (
        <>
          <Button
            as="a"
            color="blue"
            size="small"
            onClick={() => downloadPdfDocument("financeId", "OMES Weekly Finance Report")}
            rel="noopener noreferrer"
          >
            Download PDF
          </Button>{" "}
          <Link to="/finance"><span>
          <FontAwesomeIcon icon={faUndo} fixedWidth size="lg" className="Dark-Theme"/>
        </span></Link>
        </>
      )}
      <div id="financeId">
        <CashReporting pdfVersion={pdfVersion} />
        <AccountsPayable />
        <AccountsReceivable pdfVersion={pdfVersion} />
      </div>
    </div>
  );
}

export default Finance;
