import React, { useState } from "react";
import { faClock } from "@fortawesome/pro-duotone-svg-icons";
import { Hint, RadialChart } from "react-vis";
import AutoSizer from "react-virtualized-auto-sizer";
import styled from "@emotion/styled";
import { H3 } from "../Headings";
import CardTopItems from "../CardTopItems";
import Legend from "../BaseCharts/Legend";
import { TimeIntervals, fetcher } from "../../Utils/utils";
import Card from "../Card";
import TooltipItem from "../TooltipItem";
import useSWR from "swr";

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

// eslint-disable-next-line react/prop-types
const PieChart = ({ title, data, updatedDateTime }) => {
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);
  return (
    <Card>
      <CardTopItems
        items={[
          {
            text: `As of ${new Date(updatedDateTime).toLocaleString()}`,
            icon: faClock,
          },
        ]}
      />
      <H3>{title}</H3>
      <Wrap>
        <div>
          <AutoSizer disableHeight>
            {({ width }) => (
              <RadialChart
                colorType="literal"
                innerRadius={0}
                radius={100}
                data={data}
                animation={animation}
                onValueMouseOver={(datapoint) => {
                  setAnimation(false);
                  setHint(datapoint);
                }}
                onValueMouseOut={() => {
                  setHint(false);
                  setAnimation(true);
                }}
                width={width}
                height={200}
              >
                {hint && (
                  <Hint value={hint}>
                    <div
                      className="rv-hint__content"
                      style={{ pointerEvents: "none" }}
                    >
                      <TooltipItem
                        fill={hint.color}
                        text={`${hint.text}: ${hint.value}`}
                      />
                    </div>
                  </Hint>
                )}
              </RadialChart>
            )}
          </AutoSizer>
        </div>
        <Legend>
          {/* eslint-disable-next-line react/prop-types */}
          {data.map((x, i) => (
            <TooltipItem
              key={i}
              fill={x.color}
              text={`${x.text}: ${x.value}`}
            />
          ))}
        </Legend>
      </Wrap>
    </Card>
  );
};

const getColor = (label) => {
  switch (label) {
    case "Closed":
      return "#669b41";
    case "In Progress":
      return "#de9027";
    case "On Hold":
      return "#d15420";
    case "Resolved":
      return "#187bc0";
    case "Canceled":
      return "#464646";
    default:
      return "#1ca6df";
  }
};

const Defense = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/DefenseMetricsData.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.THIRTY_MINUTES,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );

  if (!data) {
    return <></>;
  }
  if (!data && !error && !error?.status) {
    return <></>;
  }
  if (data?.RenderCard !== undefined && !data.RenderCard) {
    return <></>;
  }
  let isRender = data?.RenderCard;
  var helper = {};
  var result = data?.NintyDayRolling.reduce(function (r, o) {
    var key = o.Label;
    if (!helper[key]) {
      helper[key] = Object.assign({}, o); // create a copy of o
      r.push(helper[key]);
    } else {
      helper[key].Count += o.Count; 
    }

    return r;
  }, []);
  const actualData = result?.map((x) => ({
    angle: x.Count,
    value: x.Count,
    text: x.Label,
    color: getColor(x.Label),
  }));

  return (
    <>
      {isRender && (
        <PieChart
          title="Defense"
          data={actualData}
          updatedDateTime={data?.Reported}
        />
      )}
    </>
  );
};

export default Defense;
