import React from "react";
import useSWR from "swr";
import { TimeIntervals, fetcher } from "../../../../../Utils/utils";
import CommonTabs from "./CommonTabs";
import "../../../../../styles/ProjectsReviewed.css";

const Under10M = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/rac-cap-under10M.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  if (!data && !error && !error?.status) {
    return <></>;
  }

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }

  return (
    <CommonTabs
      data={data?.statusUnder10M}
      timeStamp={data?.timeStamp}
      title={`CAP Projects less than $10M`}
    />
  );
};

export default Under10M;
