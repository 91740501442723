import React, { useState } from "react";
import styled from "@emotion/styled";
import CardInnerWithTime from "../../CardInnerWithTime";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import TooltipItem from "../../TooltipItem";
import Legend from "../../BaseCharts/Legend";
import "../../../styles/LegislativePublicAffairs.css";
import "../../../styles/Common.css";
import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalBarSeries,
  Crosshair,
} from "react-vis";
import { monthsList } from "../../../Utils/utils";

const LegendWrap = styled.div`
  margin-top: -1rem;
  div {
    display: flex;
    flex-direction: column;
  }
`;

const GovDelivery = ({ data }) => {
  const [hint, setHint] = useState(false);
  const [animation, setAnimation] = useState(true);
  const [crosshairValues, setCrosshairValues] = useState([]);

  let govInternallyArray = [];
  let govExternallyArray = [];
  let internalDataSet = [];
  let externalDataSet = [];

  if (data) {
    data.visits
      .sort((a, b) => {
        if (a.year !== b.year) return a.year - b.year;
        return monthsList.indexOf(a.month) - monthsList.indexOf(b.month);
      })
      .forEach((item) => {
        govInternallyArray.push({
          x: `${item.month} ${item.year}`,
          y: item.govDeliveryInternally,
          color: "#0066a6",
        });
        govExternallyArray.push({
          x: `${item.month} ${item.year}`,
          y: item.govDeliveryExternally,
          color: "#ff8c00",
        });
      });
  }

  if (govInternallyArray.length > 12 && govExternallyArray.length > 12) {
    internalDataSet = [...govInternallyArray].reverse();
    internalDataSet.length = 12;
    internalDataSet = internalDataSet.reverse();

    externalDataSet = [...govExternallyArray].reverse();
    externalDataSet.length = 12;
    externalDataSet = externalDataSet.reverse();
  } else {
    internalDataSet = [...govInternallyArray];
    externalDataSet = [...govExternallyArray];
  }

  let combinedArray = [...govInternallyArray, ...govExternallyArray];

  let sortedArray = combinedArray.sort((a, b) => b.y - a.y);
  let highestValue = sortedArray[0]?.y * 1.2;

  return (
    <div className="GovDelivery">
      <CardInnerWithTime
        loading={!data}
        title="govDelivery: Monthly Unique Visitors"
        subtitle="(% of Newsletter Recipients)"
        refreshedTime={data?.timeStamp ?? ""}
        extraIcons={[
          {
            text: `The govDelivery newsletters are sent
out via email to both OMES and non-
OMES recipients on an as-needed basis
for mass communication.`,
            icon: faInfoCircle,
            style: {
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              textAlign: "justify",
            },
          },
        ]}
        mainComponent={() => (
          <>
            <FlexibleWidthXYPlot
              height={200}
              xType="ordinal"
              yDomain={[0, 1]}
              onMouseLeave={() => {
                setCrosshairValues(false);
              }}
            >
              <HorizontalGridLines />
              {/* <XAxis tickLabelAngle={-45} tickPadding={20} /> */}
              <XAxis tickPadding={20} />
              <YAxis tickFormat={(d) => `${d * 100}%`} />
              <VerticalBarSeries
                barWidth={0.5}
                color="#0066a6"
                data={internalDataSet}
                animation={animation}
                onValueMouseOver={(datapoint) => {
                  setAnimation(false);
                  setHint(datapoint);
                  setCrosshairValues([datapoint]);
                }}
                onValueMouseOut={() => {
                  setHint(false);
                  setAnimation(true);
                }}
              />
              <VerticalBarSeries
                barWidth={0.5}
                color="#ff8c00"
                data={externalDataSet}
                animation={animation}
                onValueMouseOver={(datapoint) => {
                  setAnimation(false);
                  setHint(datapoint);
                  setCrosshairValues([datapoint]);
                }}
                onValueMouseOut={() => {
                  setHint(false);
                  setAnimation(true);
                }}
              />

              {crosshairValues && (
                <Crosshair values={crosshairValues}>
                  <div className="ShowHint">
                    <TooltipItem
                      fill="#0066a6"
                      text={`${hint.x}: ${(
                        internalDataSet.filter((item) => item.x === hint.x)?.[0]
                          ?.y * 100
                      ).toFixed(2)}%`}
                    />
                    <TooltipItem
                      fill="#ff8c00"
                      text={`${hint.x}: ${(
                        externalDataSet.filter((item) => item.x === hint.x)?.[0]
                          ?.y * 100
                      ).toFixed(2)}%`}
                    />
                  </div>
                </Crosshair>
              )}
            </FlexibleWidthXYPlot>

            <LegendWrap>
              <Legend>
                <TooltipItem
                  fill="#0066a6"
                  text="% of OMES recipients who click on the newsletter"
                />
                <TooltipItem
                  fill="#ff8c00"
                  text="% of non-OMES recipients who click on the newsletter"
                />
              </Legend>
            </LegendWrap>
          </>
        )}
      />
    </div>
  );
};

export default GovDelivery;
