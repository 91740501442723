import React, { useEffect, useState } from "react";
import Card from "../../../Card";
import CardInnerWithTime from "../../../CardInnerWithTime";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { H1, H2 } from "../../../Headings";
import TooltipItem from "../../../TooltipItem";
import "../../../../styles/CashReporting.css";
import "../../../../styles/Common.css";
import {
  Crosshair,
  XYPlot,
  HorizontalGridLines,
  XAxis,
  YAxis,
  VerticalBarSeries,
} from "react-vis";
import AutoSizer from "react-virtualized-auto-sizer";
import styled from "@emotion/styled";

const LegendWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 3rem;
`;

const InnerComponent = ({ data }) => {
  const [hint, setHint] = useState(false);
  const [crosshairValues, setCrosshairValues] = useState([]);
  const [formatted90ByYear, setFormatted90ByYear] = useState({});
  const [formattedOtherByYear, setFormattedOtherByYear] = useState({});
  const [yDomainTopValue, setYDomainTopValue] = useState(0);

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (data) {
      setFormatted90ByYear(
        data?.dayCompleted90ByYear?.filter(
          (item) => item.year >= currentYear - 4
        )
      );
      setFormattedOtherByYear(
        data?.otherByYear?.filter((item) => item.year >= currentYear - 4)
      );
    }
    const max90Day = Math.max(
      ...data?.dayCompleted90ByYear.map((o) => o.totalPercent)
    );

    const maxOtherDay = Math.max(
      ...data?.otherByYear.map((o) => o.totalPercent)
    );

    setYDomainTopValue([0, 1.4 * Math.max(max90Day, maxOtherDay)]);
  }, [data]);

  return (
    <>
      <Card className="CashReporting">
        <CardInnerWithTime
          loading={!data}
          title="Legal Claims Brought on Behalf of the State of Oklahoma"
          subtitle={`Cumulative Percentage from ${
            currentYear - 4
          } to Current Year`}
          refreshedTime={data?.runTimeStamp ?? ""}
          extraIcons={[
            {
              text: `Data represents claims brought pursuant
to the Oklahoma Governmental Tort Claims Act.`,
              icon: faInfoCircle,
              style: {
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
                textAlign: "justify",
              },
            },
          ]}
          mainComponent={() => (
            <>
              <div className="TopHeader Margin-Top-2rem">
                <div className="HeadCounts">
                  <H1 style={{ color: "#72b57a" }} as="span">
                    {data?.dayCompletion90.toFixed(1)}%
                  </H1>
                  <H2 as="h5">90 Day Completion</H2>
                </div>
                <div className="HeadCounts">
                  <H1 style={{ color: "#d89f55" }} as="span">
                    {Math.round(data?.averageDays)}
                  </H1>
                  <H2 as="h5">Average Days to Complete a Claim</H2>
                </div>
                <div className="HeadCounts">
                  <H1 style={{ color: "#7eb5d7" }} as="span">
                    {data?.other?.toFixed(1)}%
                  </H1>
                  <H2 as="h5">{data?.otherText}</H2>
                </div>
              </div>
              <div className="Horizontal-Divider"></div>

              <H2 as="h3">Annualized Claims Close Rates</H2>
              <span className="Day-Subtitle">
                Percent of Claims Completed within 90 Days
              </span>

              <div className="LiabilityChart">
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <XYPlot
                      xType="ordinal"
                      colorType="literal"
                      width={width}
                      height={200}
                      yDomain={yDomainTopValue}
                      margin={{
                        left: 70,
                      }}
                      onMouseLeave={() => {
                        setHint(false);
                        setCrosshairValues(false);
                      }}
                    >
                      <HorizontalGridLines />
                      <XAxis
                        style={{ fontSize: "11px", wordBreak: "break-all" }}
                      />
                      <YAxis
                        title="Percentage of Completed Claims"
                        tickFormat={(d) => d + "%"}
                      />
                      <VerticalBarSeries
                        data={formatted90ByYear
                          ?.sort((a, b) => a.year - b.year)
                          .map((item) => ({
                            x: item.year,
                            y: item.totalPercent,
                            color: "#72b57a",
                          }))}
                        barWidth={0.5}
                        onValueMouseOver={(datapoint) => {
                          setHint(datapoint);
                          setCrosshairValues([datapoint]);
                        }}
                      />

                      <VerticalBarSeries
                        data={formattedOtherByYear
                          ?.sort((a, b) => a.year - b.year)
                          .map((item) => ({
                            x: item.year,
                            y: item.totalPercent,
                            color: "#7eb5d7",
                          }))}
                        barWidth={0.5}
                        onValueMouseOver={(datapoint) => {
                          setHint(datapoint);
                          setCrosshairValues([datapoint]);
                        }}
                      />

                      {crosshairValues && (
                        <Crosshair values={crosshairValues}>
                          <div className="ShowHint">
                            <h3>{hint.x}</h3>
                            <TooltipItem
                              fill="#72b57a"
                              text={`Completed within 90 days : ${formatted90ByYear
                                .filter((item) => item.year === hint.x)?.[0]
                                ?.totalPercent?.toFixed(1)}%`}
                            />

                            <TooltipItem
                              fill="#7eb5d7"
                              text={`${
                                data?.otherText +
                                ": " +
                                formattedOtherByYear
                                  .filter((item) => item.year === hint.x)?.[0]
                                  ?.totalPercent?.toFixed(1)
                              }%`}
                            />
                          </div>
                        </Crosshair>
                      )}
                    </XYPlot>
                  )}
                </AutoSizer>
              </div>
              <LegendWrap>
                <TooltipItem fill="#72b57a" text="Completed within 90 days" />
                <TooltipItem fill="#7eb5d7" text={data?.otherText} />
              </LegendWrap>
            </>
          )}
        />
      </Card>
    </>
  );
};

const LiabilityClaimPercent = ({ data }) => {
  if (!data) return <></>;

  if (data?.renderCard !== undefined && !data.renderCard) {
    return <></>;
  }
  return <InnerComponent data={data} />;
};

export default LiabilityClaimPercent;
