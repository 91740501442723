import React from 'react';
import { TEXTS } from '../Utils/staticText';

const About = () => (
  <>
    <h1>Welcome to the PIG!</h1>
    <p>
      At the beginning of the COVID-19 pandemic in 2020, service desk calls were
      on the rise, teleworking became the new standard and the state’s virtual
      private network capacity rose from 2,500 to 25,000 in a matter of days.
    </p>
    <p>
      {TEXTS.STATE_TITLE_EXPANDED} {TEXTS.NAME} saw the need to give customers
      a look into what services OMES was providing and
      continuously updated progress reports.
    </p>
    <p>
      This is where the Performance Improvement Graph, PIG for short, was born.
      It started as a dashboard to show service desk call volume, and now
      features a variety of metrics for various projects and services.
    </p>
    <p>
      The PIG dashboard is constantly being updated with information to give
      customers the most accurate data available. This is to provide
      transparency and a look into the status of the everyday services provided
      by OMES and how it affects state agencies. Continue to check back to
      see the updated metrics.
    </p>
  </>
);

export default About;
