import React from "react";
import useSWR from "swr";
import { fetcher, TimeIntervals } from "../../../Utils/utils";
import ColumnSection from "../../../Components/ColumnSection";
import {
  Tabs,
  TabList,
  TabPanelsEnhanced,
  TabPanel,
  TabEnhanced,
} from "../../../Components/Tabs";
import DataTab from "./DataTab";
import "../../../styles/OfferedTraining.css";

const OfferedTraining = () => {
  const { data } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/LDOfferings.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
    }
  );

  let uniqueQuarters = [
    ...new Set(data?.ldOfferings?.map((item) => item.fiscalQuarter)),
  ];

  let recentQuarter1 = data?.ldOfferings?.filter(
    (item) => item.fiscalQuarter === uniqueQuarters[0]
  );
  let recentQuarter2 = data?.ldOfferings?.filter(
    (item) => item.fiscalQuarter === uniqueQuarters[1]
  );
  let recentQuarter3 = data?.ldOfferings?.filter(
    (item) => item.fiscalQuarter === uniqueQuarters[2]
  );

  let recentQuarter4 = data?.ldOfferings?.filter(
    (item) => item.fiscalQuarter === uniqueQuarters[3]
  );

  let year1 = recentQuarter1?.[0]?.fiscalYear;
  let quarter1 = recentQuarter1?.[0]?.fiscalQuarter;

  let year2 = recentQuarter2?.[0]?.fiscalYear;
  let quarter2 = recentQuarter2?.[0]?.fiscalQuarter;

  let year3 = recentQuarter3?.[0]?.fiscalYear;
  let quarter3 = recentQuarter3?.[0]?.fiscalQuarter;

  let year4 =
    recentQuarter4?.length > 0 ? recentQuarter4?.[0]?.fiscalYear : false;
  let quarter4 =
    recentQuarter4?.length > 0 ? recentQuarter4?.[0]?.fiscalQuarter : false;
  return (
    <>
      {data?.renderCard && (
        <>
          <span className="Heading">Learning & Development</span>
          <ColumnSection cols={2}>
            <Tabs>
              <TabList>
                {year1 && quarter1 && (
                  <TabEnhanced className="SmallTabs">{`Q${quarter1} FY${year1}`}</TabEnhanced>
                )}
                {year2 && quarter2 && (
                  <TabEnhanced className="SmallTabs">{`Q${quarter2} FY${year2}`}</TabEnhanced>
                )}
                {year3 && quarter3 && (
                  <TabEnhanced className="SmallTabs">{`Q${quarter3} FY${year3}`}</TabEnhanced>
                )}
                {year4 && quarter4 && (
                  <TabEnhanced className="SmallTabs">{`Q${quarter4} FY${year4}`}</TabEnhanced>
                )}
              </TabList>
              <TabPanelsEnhanced>
                <TabPanel>
                  <DataTab
                    data={data}
                    quarterData={recentQuarter1}
                    quarter={quarter1}
                  />
                </TabPanel>
                <TabPanel>
                  <DataTab
                    data={data}
                    quarterData={recentQuarter2}
                    quarter={quarter2}
                  />
                </TabPanel>
                <TabPanel>
                  <DataTab
                    data={data}
                    quarterData={recentQuarter3}
                    quarter={quarter3}
                  />
                </TabPanel>
                {year4 && quarter4 && (
                  <TabPanel>
                    <DataTab
                      data={data}
                      quarterData={recentQuarter4}
                      quarter={quarter4}
                    />
                  </TabPanel>
                )}
              </TabPanelsEnhanced>
            </Tabs>
          </ColumnSection>
        </>
      )}
    </>
  );
};

export default OfferedTraining;
