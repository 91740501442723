import React, { useState } from "react";
import Select from "react-select";
import CountPieChart from "./CountPieChart";
import ColumnSection from "../../ColumnSection";
import AmountPieChart from "./AmountPieChart";
import "../../../styles/CAPProjects.css";

const CAPPieCharts = ({ data }) => {
  const [selectedProject, setSelectedProject] = useState("All Project Types");

  const getColor = (label) => {
    switch (label) {
      case "Close-Out Phase":
        return "#669b41";
      case "Monitor & Control Phase":
        return "#de9027";
      case "Execution Phase":
        return "#1ca6df";
    }
  };

  const defaultCountData = data.allActiveProjects.map((item) => ({
    angle: item.Count,
    value: item.Count,
    color: getColor(item.ProjectStatus),
    text: item.ProjectStatus,
  }));

  const defaultAmountData = data.allActiveProjects.map((item) => ({
    angle: item.ProjectAmount,
    value: item.ProjectAmount,
    color: getColor(item.ProjectStatus),
    text: item.ProjectStatus,
  }));

  const allProjectsCountData = data.activeProjectsByType
    .filter((item) => item.ProjectType === selectedProject)
    .map((item) => ({
      angle: item.Count,
      value: item.Count,
      color: getColor(item.ProjectStatus),
      text: item.ProjectStatus,
    }));

  const allProjectsAmountData = data.activeProjectsByType
    .filter((item) => item.ProjectType === selectedProject)
    .map((item) => ({
      angle: item.ProjectAmount,
      value: item.ProjectAmount,
      color: getColor(item.ProjectStatus),
      text: item.ProjectStatus,
    }));

  const firstElement = {
    value: "All Project Types",
    label: "All Project Types",
  };

  const filteredList = data?.allProjectType
    ?.filter((item) => item.ProjectType !== "")
    .map((item) => ({
      value: item.ProjectType,
      label: item.ProjectType,
    }));

  const list = [firstElement].concat(filteredList);

  return (
    <>
      <Select
        className="SelectProjectType"
        options={list}
        onChange={(e) => {
          setSelectedProject(e.value);
        }}
        defaultValue={list[0]}
      />

      <ColumnSection cols={2}>
        <CountPieChart
          subtitle={selectedProject}
          timeStamp={data?.timeStamp}
          data={
            selectedProject === "" || selectedProject === "All Project Types"
              ? defaultCountData
              : allProjectsCountData
          }
        />
        <AmountPieChart
          subtitle={selectedProject}
          timeStamp={data?.timeStamp}
          data={
            selectedProject === "" || selectedProject === "All Project Types"
              ? defaultAmountData
              : allProjectsAmountData
          }
        />
      </ColumnSection>
    </>
  );
};

export default CAPPieCharts;
