import React from "react";
import useSWR from "swr";
import { fetcher, TimeIntervals } from "../../Utils/utils";
import "../../styles/Common.css";

const InnerComponent = ({ data, bannerFlag }) => {
  const outageBanner =  data?.bannerDetails?.filter((x) => x.pageName === bannerFlag)[0]?.bannerText;
  return (
    <>
      {outageBanner && 
        // <div className="Banner-Style">{outageBanner}</div> 
        <div className="Banner-Style" dangerouslySetInnerHTML={{ __html: outageBanner}} />
}
    </>
  );
};

const Banner = ({ bannerFlag }) => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/Outage_Banner.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.THIRTY_MINUTES,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );
  if (!data && !error && !error?.status) {
    return <></>;
  }
  return <InnerComponent data={data} bannerFlag={bannerFlag} />;
};

export default Banner;
