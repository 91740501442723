import React from "react";
import { fetcher, TimeIntervals } from "../../Utils/utils";
import ColumnSection from "../ColumnSection";
import {
  Tabs,
  TabList,
  TabEnhanced,
  TabPanelsEnhanced,
  TabPanel,
} from "../Tabs";
import CPInnerBodySection from "./CPInnerBodySection";
import useSWR from "swr";

const InnerComponent = ({
  role,
  dataNonIT,
  dataIT,
  dataStateUse,
}) => {
  return (
    <>
      <ColumnSection cols={1}>
        <Tabs>
          <TabList>
            <TabEnhanced>NON-IT</TabEnhanced>
            <TabEnhanced>IT</TabEnhanced>
            <TabEnhanced>STATE USE</TabEnhanced>
          </TabList>
          <TabPanelsEnhanced>
            <TabPanel>
              <CPInnerBodySection
                role={role}
                sheetData={dataNonIT}
                h2Title="NON-IT"
              ></CPInnerBodySection>
            </TabPanel>
            <TabPanel>
              <CPInnerBodySection
                role={role}
                sheetData={dataIT}
                h2Title="IT"
              ></CPInnerBodySection>
            </TabPanel>
            <TabPanel>
              <CPInnerBodySection
                role={role}
                sheetData={dataStateUse}
                h2Title="STATE USE"
              ></CPInnerBodySection>
            </TabPanel>
          </TabPanelsEnhanced>
        </Tabs>
      </ColumnSection>
    </>
  );
};

const CentralPurchasingTabs = ({ role }) => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_PUBLIC_STORE}/cp-dollar-awards.json`,
    fetcher,
    {
      refreshInterval: TimeIntervals.ONE_DAY,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  ); 
  if (!data && !error && !error?.status) {
    return <></>;
  }
  if(data?.renderCard !== undefined && !data.renderCard)
  {
    return <></>;
  }

  if (error?.status === 401) {
    return <></>;
  }
  var nonIT = data?.filter((temp) => temp.name.startsWith("NON-IT"));
  var IT = data?.filter((temp) => temp.name.startsWith("IT"));
  var stateUse = data?.filter((temp) => temp.name.startsWith("STATE USE"));

  return (
    <InnerComponent
      role={role}
      dataNonIT={nonIT}
      dataIT={IT}
      dataStateUse={stateUse}
    />
  );
};

export default CentralPurchasingTabs;
